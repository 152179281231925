import React, {useState}from "react";
import { convertToCurrency, dateFormatter } from "../../utils";
import btc from "../../assets/img/sports/btc.svg";
import close from "../../assets/img/sports/close.svg";

export const BetsTable = () => {
  const [selectedRow, setSelectedRow] = useState(null);

  const data = [
    {
      event: "Lakers",
      id: "4123412343123",
      time: "02-24-2024 23:43:22",
      odds: "2.0",
      amount: "0.000343 ",
    },
    {
      event: "Lakers",
      id: "4123412343123",
      time: "02-24-2024 23:43:22",
      odds: "2.0",
      amount: "0.000343 ",
    },
    {
      event: "Lakers",
      id: "4123412343123",
      time: "02-24-2024 23:43:22",
      odds: "2.0",
      amount: "0.000343 ",
    },
    {
      event: "Lakers",
      id: "4123412343123",
      time: "02-24-2024 23:43:22",
      odds: "2.0",
      amount: "0.000343 ",
    },
    {
      event: "Lakers",
      id: "4123412343123",
      time: "02-24-2024 23:43:22",
      odds: "2.0",
      amount: "0.000343 ",
    },
  ];

  const openModal = (row) => {
    setSelectedRow(row);
  };

  const closeModal = () => {
    setSelectedRow(null);
  };

  return (
    <>
    <div className="bg-[#1A1A1B] px-6 py-4 mt-4 rounded-lg w-full">
        {data && data.length === 0 ? (
          <div>
            <h1 className="no-data-available text-center text-white">Oops! There is no data yet!</h1>
          </div>
        ) : (
          <table className="w-full lgmed:min-w-[800px]">
            <thead>
              <tr className="h-[56px] border-b border-[#3A3A3A]">
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Game/Event
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Bet ID
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Timestamp
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Odds
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Bet Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row, key) => (
                <tr key={key} className="h-[56px]" onClick={() => openModal(row)}>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.event}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.id}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.time}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.odds}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    <div className="flex">
                    {row.amount}&nbsp;<img src={btc}/>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>

    {selectedRow && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-[#1A1A1B] p-8 rounded-lg w-1/4">
            <div className="flex justify-between items-center border-b border-[#fff] pb-4">
              <h2 className="text-[16px] text-white font-arabicbold">My Bet</h2>
              <img src={close} className="cursor-pointer" alt="close" onClick={closeModal}/>
            </div>
            <div className="text-center">
              <h1 className="text-[#fff] font-arabicbold text-[12px] mt-4">Sports</h1>
              <h1 className="text-[#fff] font-arabicbold text-[12px]">{selectedRow.id}</h1>
              <p className="text-[#3C403F] font-arabicreg text-[12px]">{selectedRow.time}</p>
            </div>
            <div className="bg-[#2E2E2E] p-4 rounded-lg my-4">
              <div className="flex justify-start">
                <h1 className="text-[#fff] font-arabicbold text-[12px]">Detroit Pistons</h1>
                <h1 className="text-[#fff] font-arabicbold text-[12px]">&nbsp;-&nbsp;</h1>
                <h1 className="text-[#fff] font-arabicbold text-[12px]">Los Angeles Clippers</h1>
              </div>
              <h1 className="text-[#fff] font-arabicreg text-[12px] mt-4">Winner (Incl. Overtime)</h1>
              <div className="flex justify-between">
                <h1 className="text-[#fff] font-arabicbold text-[12px]">Detroit Pistons</h1>
                <h1 className="text-[#6FCF17] font-arabicbold text-[12px]">1.84</h1>
              </div>
              <div className="flex justify-between mt-8">
                <h1 className="text-[#fff] font-arabicreg text-[12px]">Stake</h1>
                <h1 className="text-[#fff] font-arabicreg text-[12px] flex">0.000205950&nbsp;<img src={btc}/></h1>
              </div>
              <div className="flex justify-between">
                <h1 className="text-[#fff] font-arabicreg text-[12px]">Est. Payout</h1>
                <h1 className="text-[#fff] font-arabicreg text-[12px] flex">0.00379916&nbsp;<img src={btc}/></h1>
              </div>
            </div>
            
          </div>
        </div>
      )}
    </>
  );
};