import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import home from "../../assets/img/sports/home.svg";
import basketball from "../../assets/img/sports/basketball.svg";
import soccer from "../../assets/img/sports/soccer.svg";
import football from "../../assets/img/sports/football.svg";
import volleyball from "../../assets/img/sports/volleyball.svg";
import baseball from "../../assets/img/sports/baseball.svg";
import golf from "../../assets/img/sports/golf.svg";
import cricket from "../../assets/img/sports/cricket.svg";
import hockey from "../../assets/img/sports/hockey.svg";
import boxing from "../../assets/img/sports/boxing.svg";
import formula from "../../assets/img/sports/formula.svg";
import horse from "../../assets/img/sports/horse.svg";
import virtual from "../../assets/img/sports/virtual.svg";
import HomeSports from "./HomeSports";
import LobbySports from "./LobbySports";
import {
  fetchSportsBetGroups,
} from "../../queries/gaming";

const sportImages = [
  "basketball",
  "soccer",
  "football",
  "volleyball",
  "baseball",
  "golf",
  "cricket",
  "hockey",
  "boxing",
  "formula",
  "horse",
  "virtual"
];


const getImageForSport = (sport) => {
  try {
    const imageName = sport.toLowerCase();
    const matchingSport = sportImages.find(sport => imageName.includes(sport));
    if (matchingSport) {
      return require(`../../assets/img/sports/${matchingSport}.svg`);
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};

export const Lobby = () => {
  const [activeSport, setActiveSport] = useState(1);
  const handleClick = (index) => {
    setActiveSport(index);
  }; 
  const [group, setGroup] = useState([]);
  const fetchGroups = async () => {
    try {
      const { data } = await fetchSportsBetGroups();
      setGroup(data);
    } catch (error) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    fetchGroups();
  }, [])

  // useEffect(() => {
  //   fetchSportsCategory();
  // }, [activeSport])

  return (
    <>
      <div className="font-arabicmed w-full">
        <div className="w-full overflow-x-auto">
          <div className="flex justify-between gap-2 my-2 whitespace-nowrap">
            {/* <button
              className={`py-3 px-4 bg-[#252525] hover:bg-gradient-to-br hover:from-[#9BE402] hover:to-[#1FA83C] border border-[#3C403F] rounded-lg ${
                activeSport === 0
                  ? "bg-gradient-to-br from-[#9BE402] to-[#1FA83C]"
                  : ""
              }`}
              onClick={() => handleClick(0)}
            >
              <img src={home} className="mx-auto w-6 h-6" alt="Home" />
              <p className="text-[#fff] text-[14px] pt-1">Home</p>
            </button> */}
            {group.map((sport, index) => (
              <button
                key={index}
                className={`py-3 px-4 bg-[#252525] hover:bg-gradient-to-br hover:from-[#9BE402] hover:to-[#1FA83C] border border-[#3C403F] rounded-lg ${
                  activeSport === index + 1
                    ? "bg-gradient-to-br from-[#9BE402] to-[#1FA83C]"
                    : ""
                }`}
                onClick={() => handleClick(index + 1)}
              >
                <img
                  src={getImageForSport(sport)}
                  className="mx-auto w-6 h-6"
                  alt={sport}
                />
                <p className="text-[#fff] text-[14px] pt-1">{sport}</p>
              </button>
            ))}
          </div>
        </div>
        
        {activeSport &&
          <LobbySports sportName={group[activeSport-1]} /> 
        }
      </div>
    </>
  );
};