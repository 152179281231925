import React, { useEffect, useRef, useState } from "react";
import "../assets/css/wallet/index.css";

// images
import banner13 from "../assets/img/banner/banner13.png";
import arrowDown from "../assets/img/popup/arrow-down.svg";
import dollar from "../assets/img/popup/dollar.svg";
import moonPay from "../assets/img/popup/moon-pay.svg";
import tusdt from "../assets/img/popup/t-usdt.svg";
import close from "../assets/img/settings/svg/close.svg";
import { CurrencyCoins } from "../utils/staking/CurrencyCoins";

// components
import copy from "copy-to-clipboard";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { privateRequest } from "../config/axios.config";
import {
  usePayEplayment,
  usePayWithExternalWallet,
  usePaymentStatus,
} from "../queries/balance";
import { useGetAllWalletAddresses } from "../queries/wallet";
import { CopyCutter } from "../utils/modules/CopyCustter";
import { CryptoCoins } from "../utils/staking/CryptoCoins";
import ButtonLoader from "./ButtonLoader";
import CircleTimer from "./CircleTimer";
import AcceptedCard from "./common/AcceptedCard";
import { WalletOptions } from "./common/WalletOptions";
import { DepositeCurrencyDropdown } from "./popups/DepositeCurrencyDropdown";
import DepositCryptoCurrencyDropdown from "./wallet/DepositCryptoCurrencyDropdown";

function getCoinName(coin) {
  switch (coin) {
    case "BTC":
      return "bitcoin";
    case "ETH":
      return "ethereum";
    case "USDT":
      return "tether";
    case "BNB":
      return "bnb";
    case "TRX":
      return "Tron";
    case "USDC":
      return "usd-coin";
    default:
      return coin;
  }
}

export const DepositBinanceLeft = () => {
  const ref = useRef(null);
  const [optionDeposite, setoptionDeposite] = useState(2);
  const [wallet, setWallet] = useState();
  const refText = useRef(null);
  const navigate = useNavigate();
  const copyCutter = new CopyCutter();
  // Add state to store the amount in USD
  const [usdAmount, setUsdAmount] = useState("");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [calculatedValue, setCalculatedValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("PHP");
  const [selectedCrypto, setSelectedCrypto] = useState();

  const payEplaymentMutation = usePayEplayment();
  const paywithExternalWallet = usePayWithExternalWallet();
  const [paycheck, setPaycheck] = useState(true);
  const [loadingGcash, setLoadingGcash] = useState(false);
  const [loadingEplayment, setLoadingEplayment] = useState(false);
  const [showGcashButton, setShowGcashButton] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState("fiat");
  const [externalPaymentData, setExternalPaymentData] = useState();
  const [showExternalPaymentMethod, setShowExternalPaymentMethod] =
    useState(false);
  const [paymentAddress, setPaymentAddress] = useState("");
  const [qrCodeValue, setQRCodeValue] = useState("");
  const [qrCodeAmount, setQRCodeAmount] = useState("");
  const [copyAddress, setCopyAddress] = useState("Copy");
  const [copyAmount, setCopyAmount] = useState("Copy");
  const [paymentReceived, setPaymentReceived] = useState(false);
  const [redirectCounter, setRedirectCounter] = useState(5);
  const { data: walletAddresses, isLoading: isLoadingWalletAddresses } = useGetAllWalletAddresses();
  const [filteredAddresses, setFilteredAddresses] = useState([]);

  // When a user selects a currency, filter the addresses for that currency
  const handleCurrencyChange = (selectedCurrency) => {
    setSelectedCurrency(selectedCurrency);
    if (walletAddresses?.data[selectedCurrency]) {
      setFilteredAddresses(walletAddresses?.data[selectedCurrency]);
      // console.log("walletAddresses.data[selectedCurrency]", walletAddresses?.data[selectedCurrency]);

      setWallet(walletAddresses?.data[selectedCurrency][0])
    } else {
      setFilteredAddresses([]);
    }
  };

  const handleAmountChange = (event) => {
    const amountInUSD = event.target.value;
    setUsdAmount(amountInUSD);

    // Calculate the value based on the exchange rate
    const value = amountInUSD * exchangeRate;
    if (value > 50000) {
      setShowGcashButton(false);
    } else {
      setShowGcashButton(true);
    }
    setCalculatedValue(value);
  };

  useEffect(() => {
    const fetchExchangeRate = async (currency) => {
      try {
        // Modify this URL based on how your API expects to receive the currency parameter
        const url = `/user/balance/get-rate?currency=${currency}`;
        const response = await privateRequest.get(url);
        setExchangeRate(response.data.rate); // Assuming 'rate' is returned in the response
        const value = usdAmount * response.data.rate; // Recalculate the value
        setCalculatedValue(value);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };

    fetchExchangeRate(selectedCurrency);
  }, [selectedCurrency]);

  const payEplayment = async (currency, provider, eplaymentOption) => {
    try {
      setLoading(true);
      if (eplaymentOption === "gcash") {
        setLoadingGcash(true);
      } else if (eplaymentOption === "select") {
        setLoadingEplayment(true);
      }
      const amountNumber = Number(usdAmount);
      if (amountNumber < 10) {
        toast.error(`The minimum amount is 50 USD`);
        setLoading(false);
        return;
      }
      const data = await payEplaymentMutation.mutateAsync({
        amount: usdAmount,
        currency,
        eplaymentOption: eplaymentOption ? eplaymentOption : undefined,
        provider,
      });
      // Redirect to redUrl on success
      window.location.href = data.redUrl;
      setTimeout(() => {
        window.location.href = data.redUrl; // Redirect to redUrl on success
      }, 2000); // 2000 milliseconds = 2 seconds
      setLoadingGcash(false);
      setLoadingEplayment(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message ?? "Something went wrong");
      setLoadingGcash(false);
      setLoadingEplayment(false);
      console.error("Payment error:", error);
      // Handle error (e.g., show error message)
    }
  };

  const externalPayment = async (currency) => {
    try {
      setLoading(true);

      const amountNumber = Number(usdAmount);
      if (amountNumber < 10) {
        toast.error(`The minimum amount is 10 USD`);
        setLoading(false);
        return;
      }

      const data = await paywithExternalWallet.mutateAsync({
        amount: usdAmount,
        currency: "usd",
        identifier: "cashwallet_deposit",
        name: "Deposit",
        description: "Deposit to Cashwallet",
      });
      //  setShowExternalPaymentMethod(true);

      //  setExternalPaymentData(data);

      window.location.href = data.coinbaseCharge.hosted_url;
      setTimeout(() => {
        window.location.href = data.coinbaseCharge.hosted_url; // Redirect to redUrl on success
      }, 2000);

      setLoading(false);
      setSelectedCrypto("bitcoin");
      setLoadingGcash(false);
      setLoadingEplayment(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message ?? "Something went wrong");
      setLoadingGcash(false);
      setLoadingEplayment(false);
      // Handle error (e.g., show error message)
    }
  };

  const charges = usePaymentStatus(externalPaymentData?.coinbaseCharge?.id);

  useEffect(() => {
    // Check the payment status and update the state
    if (charges?.charge === "COMPLETED") {
      setPaymentReceived(true);
      setShowExternalPaymentMethod(false);
      setPaymentMethod("");
    } else {
      setPaymentReceived(false);
    }
  }, [charges]);

  useEffect(() => {
    if (paymentReceived) {
      // Countdown logic
      const countdownInterval = setInterval(() => {
        setRedirectCounter((prevCounter) => prevCounter - 1);
      }, 1000);
      // Redirect when the counter reaches 0
      if (redirectCounter === 0) {
        window.location.href = "/home/wallet/deposite";
      }
      // Cleanup interval when component unmounts
      return () => clearInterval(countdownInterval);
    }
  }, [redirectCounter, paymentReceived]);

  // coins
  useEffect(() => {
    // Check if the necessary data is available in the response
    if (
      externalPaymentData &&
      externalPaymentData.coinbaseCharge &&
      externalPaymentData.coinbaseCharge.pricing
    ) {
      const { pricing, payments, addresses } =
        externalPaymentData.coinbaseCharge;
      const selectedCurrencyData = pricing[selectedCrypto.toLowerCase()];
      console.log(externalPaymentData);
      console.log(externalPaymentData.coinbaseCharge);
      if (selectedCurrencyData) {
        // Update the QR code value and amount based on the selected currency
        setQRCodeValue(selectedCurrencyData.amount);
        setQRCodeAmount(
          `${selectedCurrencyData.amount} ${selectedCurrencyData.currency}`
        );
        setPaymentAddress(addresses["ethereum"]);
        setShowExternalPaymentMethod(true);
      }
    }

    // check payment
  }, [selectedCrypto]);

  // const { data: wallets } = useGetWallet();
  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen bg-[#1A1A1B]">
        <div className="text-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-green-900 mx-auto"></div>
          <p className="text-white text-lg mt-3">
            Getting you to the payment processor page, please wait...
          </p>
        </div>
      </div>
    );
  }
  return (
    // <div className="body-left-area  mr-6" ref={ref}>
    <div className="body-left-area  mr-6 w-full">
      <ToastContainer theme="dark" autoClose={3000} />
      <div className="settings-area  rounded-lg">
        <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
          <h1>Wallet</h1>
          <img
            src={close}
            className="cursor-pointer"
            alt=""
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>

        <div className="settings-content-area flex items-start relative">
          <div className="settings-options-area flex flex-col p-3">
            <WalletOptions active={2} />
          </div>

          <div className="wallet-content-body-area w-full py-6 px-6 flex-1">
            <div className="popup DepositePopUp rounded-xl">
              <div className="buttons-wrapper pb-6 mb-6 flex justify-center gap-3">
                <button
                  className={`${optionDeposite === 1 && "active"} px-4`}
                  onClick={(e) => {
                    setoptionDeposite(1);
                  }}
                >
                  Crypto Wallet
                </button>
                <button
                  className={`${optionDeposite === 2 && "active"} px-4`}
                  onClick={(e) => {
                    setoptionDeposite(2);
                  }}
                >
                  Cash Wallet
                </button>
                {/* <button
                  className={`${optionDeposite === 4 && "active"} px-4`}
                  onClick={(e) => {
                    setoptionDeposite(4);
                  }}
                >
                  External Wallet
                </button> */}
              </div>
              {optionDeposite === 1 && (
                <div className="option-one">
                  {/* <div className="text-white mb-6">
                    <h4 className="text-xl mb-3 font-bold">
                      🛠 Exciting Updates on the Way!
                    </h4>
                    <p className="mb-3">
                      <span className="font-bold text-[#6FCF17]">
                        🔒 What's Happening?
                      </span>{" "}
                      <span className="text-[#FFF]">
                        We're currently syncing and enhancing the security of
                        our crypto wallets. Plus, we're upgrading our blockchain
                        protocols fo rbetter and faster performance.
                      </span>
                    </p>
                    <p>
                      <span className="font-bold text-[#6FCF17]">
                        ⌛️ Back Soon:
                      </span>{" "}
                      <span className="text-[#FFF]">
                        {" "}
                        Our services will resume shortly. We appreciate your
                        patience and understanding during this brief pause.
                      </span>
                    </p>
                  </div> */}
                  <div className="your-mall">
                    {/* <h1>Your Currency</h1> */}
                    <div className="">
                      <div className="flex items-center mt-3 mb-3">
                        {isLoadingWalletAddresses ? <ButtonLoader /> : <>
                          <DepositCryptoCurrencyDropdown
                            onCurrencyChange={handleCurrencyChange}
                            list={Object.keys(walletAddresses.data).map(currency => ({ label: currency, value: currency }))}
                          />
                        </>}


                        {/* {depositAddressData?.data.map((row) => (
                          <button
                            className={`flex items-center justify-center ${row.currency === wallet?.currency && "active"
                              }`}
                            onClick={() => {
                              setWallet(row);
                            }}
                          >
                            <img
                              src={`https://cryptologos.cc/logos/thumbs/${getCoinName(
                                row.currency.split("_")[0]
                              )}.png?v=030`}
                              alt=""
                              className="mr-2 h-5 w-5"
                            />

                            {row?.currency}
                          </button>
                        ))} */}

                      </div>
                      <div className="flex items-center mt-6 mb-3">
                        {isLoadingWalletAddresses && <p>Loading wallets...</p>}
                        {filteredAddresses.map((addressObj, index) => (
                          <button
                            key={index}
                            className={`flex items-center justify-center ${addressObj.address === wallet?.address && "active"}`}
                            onClick={() => setWallet(addressObj)}
                          >
                            <span>{`${addressObj.network.toUpperCase()}`}</span>
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                  {wallet?.address && (

                    <div className="qr-container p-5 mt-6 flex items-center">
                      <div className="qr-wrapper mr-6">
                        {wallet?.address && (
                          <QRCode
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "140px",
                            }}
                            value={wallet?.address}
                            imageSettings={{
                              src: "https://picsum.photos/id/67/300/300",
                              excavate: true
                            }}
                          />
                        )}
                      </div>
                      <div className="presentation flex-1">
                        <h1>Deposit Address</h1>
                        <div className="copy-area w-full mt-6 px-4 flex items-center">
                          <input
                            ref={refText}
                            type="text"
                            className="w-full flex-1 bg-transparent border-0 outline-none"
                            value={wallet?.address}
                          />

                          <button
                            onClick={() => {
                              if (!wallet?.address) return;
                              copy(wallet?.address);
                              toast.success("Deposit Address Copied");
                            }}
                          >
                            Copy Address
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* {optionDeposite === 2 && (
                <div className="option-two">
                  <div className="input-container mt-4">
                    <label htmlFor="">Deposit Currency</label>
                    <DepositeCurrencyDropdown
                      dollar={gcash}
                      heading="PHP"
                      list={[
                        {
                          heading: "PHP",
                          img: gcash,
                        },
                        {
                          heading: "PHP Testing",
                          img: gcash,
                        },
                      ]}
                    />
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="">Choose a Payment Method</label>
                    <div className="details-wrapper mt-3 px-3 py-3 rounded-lg">
                      <div className="input-container">
                        <label htmlFor="">Recommended</label>

                        <DepositeCurrencyDropdown
                          dollar={gcash}
                          heading="PHP"
                          list={[
                            {
                              heading: "PHP",
                              heading2: "15-450 PHP",
                              img: gcash,
                            },
                            {
                              heading: "PHP Testing",
                              heading2: "15-250 PHP",
                              img: gcash,
                            },
                          ]}
                        />
                      </div>

                      <div className="input-container mt-4">
                        <label htmlFor="">E-wallet</label>

                        <DepositeCurrencyDropdown
                          dollar={gcash}
                          heading="PHP"
                          list={[
                            {
                              heading: "PHP",
                              heading2: "15-450 PHP",
                              img: gcash,
                            },
                            {
                              heading: "PHP Testing",
                              heading2: "15-250 PHP",
                              img: gcash,
                            },
                          ]}
                        />

                        <DepositeCurrencyDropdown
                          dollar={gcash}
                          heading="PHP"
                          list={[
                            {
                              heading: "PHP",
                              heading2: "15-450 PHP",
                              img: gcash,
                            },
                            {
                              heading: "PHP Testing",
                              heading2: "15-250 PHP",
                              img: gcash,
                            },
                          ]}
                        />

                        <DepositeCurrencyDropdown
                          dollar={gcash}
                          heading="PHP"
                          list={[
                            {
                              heading: "PHP",
                              heading2: "15-450 PHP",
                              img: gcash,
                            },
                            {
                              heading: "PHP Testing",
                              heading2: "15-250 PHP",
                              img: gcash,
                            },
                          ]}
                        />
                      </div>

                      <button className="flex moon-pay-btn items-center justify-center mt-3 w-full ">
                        Show More
                      </button>

                      <div className="input-container mt-4">
                        <label htmlFor="">Bank transfer</label>

                        <DepositeCurrencyDropdown
                          dollar={gcash}
                          heading="PHP"
                          list={[
                            {
                              heading: "PHP",
                              heading2: "15-450 PHP",
                              img: gcash,
                            },
                            {
                              heading: "PHP Testing",
                              heading2: "15-250 PHP",
                              img: gcash,
                            },
                          ]}
                        />

                        <DepositeCurrencyDropdown
                          dollar={gcash}
                          heading="PHP"
                          list={[
                            {
                              heading: "PHP",
                              heading2: "15-450 PHP",
                              img: gcash,
                            },
                            {
                              heading: "PHP Testing",
                              heading2: "15-250 PHP",
                              img: gcash,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
              {optionDeposite === 2 && (
                <div className="option-two mt-4">
                  {/* Amount in USD input */}
                  <div className="mb-4">
                    <label
                      htmlFor="usdAmount"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Amount in USD
                    </label>
                    <input
                      id="usdAmount"
                      type="text"
                      value={usdAmount}
                      onChange={handleAmountChange}
                      placeholder="Enter amount in USD"
                      className="mt-1 block w-full px-3 py-2 text-white bg-gray-800 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="mt-8">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="usdAmount"
                        className="block text-sm font-medium text-gray-700 mb-2"
                      >
                        Select Payment Method
                      </label>
                    </div>
                    {/* <div className="relative">
                      <select
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6 sm:text-[14px]"
                        id=""
                        defaultValue={paymentMethod}
                      >
                        <option value="fiat">Fiat</option>
                        <option value="external_wallet">
                          External Crypto Wallet
                        </option>
                      </select>
                    </div> */}
                    <div className="relative flex gap-4">
                      <button
                        onClick={() => setPaymentMethod("fiat")}
                        className={`bg-[#111010] border-[1px] border-[#3C403F] h-[50px] rounded-lg outline-none text-white text-[18px] font-arabicmed px-6 sm:text-[14px] hover:bg-[#3C403F] ${paymentMethod === "fiat" ? "border-[#00FF00]" : "" // Highlight for selection
                          }`}
                      >
                        Fiat
                      </button>
                      <button
                        onClick={() => setPaymentMethod("external_wallet")}
                        className={`bg-[#111010] border-[1px] border-[#3C403F] h-[50px] rounded-lg outline-none text-white text-[18px] font-arabicmed px-6 sm:text-[14px] hover:bg-[#3C403F] ${paymentMethod === "external_wallet"
                          ? "border-[#00FF00]"
                          : "" // Highlight for selection
                          }`}
                      >
                        External Crypto Wallet
                      </button>
                    </div>
                  </div>

                  {paymentMethod === "fiat" && (
                    <>
                      <div className="input-container mt-4">
                        <label htmlFor=""> Currency</label>

                        <DepositeCurrencyDropdown
                          onCurrencyChange={(currency) => {
                            setSelectedCurrency(currency);
                            setPaycheck(currency === "PHP");
                          }}
                          list={CurrencyCoins}
                        />
                      </div>
                      {/* Display Calculated Value */}
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-white mt-2">
                          Total Amount
                        </label>
                        <p className="mt-1 text-white">
                          {calculatedValue.toFixed(2)} {selectedCurrency}
                        </p>
                      </div>
                      {/* We accept component */}
                      {/* {selectedCurrency === "PHP" ? (
                        <>
                          <BankTransfer Paycheck="true" />
                        </>
                      ) : ( */}
                      <>
                        <div className="mb-6">
                          <AcceptedCard />
                        </div>
                      </>
                      {/* )} */}

                      {/* {selectedCurrency === "PHP" ? (
                        <div className="flex space-x-4 !mt-12 mb-10 p-4 buttons-wrapper">
                          {showGcashButton && (
                            <button
                              className="active px-4 !text-lg hover:bg-blue-500 hover:text-white hover:scale-105 transition duration-200 ease-in-out sm:px-2"
                              onClick={() =>
                                payEplayment(
                                  selectedCurrency,
                                  "eplayment",
                                  "gcash"
                                )
                              }
                            >
                              Pay using GCash
                            </button>
                          )}
                          <button
                          className="active px-4 !text-lg hover:bg-blue-500 hover:text-white hover:scale-105 transition duration-200 ease-in-out sm:px-2"
                          onClick={() =>
                            payEplayment(
                              selectedCurrency,
                              "eplayment",
                              "select"
                            )
                          }
                        >
                          Pay via Eplayment
                        </button>
                        </div>
                      ) : ( */}
                      <div className="buttons-wrapper pb-10">
                        <button
                          className="active px-20 !text-lg transition duration-200 ease-in-out"
                          onClick={() =>
                            payEplayment(selectedCurrency, "moneycollect")
                          }
                        >
                          Pay Now
                        </button>
                      </div>
                      {/* )} */}
                    </>
                  )}

                  {paymentReceived && (
                    <>
                      <div className="text-center py-10 bg-green-200 rounded-md my-5">
                        <h4 className="text-green-800 text-3xl">
                          Payment Received Successfully
                        </h4>
                        <p className="py-3">
                          Redirect in {redirectCounter} Seconds
                        </p>
                      </div>
                    </>
                  )}
                  {showExternalPaymentMethod && (
                    <>
                      <div className="my-5">
                        <label
                          htmlFor="usdAmount"
                          className="block text-sm font-medium text-gray-700 mb-2"
                        >
                          Select Currency
                        </label>
                        <DepositeCurrencyDropdown
                          onCurrencyChange={(currency) => {
                            setSelectedCrypto(currency);
                          }}
                          list={CryptoCoins}
                        />
                      </div>
                      <div className="my-5">
                        <div className="info flex py-5 gap-5 justify-between">
                          <div className="left space-y-2">
                            <h3 className="text-white text-3xl">
                              Send payment
                            </h3>
                            <p className="text-white">
                              Open your crypto wallet and scan the QR code, or
                              copy the {selectedCrypto.toUpperCase()} address
                              below to make a payment.
                            </p>
                          </div>
                          <div>
                            <CircleTimer totalTimeInSeconds={1200} />
                          </div>
                        </div>
                        <div className="bg-white flex items-center justify-center w-40 rounded-lg">
                          <QRCode
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              padding: "10px",
                              background: "#FFFFF",
                            }}
                            title="Referral Link"
                            value={paymentAddress} // Use the dynamic QR code value
                            bgColor="#FFFFFF"
                            fgcolor="#6CE4BA"
                            size="350"
                            level="L"
                            viewBox={`0 0 256 256`}
                          />
                        </div>
                        <div className="flex justify-between my-5 items-center">
                          <div className="space-y-2">
                            <p className="text-lg text-white">
                              {selectedCrypto.toUpperCase()} Address
                            </p>
                            <h4 className="text-white text-xl">
                              {paymentAddress}
                            </h4>
                          </div>

                          <div>
                            <button
                              onClick={(e) => {
                                navigator.clipboard.writeText(paymentAddress);
                                setCopyAddress("Copied");
                              }}
                              className="text-[#49BC28] text-xl font-bold"
                            >
                              {copyAddress}
                            </button>
                          </div>
                        </div>
                        <div className="flex justify-between my-5">
                          <div>
                            <p className="text-lg text-white">Total amount</p>
                            <h4 className="text-white text-xl">
                              {qrCodeAmount}
                            </h4>
                          </div>
                          <div>
                            <button
                              onClick={(e) => {
                                navigator.clipboard.writeText(qrCodeAmount);
                                setCopyAmount("Copied");
                              }}
                              className="text-[#49BC28] text-xl font-bold"
                            >
                              {copyAmount}
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {paymentMethod === "external_wallet" &&
                    !showExternalPaymentMethod && (
                      <>
                        <div className="mb-3 mt-5 rounded-lg">
                          <h4 className="text-xl mb-5 text-white">We accept</h4>
                          <img
                            src={banner13}
                            className="w-3/4 md:full rounded-lg"
                            alt="Crypto Payments"
                          />
                        </div>
                        <div className="buttons-wrapper pb-10 mb-10 mt-7">
                          <button
                            className="active px-20 !text-lg transition duration-200 ease-in-out"
                            onClick={() => externalPayment(selectedCurrency)}
                          >
                            Pay Now
                          </button>
                        </div>
                      </>
                    )}
                </div>
              )}

              {optionDeposite === 3 && (
                <div className="option-three">
                  <div className="input-container mt-4">
                    <label htmlFor="">You pay with</label>
                    <div className="input-notify-wrapper rounded-lg mt-3 flex items-center px-4">
                      <p className="flex-1">30</p>

                      <div className="dropdown">
                        <div className="dropdown-head rounded flex px-3 py-2 items-center">
                          <img src={dollar} alt="" className="mr-2" />
                          <p>USD</p>
                          <img src={arrowDown} alt="" className="ml-3" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-container mt-4">
                    <label htmlFor="">You get</label>
                    <div className="input-notify-wrapper rounded-lg mt-3 flex items-center px-4">
                      <p className="flex-1">29.55</p>

                      <div className="dropdown">
                        <div className="dropdown-head rounded flex px-3 py-2 items-center">
                          <img src={tusdt} alt="" className="mr-2" />
                          <p>USDT</p>
                          <img src={arrowDown} alt="" className="ml-3" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="">Payment Details</label>
                    <div className="details-wrapper mt-3 px-3 py-6 rounded-lg">
                      <div className="detail-row flex pb-6 items-center justify-between">
                        <h1>Provider</h1>
                        <p>MoonPay</p>
                      </div>
                      <div className="detail-row flex pb-6 items-center justify-between">
                        <h1>Method</h1>
                        <p>Credit Card</p>
                      </div>
                      <div className="detail-row flex items-center justify-between">
                        <h1>Deposit to account</h1>
                        <p>Kiyskvotnyb</p>
                      </div>
                    </div>
                    <div className="details-wrapper mt-3 px-3 py-6 rounded-lg">
                      <div className="detail-row flex pb-6 items-center justify-between">
                        <h1>Total (including fee)</h1>
                        <p>30 USD</p>
                      </div>
                      <div className="detail-row flex  items-center justify-between">
                        <h1>You will get</h1>
                        <p className="yellow">29.55 USDT</p>
                      </div>
                    </div>
                  </div>

                  <div className="warning mt-5 flex items-center px-6 rounded-xl">
                    <p>
                      <span>Notice</span> : Depending on the blockchain, the
                      deposit may take a few minutes to 1 hour to arrive.
                    </p>
                  </div>

                  <div className="agreement flex items-center my-5">
                    <input type="checkbox" id="agreement-deposit" />
                    <label
                      htmlFor="agreement-deposit"
                      className="w-3 h-3 rounded flex items-center justify-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="#fff"
                        class="w-6 h-6"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </label>
                    <p className="ml-3">
                      I have read and agree to the disclaimer.
                    </p>
                  </div>

                  <button className="flex moon-pay-btn items-center justify-center w-full ">
                    <img src={moonPay} alt="" className="mr-2" />
                    Buy Via MoonPay
                  </button>
                </div>
              )}

              {optionDeposite === 4 && (
                <div className="option-two mt-4">
                  {/* Amount in USD input */}
                  <div className="mb-4">
                    <label
                      htmlFor="usdAmount"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Enter Amount USDT
                    </label>
                    <input
                      id="usdAmount"
                      type="text"
                      value={usdAmount}
                      onChange={handleAmountChange}
                      placeholder="Enter amount"
                      className="mt-1 block w-full px-3 py-2 text-white bg-gray-800 border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>

                  {/* We accept component */}
                  <div className="flex space-x-4 mt-2 ! pb-5 mb-15 buttons-wrapper">
                    <button
                      className="active px-4 !text-lg hover:bg-blue-500 hover:text-white hover:scale-105 transition duration-200 ease-in-out sm:px-2"
                      onClick={() => externalPayment(selectedCurrency)}
                    >
                      Deposit
                      {/* <img src={eplay} alt="" className="ml-3 h-5 w-5" /> */}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
