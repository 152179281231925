import React, { useEffect, useRef, useState } from "react";
import { Header } from "./Header";

import { WithdrawAmount } from "./WithdrawAmount";
export const RightAreaHomewithdraw = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const [notifyType, setnotifyType] = useState(1);
  const [typeShowAside, settypeShowAside] = useState(1);

  const refRight = useRef(null);

  const ResizeWork = (e) => {
    let ElementHeight = window.innerHeight;
    if (refRight?.current) {
      refRight.current.style.height = `${ElementHeight - 140}px`;
    }
  };
  useEffect(() => {
    ResizeWork();
    window.addEventListener("resize", ResizeWork);
  }, []);
  return (
    <div className="flex-1">
      <Header
        login={login}
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex items-start px-6 pt-6">
        <WithdrawAmount />
        {/* <div className="body-right-area flex-1 rounded-2xl p-4">
          <div className="scroll-area-start pt-4 pb-10" ref={refRight}>
            {typeShowAside === 1 && (
              <>
                <div className="top-area top-area-first-bar mb-2 pb-4 flex items-center relative">
                  <img src={chat} alt="" />
                  <h1 className="flex-1 ml-1">General Chat</h1>
                  <p className="mr-1 absolute">0</p>
                  <div className="flex items-center">
                    <img
                      src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2960&q=80"
                      alt="first user online"
                      className="w-5 h-5 rounded-full"
                    />
                    <img
                      src="https://images.unsplash.com/photo-1599566150163-29194dcaad36?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2787&q=80"
                      alt="second user online"
                      className="w-5 h-5 rounded-full"
                    />
                    <img
                      src="https://images.unsplash.com/photo-1527980965255-d3b416303d12?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2960&q=80"
                      alt="third user online"
                      className="w-5 h-5 rounded-full"
                    />
                  </div>
                </div>

                <div className="comment-area flex items-center px-4 h-14 rounded-bl-2xl rounded-br-2xl">
                  <input
                    type="text"
                    placeholder="Send a message..."
                    className="flex-1 w-full"
                  />
                  <div className="flex">
                    <img src={emoji} alt="emoji" className="mr-3" />
                    <img src={send} alt="send" />
                  </div>
                </div>
              </>
            )}
            {typeShowAside === 2 && (
              <>
                <div className="notify-top-custom mb-5">
                  <h1>Notification</h1>
                  <div className="buttons-wrapper grid grid-cols-2 mt-2 gap-1">
                    <button
                      className={`w-full ${notifyType === 1 && "active"}`}
                      onClick={(e) => {
                        setnotifyType(1);
                      }}
                    >
                      System Notice
                    </button>
                    <button
                      className={`w-full ${notifyType === 2 && "active"}`}
                      onClick={(e) => {
                        setnotifyType(2);
                      }}
                    >
                      Activities
                    </button>
                  </div>
                </div>

                {notifyType === 1 && (
                  <div className="notify-one">
                    <div className="notification-box p-2 mb-2 rounded-lg">
                      <div className="top flex items-center justify-between">
                        <h1>9/13/2023 ,12:03:10 AM</h1>
                        <span></span>
                      </div>
                      <h1 className="mt-2 mb-3">📣 Weekly Update 📣</h1>
                      <img src={notification} className="w-full mb-2" alt="" />
                      <p>
                        Discover what's new and exiting at Gamebox with our
                        weekly update
                      </p>
                      <button className="w-full mt-2 mb-4">Show all</button>
                      <div className="flex download-wrapper items-center justify-between">
                        <p>1997654321455667.pbf</p>
                        <img src={download} alt="" />
                      </div>
                    </div>

                    <div className="notification-box p-2 mb-2 rounded-lg">
                      <div className="top flex items-center justify-between">
                        <h1>9/13/2023 ,12:03:10 AM</h1>
                        <span></span>
                      </div>
                      <h1 className="mt-2 mb-3">🔧Trading Maintenance🔧</h1>

                      <p>
                        Discover what's new and exiting at Gamebox with our
                        weekly update
                      </p>
                      <button className="w-full mt-2 mb-4">Show all</button>
                    </div>
                    <div className="notification-box p-2 mb-2 rounded-lg">
                      <div className="top flex items-center justify-between">
                        <h1>9/13/2023 ,12:03:10 AM</h1>
                        <span></span>
                      </div>
                      <h1 className="mt-2 mb-3">📣 Weekly Update 📣</h1>
                      <img src={notification} className="w-full mb-2" alt="" />
                      <p>
                        Discover what's new and exiting at Gamebox with our
                        weekly update
                      </p>
                      <button className="w-full mt-2 mb-4">Show all</button>
                      <div className="flex download-wrapper items-center justify-between">
                        <p>1997654321455667.pbf</p>
                        <img src={download} alt="" />
                      </div>
                    </div>
                  </div>
                )}
                {notifyType === 2 && (
                  <div className="notify-two">
                    <div className="no-data flex flex-col items-center mb-5">
                      <img src={dinasour} alt="" />
                      <p className="no-data-picture">
                        Oops! There is no data yet!
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};
