import bch from "../../assets/img/crypto/btc-cash.png";
import btc from "../../assets/img/crypto/btc.png";
import eth from "../../assets/img/crypto/eth.png";
import ltc from "../../assets/img/crypto/lte.png";
import matic from "../../assets/img/crypto/matic.png";
import usdc from "../../assets/img/crypto/usd-coin.png";
import PWETH from "../../assets/img/crypto/wrapped-eth.png";
export const Coin = [
  {
    heading: "BTC",
    img: btc,
    crypto: "bitcoin"
  },
  {
    heading: "BCH",
    img: bch,
    crypto: "bitcoincash"
  },
  {
    heading: "ETH",
    img: eth,
    crypto: "ethereum"
  },
  {
    heading: "LTC",
    img: ltc,
    crypto: "litecoin"
  },
  {
    heading: "MATIC",
    img: matic,
    crypto: "polygon"
  },
  {
    heading: "USDC",
    img: usdc,
    crypto: "usdc"
  },
  {
    heading: "WETH",
    img: PWETH,
    crypto: "pweth"
  },
  // {
  //   heading: "USDT",
  //   img: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
  //   crypto: "etherium"
  // },
  // {
  //   heading: "BNB",
  //   img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
  //   crypto: "etherium"
  // },
  // {
  //   heading: "XRP",
  //   img: "https://s2.coinmarketcap.com/static/img/coins/64x64/52.png",
  //   crypto: "etherium"
  // },

  // {
  //   heading: "BUSD",
  //   img: "https://cryptologos.cc/logos/thumbs/binance-usd.png?v=025",
  //   crypto: "etherium"
  // },
  // {
  //   heading: "SOL",
  //   img: "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
  //   crypto: "etherium"
  // },
  // {
  //   heading: "ADA",
  //   img: "https://cryptologos.cc/logos/thumbs/cardano.png?v=025",
  //   crypto: "etherium"
  // },
  // {
  //   heading: "DOGE",
  //   img: "https://s2.coinmarketcap.com/static/img/coins/64x64/74.png",
  //   crypto: "etherium"
  // },
  // {
  //   heading: "TRX",
  //   img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png",
  //   crypto: "etherium"
  // },
];
