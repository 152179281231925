import { useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

export const useGetSubscription = () => useQuery("subscription", async () => {
  try {
    const res = await privateRequest.get('user/stacking/subscriptions')
    return res.data.subscriptions
  } catch (error) {
    console.log(error)
    throw new Error(error?.response?.data?.message ?? "Error getting subscription")
  }
});