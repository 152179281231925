import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import info from "../../assets/img/casino/circle-excl.svg";
import imoongame1 from "../../assets/img/imoonimage/crashroyale.jpg";
import imoongame2 from "../../assets/img/imoonimage/Crash3DX.jpg";
import imoongame3 from "../../assets/img/imoonimage/BloodBurst.jpg";
import imoongame4 from "../../assets/img/imoonimage/CrashGhostly.jpg";
import imoongame5 from "../../assets/img/imoonimage/CrashWitch.jpg";
import imoongame6 from "../../assets/img/imoonimage/Crash1917.jpg";
import imoongame7 from "../../assets/img/imoonimage/DragonFlare.jpg";
import imoongame8 from "../../assets/img/imoonimage/JogoDoBitcho.jpg";
import { useInfiniteGames, GetImoonGames } from "../../queries/gaming";
import ButtonLoader from "../ButtonLoader";
export const GameCategory = ({ filterCategory, title, types, type }) => {
  const navigate = useNavigate();
  // mobile filter
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setMobile(true);
    }
  }, []);

  const {
    data: imoonGames,
    isLoading: imoonGameLoading,
    error: imoonGameError,
  } = GetImoonGames();
  return (
    <>
      <div className="flex overflow-hidden flex-col w-full mb-8 no-scrollbar">
        <div className="flex  justify-between items-center mb-4">
          <h3 className="text-[24px] text-white font-medium">{title || ""}</h3>
          {type && (
            <>
              <button
                className="capitalize text-[#6FCF17] text-base font-medium"
                onClick={() => navigate("/browse-game/${type}")}
              >
                See all
              </button>
            </>
          )}
        </div>
        {!type && !types && (
          <>
            <div className="grid grid-cols-6 gap-3 overflow-visible no-scrollbar">
              {imoonGames ? (
                imoonGames.map((game, index) => (
                  <div
                    key={game._id}
                    onClick={() => navigate(`/game/${game._id}&game=original`)}
                    className="group rounded-xl overflow-hidden border border-[#3C403F] cursor-pointer lg:flex-col hover:shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out"
                  >
                    <img
                      src={
                        index === 0
                          ? imoongame1
                          : index === 1
                            ? imoongame2
                            : index === 2
                              ? imoongame3
                              : index === 3
                                ? imoongame4
                                : index === 4
                                  ? imoongame5
                                  : index === 5
                                    ? imoongame6
                                    : index === 6
                                      ? imoongame7
                                      : imoongame8
                      }
                      alt=""
                      className="h-58   w-full rounded-tr-xl rounded-tl-xl object-cover"
                    />
                    <div className="flex items-center justify-between p-3">
                      <h5 className="text-sm text-white">{game.title}</h5>
                    </div>
                  </div>
                ))
              ) : (
                <div class="text-neon-green">Loading...</div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
