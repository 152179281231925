import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useCashBackStatistics, useClaimCashback } from "../../queries/bonus";
import AmountHistoryModal from "./amountHistory";
import Tooltip from "../../components/common/Tooltip";

export default function CashbackCard({
  type,
  image,
  info,
  title,
  duration,
  cashbackData,
  tootipContent,
}) {
  const [showClaimButton, setShowClaimButton] = useState(false);
  const [disable, setDisable] = useState(false);
  const [timeUntilNextEvent, setTimeUntilNextEvent] = useState(0);
  const { data: cashBackStats } = useCashBackStatistics();
  const queryClient = useQueryClient();
  const claimCashbackBonus = useClaimCashback();
  const [showMonthlyClaimButton, setShowMonthlyClaimButton] = useState(false);
  const [showWeeklyClaimButton, setShowWeeklyClaimButton] = useState(false);
  const [showWeeklySportsClaimButton, setShowWeeklySportsClaimButton] =
    useState(false);
  const [showAmontHistory, setShowAmountHistory] = useState(false);
  const [cashBackType, setCashBackType] = useState("");

  // claimCashback
  const claimCashback = async () => {
    setDisable(true);

    try {
      await toast.promise(claimCashbackBonus.mutateAsync(cashBackType), {
        pending: `Claiming Cashback`,
        success: "Cashback Claimed",
        error: (error) => error.error,
      });

      setShowAmountHistory(false);
      queryClient.invalidateQueries(["unClaimedBonus"]);
    } catch (error) {
      setShowAmountHistory(false);
    } finally {
      setDisable(false);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentUTCDate = currentDate.getUTCDate();
    const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

    const unclaimedCashback = cashbackData?.totalUnclaimed ?? 0;

    if (type === "monthly_cashback") {
      console.log("unclaimedCashback mmm", unclaimedCashback);
      setShowMonthlyClaimButton(currentUTCDate === 25 && unclaimedCashback > 0);
      console.log("unclaimedCashback yyy", showMonthlyClaimButton);

      if (currentUTCDate !== 25 || unclaimedCashback === 0) {
        const twentyFifthThisMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          25
        );
        twentyFifthThisMonth.setHours(0, 0, 0, 0);
        const timeUntilTwentyFifth =
          twentyFifthThisMonth.getTime() - currentDate.getTime();
        setTimeUntilNextEvent(timeUntilTwentyFifth);
      }
    } else if (
      type === "weekly_cashback" ||
      type === "sports_weekly_cashback"
    ) {
      const isWeeklyCashback = type === "weekly_cashback";
      setShowWeeklyClaimButton(
        isWeeklyCashback && currentDay === 0 && unclaimedCashback > 0
      );
      setShowWeeklySportsClaimButton(
        !isWeeklyCashback && currentDay === 0 && unclaimedCashback > 0
      );

      if (currentDay !== 0 || unclaimedCashback === 0) {
        const nextSunday = new Date();
        nextSunday.setDate(currentDate.getDate() + ((7 - currentDay) % 7));
        nextSunday.setHours(0, 0, 0, 0);
        const timeUntilNextSunday =
          nextSunday.getTime() - currentDate.getTime();
        setTimeUntilNextEvent(timeUntilNextSunday);
      }
    }

    // Timer for showing countdown
    const intervalId = setInterval(() => {
      setTimeUntilNextEvent((prevTime) => {
        const newTime = Math.max(prevTime - 1000, 0);
        if (newTime <= 0) {
          clearInterval(intervalId);
          if (type === "monthly_cashback") {
            setShowMonthlyClaimButton(unclaimedCashback > 0);
          } else if (type === "weekly_cashback") {
            setShowWeeklyClaimButton(unclaimedCashback > 0);
          } else if (type === "sports_weekly_cashback") {
            setShowWeeklySportsClaimButton(unclaimedCashback > 0);
          }
        }
        return newTime;
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [type, cashbackData]);

  const renderTime = () => {
    const days = Math.floor(timeUntilNextEvent / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeUntilNextEvent % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeUntilNextEvent % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeUntilNextEvent % (1000 * 60)) / 1000);
    const formatTimePart = (timePart) => String(timePart).padStart(2, "0");
    return `${formatTimePart(days)}d ${formatTimePart(hours)}h ${formatTimePart(
      minutes
    )}m ${formatTimePart(seconds)}s`;
  };

  return (
    <>
      {showAmontHistory && (
        <>
          <AmountHistoryModal
            onClose={(e) => setShowAmountHistory(false)}
            amount={cashbackData?.totalUnclaimed}
            claimCashback={claimCashback}
            title={cashBackType}
          />
        </>
      )}

      <div className="p-2.5 w-1/3 sm:w-full md:min-w-1/3">
        <div className="flex-col relative flex-1 z-20 rounded-[20px]">
          <img
            src={image}
            alt="Card 1"
            className="-z-10 rounded-[20px] absolute w-full h-full object-cover object-center"
          />
          <div className="p-5 bg-transparent flex-col w-full">
            <div className="inline-flex items-center mb-1.5">
              <h4 className="font-medium text-[18px] text-white">{title}</h4>
              <div className="inline-flex ml-2 relative group">
                <img src={info} alt="Info" />
                <Tooltip message={tootipContent} classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center" />
              </div>
            </div>
            <p className="text-[10px] text-[#AEB2B1] font-normal">
              Every {duration}
            </p>
            <div className="inline-flex items-center mt-[18px] w-full">
              <p className="text-[10px] text-[#AEB2B1] font-normal w-[130px]">
                Wager Required
              </p>
              <p className="text-[10px] text-white font-bold">100 GBC</p>
            </div>
            <br />
            <div className="inline-flex items-center w-full">
              <p className="text-[10px] text-[#AEB2B1] font-normal w-[130px]">
                Last week received
              </p>
              <p className="text-[10px] text-white font-bold">
                {cashBackStats?.data?.monthly_cashback?.lastAmount ?? 0} GBC
              </p>
            </div>
            <div className="flex flex-col items-center gap-2 mt-8">
              {type === "monthly_cashback" && (
                <>
                  {showMonthlyClaimButton ? (
                    <button
                      className="py-1 px-5 bg-gradient-to-b from-[#F8D634] to-[#D08A1A] rounded-lg flex items-center justify-start text-white text-[14px] font-medium"
                      disabled={disable}
                      onClick={() => {
                        setShowAmountHistory(true);
                        setCashBackType("monthly_cashback");
                      }}
                    >
                      Claim Cashback
                    </button>
                  ) : (
                    <div className="w-full rounded-md bg-[#121212] h-[30px] flex items-center justify-center text-white text-[10px] font-normal">
                      End in &nbsp; {renderTime()}
                    </div>
                  )}
                </>
              )}

              {type === "weekly_cashback" && (
                <>
                  {showWeeklyClaimButton ? (
                    <button
                      className="py-1 px-5 bg-gradient-to-b from-[#F8D634] to-[#D08A1A] rounded-lg flex items-center justify-start text-white text-[14px] font-medium"
                      disabled={disable}
                      onClick={() => {
                        setShowAmountHistory(true);
                        setCashBackType("weekly_cashback");
                      }}
                    >
                      Claim Cashback
                    </button>
                  ) : (
                    <>
                      <div className="w-full rounded-md bg-[#121212] h-[30px] flex items-center justify-center text-white text-[10px] font-normal">
                        End in &nbsp; {renderTime()}
                      </div>
                    </>
                  )}
                </>
              )}

              {type === "sports_weekly_cashback" && (
                <>
                  {showWeeklySportsClaimButton ? (
                    <button
                      className="py-1 px-5 bg-gradient-to-b from-[#F8D634] to-[#D08A1A] rounded-lg flex items-center justify-start text-white text-[14px] font-medium"
                      onClick={() => setShowAmountHistory(true)}
                      disabled={disable}
                    >
                      Claim Cashback
                    </button>
                  ) : (
                    <div className="w-full rounded-md bg-[#121212] h-[30px] flex items-center justify-center text-white text-[10px] font-normal">
                      End in &nbsp; {renderTime()}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
