import React from "react";
import { PayDropdown } from "../popups/PayDropdown";
import tusdt from "../../assets/img/popup/t-usdt.svg";
export const CalculationBox = ({ usdtvalue, setusdtvalue }) => {
  return (
    <div className="bg-[#252525] px-6 h-[80px] lg:min-h-[80px] mx-[63px] flex items-center flex-1 justify-between rounded-xl lg:mx-[0] sm:!min-h-[50px]">
      <input
        className="text-[18px] font-arabicmed text-white bg-transparent w-full flex-1 mr-2 outline-none"
        placeholder="0.00"
        // value={usdtvalue}
        onChange={(e) => {
          setusdtvalue(e.target.value);
        }}
      />

      <PayDropdown
        stake
        dollar={tusdt}
        heading="USDT"
        list={[
          {
            heading: "USDT",
            img: tusdt,
          },
        ]}
      />
    </div>
  );
};
