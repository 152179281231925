import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../Header";
import { BrowseGame } from "./BrowseGame";
import { SingleBannerCard } from "./SingleBannerCard";
import { useGameStatistics } from "../../queries/bonus";
import casino from "../../assets/img/casino.svg";
import home from "../../assets/img/casino/home.svg";
import esport from "../../assets/img/esport.svg";
import racing from "../../assets/img/racing.svg";
import sports from "../../assets/img/sports-white.svg";
import dashboard from "../../assets/img/dashboard-icon.svg";
import { Aside } from "../Aside";
import LinkCarousel from "../affiliate/LinkCarousel";
import banner7 from "../../assets/img/banner/banner7.png";
import banner8 from "../../assets/img/banner/banner8.png";
import banner9 from "../../assets/img/banner/banner9.png";

const sidelist = [

  {
    heading: "Gaming Hub",
    img: dashboard,
    link: "/all-games",
  },
  {
    heading: "Casino",
    img: casino,
    link: "/casino",
  },
  {
    heading: "Sports",
    img: sports,
    link: "/sports",
  },
  {
    heading: "Bingo",
    img: esport,
    link: "/bingo",
  },
  {
    heading: "Racing",
    img: racing,
    link: "/racing",
  },
];

const AllGames = ({ type, types, login, setsigninState, setSignUpState }) => {
  const [typeShowAside, settypeShowAside] = useState(-1);
  const { data: gameStatistics } = useGameStatistics();
  const navigate = useNavigate();
  const [filterCategory, setFilterCategory] = useState("Casino");

  
  return (
    <div className="flex-1 overflow-hidden DepositePopUp">
      <Header
        login={login}
        headerType="player"
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />

      <div className="relative w-full flex flex-wrap p-6 overflow-x-auto no-scrollbar">
        <div className="flex w-full justify-between gap-3 mb-8 lg:flex-col">
            <div className="body-area flex-1 staker-dashboard">
              <LinkCarousel
                banners={[
                  { imageUrl: banner9, route: '/all-games' },
                  { imageUrl: banner8, route: '/casino' },
                  { imageUrl: banner7, route: '/sports' }
                ]}
              />
            </div>
          
          {/* <SingleBannerCard
            bannerImg={Banner1}
            amount={`2,500 GBC`}
            //amount={`$${parseFloat(gameStatistics?.bet).toFixed()}`}
            title="Bet & Win Challenge"
          />
          <SingleBannerCard
            bannerImg={Banner2}
            amount={`Bet & Win`}
            //amount={`$${parseFloat(gameStatistics?.bet).toFixed()}`}
            title="Challenge"
          />
          <SingleBannerCard
            bannerImg={Banner3}
            amount={`Exclusive`}
            //amount={`$${parseFloat(gameStatistics?.won).toFixed()}`}
            title="Release New Game"
          /> */}
        </div>

        {/**** GAME NAVS *****/}

        <div class="flex justify-center gap-6 overflow-x-auto whitespace-nowrap">
          {sidelist?.map((category) => (
            <button
              onClick={(e) => {
                setFilterCategory(category.heading);
                navigate(category.link);
              }}
              key={category.link}
              className={`inline-flex items-center py-3 px-6 my-2 rounded-md border border-[#3C403F] bg-[#252525] ${
                filterCategory === category.heading &&
                "bg-gradient-to-br from-[#9BE402] to-[#1FA83C]"
              }`}
            >
              <img
                src={category.img}
                alt=""
                className="text-white capitalize mr-3"
              />
              <span className="text-white capitalize ml-3">
                {" "}
                {category.heading}
              </span>
            </button>
          ))}
        </div>

        {/**** END GAME NAVS *****/}

        <BrowseGame type={type} />
      </div>
    </div>
  );
};

export default AllGames;
