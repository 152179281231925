import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import casino from "../../assets/img/casino.svg";
import Banner1 from "../../assets/img/casino/banner1.png";
import Banner2 from "../../assets/img/casino/banner2.png";
import Banner3 from "../../assets/img/casino/banner3.png";
import home from "../../assets/img/casino/home.svg";
import esport from "../../assets/img/esport.svg";
import racing from "../../assets/img/racing.svg";
import sports from "../../assets/img/sports-white.svg";
import basketball from "../../assets/img/sports/basketball.svg";
import virtual from "../../assets/img/virtual.svg";
import livegames from "../../assets/img/livegames.svg";
import rocket from "../../assets/img/rocket.svg";
import dashboard from "../../assets/img/dashboard-icon.svg";
import { useBonusBalance, useGameStatistics } from "../../queries/bonus";
import { AffiliateHeader } from "../AffiliateHeader";
import { SidebarArea } from "../SidebarArea";
import { BrowseGame } from "./BrowseGame";
import { GameCategory } from "./GameCategory";
import { SingleBannerCard } from "./SingleBannerCard";
import { Aside } from "../../components/Aside";
import LinkCarousel from "../affiliate/LinkCarousel";
import banner7 from "../../assets/img/banner/banner7.png";
import banner8 from "../../assets/img/banner/banner8.png";
import banner9 from "../../assets/img/banner/banner9.png";
import { Sports } from "../sports/Sports";

const sidelist = [
  {
    heading: "Gaming Hub",
    img: dashboard,
    link: "/all-games",
    category: "gaminghub",
  },
  {
    heading: "Live Casino",
    img: sports,
    link: "/live-casino",
    category: "Live Casino",
  },
  {
    heading: "Casino",
    img: casino,
    link: "/casino",
    category: "Casino",
  },
  {
    heading: "Virtual Sports",
    img: virtual,
    link: "/virtual-sports",
    category: "Virtual Sports",
  },
  {
    heading: "Live Games",
    img: livegames,
    link: "/live-games",
    category: "Live Games",
  },
  {
    heading: "Crash & Fast",
    img: rocket,
    link: "/crash-fast",
    category: "Crash & Fast",
  },
  {
    heading: "Sports",
    img: basketball,
    link: "/sports",
    category: "Sports",
  },
  // {
  //   heading: "Sportsbook",
  //   link: "/sportsbook",
  //   category: "Sportsbook",
  // },

  // {
  //   heading: "Racing",
  //   img: racing,
  //   link: "/racing",
  //   category: "racing",
  // },
];

export const GameIndex = ({
  setsigninState,
  setSignUpState,
  login,
  category,
  subcategory,
}) => {
  const navigate = useNavigate();
  const [filterCategory, setFilterCategory] = useState(category);
  const [heading, setHeading] = useState("");
  const [typeShowAside, settypeShowAside] = useState(-1);
  const { data: balances } = useBonusBalance();
  const { data: gameStatistics } = useGameStatistics();

  return (
    <div className="flex-1 overflow-hidden">
      <AffiliateHeader
        login={login}
        headerType="Affiliate"
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="relative w-full flex flex-wrap p-6 overflow-x-auto no-scrollbar">
        {category === "sportsbet" ? (
          <Sports />
        ) : (
          <>
            {filterCategory === "gaminghub" && (
              <div className="flex w-full justify-between gap-3 mb-8 lg:flex-col">
                <div className="body-area flex-1 staker-dashboard">
                  <LinkCarousel
                    banners={[
                      { imageUrl: banner9, route: "/all-games" },
                      { imageUrl: banner8, route: "/casino" },
                      { imageUrl: banner7, route: "/sports" },
                    ]}
                  />
                </div>

                {/* <SingleBannerCard
              bannerImg={Banner1}
              amount={`2,500 GBC`}
              //amount={`$${parseFloat(gameStatistics?.bet).toFixed()}`}
              title="Bet & Win Challenge"
            />
            <SingleBannerCard
              bannerImg={Banner2}
              amount={`Bet & Win`}
              //amount={`$${parseFloat(gameStatistics?.bet).toFixed()}`}
              title="Challenge"
            />
            <SingleBannerCard
              bannerImg={Banner3}
              amount={`Exclusive`}
              //amount={`$${parseFloat(gameStatistics?.won).toFixed()}`}
              title="Release New Game"
            /> */}
              </div>
            )}
            {filterCategory && (
              <div className="sm:w-full overflow-x-auto">
                <div class="flex gap-6 whitespace-nowrap">
                  {sidelist?.map((category) => (
                    <button
                      onClick={(e) => {
                        setFilterCategory(category.category);
                        setHeading(category.heading);
                        navigate(category.link);
                      }}
                      key={category.link}
                      className={`inline-flex items-center py-3 px-6 my-2 rounded-md border border-[#403c3c] bg-[#252525] ${
                        filterCategory === category.category &&
                        "bg-gradient-to-br from-[#9BE402] to-[#1FA83C]"
                      }`}
                    >
                      <img
                        src={category.img}
                        alt=""
                        className="text-white capitalize mr-3"
                      />
                      <span className="text-white capitalize ml-3">
                        {" "}
                        {category.heading}
                      </span>
                    </button>
                  ))}
                </div>
              </div>
            )}

            {/* {category === "gaminghub" ? (
              <>
                <BrowseGame
                  filterCategory={heading}
                  category="hot-games"
                  title="Hot Games"
                />
              </>
            ) : (
              <BrowseGame category={category} subcategory={subcategory} />
            )} */}

            {category === "gaminghub" ? (
              <BrowseGame
                filterCategory={heading}
                category="hot-games"
                title="Hot Games"
              />
            ) : (
                <BrowseGame category={category} subcategory={subcategory} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GameIndex;
