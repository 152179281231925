import React, { useState } from 'react';
import Chart from "react-apexcharts";
import { useQuery } from 'react-query';
import analysis from "../../assets/img/affiliate/analysis.svg";
import graph from "../../assets/img/affiliate/graph.svg";
import pie from "../../assets/img/affiliate/pie.svg";
import { privateRequest } from '../../config/axios.config';
import { charts } from "../../utils/modules/Charts";
import bar from "../../assets/img/sidebar/bar.png"
export default function RewardChart() {
    const [chartType, setChartType] = useState('bar'); // Default chart type is 'bar'

    const handleChartTypeChange = (newChartType) => {
        setChartType(newChartType);
    };

    // chart api data
    const { data } = useQuery("chartData", async () => {
        const res = await privateRequest.get("user/staking/total-reward-paid");
        return res?.data;
    });

    // Process data for ApexCharts
    const seriesData = [{
        name: 'Total Rewards',
        data: data?.map(item => item?.totalAmount),
    }];

    const categories = data?.map(item => `${item?._id?.year}-${item?._id?.month}`);

    const chartOptions = charts.affiliateChart()?.options;

    console.log(data);

    return (
        <>
            <div className="flex items-center justify-between px-8 sm:!px-5">
                <h1 className="text-[24px] sm:text-[20px]  font-arabicmed text-white">
                    Total Rewards Paid
                </h1>
                <div className="flex items-center gap-3">
                    <img className="cursor-pointer" src={bar} alt="" onClick={() => handleChartTypeChange('bar')} />
                    <img className="cursor-pointer" src={pie} alt="" onClick={() => handleChartTypeChange('pie')} />
                    <img className="cursor-pointer" src={analysis} alt="" onClick={() => handleChartTypeChange('area')} />
                </div>
            </div>

            <div className="chart-wrapper pl-3 pr-6 mt-8">
                <Chart
                    options={chartOptions}
                    series={seriesData}
                    type={chartType}
                    width="100%"
                    height={190}
                    categories={categories}
                />
            </div>
        </>
    );
}
