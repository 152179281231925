import React, { useState } from "react";
import logo from "../assets/img/dashboard/logo-footer.svg";
import facebook from "../assets/img/dashboard/facebook-footer.svg";
import twitter from "../assets/img/dashboard/landing-twitter.svg";
import ista from "../assets/img/dashboard/landing-ista.svg";
import sendflag from "../assets/img/dashboard/send-footer.svg";
import warning18 from "../assets/img/dashboard/18+.svg";
import responsible from "../assets/img/dashboard/responsible.svg";
import link from "../assets/img/dashboard/link.svg";
import f1 from "../assets/img/footer/f1.svg";
import f2 from "../assets/img/footer/f2.svg";
import f3 from "../assets/img/footer/f3.svg";
import f4 from "../assets/img/footer/f4.svg";
import f5 from "../assets/img/footer/f5.svg";
import f6 from "../assets/img/footer/f6.svg";
import f7 from "../assets/img/footer/f7.svg";
import f8 from "../assets/img/footer/f8.svg";
import f9 from "../assets/img/footer/f9.svg";
import f10 from "../assets/img/footer/f10.svg";
import f11 from "../assets/img/footer/f11.svg";
import b1 from "../assets/img/footer/b1.svg";
import b2 from "../assets/img/footer/b2.svg";
import b3 from "../assets/img/footer/b3.svg";
import b4 from "../assets/img/footer/b4.svg";
import b5 from "../assets/img/footer/b5.svg";
import b6 from "../assets/img/footer/b6.svg";
import b7 from "../assets/img/footer/b7.svg";
import b8 from "../assets/img/footer/b8.svg";
import close from "../assets/img/popup/close.svg";
import youtube from "../assets/img/footer/youtube.svg";
import member from "../assets/img/footer/member.svg";
import { Link } from "react-router-dom";

export const Footer = () => {
  const [lowFee, setLowFee] = useState(false);
  const [shortContent, setShortContent] = useState(null);

  return (
    <>
    {lowFee && (
      <div className="fixed welcome-ref-popup left-0 top-0 h-[100vh] z-[99999999999999999] w-full flex items-center justify-center">
        <div className="w-[766px] rounded-lg border-[#3C403F] border-[1px] bg-[#252525] lg:!w-full lg:!h-[100vh] lg:rounded-none">
          <div className="h-[80px] flex items-center justify-between px-8 rounded-tr-lg  rounded-tl-lg sm:p-4  border-b-[1px] border-b-[#3C403F]">
            <h1 className="text-[24px] sm:text-[20px] text-[#fff] font-arabicmed">
              Low Fees
            </h1>
            <img
              src={close}
              className="cursor-pointer"
              alt=""
              onClick={(e) => {
                setLowFee(false);
              }}
            />
          </div>
          <div className="grid grid-cols-3 p-8 sm:p-4 gap-10 lg:grid-cols-1">
            <div className="">
              <h1 className="text-[18px]  sm:text-[16px] font-medium text-white mb-4">
                Exchange
              </h1>
              <ul>
                <li className="flex items-center justify-between">
                  <p className="text-[#AEB2B1] text-[16px]  sm:text-[14px]">
                    Crypto to Fiat
                  </p>
                  <p className="text-[#AEB2B1] text-[16px]  sm:text-[14px]">
                    5%
                  </p>
                </li>
                <li className="flex items-center justify-between">
                  <p className="text-[#AEB2B1] text-[16px]  sm:text-[14px]">
                    CashWallet to Fiat
                  </p>
                  <p className="text-[#AEB2B1] text-[16px]  sm:text-[14px]">
                    2%
                  </p>
                </li>
              </ul>
            </div>
            <div className="">
              <h1 className="text-[18px] sm:text-[16px] font-medium text-white mb-4">
                Withdrawal
              </h1>
              <ul>
                <li className="flex items-center justify-between">
                  <p className="text-[#AEB2B1] text-[16px]  sm:text-[14px]">
                    CashWallet to Crypto
                  </p>
                  <p className="text-[#AEB2B1] text-[16px]  sm:text-[14px]">
                    2%
                  </p>
                </li>
                <li className="flex items-center justify-between">
                  <p className="text-[#AEB2B1] text-[16px]  sm:text-[14px]">
                    Bank Transfer
                  </p>
                  <p className="text-[#AEB2B1] text-[16px]  sm:text-[14px]">
                    5%
                  </p>
                </li>
              </ul>
            </div>
            <div className="">
              <h1 className="text-[18px] sm:text-[16px] font-medium text-white mb-4">
                Deposit
              </h1>
              <ul>
                <li className="flex items-center justify-between">
                  <p className="text-[#AEB2B1] text-[16px]  sm:text-[14px]">
                    Crypto
                  </p>
                  <p className="text-[#AEB2B1] text-[16px]  sm:text-[14px]">
                    Free
                  </p>
                </li>
                <li className="flex items-center justify-between">
                  <p className="text-[#AEB2B1] text-[16px]  sm:text-[14px]">
                    Fiat
                  </p>
                  <p className="text-[#AEB2B1] text-[16px]  sm:text-[14px]">
                    2%
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )}

    {shortContent && (
      <div className="fixed welcome-ref-popup left-0 top-0 h-[100vh] z-[99999999999999999] w-full flex items-center justify-center">
        <div className="w-[766px] rounded-lg border-[#3C403F] border-[1px] bg-[#252525] lg:!w-full lg:!h-[100vh] lg:rounded-none">
          <div className="h-[80px] flex items-center justify-between px-8 rounded-tr-lg  rounded-tl-lg sm:p-4  border-b-[1px] border-b-[#3C403F]">
            <h1 className="text-[24px] sm:text-[20px] text-[#fff] font-arabicmed">
              {shortContent.heading}
            </h1>
            {/* Close button */}
            <button
              onClick={() => setShortContent(null)}
              className="text-white focus:outline-none"
            >
              <img
                src={close}
                className="cursor-pointer"
                alt="Close"
              />
            </button>
          </div>
          <p className="p-8 text-[18px] sm:text-[14px] sm:p-4 font-medium text-white">
            {shortContent.para}
          </p>
        </div>
      </div>
    )}

    <footer
      className="LandingFooter mt-[60px] relative overflow-hidden"
      id="contact-footer"
    >
      <div className="common-width-landing">
        <div className="landing-footer-top flex items-center justify-between">
          <div className="col">
            <Link to="/">
              <img src={logo} alt="" className="logo" />
            </Link>

            <p className="mt-6 w-[845px] lg:w-full sm:!text-[14px]">
              GameBox extends an invitation to you to seize the limitless
              opportunities presented by the gaming industry. Whether you're a
              player, affiliate, staker, or gaming operator, our platform is
              your gateway to success. With our expert guidance, a wide array
              of product and service options, dedication to sustainable
              growth, and access to an influential network of industry
              leaders, your journey in the world of gaming starts right here.
              Join us today, and together, let's mold the future of gaming.
            </p>
          </div>

          <div className="col">
            <h1 className="mb-4">Contact us with</h1>
            <a
              href="mailto:support@gamebox88.net"
              className="flex contact-link items-center mb-4 text-[#51E6B2] sm:!text-[14px]"
            >
              <img src={link} alt="" className="mr-3" />
              support@gamebox88.net
            </a>
            <div className="flex items-center">
              <a
                href="https://www.facebook.com/gamebox888"
                className="mr-5"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="" />
              </a>

              <a
                href="https://www.instagram.com/gamebox888/"
                className="mr-5"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ista} alt="" />
              </a>
              <a
                href="https://twitter.com/GameBox462820"
                className="mr-5"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} alt="" />
              </a>
              <a
                href="https://t.me/+tk0KuKiXIoY3ZDlh"
                rel="noreferrer"
                target="_blank"
                className="mr-5"
              >
                <img src={sendflag} alt="" />
              </a>

              <a
                href="https://www.youtube.com/channel/UC1U3DSozpww426OeHOZTZSA"
                rel="noreferrer"
                target="_blank"
              >
                <img src={youtube} alt="" />
              </a>
            </div>
          </div>
        </div>

        <div className="landing-footer-grid-area flex justify-between gap-12 lg:!grid-cols-1">
          <ul className="min-w-[239px]">
            <li className="head mb-4">Products & Services</li>
            <li className="mb-3">
              <a
                href="#keyfeatures"
                className="sm:!text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  setShortContent({
                    heading: "Online & Land-based Casino",
                    para: "Experience the best of both worlds at GameBox888—an online gaming platform that seamlessly bridges the excitement of the virtual realm with the timeless charm of land-based casinos. Explore a vast array of thrilling games, from classic favorites to cutting-edge innovations, all designed to deliver top-tier entertainment. Whether you're a seasoned gamer or a newcomer, GameBox888 offers an immersive casino experience like no other. Join us today and discover the perfect blend of online convenience and the allure of land-based gaming. Your adventure awaits!",
                  });
                }}
              >
                Online & Land-based Casino
              </a>
            </li>
            <li className="mb-3">
              <a
                href="#vip_service"
                className="sm:!text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  setShortContent({
                    heading: "Vip Services",
                    para: "Experience gaming like never before with our VIP services at GameBox888. We go beyond the ordinary, offering an unparalleled blend of online and land-based luxury that transcends traditional gaming. As a VIP, you'll enjoy exclusive perks, personalized attention, and a gaming experience that goes above and beyond. Join us and elevate your gaming journey to a realm where it's more than just a game—it's an extraordinary adventure. Discover the pinnacle of gaming excellence with GameBox888 VIP services.",
                  });
                }}
              >
                VIP Services
              </a>
            </li>
            <li className="mb-3">
              <a
                href="#game-op"
                className="sm:!text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  setShortContent({
                    heading: "Game Aggregator",
                    para: "Elevating Gaming to the Next Level We take pride in being one of the most advanced gaming aggregators. We're all about creating opportunities—whether you're a player seeking excitement, an affiliate looking to maximize your earnings, a staker in pursuit of rewards, or a gaming operator aiming for success. Our platform is your gateway to a world of endless possibilities. Join us and experience gaming like never before, where opportunities are limitless, and adventure awaits at every turn. Where Every Moment is an Opportunity.",
                  });
                }}
              >
                Game Aggregator
              </a>
            </li>
            <li className="mb-3">
              <a
                href="#casino-game"
                className="sm:!text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  setShortContent({
                    heading: "Sportsbook Software",
                    para: "Redefining Sports Betting with Cutting-Edge AI We're proud to offer some of the most advanced sportsbook software in the industry. Our AI-powered platform takes sports betting to a whole new level, providing you with the widest range of options and the latest in technological innovation. Whether you're a seasoned bettor or just looking to have some fun, our sportsbook software ensures you have the best tools and insights at your fingertips. Join us and experience the future of sports betting with GameBox888's AI-driven software. Betting has never been this smart.",
                  });
                }}
              >
                Sportsbook Software
              </a>
            </li>
          </ul>

          <ul className="w-[179px]">
            <li className="head mb-4">Solutions</li>
            <li className="mb-3">
              <a
                href="#whitelabel-casino"
                className="sm:!text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  setShortContent({
                    heading: "Whitelabel Casino and. Sportsbook",
                    para: "GameBox888 offers a game-changing opportunity to launch your own branded casino and sportsbook with ease. Our white label solution comes packed with advanced features and is designed for effortless deployment. Customize your platform, and watch your brand thrive in the world of online gaming. Unleash the potential of your vision with GameBox888 and take the reins of your very own casino and sportsbook. Your brand, your rules, and limitless possibilities await..",
                  });
                }}
              >
                Whitelabel Casino and Sportsbook
              </a>
            </li>
            <li className="mb-3">
              <a
                href="#for-players"
                className="sm:!text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  setShortContent({
                    heading: "Gaming Development",
                    para: "Where Culture and Games Converge. We're on a mission to bring the world closer through gaming. Our development hub is dedicated to creating top-notch games inspired by the gaming culture and popular titles in every country. We bridge the gap between tradition and innovation, connecting cultures through the universal language of gaming. Join us in exploring a world where diverse gaming experiences come to life, where culture and games unite to create something truly extraordinary. Welcome to GameBoxX Gaming Studio, where every game is a cultural journey waiting to be embarked upon.",
                  });
                }}
              >
                Gaming Development
              </a>
            </li>
          </ul>
          <ul>
            <li className="head mb-4">Exchange</li>
            <li className="mb-3">
              <a
                href="#liq-provider"
                className="sm:!text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  setShortContent({
                    heading: "Fiat, Crypto and Gaming Chips",
                    para: "We're revolutionizing the way you access and use your funds. With support for over 50+ fiat currencies and more than 100 cryptocurrencies, we offer an extensive range of payment options that cater to your every need. But that's not all. We've taken it a step further by integrating our vast land-based gaming network. This means that gaming chips are easily accessible to players, enhancing your gaming experience to a whole new level. Coupled with our VIP services, you're in for a gaming journey like no other. GameBox Exchange is where convenience meets sophistication, ensuring you have the tools you need to make the most of your gaming adventurers.",
                  });
                }}
              >
                Fiat, Crypto, Chips
              </a>
            </li>
            <li className="mb-3">
              <a
                href="/"
                className="sm:!text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  setShortContent({
                    heading: "Cross-border and Cross-Chain",
                    para: "Bridging Borders and Blockchains in Web3. We're pioneers in bridging the gap between fiat and crypto payments, creating seamless liquidity within the Web3 ecosystem. Our extensive network of ramp-on and off payment structures ensures that you can navigate the digital landscape with ease. We're breaking down barriers and opening up a world of possibilities where borders and blockchains become bridges, not barriers. GameBox is your gateway to a future where the traditional and the cutting-edge coexist harmoniously, delivering a truly interconnected gaming experience. Join us as we lead the way in shaping the future of gaming and finance in the decentralized world of Web3.",
                  });
                }}
              >
                Crossborder
              </a>
            </li>
            <li className="mb-3">
              <a
                href="/"
                className="sm:!text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  setShortContent({
                    heading: "Cross-border and Cross-Chain",
                    para: "Bridging Borders and Blockchains in Web3. We're pioneers in bridging the gap between fiat and crypto payments, creating seamless liquidity within the Web3 ecosystem. Our extensive network of ramp-on and off payment structures ensures that you can navigate the digital landscape with ease. We're breaking down barriers and opening up a world of possibilities where borders and blockchains become bridges, not barriers. GameBox is your gateway to a future where the traditional and the cutting-edge coexist harmoniously, delivering a truly interconnected gaming experience. Join us as we lead the way in shaping the future of gaming and finance in the decentralized world of Web3.",
                  });
                }}
              >
                CrossChain
              </a>
            </li>
            <li className="mb-3">
              <a
                href="/"
                className="sm:!text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  setLowFee(true);
                }}
              >
                Low Fees
              </a>
            </li>
          </ul>

          <ul>
            <li className="head mb-4">Resource</li>
            <li className="mb-3">
              <a 
                href="https://gamebox888.net/Faq" 
                target="_blank"
                className="sm:!text-[14px]">
                FAQ
              </a>
            </li>
            <li className="mb-3">
              <a
                href="https://medium.com/@gamebox888.net"
                target="_blank"
                className="sm:!text-[14px]">
                Knowledge Base
              </a>
            </li>
          </ul>

          <ul>
            <li className="head mb-4">Company</li>

            <li className="mb-3">
              <a 
                href="https://gamebox888.net/about"
                target="_blank"
                className="sm:!text-[14px]">
                About Us
              </a>
            </li>
            <li className="mb-3">
              <a 
                href="https://gamebox888.net/" 
                target="_blank"
                className="sm:!text-[14px]">
                Social Responsibility
              </a>
            </li>
            <li className="mb-3">
              <a 
                href="https://gamebox888.net/#for-stakers"
                target="_blank"
                className="sm:!text-[14px]">
                Latest News
              </a>
            </li>
          </ul>
          <ul>
            <li className="head mb-4">Partners</li>
            <li className="mb-3">
              <a 
                href="https://gamebox888.net/game-providers" 
                target="_blank"
                className="sm:!text-[14px]">
                Game Providers
              </a>
            </li>
            <li className="mb-3">
              <a 
                href="https://gamebox888.net/exchange" 
                target="_blank"
                className="sm:!text-[14px]">
                Exchange
              </a>
            </li>
            <li className="mb-3">
              <a 
                href="https://gamebox888.net/business-partners" 
                target="_blank"
                className="sm:!text-[14px]">
                Business Partners
              </a>
            </li>
          </ul>
        </div>

        <div className="flex items-center justify-between mb-[32px] lg:grid lg:grid-cols-5 lg:gap-2 sm:!grid-cols-3">
          <img src={f1} alt="" className="sm:w-full" />
          <img src={f2} alt="" className="sm:w-full" />
          <img src={f3} alt="" className="sm:w-full" />
          <img src={f4} alt="" className="sm:w-full" />
          <img src={f5} alt="" className="sm:w-full" />
          <img src={f6} alt="" className="sm:w-full" />
          <img src={f7} alt="" className="sm:w-full " />
          <img src={f8} alt="" className="sm:w-full " />
          <img src={f9} alt="" className="sm:w-full " />
          <img src={f10} alt="" className="sm:w-full " />
          <img src={f11} alt="" className="sm:w-full " />
        </div>

        <div className="flex items-center justify-between mb-[32px] lg:grid lg:grid-cols-5 lg:gap-2 sm:!grid-cols-3">
            <img src={b1} alt="" className="sm:w-full" />
            <img src={b2} alt="" className="sm:w-full" />
            <img src={b3} alt="" className="sm:w-full" />
            <img src={b4} alt="" className="sm:w-full" />
            <img src={b5} alt="" className="sm:w-full" />
            <img src={b6} alt="" className="sm:w-full" />
            <img src={b7} alt="" className="sm:w-full " />
            <img src={b8} alt="" className="sm:w-full " />
          </div>

        <div className="copyright-area flex items-center justify-between">
          <h1 className="sm:!text-[14px]">
            Copyright © 2024 GameBox. All rights reserved.
          </h1>
          <div className="flex items-center gap-10 sm:gap-2 sm:grid sm:grid-cols-3">
            <img src={warning18} alt="" />
            <a
              href="https://cryptovalley.swiss/"
              rel="noreferrer"
              target="_blank"
            >
              <img src={member} alt="" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.responsiblegambling.org/"
            >
              <img src={responsible} alt="" />
            </a>
          </div>

          <nav className="flex items-center justify-between">
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>

            <li>
              <Link to="/terms-conditions">Terms and Conditions</Link>
            </li>
          </nav>
        </div>
      </div>
    </footer>
    </>
  );
};
