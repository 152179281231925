import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import banner14 from "../../assets/img/banner/banner14.png";
import arrow from "../../assets/img/sports/arrow.svg";
import search from "../../assets/img/search-header.svg";
import lobby from "../../assets/img/sports/lobby.svg";
import lobbycolored from "../../assets/img/sports/lobbycolored.svg";
import bets from "../../assets/img/sports/bets.svg";
import p2p from "../../assets/img/sports/p2p.svg";
import betscolored from "../../assets/img/sports/betscolored.svg";
import p2pcolored from "../../assets/img/sports/p2pcolored.svg";
import clock from "../../assets/img/sports/clock.svg";
import clockcolored from "../../assets/img/sports/clockcolored.svg";
import { Lobby } from "./Lobby";
import { P2P } from "./P2P";
import { MyBets } from "./MyBets";
import { Favourites } from "./Favourites";
import { LiveEvents } from "./LiveEvents";
import { StartingSoon } from "./StartingSoon";

const filterData = [
    {
    name: "Lobby",
    img: lobby,
    imgc: lobbycolored,
    active: false,
    },
    // {
    // name: "P2P",
    // img: p2p,
    // imgc: p2pcolored,
    // active: false,
    // },
    {
    name: "My Bets",
    img: bets,
    imgc: betscolored,
    active: false,
    },
    {
    name: "Favourites",
    img: clock,
    imgc: clockcolored,
    active: false,
    },
    {
    name: "Live Events",
    img: clock,
    imgc: clockcolored,
    active: false,
    },
    {
    name: "Starting Soon",
    img: clock,
    imgc: clockcolored,
    active: false,
    },
];

export const Sports = () => {
    const navigate = useNavigate();
    const [filters, setFilters] = useState(
    filterData.map((filter, index) => ({
            ...filter,
            active: index === 0,
        }))
    );
    const toggleFilter = (index) => {
        const updatedFilters = filters.map((filter, i) => ({
                ...filter,
                active: i === index,
            }));
        setFilters(updatedFilters);
    };
    const activeFilter = filters.find((filter) => filter.active);

    

    return (
    <>
        <div className="font-arabicmed w-full">
            <div className="flex justify-between w-full flex-wrap mb-2">
                <h1 className="text-[24px] text-[#fff]">Player Dashboard</h1>
                <div className="flex gap-1 items-center">
                <img src={arrow} className="w-6 h-6" />
                <button className="text-[#6FCF17]" onClick={(e) => {
                        navigate("/player/dashboard");
                    }}>
                    Player
                </button>
                <p className="text-[#fff]">/ Sports</p>
                </div>
            </div>
            <img src={banner14} className="w-full rounded-lg mb-6" alt="" />

            <div className="flex md:flex-col justify-between gap-2">
                <div className="flex overflow-x-auto items-center">
                    <div className="flex justify-between whitespace-nowrap">
                        <div className="flex my-4">
                        {filters.map((filter, index) => (
                            <button
                            key={index}
                            className="flex items-center mr-4"
                            onClick={() => toggleFilter(index)}
                            >
                            <img
                                src={filter.active ? filter.imgc : filter.img}
                                alt={filter.name}
                                className="mx-2"
                            />
                            <p
                                className={
                                filter.active ? "text-[#6FCF17]" : "text-[#68727B]"
                                }
                            >
                                {filter.name}
                            </p>
                            </button>
                        ))}
                        </div>
                    </div>
                </div>
                {activeFilter && activeFilter.name !== "My Bets" && (
                    <div className="flex w-fit items-center my-2 md:w-full">
                        <p className="text-[#fff] mr-2 md:hidden">Search Event</p>
                        <div className="search-box-container w-[201px] h-8 px-4 bg-[#2E2E2E] border-[1px] border-[#3C403F] rounded-lg  flex items-center gap-1 md:w-full">
                            <img src={search} alt="" />
                            <input
                            type="text"
                            placeholder="Search here"
                            className="w-full bg-transparent border-0 outline-none text-[14px] text-[#AEB2B1]"
                            />
                        </div>
                    </div>
                )}
            </div>
            
            {/* Render the active filter component */}
            {activeFilter && activeFilter.name === "Lobby" && <Lobby />}
            {activeFilter && activeFilter.name === "P2P" && <P2P />}
            {activeFilter && activeFilter.name === "My Bets" && <MyBets />}
            {activeFilter && activeFilter.name === "Favourites" && <Favourites />}
            {activeFilter && activeFilter.name === "Live Events" && <LiveEvents />}
            {activeFilter && activeFilter.name === "Starting Soon" && <StartingSoon />}
        </div>
    </>
    );
};