import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import claim from "../../assets/img/bonus-dashboard/claim.png";
import info from "../../assets/img/bonus-dashboard/info.png";
import leftArrow from "../../assets/img/bonus-dashboard/left-arrow.png";
import notFound from "../../assets/img/bonus-dashboard/not-found.png";
import unclaim from "../../assets/img/bonus-dashboard/unclaim.png";
import { useBonsTransactions, useClaimBonus } from "../../queries/bonus";
import { dateFormatter } from "../../utils";
import { Header } from "../Header";
import { SidebarArea } from "../SidebarArea";

const BonusDetails = ({
  setsigninState,
  setSignUpState,
  login,
  balance,
  refetch,
}) => {
  const navigate = useNavigate();
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [isTransactionData, setIsTransactionData] = useState(true);
  const [loading, setLoading] = useState(false);
  const claimBonusMutation = useClaimBonus();
  const bonusTransactions = useBonsTransactions();
  const transactions = bonusTransactions?.data?.transactions;

  const claimBonus = async () => {
    try {
      toast.promise(claimBonusMutation.mutateAsync(), {
        pending: "Claiming Bonus",
        success: "Bonus Claimed",
        error: "Error Claiming Bonus",
      });

      refetch();
    } catch (error) {}
  };

  return (
    <div className="flex-1 overflow-hidden DepositePopUp">
      <ToastContainer position="bottom-right" theme="dark" autoClose={3000} />
      <Header
        login={login}
        headerType="player"
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex gap-2 affiliate-body-area items-start px-6 pt-6">
        <div className="body-left-area w-full">
          <div className="flex flex-wrap items-center justify-between w-full mb-6">
            <h2 className="text-white text-[24px] font-medium">
              Bonus Details
            </h2>
            <div className="inline-flex items-center gap-x-3">
              <img src={leftArrow} alt="<" />
              <a href="/player/dashboard" className="text-[#6FCF17] text-base font-normal">
                Home
              </a>
              <span className="text-[#AEB2B1] text-base font-normal">
                / &nbsp; Bonus with Promo-code
              </span>
            </div>
          </div>
          <h3 className="text-[#6FCF17] text-[18px] font-bold mb-8">
            Available Bonus
          </h3>
          <div className="flex flex-wrap w-full bg-[#1A1A1B] border-[1px] border-[#3C403F] rounded-xl p-5">
            <div className="flex-1 flex justify-between pl-6 pr-10">
              <div className="inline-flex justify-center items-center flex-col">
                <div className="flex flex-wrap gap-x-2 items-center mb-2">
                  <img src={claim} alt="Claim" className="w-4 h-4" />
                  <p className="text-[14px] text-white text-medium">
                    Total Bonus Claimed
                  </p>
                  <div className="inline-flex relative">
                    <img src={info} alt="Info" />
                  </div>
                </div>
                <div className="text-[18px] font-bold text-[#6FCF17]">
                  ${balance?.claimedBonus.toFixed(2) ?? 0.0}
                </div>
              </div>
              <div className="inline-flex justify-center items-center flex-col">
                <div className="flex flex-wrap gap-x-2 items-center mb-2">
                  <img src={unclaim} alt="Claim" className="w-4 h-4" />
                  <p className="text-[14px] text-white text-medium">
                    Unclaimed Bonus
                  </p>
                  <div className="inline-flex relative">
                    <img src={info} alt="Info" />
                  </div>
                </div>
                <div className="text-[18px] font-bold text-[#464646]">
                  $ {balance?.claimableBonus.toFixed(2) ?? 0.0}
                </div>
              </div>
              <div className="inline-flex justify-center items-center flex-col">
                {balance?.claimableBonus > 0 ? (
                  <>
                    <button
                      className="h-10 px-5 bg-gradient-to-b from-[#F8D634] to-[#D08A1A] rounded-lg inline-flex items-center justify-start text-white text-[14px] font-medium"
                      onClick={claimBonus}
                    >
                      {loading ? <>Claiming Bonus</> : <>Claim</>}
                    </button>
                  </>
                ) : (
                  <>
                    <button className="h-10 px-5 bg-gradient-to-b from-[#8d8d8d] to-[#383838] rounded-lg inline-flex items-center justify-start text-white text-[14px] font-medium">
                      Claim
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="inline-flex items-center gap-x-3 px-8 py-2 border-l border-[#3C403F]">
              <button className="inline-flex h-[42px] items-center px-2.5 rounded-[10px] text-base font-medium text-white bg-gradient-to-b from-[#9435E2] to-[#3733B9]">
                Redeem Bonus Code
              </button>
              <input
                type="text"
                placeholder="Type your code here"
                className="h-[42px] bg-[#2E2E2E] border border-[#3C403F] w-[200px] px-5 text-center rounded-lg text-[#AEB2B1] text-[14px] font-normal focus:outline-none"
              />
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-between w-full mb-6 mt-8">
            <h2 className="text-white text-[24px] font-medium">
              Bonus Transactions
            </h2>
            <div className="inline-flex items-center">
              <a
                href="/"
                className="bg-[#252525] text-[#AEB2B1] py-2 px-3 rounded-sm text-[18px] font-normal"
              >
                All bonuses
              </a>
            </div>
          </div>
          {/* <h3 className="text-[#AEB2B1] text-[18px] font-medium mb-6">
            Only the latest 60 days transactions will be displayed below.
          </h3> */}
          <div className="transaction-box bg-[#1A1A1B] border-[1px] border-[#3C403F] rounded-xl p-6 flex-1 sm:p-5">
            <div className="lg:overflow-auto">
              <>
                {!transactions?.length > 0 ? (
                  <>
                    <table className="w-full lgmed:min-w-[800px]">
                      <thead>
                        <tr className="h-[56px] bg-[#252525] border-[1px] border-[#3C403F]">
                          <th className="pl-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                            Bonus Type
                          </th>
                          <th className="text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                            Amount
                          </th>
                          <th className="text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                            Time
                          </th>
                          <th className="pr-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            colSpan={4}
                            className="text-center text-white py-3"
                          >
                            <img
                              src={notFound}
                              alt="Not Found"
                              className="mx-auto my-0"
                            />
                            <p className="text-sm font-normal text-[#AEB2B1]">
                              Oops! There is no data yet!
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                ) : (
                  <>
                    <table className="w-full lgmed:min-w-[800px]">
                      <thead>
                        <tr className="h-[56px] bg-[#252525] border-[1px] border-[#3C403F]">
                          <th className="pl-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                            Bonus Type
                          </th>
                          <th className="text-left font-arabicreg text-[18px] text-[#AEB2B1]  ">
                            Amount
                          </th>
                          <th className="text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                            Time
                          </th>
                          {/* <th className="pr-4 text-left font-arabicreg text-[18px] text-[#AEB2B1]">
                            Status
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {transactions?.map((item, index) => (
                          <tr
                            key={index} // Add a unique key for each row
                            className="h-[56px] bg-[#1A1A1B] border-b-[1px] border-b-[#3C403F] border-l-[1px] border-l-[#3C403F] border-r-[1px] border-r-[#3C403F]"
                          >
                            <td className="pl-4 font-arabicreg text-[18px] text-[#fff]">
                              {item.type}{" "}
                              {/* Populate with actual bonus type */}
                            </td>
                            <td className="font-arabicreg text-[18px] text-[#fff] uppercase">
                              ${item.amount} {/* Populate with actual amount */}
                            </td>
                            <td className="font-arabicreg text-[18px] text-[#fff]">
                              {dateFormatter(item.created_at)}{" "}
                              {/* Populate with actual timestamp */}
                            </td>
                            <td className="font-arabicreg capitalize text-[18px] text-[#fff]">
                              {/* {item.status ?? 'confirmed'} Populate with actual status */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};

export default BonusDetails;
