import React, { useState } from "react";
import { Link } from "react-router-dom";
import arrow from "../../assets/img/dashboard/arrow.svg";
import exchange from "../../assets/img/exchange.svg";
import p2p from "../../assets/img/p2p.svg";
import swap from "../../assets/img/swap.svg";
export const AsideOption = () => {
  const sidelist = [
    {
      heading: "Swap",
      img: swap,
      link: "/swap/crypto",
    },
    {
      heading: "P2P",
      img: p2p,
      // link: "/p2p/buy",
      link: "/",
    },
  ];
  const [active, setActive] = useState(false);
  return (
    <div className="dropdownaside relative">
      <div
        className={`bonus-area cursor-pointer px-5 border-[transparent] border-[1px] rounded-lg flex items-center h-11 `}
        onClick={(e) => {
          setActive(!active);
        }}
      >
        <img src={exchange} alt="card" />

        <h1 className="flex-1 ml-2">Exchange</h1>

        <img src={arrow} alt="" className={`${active && "rotate-[-90deg]"}`} />
      </div>
      {active && (
        <ul className=" w-full flex flex-col items-end">
          {sidelist.map((EachList, key) => (
            <Link
              to={EachList.link}
              className={`bonus-area px-5 w-[181px]  flex items-center h-11 
               rounded-lg !bg-[#252525] mt-2`}
            >
              <img src={EachList.img} alt="card" />
              <h1 className="flex-1 ml-2">{EachList.heading}</h1>
            </Link>
          ))}
        </ul>
      )}
    </div>
  );
};
