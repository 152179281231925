import React, { useState } from "react";
import basketball from "../../assets/img/sports/basketball.svg";
import soccer from "../../assets/img/sports/soccer.svg";
import football from "../../assets/img/sports/football.svg";
import volleyball from "../../assets/img/sports/volleyball.svg";
import baseball from "../../assets/img/sports/baseball.svg";
import golf from "../../assets/img/sports/golf.svg";
import cricket from "../../assets/img/sports/cricket.svg";
import hockey from "../../assets/img/sports/hockey.svg";
import boxing from "../../assets/img/sports/boxing.svg";
import formula from "../../assets/img/sports/formula.svg";
import horse from "../../assets/img/sports/horse.svg";
import virtual from "../../assets/img/sports/virtual.svg";
import expand from "../../assets/img/sports/expand.svg";
import collapse from "../../assets/img/sports/collapse.svg";
import close from "../../assets/img/sports/close.svg";
import live from "../../assets/img/sports/live.svg";
import up from "../../assets/img/sports/up.svg";
import down from "../../assets/img/sports/down.svg";
import place from "../../assets/img/sports/place.svg";
import btc from "../../assets/img/sports/btc.svg";

const sportsData = [
  {
    name: "Basketball",
    category: "Basketball",
    img: basketball,
  },
  {
    name: "Soccer",
    category: "Soccer",
    img: soccer,
  },
  {
    name: "Football",
    category: "Football",
    img: football,
  },
  {
    name: "Volleyball",
    category: "Volleyball",
    img: volleyball,
  },
  {
    name: "Baseball",
    category: "Baseball",
    img: baseball,
  },
  {
    name: "Golf",
    category: "Golf",
    img: golf,
  },
  {
    name: "Cricket",
    category: "Cricket",
    img: cricket,
  },
  {
    name: "Ice Hockey",
    category: "IceHockey",
    img: hockey,
  },
  {
    name: "Boxing",
    category: "Boxing",
    img: boxing,
  },
  {
    name: "Formula",
    category: "Formula",
    img: formula,
  },
  {
    name: "Horse",
    category: "Horse",
    img: horse,
  },
  {
    name: "Virtual Sports",
    category: "VirtualSports",
    img: virtual,
  },
];

export const HomeSports = () => {
  const [expandedItems, setExpandedItems] = useState(
    new Array(sportsData.length).fill(false)
  );
  const toggleCollapse = (index) => {
    const newExpandedItems = [...expandedItems];
    newExpandedItems[index] = !newExpandedItems[index];
    setExpandedItems(newExpandedItems);
  };

  const [active, setActive] = useState(true);
  const handleClick = () => {
    setActive(!active);
  };

  const [showBet, setShowBet] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [showBetSlip, setShowBetSlip] = useState(false);
  const handleClick1 = () => {
    setIsActive1(!isActive1);
    setShowBetSlip(true);
    setShowBet(true);
  };
  const handleClick2 = () => {
    setIsActive2(!isActive2);
    setShowBetSlip(true);
    setShowBet(true);
  };
  const handleClick3 = () => {
    setIsActive3(!isActive3);
    setShowBetSlip(true);
    setShowBet(true);
  };

  const [number, setNumber] = useState(0);
  const handleChange = (event) => {
    const inputNumber = parseFloat(event.target.value);
    if (!isNaN(inputNumber)) {
      setNumber(inputNumber);
    }
  };
  const [odd, setOdd] = useState(0);
  const handleOddChange = (event) => {
    const inputOdd = parseInt(event.target.value);
    if (!isNaN(inputOdd)) {
      setOdd(inputOdd);
    }
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const handlePlaceBet = () => {
    setShowConfirmation(true);
  };

  const [stepActive, setStepActive] = useState(1);

  return (
    <>
      <div className="flex justify-between gap-2">
        <div className="flex flex-col w-full">
          {sportsData.map((sport, index) => (
            <div className="bg-[#1A1A1B] p-6 rounded-lg mt-4">
              <div className="flex justify-between">
                <h1 className="text-[14px] text-[#fff] font-arabicbold">
                  {sport.name}
                </h1>
                <button onClick={() => toggleCollapse(index)}>
                  {expandedItems[index] ? (
                    <img src={collapse} />
                  ) : (
                    <img src={expand} />
                  )}
                </button>
              </div>
              {expandedItems[index] && (
                <>
                  <div className="py-6 border-t border-[#2E2E2E] mt-6 text-[12px] ">
                    <div className="flex md:flex-col justify-between items-start">
                      <div className="w-3/4 md:w-full md:mb-10 ">
                        <p className="text-[#fff] font-arabicreg mb-6">
                          Sat, Feb 03 8:00 AM
                        </p>
                        <div className="flex justify-between mb-1">
                          <h1 className="w-full text-[#fff] font-arabicbold">
                            Detroit Pistons
                          </h1>
                          <p className="w-full text-center text-[#6FCF17] font-arabicbold">
                            0
                          </p>
                        </div>
                        <div className="flex justify-between">
                          <h1 className="w-full text-[#fff] font-arabicbold">
                            Los Angeles Clippers
                          </h1>
                          <p className="w-full text-center text-[#6FCF17] font-arabicbold">
                            2
                          </p>
                        </div>
                      </div>

                      <div className="flex w-full md:w-full justify-between items-center">
                        <div className="w-full text-center">
                          <p className="text-[#fff] font-arabicreg mb-4">
                            Winner (Incl. Overtime)
                          </p>
                          <div className="flex sm:flex-col justify-between gap-4">
                            <button
                              className={`w-full py-3 px-6 rounded-lg text-start ${
                                isActive1 ? "bg-[#111010]" : "bg-[#2E2E2E]"
                              }`}
                              onClick={handleClick1}
                            >
                              <h1 className="text-[#fff] font-arabicbold ">
                                Detroit Pistons
                              </h1>
                              <h1 className="text-[#fff] font-arabicbold ">
                                5.80
                              </h1>
                            </button>
                            <button
                              className={`py-3 px-6 rounded-lg text-start ${
                                isActive2 ? "bg-[#111010]" : "bg-[#2E2E2E]"
                              }`}
                              onClick={handleClick2}
                            >
                              <h1 className="text-[#fff] font-arabicbold ">
                                Draw
                              </h1>
                              <h1 className="text-[#fff] font-arabicbold">
                                5.80
                              </h1>
                            </button>
                            <button
                              className={`w-full py-3 px-6 rounded-lg text-start ${
                                isActive3 ? "bg-[#111010]" : "bg-[#2E2E2E]"
                              }`}
                              onClick={handleClick3}
                            >
                              <h1 className="text-[#fff] font-arabicbold">
                                Los Angeles Clippers
                              </h1>
                              <h1 className="text-[#fff] font-arabicbold">
                                5.80
                              </h1>
                            </button>
                          </div>
                        </div>

                        <div className="items-center my-auto w-1/3">
                          <h1 className="text-[16px] text-end text-[#fff] font-arabicbold mt-10 mr-4">
                            +37
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        {showBetSlip && (
          <div className="w-1/2 lg:inset-0 lg:w-full lg:absolute lg:items-center lg:justify-center lg:bg-gray-800 lg:bg-opacity-80">
            <div className="flex flex-col smd:w-3/4 mmd:w-full lg:w-full lg:fixed smd:inset-x-20 lg:inset-x-0 smd:inset-y-20 lg:inset-y-0 lg:justify-center lg:items-center">
              <div className="flex flex-col justify-between bg-[#1A1A1B] p-4 rounded-lg my-4 mr-4 lg:mr-4 lg:ml-4 w-full">
                <div>
                  <div className="flex justify-between border-b pt-2 pb-5 border-white">
                    <div className="flex justify-between gap-2 items-center">
                      <img src={basketball} alt="Basketball" />
                      <h1 className="text-[14px] text-[#fff] font-arabicbold w-full">
                        Bet Slip{" "}
                      </h1>
                    </div>
                    <button onClick={() => setShowBetSlip(false)}>
                      {" "}
                      <img src={close} alt="close" />{" "}
                    </button>
                  </div>

                  {stepActive !== 3 && (
                    <div className="py-6">
                      <div className="flex justify-between gap-1 bg-[#252525] p-3 rounded-full w-full max-w-[200px]">
                        <button
                          className={`text-white font-arabicmed text-[12px] py-3 px-5 rounded-3xl ${
                            stepActive === 1
                              ? "bg-[#3A3A3A] w-full"
                              : "bg-transparent w-1/2"
                          }`}
                          onClick={(e) => {
                            setStepActive(1);
                          }}
                        >
                          Single
                        </button>
                        <button
                          className={`text-white font-arabicmed text-[12px] py-3 px-5 rounded-3xl ${
                            stepActive === 2
                              ? "bg-[#3A3A3A] w-full"
                              : "bg-transparent w-1/2"
                          }`}
                          onClick={(e) => {
                            setStepActive(2);
                          }}
                        >
                          Multiple
                        </button>
                      </div>
                    </div>
                  )}

                  <h1 className="font-arabicbold text-white flex justify-end p-2 text-[14px]">
                    Clear All
                  </h1>

                  {/* {showBet && ( */}
                  {stepActive === 1 && (
                    <div>
                      {showBet && (
                        <div className="bg-[#2E2E2E] rounded-md p-3 mb-4">
                          <div className="flex justify-between items-center">
                            <img src={live} />
                            <h1 className="text-white font-arabicbold text-[14px]">
                              {" "}
                              Detroit Pistons{" "}
                            </h1>
                            <h1 className="text-white font-arabicbold text-[14px]">
                              {" "}
                              &nbsp;-&nbsp;{" "}
                            </h1>
                            <h1 className="text-white font-arabicbold text-[14px]">
                              {" "}
                              Los Angeles Clipper{" "}
                            </h1>
                            <button
                              className="text-white font-arabicbold text-[14px]"
                              onClick={() => setShowBet(false)}
                            >
                              {" "}
                              x{" "}
                            </button>
                          </div>
                          <h1 className="text-white font-arabicreg text-[14px] mt-4">
                            {" "}
                            Winner (Incl. Overtime){" "}
                          </h1>
                          <div className="flex justify-between mb-4">
                            <h1 className="text-white font-arabicbold text-[14px]">
                              {" "}
                              Detroit Pistons{" "}
                            </h1>
                            <h1 className="text-white font-arabicbold text-[14px]">
                              {" "}
                              1.55{" "}
                            </h1>
                          </div>
                          <div className="flex justify-between items-end">
                            <h1 className="text-white font-arabicreg text-[13px]">
                              Odd
                            </h1>
                            <div className="bg-[#171717] py-1 px-2 rounded-lg">
                              <input
                                className="text-[13px] font-arabicreg text-[#AEB2B1] bg-transparent w-6 text-center outline-none"
                                placeholder="0"
                                value={odd}
                                onChange={handleOddChange}
                              />
                            </div>
                          </div>
                          <div className="flex items-center space-x-2 justify-between">
                            <div className="flex flex-row items-center gap-2 w-2/5 bg-[#171717] p-3 rounded-lg">
                              <span className="text-center font-arabicreg text-white text-[14px]">
                                {" "}
                                {number.toFixed(8)}{" "}
                              </span>
                              <div className="flex flex-col items-center gap-2">
                                <button
                                  className="focus:outline-none"
                                  // onClick={handleIncrease}
                                >
                                  {" "}
                                  <img src={up} />{" "}
                                </button>
                                <button
                                  className="focus:outline-none"
                                  // // onClick={handleDecrease}
                                >
                                  {" "}
                                  <img src={down} />{" "}
                                </button>
                              </div>
                            </div>
                            <div>
                              <h1 className="text-center font-arabicreg text-white text-[14px] flex justify-end">
                                {" "}
                                Est. Payout{" "}
                              </h1>
                              <h1 className="text-center font-arabicreg text-white text-[14px] flex justify-end">
                                {" "}
                                {number.toFixed(8)} GBC{" "}
                              </h1>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="items-end">
                        <div>
                          <div className="flex justify-between">
                            <h1 className="text-center font-arabicbold text-white text-[14px] flex justify-end">
                              Odd
                            </h1>
                            <h1 className="text-center font-arabicreg text-white text-[14px] flex justify-end">
                              {odd}
                            </h1>
                          </div>
                          <div className="flex justify-between">
                            <h1 className="text-center font-arabicbold text-white text-[14px] flex justify-end">
                              Total Stake
                            </h1>
                            <h1 className="text-center font-arabicreg text-white text-[14px] flex justify-end">
                              {number.toFixed(4)} GBC
                            </h1>
                          </div>
                          <div className="flex justify-between">
                            <h1 className="text-center font-arabicbold text-white text-[14px] flex justify-end">
                              Est. Payout
                            </h1>
                            <h1 className="text-center font-arabicreg text-white text-[14px] flex justify-end">
                              {number.toFixed(4)} GBC
                            </h1>
                          </div>
                        </div>
                        <button
                          className="w-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C] flex items-center justify-center gap-2 py-3 my-4 rounded-lg text-white text-[16px]"
                          onClick={handlePlaceBet}
                        >
                          <img src={place} />
                          Place Bet
                        </button>
                      </div>
                    </div>
                  )}

                  {stepActive === 2 && (
                    <div>
                      {showBet && (
                        <div className="bg-[#2E2E2E] rounded-md p-3 mb-4">
                          <div className="flex justify-between items-center">
                            <img src={live} />
                            <h1 className="text-white font-arabicbold text-[14px]">
                              {" "}
                              Detroit Pistons{" "}
                            </h1>
                            <h1 className="text-white font-arabicbold text-[14px]">
                              {" "}
                              &nbsp;-&nbsp;{" "}
                            </h1>
                            <h1 className="text-white font-arabicbold text-[14px]">
                              {" "}
                              Los Angeles Clipper{" "}
                            </h1>
                            <button
                              className="text-white font-arabicbold text-[14px]"
                              onClick={() => setShowBet(false)}
                            >
                              {" "}
                              x{" "}
                            </button>
                          </div>
                          <h1 className="text-white font-arabicreg text-[14px] mt-4">
                            {" "}
                            Winner (Incl. Overtime){" "}
                          </h1>
                          <div className="flex justify-between mb-4">
                            <h1 className="text-white font-arabicbold text-[14px]">
                              {" "}
                              Detroit Pistons{" "}
                            </h1>
                            <h1 className="text-white font-arabicbold text-[14px]">
                              {" "}
                              1.55{" "}
                            </h1>
                          </div>
                          {/* <div className="flex justify-between items-end">
                            <h1 className="text-white font-arabicreg text-[13px]">
                              Odd
                            </h1>
                            <div className="bg-[#171717] py-1 px-2 rounded-lg">
                              <input
                                className="text-[13px] font-arabicreg text-[#AEB2B1] bg-transparent w-6 text-center outline-none"
                                placeholder="0"
                                value={odd}
                                onChange={handleOddChange}
                              />
                            </div>
                          </div> */}
                          {/* <div className="flex items-center space-x-2 justify-between">
                            <div className="flex flex-row items-center gap-2 w-2/5 bg-[#171717] p-3 rounded-lg">
                              <span className="text-center font-arabicreg text-white text-[14px]">
                                {" "}
                                {number.toFixed(8)}{" "}
                              </span>
                              <div className="flex flex-col items-center gap-2">
                                <button
                                  className="focus:outline-none"
                                  
                                >
                                  {" "}
                                  <img src={up} />{" "}
                                </button>
                                <button
                                  className="focus:outline-none"

                                >
                                  {" "}
                                  <img src={down} />{" "}
                                </button>
                              </div>
                            </div>
                            <div>
                              <h1 className="text-center font-arabicreg text-white text-[14px] flex justify-end">
                                {" "}
                                Est. Payout{" "}
                              </h1>
                              <h1 className="text-center font-arabicreg text-white text-[14px] flex justify-end">
                                {" "}
                                {number.toFixed(8)} GBC{" "}
                              </h1>
                            </div>
                          </div> */}
                        </div>
                      )}

                      <div className="items-end">
                        <div>
                          <div className="flex items-center space-x-2 justify-between">
                            <div className="flex flex-row w-full items-center justify-between gap-2  bg-[#2E2E2E] p-3 rounded-lg mb-4">
                              <span className="text-center font-arabicreg text-white text-[14px]">
                                {" "}
                                {number.toFixed(8)}{" "}
                              </span>
                              <div className="flex flex-col items-center gap-2">
                                <button className="focus:outline-none">
                                  {" "}
                                  <img src={up} />{" "}
                                </button>
                                <button className="focus:outline-none">
                                  {" "}
                                  <img src={down} />{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <h1 className="text-center font-arabicbold text-white text-[14px] flex justify-end">
                              Total Odds
                            </h1>
                            <h1 className="text-center font-arabicreg  text-[#5EC61F]  text-[14px] flex justify-end">
                              {odd}
                            </h1>
                          </div>
                          <div className="flex justify-between">
                            <h1 className="text-center font-arabicbold text-white text-[14px] flex justify-end">
                              Est. Payout
                            </h1>
                            <h1 className="text-center font-arabicreg text-white text-[14px] flex justify-end">
                              {number.toFixed(4)} GBC
                            </h1>
                          </div>
                        </div>
                        <button
                          className="w-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C] flex items-center justify-center gap-2 py-3 my-4 rounded-lg text-white text-[16px]"
                          onClick={handlePlaceBet}
                        >
                          <img src={place} />
                          Place Bet
                        </button>
                      </div>
                    </div>
                  )}

                  {/* )} */}
                </div>
              </div>

              {showConfirmation && (
                <div className="bg-gray-900 p-4 rounded-md mb-2">
                  <div className="flex justify-between items-center border-b border-[#fff] pb-2">
                    <h2 className="text-[16px] text-white font-arabicbold">
                      Booked Bet
                    </h2>
                    <img
                      src={close}
                      className="cursor-pointer"
                      alt="close"
                      onClick={() => setShowConfirmation(false)}
                    />
                  </div>
                  {showBet && (
                    <div className="bg-[#2E2E2E] rounded-md p-4 mt-4">
                      <div className="flex justify-between items-center">
                        <img src={live} />
                        <h1 className="text-white font-arabicbold text-[14px]">
                          {" "}
                          Detroit Pistons{" "}
                        </h1>
                        <h1 className="text-white font-arabicbold text-[14px]">
                          {" "}
                          &nbsp;-&nbsp;{" "}
                        </h1>
                        <h1 className="text-white font-arabicbold text-[14px]">
                          {" "}
                          Los Angeles Clipper{" "}
                        </h1>
                        <button
                          className="text-white font-arabicbold text-[14px]"
                          onClick={() => setShowBet(false)}
                        >
                          {" "}
                          x{" "}
                        </button>
                      </div>
                      <h1 className="text-white font-arabicreg text-[14px] mt-4">
                        {" "}
                        Winner (Incl. Overtime){" "}
                      </h1>
                      <div className="flex justify-between mb-4">
                        <h1 className="text-white font-arabicbold text-[14px]">
                          {" "}
                          Detroit Pistons{" "}
                        </h1>
                        <h1 className="text-white font-arabicbold text-[14px]">
                          {" "}
                          1.55{" "}
                        </h1>
                      </div>
                      <div className="flex justify-between">
                        <h1 className="text-[#fff] font-arabicreg text-[12px]">
                          Est. Payout
                        </h1>
                        <h1 className="text-[#fff] font-arabicreg text-[12px] flex">
                          0.00379916&nbsp;
                          <img src={btc} />
                        </h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default HomeSports;
