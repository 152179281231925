import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import affiliateheader from "../assets/img/affiliate/affiliate-header.svg";
import avatar from "../assets/img/avatar-custom.svg";
import chat from "../assets/img/chat.svg";
import logo from "../assets/img/logo.svg";
import notification from "../assets/img/notification.svg";
import search from "../assets/img/search-header.svg";
import { useAuth } from "../contexts/AuthContext";
import { Avatar } from "./Avatar";
import { Iconbutton } from "./Iconbutton";
import { MasterMedal } from "./MasterMedal";
import { MedalsDetails } from "./MedalsDetails";
import { UserEditProfile } from "./UserEditProfile";
import { UserInformation } from "./UserInformation";

import JuniorAssociate from "../assets/img/affiliate/rank/JuniorAssociate.png";
import ManagingDirector from "../assets/img/affiliate/rank/ManagingDirector.png";
import NoRank from "../assets/img/affiliate/rank/NoRank.png";
import SeniorPartner from "../assets/img/affiliate/rank/SeniorPartner.png";

const rankImages = {
  JuniorAssociate: JuniorAssociate,
  ManagingDirector: ManagingDirector,
  SeniorPartner: SeniorPartner,
};

export const AffiliateHeader = ({
  login,
  settypeShowAside,
  setSignUpState,
  setsigninState,
  headerType = "Player",
}) => {
  const [usernameState, setusername] = useState(false);
  const [profileState, setprofileState] = useState(false);
  const [medalState, setmedalState] = useState(false);
  const [mastermedalState, setmastermedalState] = useState(false);
  const navigate = useNavigate();
  // Logout function
  const handleLogout = () => {
    localStorage.removeItem("token"); // Clear the token from storage
    window.location.href = `${process.env.REACT_APP_WEB_URL}`;
  };

  const { userInfo } = useAuth();

  return (
    <>
      {usernameState && (
        <UserInformation
          setusername={setusername}
          setprofileState={setprofileState}
          setmedalState={setmedalState}
          setmastermedalState={setmastermedalState}
        />
      )}
      {profileState && (
        <UserEditProfile
          setprofileState={setprofileState}
          setusername={setusername}
        />
      )}
      {mastermedalState && (
        <MasterMedal
          setmastermedalState={setmastermedalState}
          setusername={setusername}
        />
      )}
      {medalState && (
        <MedalsDetails
          setmedalState={setmedalState}
          setusername={setusername}
        />
      )}

      <header className="px-6 h-16 flex items-center">
        <img
          src={logo}
          alt="logo"
          className="logo-header-img hidden sm:!block"
        />
        <div className="search-box-wrapper flex items-center gap-4">
          <h1 className="text-[#fff] text-[16px] mr-2 sm:!hidden !block">
            <Link to={`/`} >
              Dashboard
            </Link>
          </h1>
          <div className="search-box-container w-[201px] h-8 px-4 bg-[#2E2E2E] border-[1px] border-[#3C403F] rounded-lg  flex items-center gap-1 sm:hidden">
            <img src={search} alt="" />
            <input
              type="text"
              placeholder="Search here"
              className="w-full bg-transparent border-0 outline-none text-[14px] text-[#AEB2B1]"
            />
          </div>
        </div>

        <div className="right-header-area items-center flex flex-1 justify-end">
          {!login && (
            <div className="not_login flex items-center">
              <button
                className="flex rounded-lg ml-3 deposit-button border-button items-center px-3.5 h-8"
                onClick={(e) => {
                  setsigninState(true);
                }}
              >
                Sign In
              </button>

              <button
                className="flex rounded-lg ml-3 mr-5 deposit-button items-center px-3.5 h-8"
                onClick={(e) => {
                  setSignUpState(true);
                }}
              >
                Sign Up
              </button>
              <Iconbutton
                className="w-8 h-8 icon-btn"
                onclick={(e) => {
                  settypeShowAside(1);
                }}
              >
                <img src={chat} alt="chat" />
              </Iconbutton>
            </div>
          )}
          {login && (
            <div className="icon-buttons flex items-center">
              <Iconbutton
                className="mr-3 w-8 h-8 icon-btn"
                onclick={(e) => {
                  settypeShowAside((prevTypeShowAside) => {
                    return prevTypeShowAside === -1 ? 2 : -1;
                  });
                }}
              >
                <img src={notification} alt="notification" />
              </Iconbutton>
              <Iconbutton
                className="mr-3 w-8 h-8 icon-btn"
                onclick={(e) => {
                  settypeShowAside((prevTypeShowAside) => {
                    return prevTypeShowAside === -1 ? 1 : -1;
                  });
                }}
              >
                <img src={chat} alt="chat" />
              </Iconbutton>

              <Iconbutton
                className="mr-3 w-8 h-8 icon-btn"
                onclick={(e) => {
                  navigate("/home/global-settings");
                }}
              >
                <img src={affiliateheader} alt="cycle" />
              </Iconbutton>

              <div className="naming-area text-right ml-4  flex items-center mr-2 sm:!hidden">
                {userInfo?.user?.rank !== "NoRank" && (
                  <img
                    src={rankImages[userInfo?.user?.rank]}
                    alt=""
                    className="mr-4 w-7"
                  />
                )}
                {userInfo?.user?.rank === 'NoRank' && <>
                  <img
                    src={NoRank}
                    alt=""
                    className="mr-4 w-7"
                  />
                </>}
                <p className="text-[#AEB2B1] text-[12px] font-arabicreg">

                  {userInfo?.user?.rank !== "NoRank" && (
                    <>
                      {userInfo?.user?.rank}
                    </>
                  )}


                  {userInfo?.user?.rank === "NoRank" && (
                    <>
                      Player
                    </>
                  )}
                </p>
              </div>

              <Menu>
                <MenuHandler>
                  <Button className="p-0 bg-transparent rounded-full">
                    {userInfo?.user?.avatar ? <>
                      <Avatar img={userInfo?.user?.avatar} />
                    </> : <>
                      <Avatar img={avatar} />
                    </>}
                  </Button>
                </MenuHandler>
                <MenuList className="menu-list">
                  <MenuItem className="p-0">
                    <Link to="#" className="px-3 py-2 flex">
                      {/* <Link to="/home/wallet/balance" className="px-3 py-2 flex"> */}
                      Wallet
                    </Link>
                  </MenuItem>
                  <MenuItem className="p-0">
                    <Link to="/home/wallet/withDraw" className="px-3 py-2 flex">
                      Withdraw
                    </Link>
                  </MenuItem>
                  <MenuItem className="p-0">
                    <Link
                      to="/home/wallet/trasaction"
                      className="px-3 py-2 flex"
                    >
                      Transaction
                    </Link>
                  </MenuItem>
                  <MenuItem className="p-0">
                    <Link
                      className="px-3 py-2 flex"
                      onClick={(e) => {
                        e.preventDefault();
                        setusername(true);
                      }}
                    >
                      My Profile
                    </Link>
                  </MenuItem>
                  <MenuItem className="p-0">
                    <Link to="/home/global-settings" className="px-3 py-2 flex">
                      Global Settings
                    </Link>
                  </MenuItem>
                  <MenuItem className="p-0">
                    <div className="px-3 py-2 flex" onClick={handleLogout}>
                      Logout
                    </div>
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          )}

          <svg
            onClick={(e) => {
              document.querySelector("aside").classList.toggle("active");
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#fff"
            className="w-6 h-6 hidden burger-icon"
          >
            <path
              fillRule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </header>
    </>
  );
};
