import React, { useState } from "react";

export const SwitchStake = ({ setmonths }) => {
  const [active, setActive] = useState(2);
  return (
    <div className="bg-[#111010] border-[1px] border-[#3C403F] h-[48px] w-[184px]  flex items-center flex-1 justify-between rounded-xl ml-3  sm:!h-10  ">
      <button
        className={`text-[#B7B8BC] cursor-pointer h-full w-[91px] rounded-xl text-[18px] font-arabicreg  ${
          active === 1 &&
          "bg-gradient-to-br from-[#9BE402] to-[#1FA83C] !text-[#111010]"
        }

        lg:flex-1
       sm:!text-[16px] sm:!rounded-lg
       xs:!text-[14px]
        `}
        onClick={(e) => {
          setActive(1);
          setmonths(9);
        }}
      >
        9 Month
      </button>
      <button
        className={`text-[#B7B8BC] cursor-pointer h-full w-[93px] rounded-xl text-[18px] font-arabicreg ${
          active === 2 &&
          "bg-gradient-to-br from-[#9BE402] to-[#1FA83C] !text-[#111010]"
        }
        lg:flex-1
        sm:!text-[16px] sm:!rounded-lg
        xs:!text-[14px]
        `}
        onClick={(e) => {
          setActive(2);
          setmonths(12);
        }}
      >
        12 Month
      </button>
    </div>
  );
};
