import React, { useState } from "react";
import "../assets/css/settings/index.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

// images
import close from "../assets/img/settings/svg/close.svg";
import arrow from "../assets/img/settings/svg/arrow.svg";
// components
import { SettingsOptions } from "../components/common/SettingsOptions";
import { useNavigate } from "react-router-dom";
import { countryCode } from "../utils/phone";

export const GeneralMailPhoneLeft = () => {
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();

  return (
    <div className="body-left-area w-full">
      <div className="settings-area  rounded-lg">
        <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
          <h1>Global Settings</h1>
          <img
            src={close}
            className="cursor-pointer"
            alt=""
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>

        <div className="settings-content-area flex items-start relative">
          <div className="settings-options-area flex flex-col p-3">
            <SettingsOptions active={3} />
          </div>

          <div className="settings-content-body-area settings-content-body-area-grid-settings gap-6 py-6 px-6 flex-1 grid grid-cols-2">
            <div className="form-card-mail relative">
              <h1>E-mail Verification</h1>
              <p className="mt-6">
                According to the security policy of GameBox, the registered
                email cannot be changed.
              </p>
              <div className="input-wrapper mt-6">
                <label htmlFor="mail">Your Mail</label>
                <div className="input-container rounded-lg  mt-2">
                  <input
                    type="text"
                    className="w-full px-6 bg-transparent h-full "
                    placeholder="example@gmail.com"
                  />
                </div>
              </div>

              <button className="mt-8 w-full rounded-lg">Confirm</button>
            </div>
            
            <div className="form-card-mail">
              <h1>Phone Number</h1>
              <p className="mt-6">
                Verify your phone number and you can use the phone as your
                second login method.
              </p>
              <div className="input-wrapper mt-6">
                <label htmlFor="mail">Your phone number</label>
                <div className="input-container flex items-center rounded-lg  mt-2 !border-0">
                <PhoneInput
                 countryCodeEditable={false}
                  inputClass="!w-full !bg-[#111010] !border-[#3c403f] !border-l-0"
                  buttonClass="!bg-[#252525]"
                  searchClass="phone-search-field w-full !ml-0 !p-3 !bg-[#252525] "
                  dropdownClass="!bg-[#1A1A1B]"
                  country={"eg"}
                  enableSearch={true}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                />
                </div>
              </div>
              <button className="mt-8 w-full rounded-lg">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
