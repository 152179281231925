class Charts {
  affiliateChart() {
    return {
      series: [
        {
          name: "Paid",
          data: [20, 5, 10, 15, 20, 25, 7, 4, 8, 2, 8, 30],
        },
      ],
      options: {
        dataLabels: {
          enabled: false,
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: ["#40CA9A"],
        plotOptions: {
          bar: {
            borderRadius: 4,
          },
        },
        grid: {
          row: {
            // colors: ["#F44336"],
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#fff",
              fontSize: "14px",
              fontFamily: "Arabic Pro Reg",
            },
          },
        },
        xaxis: {
          axisBorder: {
            show: false,
          },

          axisTicks: {
            show: false,
          },

          labels: {
            style: {
              colors: "#fff",
              fontSize: "14px",
              fontFamily: "Arabic Pro Reg",
            },
          },
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
    };
  }
}

const charts = new Charts();

export { charts };

