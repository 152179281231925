import React, { useState } from "react";
import { Link } from "react-router-dom";
import backImg from "../assets/img/transaction/back.svg";
import exportImg from "../assets/img/transaction/export.svg";
import { useGetTransactions } from "../queries/transactions";
import { useGetGBCTransactions } from "../queries/transactions";
import { AffiliateHeader } from "./AffiliateHeader";
import { Dropdown } from "./transaction/Dropdown";
import { Input } from "./transaction/Input";
import { TransactionTable } from "./transaction/TransactionTable";
export const TransactionHistoryRightArea = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const [txid, setTxid] = useState("");
  const [status, setStatus] = useState("");

  const transactionData = useGetGBCTransactions({
    page: 1,
    interval: '',
    date: '',
    flag: '',
  });
  const transactionDataList = transactionData.data?.data?.transactions ?? [];
  return (
    <div className="flex-1">
      <AffiliateHeader
        headerType="Affiliate"
        login={login}
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex-1  px-6 pt-6">
        <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
          <div className="flex items-center  px-6 h-[91px] border-[#3C403F] border-b-[1px]">
            <Link to="/">
              <img src={backImg} alt="" />
            </Link>
            <h1 className="text-white flex-1 text-[24px]  sm:text-[18px] ml-4">
              Transaction History
            </h1>
            <Link
              to="#"
              className="text-[14px] font-arabicmed text-white w-[236px] h-[43px]
               bg-[#2E2E2E] border-[1px] border-[#6FCF17] flex items-center justify-center rounded-lg sm:text-[12px] sm:w-[140px] gap-2 hover:bg-[#3C403F]"
            >
              <img src={exportImg} alt="" />
              Export Transaction History
            </Link>
          </div>

          <div className="px-6 py-8 ">
            {/* <div className="grid grid-cols-5 gap-6">
              <button
                className={`text-[18px] font-arabicbold text-white bg-[#252525]
                border-[#3C403F] border-[1px]  h-[50px] rounded-lg cursor-pointer ${
                  active === 1 && "bg-[#2B5849] border-[#5DF9C2]"
                }`}
                onClick={(e) => {
                  setActive(1);
                }}
              >
                Crypto
              </button>
              <button
                className={`text-[18px] font-arabicbold text-white bg-[#252525]
                border-[#3C403F] border-[1px]  h-[50px] rounded-lg cursor-pointer ${
                  active === 2 && "bg-[#2B5849] border-[#5DF9C2]"
                }`}
                onClick={(e) => {
                  setActive(2);
                }}
              >
                Fiat
              </button>
              <button
                className={`text-[18px] font-arabicbold text-white bg-[#252525]
                border-[#3C403F] border-[1px]  h-[50px] rounded-lg cursor-pointer ${
                  active === 3 && "bg-[#2B5849] border-[#5DF9C2]"
                }`}
                onClick={(e) => {
                  setActive(3);
                }}
              >
                Deposit
              </button>
              <button
                className={`text-[18px] font-arabicbold text-white bg-[#252525]
              border-[#3C403F] border-[1px]  h-[50px] rounded-lg cursor-pointer ${
                active === 4 && "bg-[#2B5849] border-[#5DF9C2]"
              }`}
                onClick={(e) => {
                  setActive(4);
                }}
              >
                Withdraw
              </button>
              <button
                className={`text-[18px] font-arabicbold text-white bg-[#252525]
              border-[#3C403F] border-[1px]  h-[50px] rounded-lg cursor-pointer ${
                active === 5 && "bg-[#2B5849] border-[#5DF9C2]"
              }`}
                onClick={(e) => {
                  setActive(5);
                }}
              >
                Stake
              </button>
            </div> */}

            <div className="grid grid-cols-4 gap-6  mb-10">
              {/* <Dropdown
                head="Type"
                list={[
                  {
                    data: "Transaction",
                    value: "Transaction",
                  },
                ]}
              /> */}
              {/* <Dropdown
                head="Time"
                list={[
                  {
                    data: "Past 30 days",
                    value: "Past 30 days",
                  },
                ]}
              /> */}

              <Dropdown
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                head="Status"
                list={[
                  {
                    data: "All",
                    value: "",
                  },
                  {
                    data: "Active",
                    value: "active",
                  },
                  {
                    data: "Processing",
                    value: "processing",
                  },
                  {
                    data: "Confirmed",
                    value: "confirmed",
                  },
                  {
                    data: "Completed",
                    value: "completed",
                  },
                  {
                    data: "Pending",
                    value: "pending",
                  },
                  {
                    data: "Approved",
                    value: "approved",
                  },
                  {
                    data: "Declined",
                    value: "declined",
                  },
                  {
                    data: "Failed",
                    value: "failed",
                  },
                ]}

              />
              <Input
                value={txid}
                onChange={(e) => setTxid(e.target.value)}
                head="TxID"
                placeholder="Enter TxID"
              />
            </div>

            <div>
              <TransactionTable
                transactionDataList={transactionDataList
                  ?.filter((el) => el._id?.includes(txid))
                  ?.filter((el) => el.status.includes(status))}
              />
            </div>

            {/* {Transactions.length > 0 && (
              <div className="flex items-center justify-center">
                <button
                  className="flex items-center text-[12px] font-arabicmed 
                  text-[#AEB2B1] gap-1"
                >
                  <img src={previous} alt="" />
                  Previous
                </button>

                <div className="flex items-center mx-2 gap-1">
                  <button className="px-2 py-1 text-[#FFF] text-[10px] font-arabicmed bg-[#2E2E2E] rounded">
                    1
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    2
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    3
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    ...
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    15
                  </button>
                </div>

                <button
                  className="flex items-center text-[12px] font-arabicmed 
                     text-[#fff] gap-1"
                >
                  Next
                  <img src={forward} alt="" />
                </button>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};
