import React, { useState } from "react";
import { stake } from "../../utils/modules/Stake";
import { CalculationBox } from "./CalculationBox";
import { EstimationCalculator } from "./EstimationCalculator";
import { SwitchStake } from "./SwitchStake";

export const CalculateEarning = () => {
  const [usdtvalue, setusdtvalue] = useState(0);
  const [months, setmonths] = useState(12);
  const [estimationValue, setestimationValue] = useState(0);

  return (
    <div className="bg-[#1A1A1B] mt-10 px-10 py-12 rounded-xl border-[1px] border-[#3C403F] sm:px-5">
      <h1 className="text-white mb-6 font-arabicmed text-[23px] sm:text-[20px]">
        Calculate your crypto earnings
      </h1>
      <div className="filter-area-data flex items-center justify-between lg:!flex-col lg:!gap-5 lg:items-stretch">
        <h1 className="text-white font-arabicmed text-[18px]">I have</h1>
        <CalculationBox setusdtvalue={setusdtvalue} usdtvalue={usdtvalue} />
        <h1 className="text-white font-arabicmed text-[18px]">
          and I am interested in
        </h1>

        <div className="flex items-center ml-[63px] lg:ml-[0]">
          <button className="text-[#6FCF17] text-[18px] font-arabicreg h-12 w-[99px] border-[1px] border-[#6FCF17] bg-[#394C28] rounded-lg sm:h-10 sm:text-[16px] xs:!text-[14px]">
            Lock-in
          </button>
          <SwitchStake setmonths={setmonths} />
        </div>
      </div>

      <div className="ml-28 xs:ml-0">
        <button
          className="text-white text-[18px] font-arabicbold bg-gradient-to-br from-[#9BE402] to-[#1FA83C] hover:from-[#82e402] hover:to-[#146626]
 w-[156px] h-[58px] mt-10 rounded-lg sm:text[16px] xs:w-full xs:h-[50px] xs:text-[15px]"
          onClick={(e) => {
            setestimationValue(stake.calculateEstimation(usdtvalue, months));
          }}
        >
          Calculate
        </button>
      </div>

      <div className="grid grid-cols-2 mt-[30px] mb-[40px] lg:grid-cols-1 lg:gap-10">
        <div className="no-offer-area">
          <h1 className="text-[23px] mb-6 font-arabicmed text-white sm:text[20px]">
            Staking Offer
          </h1>
          <div className="low-risk-area l p-6 bg-[#252525] rounded-xl border-[1px] border-[#6FCF17] w-[274px] lg:w-[100%]">
            <span
              className="w-[80px] h-[16px] flex items-center justify-center text-white bg-gradient-to-br from-[#9BE402] to-[#1FA83C]
 text-[12px] rounded"
            >
              Low Risk
            </span>
            <p className="mt-6 text-white font-arabicmed text-[24px]">7%</p>
          </div>
          <button className="text-white text-[18px] font-arabicbold bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-[212px] h-[50px] mt-10 rounded-lg xs:w-full  xs:text-[15px] hover:from-[#82e402] hover:to-[#146626]">
            Stake Now
          </button>
        </div>
        <div className="estimation-area">
          <h1 className="text-white font-arabicmed text-[23px] sm:text[16px]">
            Estimated Earnings
          </h1>
          <h2 className="text-[#6FCF17] text-[23px] font-arabicmed">
            ${estimationValue.toFixed(2)} USD
          </h2>
          <EstimationCalculator
            estimationValue={estimationValue}
            months={months}
          />
        </div>
      </div>

      <p className="text-[16px] font-arabicmed text-[#AEB2B1] sm:text[14px]">
        This <span className="text-[#6FCF17]">calculation</span> is an estimate
        of rewards you will earn in Gaming Revenue Rewards (GRR) over the
        selected timeframe. It does not display the actual or predicted APR in
        any fiat currency. GRR is subject to change at any time and the
        estimated earnings may be different from the actual earnings generated.
      </p>
    </div>
  );
};
