import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../assets/css/home.css";
import { Aside } from "../components/Aside";
import { Footer } from "../components/Footer";
import { RepeatPassword } from "../components/RepeatPassword";
import { SignIn } from "../components/SignIn";
import { SignUp } from "../components/SignUp";
import { SignUpPhone } from "../components/SignUpPhone";
import GameIndex from "../components/casino/CasinoIndex";

export const AllGames = ({ category }) => {
  const { existingcategory, subcategory } = useParams();
  const [loginType, setloginType] = useState(true);
  const [signInState, setsigninState] = useState(false);
  const [RepeatPasswordState, setRepeatPasswordState] = useState(false);
  const [SignUpState, setSignUpState] = useState(false);
  const [SignUpPhoneState, setSignUpPhoneState] = useState(false);

  return (
    <div>
      <div className="flex items-start home">
        {signInState && (
          <SignIn
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setRepeatPasswordState={setRepeatPasswordState}
            setloginType={setloginType}
          />
        )}
        {RepeatPasswordState && (
          <RepeatPassword
            setsigninState={setsigninState}
            setRepeatPasswordState={setRepeatPasswordState}
          />
        )}

        {SignUpState && (
          <SignUp
            setsigninState={setsigninState}
            setSignUpState={setSignUpState}
            setSignUpPhoneState={setSignUpPhoneState}
            setloginType={setloginType}
          />
        )}
        {SignUpPhoneState && (
          <SignUpPhone
            setsigninState={setsigninState}
            setSignUpPhoneState={setSignUpPhoneState}
            setloginType={setloginType}
          />
        )}

        {/* left area */}
        {/* <Aside
          activeSwitch={
            window.sessionStorage.getItem("currentMenu") == null
              ? "affiliate-switch"
              : window.sessionStorage.getItem("currentMenu")
          }
        /> */}
        <Aside activeSwitch="player-switch" />

        {/* right area */}
        <GameIndex
          login={loginType}
          setsigninState={setsigninState}
          setRepeatPasswordState={setRepeatPasswordState}
          setSignUpState={setSignUpState}
          setSignUpPhoneState={setSignUpPhoneState}
          category={category || existingcategory}
          subcategory={subcategory}
        />
      </div>

      <Footer />
    </div>
  );
};
