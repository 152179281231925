import React, { useState } from "react";

import WorldMap from "react-svg-worldmap";
import banner1 from "../assets/img/affiliate/slide/Become_game_box_co.png";
import banner1mbl from "../assets/img/affiliate/slide/mobile-banner.jpeg";
import { useAuth } from "../contexts/AuthContext";
import { useGetCommunityTeamList } from "../queries/community";
import { AffiliateHeader } from "./AffiliateHeader";
import { SidebarArea } from "./SidebarArea";
import { CommunityTable } from "./affiliate/CommunityTable";

export const CommunityTeamRight = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const [copied, setCopied] = useState(false);
  const [active, setactive] = useState(1);
  const [typeShowAside, settypeShowAside] = useState(-1);

  const { setUserInfo, userInfo } = useAuth();

  const { data: communityList } = useGetCommunityTeamList();

  const countriesData = [
    { country: "cn", value: 1389618778 }, // china
    { country: "in", value: 1311559204 }, // india
    { country: "us", value: 331883986 }, // united states
    { country: "id", value: 264935824 }, // indonesia
    { country: "pk", value: 210797836 }, // pakistan
    { country: "br", value: 210301591 }, // brazil
    { country: "ng", value: 208679114 }, // nigeria
    { country: "bd", value: 161062905 }, // bangladesh
    { country: "ru", value: 141944641 }, // russia
    { country: "mx", value: 127318112 }, // mexico
    { country: "ph", value: 127318112 }, // mexico
  ];
  const getStyle = ({ countryCode, color }) => {
    let finder = countriesData.find(
      (data) => data.country == countryCode.toLowerCase()
    );
    console.log(countryCode);
    return {
      fill: finder != undefined ? "#6FCF17" : color,
      stroke: "#3C403F",
      strokeWidth: 1,
      strokeOpacity: 1,
      cursor: "pointer",
    };
  };
  // Function to handle the copy action
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  const referralLink = userInfo
    ? `https://gamebox888.net/signup?ref=${userInfo.userCode}`
    : "";
  const userCode = userInfo ? userInfo.userCode : "";

  return (
    <div className="flex-1">
      <AffiliateHeader
        login={login}
        headerType="Affiliate"
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex affiliate-body-area items-start px-6 pt-6">
        <div className="body-left-area w-full">
          <img src={banner1} className="w-full rounded-lg sm:hidden" alt="" />
          <img
            src={banner1mbl}
            className="w-full rounded-lg hidden sm:block"
            alt=""
          />

          {/* <WelcomeArea /> */}

          <div className="bg-[#1A1A1B] w-full mt-10 border-[1px] border-[#3C403F] rounded-xl ">
            <h1 className="text-[24px] p-5 pt-6 pb-[39] font-arabicmed text-white border-b-[1px] border-b-[#3C403F]">
              Community Team
            </h1>

            <div className="p-8 ">
              {/* <div className="grid grid-cols-3 gap-6 md:grid-cols-2 sm:!grid-cols-1"> */}
              {/* <button
                  className={`text-[18px] font-arabicbold
                  text-white h-[50px] w-full
                   bg-[#252525] border-[1px] border-[#3C403F]
                    rounded-lg ${
                      active === 1 && "border-[#6FCF17] bg-[#275243]"
                    }`}
                  onClick={(e) => {
                    setactive(1);
                  }}
                >
                  Players
                </button>
                <button
                  className={`text-[18px] font-arabicbold
                 text-white h-[50px] w-full
                  bg-[#252525] border-[1px] border-[#3C403F]
                   rounded-lg ${
                     active === 2 && "border-[#6FCF17] bg-[#275243]"
                   }`}
                  onClick={(e) => {
                    setactive(2);
                  }}
                >
                  Stakers
                </button>
                <button
                  className={`text-[18px] font-arabicbold
                 text-white h-[50px] w-full
                  bg-[#252525] border-[1px] border-[#3C403F]
                   rounded-lg ${
                     active === 3 && "border-[#6FCF17] bg-[#275243]"
                   }`}
                  onClick={(e) => {
                    setactive(3);
                  }}
                >
                  Affiliates
                </button> */}
              {/* <button
                  className={`text-[18px] font-arabicbold
                   text-white h-[50px] w-full
                    bg-[#252525] border-[1px] border-[#3C403F]
                     rounded-lg ${
                       active === 4 && "border-[#6FCF17] bg-[#275243]"
                     }`}
                  onClick={(e) => {
                    setactive(4);
                  }}
                >
                  Team Leaders
                </button>
                <button
                  className={`text-[18px] font-arabicbold
                  text-white h-[50px] w-full
                   bg-[#252525] border-[1px] border-[#3C403F]
                    rounded-lg ${
                      active === 5 && "border-[#6FCF17] bg-[#275243]"
                    }  md:col-span-2 sm:!col-span-1`}
                  onClick={(e) => {
                    setactive(5);
                  }}
                >
                  Master Leaders
                </button> */}
              {/* </div> */}

              <div className="lgmed:overflow-auto">
                <CommunityTable data={communityList} />
              </div>

              {/* <div className="flex items-center justify-center">
                <button
                  className="flex items-center text-[12px] font-arabicmed 
                  text-[#AEB2B1] gap-1"
                >
                  <img src={previous} alt="" />
                  Previous
                </button>

                <div className="flex items-center mx-2 gap-1">
                  <button className="px-2 py-1 text-[#FFF] text-[10px] font-arabicmed bg-[#2E2E2E] rounded">
                    1
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    2
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    3
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    ...
                  </button>
                  <button className="px-2 py-1 text-[#AEB2B1] text-[10px] font-arabicmed">
                    15
                  </button>
                </div>

                <button
                  className="flex items-center text-[12px] font-arabicmed 
                     text-[#fff] gap-1"
                >
                  Next
                  <img src={forward} alt="" />
                </button>
              </div> */}

              {/* <div className="bg-[#1A1A1B]  border-[1px] border-[#3C403F] rounded-xl py-8 relative">
                <div className="flex px-8 items-center justify-between">
                  <h1 className="text-[24px] font-arabicmed text-white">
                    By Country
                  </h1>
                </div>
                <div className="world-map">
                  <WorldMap
                    tooltipBgColor="#3FC999"
                    tooltipTextColor="#000"
                    color="#252525"
                    size="responsive"
                    data={countriesData}
                    styleFunction={getStyle}
                  />
                </div>
                <img src={mapCommunity} alt="" className="w-full mt-20 mb-14" />
                <div className="gap-col-4 px-8  w-[487px] lgmed:w-full grid grid-cols-3 gap-row-[10px]">
                <div className="gap-col-4 px-8 w-[487px] lgmed:w-full">
                  <div className="grid grid-cols-3 gap-2 lg:grid-cols-1 gap-row-[10px]">
                    <div className="flex items-center">
                      <p className="text-[12px] font-arabicreg text-white">
                        Players 0
                      </p>
                      <span className="w-2 h-2 block rounded-full bg-[#6FCF17] ml-1"></span>
                    </div>
                    <div className="flex items-center col-span-2">
                      <p className="text-[12px] font-arabicreg text-white">
                        Stakers 0
                      </p>
                      <span className="w-2 h-2 block rounded-full bg-[#36B5FF] ml-1"></span>
                    </div>
                    <div className="flex items-center">
                      <p className="text-[12px] font-arabicreg text-white">
                        Junior Associate 0
                      </p>
                      <span className="w-2 h-2 block rounded-full bg-[#C84716] ml-1"></span>
                    </div>
                    <div className="flex items-center sm:col-span-2">
                      <p className="text-[12px] font-arabicreg text-white">
                        Senior Partners
                      </p>
                      <span className="w-2 h-2 block rounded-full bg-[#fff] ml-1"></span>
                    </div>
                    <div className="flex items-center">
                      <p className="text-[12px] font-arabicreg text-white">
                        Managing Directors
                      </p>
                      <span className="w-2 h-2 block rounded-full bg-[#ECA11E] ml-1"></span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="grid grid-cols-2 mt-10 gap-8 lgmed:grid-cols-1">
            {/* <div className="bg-[#1A1A1B] p-8 w-full border-[1px] border-[#3C403F] rounded-xl ">
              <h1 className="text-[24px] font-arabicmed text-white ">
                Top 10 Monthly Affiliates
              </h1>
              <div className="lgmed:overflow-auto">
                <MonthlyTable />
              </div>
            </div> */}


          </div>
        </div>
        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};
