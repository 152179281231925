import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import boxStake from "../assets/img/stake/box-stake.svg";
import dollar from "../assets/img/stake/dollar.svg";
import { useGetStakingStatistics } from "../queries/balance";
import { HeaderStaker } from "./HeaderStaker";
import { SidebarArea } from "./SidebarArea";
import { CancelPopup } from "./staker/CancelPopup";
import { OverviewTable } from "./staker/OverviewTable";
import { StakePopup } from "./staker/StakePopup";
import { TerminatePopup } from "./staker/TerminatePopup";

import banner2 from "../assets/img/banner/banner2.png";
import ReactApexChart from "react-apexcharts";
import { state3, state4, state5 } from "../utils/ChartFeatured";
import per_country from "../assets/img/admin/svg/per_country.svg";

export const RightAreaStakeDashboard = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(1);
  const [StakePopupState, setStakePopupState] = useState(false);
  const [terminate, setTerminate] = useState(false);
  const [terminateId, setTerminateId] = useState();
  const [cancel, setCancel] = useState(false);
  const [cancelId, setCancelId] = useState();
  const { data: statistics } = useGetStakingStatistics();
  const [activeGRR, setactiveGRR] = useState('Daily');
  const [activeGGR, setactiveGGR] = useState('Daily');
  const [activeNGR, setactiveNGR] = useState('Daily');
  const [typeShowAside, settypeShowAside] = useState(-1);

  return (
    <div className="flex-1">
      {StakePopupState && (
        <StakePopup setStakePopupState={setStakePopupState} />
      )}
      {terminate && (
        <TerminatePopup
          closePopup={() => {
            setTerminate(false);
            setTerminateId(null);
          }}
          terminateId={terminateId}
        />
      )}

      {cancel && (
        <CancelPopup
          closePopup={() => {
            setCancel(false);
            setCancelId(null);
          }}
          cancelId={cancelId}
        />
      )}



      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
     
     <div className="body-area flex gap-2 staker-dashboard items-start px-6 pt-6">
        <div className="body-left-area w-full">
          {/* <img
            src={banner2}
            className="w-full rounded-lg mb-6"
            alt=""
          /> */}
          <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">

            <div className="flex items-center justify-between px-6 h-[91px] border-[#3C403F] border-b-[1px] sm:px-4 sm:py-4  sm:h-[unset]">
              <h1 className="text-white text-[24px] text-center sm:text-[18px]">
                Stake Portfolio
              </h1>
              <Link
                to="/"
                className="text-[14px] font-arabicmed text-white w-[185px] h-[43px]
                bg-[#2E2E2E] border-[1px] border-[#6FCF17] flex items-center justify-center rounded-lg sm:text-[10px] sm:w-[130px] sm:h-[30px] hover:bg-[#3C403F] "
              >
                Go to main Dashboard
              </Link>
            </div>

            {/* <div className="charts-wrapper grid grid-cols-3 gap-4 mt-8">
              
              <div className="chart py-4 rounded-xl">
                <div className="chart-top px-4 pb-2 ">
                  <h1>Total GRR</h1>
                </div>
                <div className="flex justify-between px-4 pt-2">
                  <p className="text-[18px] font-arabicmed text-white"> $ 0</p>
                  <div className="flex justify-between gap-2">
                  <button
                    className={`text-[12px] font-arabicmed ${activeGRR === 'Daily' ? 'bg-[#6FCF17] text-black rounded-2xl px-2' : 'text-white'}`}
                    onClick={() => setactiveGRR('Daily')}
                  >
                    Daily
                  </button>
                  <button
                    className={`text-[12px] font-arabicmed ${activeGRR === 'Weekly' ? 'bg-[#6FCF17] text-black rounded-2xl px-2' : 'text-white'}`}
                    onClick={() => setactiveGRR('Weekly')}
                  >
                    Weekly
                  </button>
                  <button
                    className={`text-[12px] font-arabicmed ${activeGRR === 'Monthly' ? 'bg-[#6FCF17] text-black rounded-2xl px-2' : 'text-white'}`}
                    onClick={() => setactiveGRR('Monthly')}
                  >
                    Monthly
                  </button>
                  </div>
                </div>
                <div>
                  <ReactApexChart
                    options={state3.options}
                    series={state3.series} 
                    type="area" 
                    height={180}
                  />
                </div>
              </div>

              <div className="chart  py-4 rounded-xl">
                <div className="chart-top px-4 pb-2">
                  <h1>Total GGR</h1>
                </div>
                <div className="flex justify-between px-4 pt-2">
                  <p className="text-[18px] font-arabicmed text-white"> $ 0</p>
                  <div className="flex justify-between gap-2">
                  <button
                    className={`text-[12px] font-arabicmed ${activeGGR === 'Daily' ? 'bg-[#6FCF17] text-black rounded-2xl px-2' : 'text-white'}`}
                    onClick={() => setactiveGGR('Daily')}
                  >
                    Daily
                  </button>
                  <button
                    className={`text-[12px] font-arabicmed ${activeGGR === 'Weekly' ? 'bg-[#6FCF17] text-black rounded-2xl px-2' : 'text-white'}`}
                    onClick={() => setactiveGGR('Weekly')}
                  >
                    Weekly
                  </button>
                  <button
                    className={`text-[12px] font-arabicmed ${activeGGR === 'Monthly' ? 'bg-[#6FCF17] text-black rounded-2xl px-2' : 'text-white'}`}
                    onClick={() => setactiveGGR('Monthly')}
                  >
                    Monthly
                  </button>
                  </div>
                </div>
                <div>
                  <ReactApexChart
                    options={state4.options}
                    series={state4.series} 
                    type="area" 
                    height={180}
                  />
                </div>
              </div>

              <div className="chart  py-4 rounded-xl">
                <div className="chart-top px-4 pb-2">
                  <h1>Total NGR</h1>
                </div>
                <div className="flex justify-between px-4 pt-2">
                  <p className="text-[18px] font-arabicmed text-white"> $ 0</p>
                  <div className="flex justify-between gap-2">
                  <button
                    className={`text-[12px] font-arabicmed ${activeNGR === 'Daily' ? 'bg-[#6FCF17] text-black rounded-2xl px-2' : 'text-white'}`}
                    onClick={() => setactiveNGR('Daily')}
                  >
                    Daily
                  </button>
                  <button
                    className={`text-[12px] font-arabicmed ${activeNGR === 'Weekly' ? 'bg-[#6FCF17] text-black rounded-2xl px-2' : 'text-white'}`}
                    onClick={() => setactiveNGR('Weekly')}
                  >
                    Weekly
                  </button>
                  <button
                    className={`text-[12px] font-arabicmed ${activeNGR === 'Monthly' ? 'bg-[#6FCF17] text-black rounded-2xl px-2' : 'text-white'}`}
                    onClick={() => setactiveNGR('Monthly')}
                  >
                    Monthly
                  </button>
                  </div>
                </div>
                <div>
                  <ReactApexChart
                    options={state5.options}
                    series={state5.series} 
                    type="area" 
                    height={180}
                  />
                </div>
              </div>

          </div> */}

            {/* <div className="border-[#3C403F] border-b-[1px] px-6 py-8 sm:px-4 sm:py-4">
              <div className="flex  items-center sm:hidden">
                <img src={dollar} alt="" />
                <div className="grid grid-cols-2 sm:grid-cols-1  ml-4 w-full flex-1">
                  <h1 className="text-[32px] font-arabicbold text-white  md:text-[14px]">
                    Total GRR
                  </h1>
                  <h2 className="text-[24px] flex items-center justify-between font-arabicmed text-white  md:text-[14px]">
                    Total Staked Balance
                    <p className="text-[#AEB2B1]">
                      ${parseFloat(statistics?.totalStakingBalance).toFixed(2)}
                    </p>
                  </h2>
                  <h2 className="text-[24px] font-arabicmed text-white  md:text-[14px]">
                    ${parseFloat(statistics?.totalStakingReward).toFixed(2)}
                  </h2>
                  <h2 className="text-[24px] flex justify-between items-center font-arabicmed text-white  md:text-[14px]">
                    <p>
                      Monthly GRR{" "}
                      <span className="block text-[#AEB2B1] text-[12px] font-arabicmed">
                        Gaming Revenue Reward (GRR) (Last Month)
                      </span>
                    </p>
                    <p className="text-[#AEB2B1]">
                      ${parseFloat(statistics?.lastMonthStakingReward).toFixed(2)}
                    </p>
                  </h2>
                </div>
              </div>

              <div className="hidden  items-center sm:flex">
                <img src={dollar} alt="" className="w-[60px] h-[60px] mr-4" />
                <div>
                  <h1 className="text-[24px] font-arabicbold text-white  md:text-[14px]">
                    Total GRR
                  </h1>
                  <h2 className="text-[22px] font-arabicmed text-white  md:text-[14px]">
                    ${parseFloat(statistics?.totalStakingReward)?.toFixed(2)}
                  </h2>
                </div>
              </div> 

              <div className="hidden sm:grid grid-cols-1 mt-6 w-full gap-3 flex-1">
                <h2 className="text-[16px] flex items-center justify-between font-arabicmed text-white">
                  Total Staked Balance
                  <p className="text-[#AEB2B1]">
                    ${statistics?.totalStakingBalance?.toFixed(2)}
                  </p>
                </h2>

                <h2 className="text-[16px] flex justify-between items-center font-arabicmed text-white">
                  <p>
                    Monthly GRR{" "}
                    <span className="block text-[#AEB2B1] text-[12px] font-arabicmed">
                      Gaming Revenue Reward (GRR)
                    </span>
                  </p>
                  <p className="text-[#AEB2B1]">
                    ${statistics?.lastMonthStakingReward}
                  </p>
                </h2>
              </div>

              <div className="flex items-center gap-4">
                <button
                  onClick={(e) => {
                    setActive(1);
                  }}
                  className={`h-[43px] border-[1px] w-[100px] bg-[#252525] font-arabicmed border-[#3C403F]  text-white text-[14px] rounded-lg  md:text-[14px] ${
                    active === 1 && "border-[#6FCF17]"
                  }`}
                >
                  Overview
                </button>
                <button
                  onClick={(e) => {
                    setActive(2);
                  }}
                  className={`h-[43px] border-[1px] w-[125px] bg-[#252525] font-arabicmed border-[#3C403F] text-white text-[14px] rounded-lg  md:text-[14px] ${
                    active === 2 && "border-[#6FCF17]"
                  }`}
                >
                  Monthly GRR
                </button>
                <button
                  onClick={(e) => {
                    setActive(3);
                  }}
                  className={`h-[43px] border-[1px] w-[85px] bg-[#252525] font-arabicmed border-[#3C403F] text-white text-[14px] rounded-lg  md:text-[14px]  ${
                    active === 3 && "border-[#6FCF17]"
                  }`}
                >
                  Staked
                </button>
              </div>
            </div> */}

            <div className="px-6 py-8">
              <div className="border-[1px] pr-10 border-[#3C403F] grid rounded-xl grid-cols-[1fr_0.5fr] lg:grid-cols-1 lg:px-5">
                <div className="flex-1 flex items-center pr-3 sm:flex-col pl-3">
                  <img src={boxStake} alt="" />
                  <div className="sm:flex sm:flex-col sm:items-center sm:pb-5 sm:min-w-full">
                    <h1 className="text-white text-[24px] md:text-[20px] sm:text-center font-arabicmed sm:text-[18px]">
                      GameBox Staking
                    </h1>
                    <p className="text-[#AEB2B1] text-[18px] sm:text-center md:text-[14px]  mt-4 mb-3 font-arabicreg sm:text-[14px]">
                      Your are currently staking at{" "}
                      <span className="text-[#6FCF17]">GameBox</span> – Your
                      gateway to the most secure and diversified staking in the
                      gaming industry.
                    </p>
                    <button
                      className="text-[#FFF] text-[18px] font-arabicmed bg-gradient-to-br from-[#9BE402] to-[#1FA83C] hover:from-[#82e402] hover:to-[#146626]
                      h-[50px] w-[223px] rounded-lg   md:text-[14px] md:w-[120px] md:h-[40px] sm:text-[12px] sm:min-w-[100%]"
                      onClick={(e) => {
                        navigate("/stake");
                      }}
                    >
                      Stake More
                    </button>
                  </div>
                </div>
                <div className="flex flex-col justify-between py-5 ">
                  <div className="flex items-center justify-between">
                    <h1 className="text-white text-[24px] md:text-[20px] sm:text-center font-arabicmed sm:!text-[18px]">
                      Daily GRR
                    </h1>
                    <p className="text-white text-[24px] md:text-[20px] sm:text-center font-arabicmed sm:!text-[18px]">
                      ${statistics?.totalStakingReward?.toFixed(2)}
                    </p>
                  </div>
                  <Link
                    to="/stake/daily-grr"
                    className="text-[#AEB2B1] text-[24px] md:text-[20px] sm:text-center font-arabicmed sm:!text-[18px] text-right hover:text-[#fff]"
                  >
                    View Daily GRR
                  </Link>
                </div>
                {/* <div className="w-[276px] md:flex md:justify-between lgmed:w-full border-l-[1px] border-l-[#3C403F]">
                  <div className="p-6 border-b-[1px] border-b-[#3C403F] md:border-0">
                    <h2 className="text-[16px]  md:text-[14px] font-arabicreg text-[#AEB2B1]">
                      ACTIVE STAKING
                    </h2>
                    <h1 className="text-[29px] flex items-center gap-1  md:text-[14px] font-arabicmed text-white">
                      <img src={usd} alt="" /> <p className="flex-1">0</p>
                    </h1>
                  </div>
                  <div className="p-6 ">
                    <h2 className="text-[16px]  md:text-[14px] font-arabicreg text-[#AEB2B1]">
                      Daily GRR
                    </h2>
                    <h1 className="text-[29px] flex items-center gap-1  md:text-[14px] font-arabicmed text-white">
                      <img src={usd} alt="" />
                      <p className="flex-1">0</p>
                      <Link
                        to="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setStakePopupState(true);
                        }}
                        className="text-[#6FCF17] text-[16px]"
                      >
                        View More
                      </Link>
                    </h1>
                  </div>
                </div> */}
              </div>

              <div className="overflow-auto">
                {active === 1 && (
                  <OverviewTable
                    setTerminate={setTerminate}
                    setTerminateId={setTerminateId}
                    setCancel={setCancel}
                    setCancelId={setCancelId}
                  />
                )}
                {/* {active === 2 && <ActivityTable />}
                {active === 3 && <StakedTable />} */}
              </div>
            </div>
          </div>
        </div>
        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};
