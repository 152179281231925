import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import cashWallet from "../assets/img/cash-wallet.svg";
import { privateRequest } from "../config/axios.config";
import { useGetBalance, usePayEplayment } from "../queries/balance";
import { HeaderStaker } from "./HeaderStaker";
import { PayDropdown } from "./popups/PayDropdown";
import { SwitchPayment } from "./staker/SwitchPayment";

export const RightAreaStakePaymentCash = ({
  setsigninState,
  setSignUpState,
  login,
  active,
}) => {
  const navigate = useNavigate();

  const [cryptoCode, setcryptoCode] = useState("BTC");

  const { data: balance } = useGetBalance();
  const [loading, setLoading] = useState(false);

  const stakeNow = () => {
    const amount = window.sessionStorage.getItem("stake-amount");
    const id = window.sessionStorage.getItem("stake-package-id");
    const payload = {
      packageId: id,
      amount: amount,
      paymentMethod: "balance",
    };

    setLoading(true);

    privateRequest
      .post("user/stacking/subscribe", payload)
      .then((res) => {
        navigate("/stake/done", { state: { data: res.data.subscription } });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message ?? "Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [selectedCurrency, setSelectedCurrency] = useState("PHP");
  const [paycheck, setPaycheck] = useState(true);
  const [calculatedValue, setCalculatedValue] = useState(0);
  const [showGcashButton, setShowGcashButton] = useState(true);
  const [loadingGcash, setLoadingGcash] = useState(false);
  const [loadingEplayment, setLoadingEplayment] = useState(false);
  const [usdAmount, setUsdAmount] = useState("");
  const payEplaymentMutation = usePayEplayment();

  const payEplayment = async (currency, provider, eplaymentOption) => {
    try {
      setLoading(true);
      if (eplaymentOption === "gcash") {
        setLoadingGcash(true);
      } else if (eplaymentOption === "select") {
        setLoadingEplayment(true);
      }
      const amountNumber = Number(usdAmount);
      if (amountNumber < 10) {
        toast.error(`The minimum amount is 50 USD`);
        setLoading(false);
        return;
      }
      const data = await payEplaymentMutation.mutateAsync({
        amount: usdAmount,
        currency,
        eplaymentOption: eplaymentOption ? eplaymentOption : undefined,
        provider,
      });
      // Redirect to redUrl on success
      window.location.href = data.redUrl;
      setTimeout(() => {
        window.location.href = data.redUrl; // Redirect to redUrl on success
      }, 2000); // 2000 milliseconds = 2 seconds
      setLoadingGcash(false);
      setLoadingEplayment(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message ?? "Something went wrong");
      setLoadingGcash(false);
      setLoadingEplayment(false);
      console.error("Payment error:", error);
      // Handle error (e.g., show error message)
    }
  };

  return (
    <div className="flex-1">
      <ToastContainer theme="dark" />
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex-1 px-6 pt-6 DepositePopUp">
        <div className="px-10 py-6 bg-[#1A1A1B] border-[1px] rounded-xl border-[#3C403F]  sm:px-5">
          <div className="flex items-center gap-8 mb-8 sm:gap-2 sm:flex-col sm:items-start">
            <h1 className="text-white text-[22px] font-arabicmed">
              Payment method
            </h1>
            <SwitchPayment active={active} classStyle="sm:ml-0 sm:mt-0 " />
          </div>

          <div className="bg-[#252525] p-6 rounded-xl">
            <div className="flex items-center justify-between xs:flex-col">
              <h1 className="text-[18px] sm:text-[14px] font-arabicmed text-white">
                Select your wallet
              </h1>
              <p className="text-[#AEB2B1] text-[18px] sm:text-[14px] font-arabicreg">
                Your Available balance{" "}
                {balance?.balances?.cashWallet?.toFixed(2) ?? 0} USD
              </p>
            </div>
            <div className="mt-4 w-full pr-6 pl-[6px] h-[50px] rounded-[10px] flex items-center bg-[#2E2E2E]">
              <PayDropdown
                stake
                classStyle="bg-[#3A3A3A] min-w-[196px] sm:min-w-[120px]"
                dollar={cashWallet}
                heading="Cash Wallet"
                setcryptoCode={setcryptoCode}
              />
            </div>
          </div>

          <div className="flex space-x-4 !mt-12 mb-10 p-4 buttons-wrapper">
            <button
              className="active px-20 !text-lg transition duration-200 ease-in-out"
              onClick={stakeNow}
              disabled={loading}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
