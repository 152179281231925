import copy from 'copy-to-clipboard';
import React, { useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import copyIcon from "../../assets/img/wallet/copy.svg";
import depositeSteps from "../../assets/img/wallet/deposite-steps.svg";
import { useGetAllWalletAddresses, useGetWallet } from "../../queries/wallet";
import { getCoinName } from "../../utils";
export const DepositeCrypto = () => {
  const refText = useRef(null);
  const notify = () => toast("Deposit Address Coppied");

  const { pathname } = useLocation();
  const { data: wallets } = useGetWallet();
  const selectedCoin = pathname?.replace("/wallet/deposit/", "");
  const selectedWallet = wallets?.find((wallet) => wallet?.currency === selectedCoin);
  const { data: cryptoWalletAddress } = useGetAllWalletAddresses();
  const params = useParams();
  const coin = params?.coin;
  const [walletAddresses, setWalletAddress] = useState(cryptoWalletAddress?.data?.[coin]?.[0]?.address);


  return (
    <div>
      <h1 className="text-[24px] text-white font-arabicmed">Deposit Crypto</h1>

      <div className="flex items-start mt-[51px] gap-12 ">
        <img src={depositeSteps} alt="" className="h-[294px] md:hidden" />
        <div className="flex-1">
          {/* <div className="mb-[36px]">
            <label
              htmlFor="#"
              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
            >
              Select Coin
            </label>
            <PayDropdown
              stake
              classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
              dollar={Coin[0].img}
              heading={Coin[0].heading}
              list={Coin}
            />
          </div> */}
          <div className="mb-[42px] flex items-center gap-2">
            <label
              htmlFor="#"
              className="text-[18px] font-arabicreg text-[#AEB2B1]  block"
            >
              Selected Coin
            </label>
            <p className="text-[#fff]">
              {selectedCoin.includes('_') ?
                `${selectedCoin.split('_')[0]} (${selectedCoin.split('_')[1]})` :
                selectedCoin
              }

            </p>
            <img
              src={`https://cryptologos.cc/logos/thumbs/${getCoinName(
                selectedCoin.split('_')[0]
              )}.png?v=030`}
              alt=""
              className="h-7"
            />
          </div>
          {/* <div className="mb-[42px]">
            <label
              htmlFor="#"
              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
            >
              Select Network
            </label>
            <NetworkDropdown
              stake
              classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
              subheading={Network[0].subheading}
              heading={Network[0].heading}
              list={Network}
            />
          </div> */}
          <div>
            <h1 className="text-white text-[24px] font-arabicmed mb-3">
              Deposit Address
            </h1>
            <div className="bg-[#111010] border-[1px] border-[#3C403F] px-4 py-3 rounded-lg flex items-center gap-8 sm:flex-col sm:items-start">
              {selectedWallet?.address && (
                <QRCode
                  style={{ height: "auto", maxWidth: "100%", width: "140px" }} value={selectedWallet?.address} />
              )}
              <div className="flex w-full items-center flex-1 sm:flex-col sm:items-center sm:gap-2">
                <div className="flex-1 w-full">
                  <h1 className="text-[#AEB2B1] text-[20px] font-arabicreg mb-[10px]">
                    Address
                  </h1>
                  <input
                    type="text"
                    ref={refText}
                    className="bg-[transparent] border-0 outline-none text-[22px] text-white font-arabicmed flex-1 w-full"
                    value={walletAddresses}
                  />
                </div>
                <button
                  className="text-[#111010] text-[16px] font-arabicbold flex items-center gap-1 w-[180px] h-[50px] rounded-lg bg-gradient-to-br from-[#9BE402] to-[#1FA83C] justify-center sm:w-full"
                  onClick={(e) => {
                    copy(walletAddresses);
                    toast("Deposit Address Coppied");
                  }}
                >
                  <img src={copyIcon} alt="" />
                  Copy Address
                </button>
              </div>
            </div>

            {/* <div className="flex mt-6 items-center justify-between">
              <h1 className="text-[18px] font-arabicreg text-[#AEB2B1]">
                Minimum Deposit
              </h1>
              <p className="text-[18px] font-arabicreg text-[#FFFFFF]">
                0.00000001 BTC
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
