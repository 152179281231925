import React from "react";
import { useGetSubscription } from "../../queries/subscription";
import { dateFormatter } from "../../utils";

export const OverviewTable = ({ setTerminate, setTerminateId, setCancel, setCancelId }) => {
  const { data } = useGetSubscription();

  return (
    <div className="grid grid-cols-2 lg:grid-cols-1 gap-6">
      {data?.map((row, key) => (
        <div
          key={key}
          className={`border-[1px] rounded-xl lgmed:flex-col p-6 mt-6 ${
            row?.status?.toLowerCase() !== "terminated"
              ? "border-[#3C403F]"
              : "border-red-900"
          }`}
        >
          <ul className="mt-10 flex flex-col gap-6 sm:mt-3 sm:gap-3">
            {[
              { name: "Payment Method", value: row.paymentMethod },
              {
                name: "Amount",
                value: `$${row.subscriptionAmount?.toFixed(2)}`,
              },
              ...(row?.status?.toLowerCase() !== "pending"
                ? [
                    {
                      name: "Start Date",
                      value: dateFormatter(row.startDate),
                    },
                    {
                      name: "Expiry Date",
                      value: dateFormatter(row.expiryDate),
                    },
                  ]
                : []),
              {
                name: "Locked Months",
                value: `${row.lockedMonths} ${
                  row.lockedMonths > 1 ? "months" : "month"
                }`,
              },
              { name: "Status", value: row.status },
            ].map((item, index) => (
              <li
                key={index}
                className="flex justify-between items-center gap-3 font-arabicmed text-[18px] text-white sm:text-[14px] "
              >
                {item.name}
                <span className="capitalize">{item.value}</span>
              </li>
            ))}
          </ul>
          {row?.status?.toLowerCase() !== "terminated" &&
            row?.status?.toLowerCase() !== "pending" &&
            row?.status?.toLowerCase() !== "canceled" && (
              <button
                className="mt-10 w-full text-[#FFF] text-[18px] font-arabicmed bg-gradient-to-br from-[#9BE402] to-[#1FA83C] h-[50px]  rounded-lg  md:text-[14px] md:w-[120px] md:h-[40px] hover:from-[#82e402] hover:to-[#146626]"
                onClick={(e) => {
                  setTerminate(true);
                  setTerminateId(row._id);
                }}
              >
                Terminate
              </button>
            )}
          {row?.status?.toLowerCase() === "pending" && (
            <button
              className="mt-10 w-full text-[#111010] text-[18px] font-arabicbold bg-gradient-to-br from-[#9BE402] to-[#1FA83C] h-[50px]  rounded-lg  md:text-[14px] md:w-[120px] md:h-[40px]"
              onClick={(e) => {
                setCancel(true);
                setCancelId(row._id);
              }}
            >
              Cancel
            </button>
          )}
        </div>
      ))}
    </div>
  );
  // return (
  //   <table className="w-full mt-8 md:min-w-[700px]">
  //     <thead>
  //       <tr className="bg-[#252525] border-[1px]  border-[#3C403F] h-[56px]">
  //         <th className="text-left pl-3  text-[18px] font-arabicreg text-[#AEB2B1]  md:text-[14px]">
  //           Asset Name
  //         </th>
  //         <th>
  //           <div className="flex items-center gap-2">
  //             <p className="text-left  md:text-[14px]  text-[18px] font-arabicreg text-[#AEB2B1]">
  //               Amount
  //             </p>
  //             <svg
  //               width="13"
  //               height="13"
  //               viewBox="0 0 13 13"
  //               fill="none"
  //               xmlns="http://www.w3.org/2000/svg"
  //             >
  //               <path
  //                 d="M2.20585 3.24257H11.0037C11.0928 3.24285 11.1801 3.26741 11.2562 3.31362C11.3324 3.35983 11.3945 3.42594 11.4359 3.50482C11.4772 3.5837 11.4963 3.67237 11.4911 3.76129C11.4858 3.85021 11.4564 3.93601 11.406 4.00945L7.00703 10.3635C6.82472 10.6269 6.3858 10.6269 6.203 10.3635L1.80408 4.00945C1.75316 3.93616 1.7233 3.85032 1.71774 3.76125C1.71219 3.67219 1.73115 3.5833 1.77256 3.50425C1.81397 3.4252 1.87626 3.35901 1.95265 3.31288C2.02904 3.26674 2.11661 3.24243 2.20585 3.24257Z"
  //                 fill="#AEB3B1"
  //               />
  //             </svg>
  //           </div>
  //         </th>
  //         <th className="text-left   md:text-[14px] text-[18px] font-arabicreg text-[#AEB2B1]">
  //           Portfolio%
  //         </th>
  //         <th className="text-left pr-3   md:text-[14px]  text-[18px] font-arabicreg text-[#AEB2B1]">
  //           Status
  //         </th>
  //       </tr>
  //     </thead>
  //     <tbody>
  //       {Dashboard.map((EachRow) => (
  //         <tr className="h-[56px] border-b-[1px] border-b-[#3C403F] border-r-[1px] border-r-[#3C403F] border-l-[1px] border-l-[#3C403F] ">
  //           <td>
  //             <div className="flex items-center pl-3 gap-3">
  //               <img src={EachRow.img} alt="" />
  //               <p className="text-[18px]  md:text-[14px] font-arabicreg text-white">
  //                 {EachRow.asname}
  //               </p>
  //             </div>
  //           </td>
  //           <td className="text-[18px]  md:text-[14px] font-arabicreg text-white">
  //             {EachRow.amount}
  //           </td>
  //           <td className="text-[18px]  md:text-[14px] font-arabicreg text-white">
  //             <span className="w-[142px] flex mb-2 bg-[#3C403F] min-h-[2px] rounded">
  //               <span
  //                 className={`bg-[#52E3B0] min-h-[2px] rounded`}
  //                 style={{ width: EachRow.profile }}
  //               ></span>
  //             </span>
  //             <p className="text-[18px]  md:text-[14px] font-arabicreg text-white">
  //               {EachRow.profile}
  //             </p>
  //           </td>
  //           <td>
  //             <div className="flex items-center  gap-4">
  //               <button
  //                 className={`h-[36px] border-[1px] w-[118px] bg-[#2B5849] font-arabicmed border-[#52E3B0] text-[#52E3B0] text-[16px] rounded-lg  md:text-[14px] md:w-[90px] md:h-[30px]`}
  //               >
  //                 Active
  //               </button>
  //               <button
  //                 className={`h-[36px] border-[1px] w-[118px] bg-[#2E2E2E] font-arabicmed border-[#3C403F] text-white text-[16px] rounded-lg  md:text-[14px] md:w-[90px] md:h-[30px]`}
  //               >
  //                 Expired
  //               </button>
  //             </div>
  //           </td>
  //         </tr>
  //       ))}
  //     </tbody>
  //   </table>
  // );
};
