import React, { useState } from "react";
import arrow from "../../assets/img/settings/svg/arrow.svg";
export const DropdownSettings = ({ img, heading, listdata }) => {
  const [data, setData] = useState({
    img: img,
    heading: heading,
  });
  const [active, setActive] = useState(false);
  return (
    <div className="DropdownSettings relative">
      <div
        onClick={(e) => {
          setActive(!active);
        }}
        className="DropdownSettingsHead cursor-pointer px-4 rounded-lg flex items-center"
      >
        <img src={data.img} alt="" />
        <h1 className="flex-1 ml-2">{data.heading}</h1>
        <img src={arrow} alt="" />
      </div>
      {active && (
        <ul className="absolute w-full z-50 h-[280px] overflow-auto">
          {listdata.map((EachList) => (
            <li
              onClick={(e) => {
                setActive(!active);
                setData(EachList);
              }}
              className="flex items-center cursor-pointer  w-full  px-4"
            >
              <img src={EachList.img} alt="" />
              <h1 className="flex-1 ml-2">{EachList.heading}</h1>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
