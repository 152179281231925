import React, { useEffect, useRef, useState } from "react";
import "../assets/css/wallet/index.css";

// images
import gcashlogo from "../assets/img/a-bank/Gcash.png";
import close from "../assets/img/settings/svg/close.svg";
// import step1 from "../assets/img/wallet/step1.svg";
import step1 from "../assets/img/sidebar/step1.png";
// import step2 from "../assets/img/wallet/step2.svg";
import step2 from "../assets/img/sidebar/step2.png";

// components
import { WalletOptions } from "./common/WalletOptions";

import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { privateRequest } from "../config/axios.config";
import { useAuth } from "../contexts/AuthContext";
import { useGetAllBalance, useGetBalance, usePayEplayment } from "../queries/balance";
import { useGetAvailableCryptos } from "../queries/crypto";
import { useGetAllWalletAddresses, useGetWallet } from "../queries/wallet";
import { getCoinName } from "../utils";
import ButtonLoader from "./ButtonLoader";
import { GA } from "./common/GA";
import { DepositeCurrencyDropdown } from "./popups/DepositeCurrencyDropdown";
import { PayDropdown } from "./popups/PayDropdown";
import DepositCryptoCurrencyDropdown from "./wallet/DepositCryptoCurrencyDropdown";

export const WithdrawBinanceLeft = () => {
  const ref = useRef(null);

  const { userInfo, refetch } = useAuth();
  const [optionDeposite, setoptionDeposite] = useState(1);
  const navigate = useNavigate();
  const params = useParams();
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [amount, setAmount] = useState();
  const [currencyAmount, setCurrencyAmount] = useState();
  const [name, setName] = useState();
  const [cellNo, setCellNo] = useState();
  const [coin, setCoin] = useState(params?.coin);
  const [address, setAddress] = useState();
  const [wallet, setWallet] = useState();
  const [usdAmount, setUsdAmount] = useState("");
  const [exchangeRate, setExchangeRate] = useState(0);
  const [calculatedValue, setCalculatedValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const payEplaymentMutation = usePayEplayment();
  const [paymentMethod, setPaymentMethod] = useState("crypto");
  const [fiatConditional, setfiatConditional] = useState(1);
  const [formattedCryptoData, setFormattedCryptoData] = useState([]);
  const [network, setNetwork] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [gasetter, setgasetter] = useState(false);
  const [onConfirm, setOnConfirm] = useState(true);
  const [otps, setOtps] = useState(["", "", "", "", "", ""]);
  const { data: walletAddresses, isLoading: isLoadingWalletAddresses } = useGetAllWalletAddresses();
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const { data: allBalance } = useGetAllBalance();

  // console.table(allBalance?.syntheticWalletBalance);
  const handleOtpsChange = (otps) => {
    setOtps(otps);
  };
  useEffect(() => {
    const activeCashWalletd = localStorage.getItem("activeCashWallet");

    if (activeCashWalletd) {
      setoptionDeposite(2);
    }

    if (params?.coin) {
      setCoin(params?.coin);
    }
  }, [params]);

  const { data: balance } = useGetBalance();

  const withDrawAmount = () => {
    if (!userInfo?.user?.is2FAEnabled) {
      toast.error(
        "Please configure your Google Authentication to continue the transfer"
      );
    } else {
      showGA();
    }
  };

  const showGA = () => {
    if (userInfo?.user?.is2FAEnabled) {
      setgasetter(true);
    }
  };

  const onConfirmWithdraw = () => {
    setIsDisabled(true);
    if (onConfirm) {
      handleWithdrawWithGA();
    }
  };

  const handleWithdrawWithGA = async () => {
    setIsDisabled(true);
    const otp = otps.join("");
    console.log(otp);
    const payload = {
      amount,
      currency:
        optionDeposite === 1
          ? coin
          : paymentMethod == "crypto"
            ? coin?.symbol
            : "php",
      walletAddress: address,
      paymentMethod: optionDeposite === 1 ? "crypto" : paymentMethod,
      name: name,
      cellNo: cellNo,
      otp,
    };
    withAmountMutation.mutate(payload);
  };

  const withAmountMutation = useMutation(
    (payload) => privateRequest.post("user/balance/withdraw-balance", payload),
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message ?? "Withdrawal successful");
        setAddress("");
        setAmount("");
        setCellNo("");
        setName("");
        setIsDisabled(false);
      },
      onError: (error) => {
        setIsDisabled(false);
        if (error?.response.data?.message) {
          toast.error(error?.response.data?.message);
          return;
        }
        toast.error("Withdrawal failed");
      },
    }
  );

  const { data: wallets } = useGetWallet();

  const selectedCoin = wallets?.find((el) => el.currency === coin);

  const confirmWithdraw = () => {
    if (optionDeposite == 1) {
      if (!coin) {
        toast.error("Please select a coin");
        return;
      }
    }
    if (!amount) {
      toast.error("Please enter an amount");
      return;
    }
    if (amount > selectedCoin?.balance) {
      toast.error("Insufficient balance");
      return;
    }

    setIsDisabled(true);
    withAmountMutation.mutate();
  };

  const handleAmountChange = (event) => {
    const amountInUSD = event.target.value;
    setAmount(amountInUSD);

    // Calculate the value based on the exchange rate
    const value = amountInUSD * exchangeRate;
    setCalculatedValue(value);
  };


  const handleCurrencyChange = (selectedCurrency) => {
    setSelectedCurrency(selectedCurrency);
    if (walletAddresses.data[selectedCurrency]) {
      setFilteredAddresses(walletAddresses.data[selectedCurrency]);
    } else {
      setFilteredAddresses([]);
    }


    if (allBalance && allBalance.syntheticWalletBalance[selectedCurrency]) {
      const balance = allBalance.syntheticWalletBalance[selectedCurrency];
      // Do something with the balance object for the selected currency
      setCurrencyAmount(balance?.total);
    } else {
      // Handle the case where the selected currency is not found
      console.log(`Balance for ${selectedCurrency} not found`);
    }
  };

  const withdrawCrypto = useMutation(
    (payload) => privateRequest.post("user/syntheticWallet/crypto-withdraw-balance", payload),
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message ?? "Withdrawal successful");
        setAddress("");
        setAmount("");
        setIsDisabled(false);
      },
      onError: (error) => {
        setIsDisabled(false);
        if (error?.response.data?.message) {
          toast.error(error?.response.data?.message);
          return;
        }
        toast.error("Withdrawal failed");
      },
    }
  );

  const confirmCryptoWithdraw = async () => {
    const payload = {
      amount: amount,
      address: address,
      currency: selectedCurrency,
      type: 'payout',
      network: wallet
    };

    console.log(payload);
    withdrawCrypto.mutateAsync(payload);
  }

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        // Modify this URL based on how your API expects to receive the currency parameter
        const url = "/user/balance/get-rate?currency=php";
        const response = await privateRequest.get(url);
        setExchangeRate(response.data.rate); // Assuming 'rate' is returned in the response
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };

    fetchExchangeRate();
  }, []);

  const payEplayment = async () => {
    try {
      setLoading(true);
      const amountNumber = Number(usdAmount);
      if (amountNumber < 10) {
        toast.error(`The minimum amount is 50 USD`);
        setLoading(false);
        return;
      }
      const data = await payEplaymentMutation.mutateAsync({
        amount: usdAmount,
      });
      // Redirect to redUrl on success
      window.location.href = data.redUrl;
      setTimeout(() => {
        window.location.href = data.redUrl; // Redirect to redUrl on success
      }, 2000); // 2000 milliseconds = 2 seconds
    } catch (error) {
      setLoading(false);
      toast.error(error.message ?? "Something went wrong");

      console.error("Payment error:", error);
      // Handle error (e.g., show error message)
    }
  };

  const {
    data: cryptoData,
    isLoading: loadingCryptos,
    error: cryptoError,
  } = useGetAvailableCryptos();
  useEffect(() => {
    if (cryptoData && cryptoData.data) {
      const newFormattedCryptoData = Object.entries(cryptoData.data).map(
        ([key, value]) => {
          return {
            symbol: key,
            heading: value?.symbol?.includes("_")
              ? `${value?.symbol.split("_")[0]} (${value?.symbol.split("_")[1]
              })`
              : value?.symbol,

            img: `https://cryptologos.cc/logos/thumbs/${getCoinName(
              value?.symbol.split("_")[0]
            )}.png?v=030`,
          };
        }
      );
      console.log("newFormattedCryptoData", newFormattedCryptoData);
      setFormattedCryptoData(newFormattedCryptoData);
    }
  }, [cryptoData]);

  return (
    <div className="body-left-area  mr-6 w-full">
      {gasetter && (
        <GA
          isLoading={withAmountMutation.isLoading}
          onOtpsChange={handleOtpsChange}
          confirm={onConfirmWithdraw}
          setgasetter={setgasetter}
        />
      )}
      {/* <div className="body-left-area  mr-6" ref={ref}> */}
      <ToastContainer theme="dark" />
      <div className="settings-area  rounded-lg">
        <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
          <h1>Wallet</h1>
          <img
            src={close}
            className="cursor-pointer"
            alt=""
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>

        <div className="settings-content-area flex items-start relative">
          <div className="settings-options-area flex flex-col p-3">
            <WalletOptions active={3} />
          </div>

          <div className="wallet-content-body-area w-full overflow-x-auto py-6 px-6 flex-1">
            <div className="popup DepositePopUp rounded-xl">
              <div className="buttons-wrapper pb-6 mb-6 flex justify-center gap-3">
                <button
                  className={`${optionDeposite === 1 && "active"} px-4`}
                  onClick={(e) => {
                    setoptionDeposite(1);
                  }}
                >
                  Crypto
                </button>
                <button
                  className={`${optionDeposite === 2 && "active"} px-4`}
                  onClick={(e) => {
                    setoptionDeposite(2);
                  }}
                >
                  Cash Wallet
                </button>
              </div>
              {optionDeposite === 1 && (
                <div className="option-one">
                  <div className="wallet-content-body-area py-6 px-6 flex-1">
                    <div className="popup DepositePopUp rounded-xl">
                      {/* <div className="text-white mb-6">
                        <h4 className="text-xl mb-3 font-bold">
                          🛠 Exciting Updates on the Way!
                        </h4>
                        <p className="mb-3">
                          <span className="text-[#6FCF17]">
                            🔒 What's Happening?
                          </span>{" "}
                          <span className="text-[#FFF]">
                            We're currently syncing and enhancing the security
                            of our crypto wallets. Plus, we're upgrading our
                            blockchain protocols fo rbetter and faster
                            performance.
                          </span>
                        </p>
                        <p>
                          <span className="text-[#6FCF17]">⌛️ Back Soon:</span>{" "}
                          <span className="text-[#FFF]">
                            {" "}
                            Our services will resume shortly. We appreciate your
                            patience and understanding during this brief pause.
                          </span>
                        </p>
                      </div> */}
                      <div className="flex items-center gap-4 mb-8">
                        <img src={step1} alt="" className="sm:w-[30px]" />
                        <h1 className="text-[22px] text-white font-arabicreg sm:text-[16px]">
                          Payment method
                        </h1>
                      </div>
                      {!params?.coin && (
                        <>

                          <div className="mb-[36px]">
                            <label
                              htmlFor="#"
                              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
                            >
                              Select Coin
                            </label>
                            {isLoadingWalletAddresses ? <ButtonLoader /> : <>
                              <DepositCryptoCurrencyDropdown
                                onCurrencyChange={handleCurrencyChange}
                                list={Object.keys(walletAddresses.data).map(currency => ({ label: currency, value: currency }))}
                              />
                            </>}

                          </div>

                          <div className="mb-[36px]">
                            <label
                              htmlFor="#"
                              className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
                            >
                              Select Network
                            </label>
                            <select className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6 sm:text-[14px]" onChange={(e) => {
                              setWallet(e.target.value);
                            }} >
                              <option value="">Select Network</option>
                              {filteredAddresses?.map((address, index) =>
                                <>
                                  <option value={address?.network} key={index}>{address?.network.toUpperCase()}</option>
                                </>
                              )}
                            </select>
                          </div>
                        </>

                      )}
                      <div className="mb-3 mt-[36px] flex items-center gap-3">
                        {params?.coin && (
                          <label
                            htmlFor="#"
                            className="text-[18px] sm:text-[14px] mr-6 font-arabicreg text-[#AEB2B1] block"
                          >
                            Your Withdrawal coin: {coin}
                          </label>
                        )}
                        <div className="flex items-center gap-3">

                        </div>
                      </div>

                      <div className="mb-3">
                        <label
                          htmlFor="#"
                          className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                          className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6 sm:text-[14px]"
                        />
                      </div>


                      <div className="flex items-center gap-4 mt-8">
                        <img src={step2} alt="" className="sm:w-[30px]" />
                        <h1 className="text-[22px] sm:text-[18px] text-white font-arabicreg">
                          Withdrawal Information
                        </h1>
                      </div>
                      <div className="mt-8">
                        <div className="flex items-center justify-between">
                          <label
                            htmlFor="#"
                            className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                          >
                            Amount
                          </label>
                          <label
                            htmlFor="#"
                            className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                          >
                            <span className="text-white">
                              {currencyAmount ?? 0}{" "}
                            </span>{" "}
                            Available
                          </label>
                        </div>
                        <div className="relative">
                          <input
                            type="text"
                            value={amount}
                            onChange={(e) => {
                              setAmount(e.target.value);
                            }}
                            className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6 sm:text-[14px]"
                          />

                          <div className="flex items-center absolute top-[13px] right-5">
                            <p
                              className="text-[18px] cursor-pointer pr-3 border-r-[1px] border-r-[#3C403F] font-arabicreg text-[#6FCF17] sm:text-[14px]"
                              onClick={() => {
                                setAmount(currencyAmount);
                              }}
                            >
                              MAX
                            </p>

                          </div>
                        </div>


                      </div>


                      <button
                        onClick={confirmCryptoWithdraw}
                        className="text-[18px] text-[#FFF] font-arabicbold h-[50px] w-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C] sm:text-[14px] sm:h-[45px] mt-6 rounded-lg hover:from-[#82e402] hover:to-[#146626]"
                        disabled={withdrawCrypto.isLoading}
                      // disabled={true}
                      >
                        {withdrawCrypto.isLoading ? (
                          <ButtonLoader />
                        ) : (
                          "Confirm to Withdraw"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {/* {optionDeposite === 2 && (
                <div className="option-two">
                  <div className="input-container mt-4">
                    <label htmlFor="">Deposit Currency</label>
                    <DepositeCurrencyDropdown
                      dollar={gcash}
                      heading="PHP"
                      list={[
                        {
                          heading: "PHP",
                          img: gcash,
                        },
                        {
                          heading: "PHP Testing",
                          img: gcash,
                        },
                      ]}
                    />
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="">Choose a Payment Method</label>
                    <div className="details-wrapper mt-3 px-3 py-3 rounded-lg">
                      <div className="input-container">
                        <label htmlFor="">Recommended</label>

                        <DepositeCurrencyDropdown
                          dollar={gcash}
                          heading="PHP"
                          list={[
                            {
                              heading: "PHP",
                              heading2: "15-450 PHP",
                              img: gcash,
                            },
                            {
                              heading: "PHP Testing",
                              heading2: "15-250 PHP",
                              img: gcash,
                            },
                          ]}
                        />
                      </div>

                      <div className="input-container mt-4">
                        <label htmlFor="">E-wallet</label>

                        <DepositeCurrencyDropdown
                          dollar={gcash}
                          heading="PHP"
                          list={[
                            {
                              heading: "PHP",
                              heading2: "15-450 PHP",
                              img: gcash,
                            },
                            {
                              heading: "PHP Testing",
                              heading2: "15-250 PHP",
                              img: gcash,
                            },
                          ]}
                        />

                        <DepositeCurrencyDropdown
                          dollar={gcash}
                          heading="PHP"
                          list={[
                            {
                              heading: "PHP",
                              heading2: "15-450 PHP",
                              img: gcash,
                            },
                            {
                              heading: "PHP Testing",
                              heading2: "15-250 PHP",
                              img: gcash,
                            },
                          ]}
                        />

                        <DepositeCurrencyDropdown
                          dollar={gcash}
                          heading="PHP"
                          list={[
                            {
                              heading: "PHP",
                              heading2: "15-450 PHP",
                              img: gcash,
                            },
                            {
                              heading: "PHP Testing",
                              heading2: "15-250 PHP",
                              img: gcash,
                            },
                          ]}
                        />
                      </div>

                      <button className="flex moon-pay-btn items-center justify-center mt-3 w-full ">
                        Show More
                      </button>

                      <div className="input-container mt-4">
                        <label htmlFor="">Bank transfer</label>

                        <DepositeCurrencyDropdown
                          dollar={gcash}
                          heading="PHP"
                          list={[
                            {
                              heading: "PHP",
                              heading2: "15-450 PHP",
                              img: gcash,
                            },
                            {
                              heading: "PHP Testing",
                              heading2: "15-250 PHP",
                              img: gcash,
                            },
                          ]}
                        />

                        <DepositeCurrencyDropdown
                          dollar={gcash}
                          heading="PHP"
                          list={[
                            {
                              heading: "PHP",
                              heading2: "15-450 PHP",
                              img: gcash,
                            },
                            {
                              heading: "PHP Testing",
                              heading2: "15-250 PHP",
                              img: gcash,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
              {optionDeposite === 2 && (
                <div className="option-two mt-4">
                  {/* Amount in USD input */}
                  <div className="mt-8">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="#"
                        className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                      >
                        Select Payment Method
                      </label>
                    </div>
                    <div className="relative">
                      <select
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6 sm:text-[14px]"
                        id=""
                      >
                        <option value="crypto">Crypto</option>
                        <option value="fiat">Fiat</option>
                      </select>
                    </div>
                  </div>

                  {paymentMethod == "crypto" ? (
                    <>
                      <div className=" flex items-start relative mt-4">
                        <div className="wallet-content-body-area flex-1">
                          <div className="popup DepositePopUp rounded-xl">
                            <div className="mb-[36px]">
                              <label
                                htmlFor="#"
                                className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
                              >
                                Select Coin
                              </label>
                              {!loadingCryptos && formattedCryptoData && (
                                <PayDropdown
                                  stake
                                  classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
                                  dollar={coin?.img}
                                  heading={coin?.heading}
                                  list={formattedCryptoData}
                                  callback={(e) => {
                                    setCoin(e);
                                    setNetwork("");
                                  }}
                                />
                              )}
                            </div>

                            <div className="mb-3">
                              <label
                                htmlFor="#"
                                className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                              >
                                Wallet Address
                              </label>
                              <input
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6 sm:text-[14px]"
                              />
                            </div>
                            <p className="text-[16px] sm:text-[12px] font-arabicreg text-[#6FCF17]">
                              Wallet address automatically matched to
                              corresponding network.
                            </p>

                            <div className="flex items-center gap-4 mt-8">
                              <img src={step2} alt="" className="sm:w-[30px]" />
                              <h1 className="text-[22px] sm:text-[18px] text-white font-arabicreg">
                                Withdrawal Information
                              </h1>
                            </div>
                            <div className="mt-8">
                              <div className="flex items-center justify-between">
                                <label
                                  htmlFor="#"
                                  className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                                >
                                  Amount
                                </label>
                                <label
                                  htmlFor="#"
                                  className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                                >
                                  <span className="text-white">
                                    {" "}
                                    {balance?.balances?.cashWallet} USD
                                  </span>{" "}
                                  Available
                                </label>
                              </div>
                              <div className="relative">
                                <input
                                  type="text"
                                  value={amount}
                                  placeholder="0.00"
                                  onChange={(e) => {
                                    if (
                                      e.target.value.match(/^\d*\.?\d{0,2}$/)
                                    ) {
                                      setAmount(e.target.value);
                                    }
                                  }}
                                  className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:[#A9A9A9] text-[18px] font-arabicmed px-6 sm:text-[14px]"
                                />

                                <div className="flex items-center absolute top-[13px] right-5">
                                  <p
                                    className="text-[18px] cursor-pointer pr-3 border-r-[1px] border-r-[#3C403F] font-arabicreg text-[#6FCF17] sm:text-[14px]"
                                    onClick={() =>
                                      setAmount(balance?.balance ?? 0)
                                    }
                                  >
                                    MAX
                                  </p>
                                  <p className="text-[18px] sm:text-[14px]  pl-3 font-arabicreg text-white">
                                    USD
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="input-container mt-4">
                        <label htmlFor=""> Currency</label>
                        <DepositeCurrencyDropdown
                          heading="PHP"
                          list={[
                            {
                              heading: "PHP",
                            },
                          ]}
                        />
                      </div>

                      {/* Display Calculated Value */}

                      <div className="mt-8">
                        <div className="flex items-center justify-between">
                          <label
                            htmlFor="#"
                            className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                          >
                            Amount
                          </label>
                          <label
                            htmlFor="#"
                            className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                          >
                            <span className="text-white">
                              {parseFloat(
                                balance?.balances?.cashWallet
                              ).toFixed(2)}{" "}
                              USD
                            </span>{" "}
                            Available
                          </label>
                        </div>
                        <div className="relative">
                          <input
                            type="text"
                            value={amount}
                            onChange={handleAmountChange}
                            className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6 sm:text-[14px]"
                          />

                          <div className="flex items-center absolute top-[13px] right-5">
                            <p
                              className="text-[18px] cursor-pointer pr-3 border-r-[1px] border-r-[#3C403F] font-arabicreg text-[#6FCF17] sm:text-[14px]"
                              onClick={() =>
                                setAmount(
                                  parseFloat(
                                    balance?.balances?.cashWallet
                                  ).toFixed(2) ?? 0
                                )
                              }
                            >
                              MAX
                            </p>
                          </div>
                          <div className="mb-4">
                            <label className="block text-sm font-medium text-white mt-2">
                              Total Amount
                            </label>
                            <p className="mt-1 text-white">
                              {calculatedValue.toFixed(2)} PHP
                            </p>
                          </div>
                          <p className="text-gray-500 text-sm my-2">
                            Please ensure that the amount you wish to withdraw
                            is within our specified limits. The minimum amount
                            you can withdraw is $1, and the maximum allowable
                            withdrawal per transaction is $888. These limits are
                            in place to ensure a smooth and secure transaction
                            process.
                          </p>
                        </div>
                      </div>

                      {/* We accept component */}
                      <div className="mb-6">
                        <h4 className="text-lg text-white mb-3">We accept</h4>
                        <img src={gcashlogo} className="h-14 object-contain" />
                      </div>

                      <div className="mt-2 mb-2">
                        <div className="flex items-center justify-between">
                          <label
                            htmlFor="#"
                            className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                          >
                            Mobile No <span className="text-red-500">*</span>{" "}
                          </label>
                        </div>
                        <div className="relative">
                          <input
                            type="text"
                            required
                            value={cellNo}
                            onChange={(e) => {
                              setCellNo(e.target.value);
                            }}
                            className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6 sm:text-[14px]"
                          />
                        </div>
                      </div>
                      <div className="mt-2 mb-2">
                        <div className="flex items-center justify-between">
                          <label
                            htmlFor="#"
                            className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                          >
                            Name <span className="text-red-500">*</span>
                          </label>
                        </div>
                        <div className="relative">
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6 sm:text-[14px]"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {/* Pay with ePlayment button */}
                  {paymentMethod === "crypto" ? (
                    <>
                      <button
                        onClick={(e) => {
                          withDrawAmount();
                        }}
                        className="text-[18px] cursor-not-allowed text-[#FFF] font-arabicbold h-[50px] w-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C] sm:text-[14px] sm:h-[45px] mt-6 rounded-lg hover:from-[#82e402] hover:to-[#146626]"
                        // disabled={withAmountMutation.isLoading}
                        disabled={true}
                      >
                        {withAmountMutation.isLoading ? (
                          <ButtonLoader />
                        ) : (
                          "Confirm to Withdraw"
                        )}
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={withDrawAmount}
                        className="text-[18px] text-[#FFF] font-arabicbold h-[50px] w-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C] sm:text-[14px] sm:h-[45px] mt-6 rounded-lg hover:from-[#82e402] hover:to-[#146626]"
                        disabled={isDisabled}
                      >
                        {withAmountMutation.isLoading ? (
                          <ButtonLoader />
                        ) : (
                          "Confirm to Withdraw"
                        )}
                      </button>
                    </>
                  )}
                </div>
              )}

              {/* {optionDeposite === 3 && (
                <div className="option-three">
                  <div className="input-container mt-4">
                    <label htmlFor="">You pay with</label>
                    <div className="input-notify-wrapper rounded-lg mt-3 flex items-center px-4">
                      <p className="flex-1">30</p>

                      <div className="dropdown">
                        <div className="dropdown-head rounded flex px-3 py-2 items-center">
                          <img src={dollar} alt="" className="mr-2" />
                          <p>USD</p>
                          <img src={arrowDown} alt="" className="ml-3" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-container mt-4">
                    <label htmlFor="">You get</label>
                    <div className="input-notify-wrapper rounded-lg mt-3 flex items-center px-4">
                      <p className="flex-1">29.55</p>

                      <div className="dropdown">
                        <div className="dropdown-head rounded flex px-3 py-2 items-center">
                          <img src={tusdt} alt="" className="mr-2" />
                          <p>USDT</p>
                          <img src={arrowDown} alt="" className="ml-3" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="input-container mt-4">
                    <label htmlFor="">Payment Details</label>
                    <div className="details-wrapper mt-3 px-3 py-6 rounded-lg">
                      <div className="detail-row flex pb-6 items-center justify-between">
                        <h1>Provider</h1>
                        <p>MoonPay</p>
                      </div>
                      <div className="detail-row flex pb-6 items-center justify-between">
                        <h1>Method</h1>
                        <p>Credit Card</p>
                      </div>
                      <div className="detail-row flex items-center justify-between">
                        <h1>Deposit to account</h1>
                        <p>Kiyskvotnyb</p>
                      </div>
                    </div>
                    <div className="details-wrapper mt-3 px-3 py-6 rounded-lg">
                      <div className="detail-row flex pb-6 items-center justify-between">
                        <h1>Total (including fee)</h1>
                        <p>30 USD</p>
                      </div>
                      <div className="detail-row flex  items-center justify-between">
                        <h1>You will get</h1>
                        <p className="yellow">29.55 USDT</p>
                      </div>
                    </div>
                  </div>

                  <div className="warning mt-5 flex items-center px-6 rounded-xl">
                    <p>
                      <span>Notice</span> : Depending on the blockchain, the
                      deposit may take a few minutes to 1 hour to arrive.
                    </p>
                  </div>

                  <div className="agreement flex items-center my-5">
                    <input type="checkbox" id="agreement-deposit" />
                    <label
                      htmlFor="agreement-deposit"
                      className="w-3 h-3 rounded flex items-center justify-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="#fff"
                        class="w-6 h-6"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </label>
                    <p className="ml-3">
                      I have read and agree to the disclaimer.
                    </p>
                  </div>

                  <button className="flex moon-pay-btn items-center justify-center w-full ">
                    <img src={moonPay} alt="" className="mr-2" />
                    Buy Via MoonPay
                  </button>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
