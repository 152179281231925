import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swap from "../assets/img/swap/swap.svg";
import loader from "../assets/img/swap/loader.svg";
import { HeaderStaker } from "./HeaderStaker";
import { SidebarArea } from "./SidebarArea";
import { PayDropdown } from "./popups/PayDropdown";
import { Coin } from "../utils/staking/Coin";
import { CurrencyCoins } from "../utils/staking/CurrencyCoins";
import { countries } from "../utils/Countries";

export const RightAreaSwapFiat = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const navigate = useNavigate();
  const [payCheck, setpayCheck] = useState(false);
  const [active, setActive] = useState(true);
  const [typeShowAside, settypeShowAside] = useState(-1);
  return (
    <div className="flex-1">
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex items-start px-6 pt-6">
        <div className="body-left-area w-full">
          <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
            <div className="flex items-center  px-6 h-[91px] border-[#3C403F] border-b-[1px] lg:flex-col">
              <h1 className="text-white text-[24px] text-center sm:text-[18px]">
                Swap
              </h1>

              <div className="bg-[#252525] text-[#B7B8BC]  ml-6 border-[1px] border-[#3C403F] h-[48px]   flex items-center  justify-between rounded-xl w-[285px]  sm:!h-10  ">
                <button
                  className={`text-[#B7B8BC] cursor-pointer h-full rounded-xl text-[14px] font-arabicmed  flex-1 sm:!rounded-lg`}
                  onClick={(e) => {
                    navigate("/swap/crypto");
                  }}
                >
                  Crypto To Crypto
                </button>
                <button
                  className={`cursor-pointer h-full rounded-xl text-[14px] font-arabicmed bg-gradient-to-br from-[#9BE402] to-[#1FA83C] !text-[#FFF] flex-1 sm:!text-[16px] sm:!rounded-lg xs:!text-[14px]
          `}
                  onClick={(e) => {
                    navigate("/swap/fiat");
                  }}
                >
                  Crypto To Fiat
                </button>
              </div>

              <span className="flex-1"></span>

              <Link
                to="/stake/dashboard"
                className="text-[18px] font-arabicmed text-[#6FCF17] sm:text-[12px] hover:text-[#a6e66b]"
              >
                Terms and Conditions
              </Link>
            </div>

            <div className="p-6">
              <>
                <div className="flex mb-3 items-center justify-between">
                  <h1 className="text-[18px] text-[#AEB2B1] font-arabicmed">
                    You send
                  </h1>
                  <p className="text-[18px] text-[#AEB2B1] font-arabicmed">
                    Min: $1
                  </p>
                </div>
                {active ? (
                  <div className="h-[80px] sm:h-[60px] rounded-lg  bg-[#252525] px-6 flex items-center sm:px-4">
                    <p className="text-[24px]  sm:text-[18px] text-white font-arabicmed flex-1">
                      0
                    </p>
                    <p className="text-[#6FCF17] text-[16px] font-arabicmed mr-6">
                      Amount
                    </p>
                    <PayDropdown
                      callback={(e) => {
                        setpayCheck(e === "PHP");
                      }}
                      stakeFix
                      dollar={CurrencyCoins[0].img}
                      heading={CurrencyCoins[0].heading}
                      list={CurrencyCoins}
                    />
                  </div>
                ) : (
                  <div className="h-[80px]  rounded-lg bg-[#252525] px-6 flex items-center">
                    <p className="text-[24px] text-white font-arabicmed flex-1">
                      0.0000279
                    </p>

                    <PayDropdown
                      callback={(e) => {
                        setpayCheck(e === "PHP");
                      }}
                      stakeFix
                      dollar={Coin[0].img}
                      heading={Coin[0].heading}
                      list={Coin}
                    />
                  </div>
                )}

                <img
                  src={swap}
                  alt=""
                  onClick={(e) => {
                    setActive(!active);
                  }}
                  className="my-3 mx-auto cursor-pointer"
                />
                <div className="flex mb-3 items-center justify-between">
                  <h1 className="text-[18px] text-[#AEB2B1] font-arabicmed">
                    You receive
                  </h1>
                </div>

                {active ? (
                  <div className="h-[80px] mb-6 rounded-lg bg-[#252525] px-6 flex items-center">
                    <p className="text-[24px] text-white font-arabicmed flex-1">
                      0.0000279
                    </p>

                    <PayDropdown
                      callback={(e) => {
                        setpayCheck(e === "PHP");
                      }}
                      stakeFix
                      dollar={Coin[0].img}
                      heading={Coin[0].heading}
                      list={Coin}
                    />
                  </div>
                ) : (
                  <div className="h-[80px] sm:h-[60px] sm:mb-2 mb-6 rounded-lg  bg-[#252525] px-6 flex items-center sm:px-4">
                    <p className="text-[24px] sm:text-[18px] text-white font-arabicmed flex-1">
                      0
                    </p>
                    <p className="text-[#6FCF17] text-[16px] font-arabicmed mr-6">
                      Amount
                    </p>
                    <PayDropdown
                      callback={(e) => {
                        setpayCheck(e === "PHP");
                      }}
                      stakeFix
                      dollar={CurrencyCoins[0].img}
                      heading={CurrencyCoins[0].heading}
                      list={CurrencyCoins}
                    />
                  </div>
                )}

                <div className="flex items-center justify-between">
                  <h1 className="text-[18px] text-[#AEB2B1] font-arabicmed">
                    Balance: <span className="text-white">54.03962 EUR</span>
                  </h1>
                  <p className="text-[18px] text-[#AEB2B1] font-arabicmed">
                    Conversion rate:{" "}
                    <span className="text-white">1.25462 USDT</span>
                  </p>
                </div>

                <div className="grid grid-cols-2 gap-y-6 gap-x-3 my-6">
                  <div className="input-wrapper">
                    <label
                      htmlFor=""
                      className="block text-[#AEB2B1] text-[14px] font-arabicreg"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="bg-[#111010] mt-2 w-full h-[50px] rounded-lg border-[1px] border-[#3C403F] text-[14px] px-4 placeholder:text-white font-arabicbold text-white  sm:text-[12px]"
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="input-wrapper">
                    <label
                      htmlFor=""
                      className="block text-[#AEB2B1] text-[14px] font-arabicreg"
                    >
                      Account Number
                    </label>
                    <input
                      type="text"
                      className="bg-[#111010] mt-2 w-full h-[50px] rounded-lg border-[1px] border-[#3C403F] text-[14px] px-4 placeholder:text-white font-arabicbold text-white  sm:text-[12px]"
                      placeholder="Account Number"
                    />
                  </div>
                  <div className="input-wrapper">
                    <label
                      htmlFor=""
                      className="block text-[#AEB2B1] text-[14px] font-arabicreg"
                    >
                      Country
                    </label>
                    <div className="bg-[#111010] mt-2 w-full h-[50px] rounded-lg border-[1px] border-[#3C403F] text-[14px] px-4 placeholder:text-white font-arabicbold text-white flex items-center ">
                      <select
                        name=""
                        id=""
                        className="border-0 outline-none h-full w-full bg-[#111010]"
                      >
                        {countries.map((EachCountry) => (
                          <option value={EachCountry.code}>
                            {EachCountry.heading}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label
                      htmlFor=""
                      className="block text-[#AEB2B1] text-[14px] font-arabicreg"
                    >
                      Payment Method
                    </label>
                    <div className="bg-[#111010] mt-2 w-full h-[50px] rounded-lg border-[1px] border-[#3C403F] text-[14px] px-4 placeholder:text-white font-arabicbold text-white flex items-center ">
                      <select
                        name=""
                        id=""
                        className="border-0 outline-none h-full w-full bg-[#111010]"
                      >
                        {payCheck ? (
                          <option value="eplayment">Eplayment</option>
                        ) : (
                          <>
                            <option value="Cash">Bank transfer</option>
                            <option value="Cash">Ewallet</option>
                            <option value="Cash">Cash</option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="h-[80px] rounded-lg  bg-[#252525] px-6 flex items-center lg:flex-col sm:pt-2 sm:h-[100px]">
                  <img src={loader} alt="" />
                  <p className="text-[18px] ml-3 text-[#ACADB1] flex-1 font-arabicmed ">
                    1 EUR = 0.00002794 BTC
                  </p>
                  <p className="text-[18px] text-[#ACADB1] font-arabicmed ">
                    0.00 USDT
                  </p>
                </div>

                <div
                  className="h-[120px] mt-2 flex flex-col justify-center
            rounded-lg  bg-[#252525] px-6"
                >
                  <div className="flex items-center justify-between">
                    <p className="text-[18px] ml-3 text-[#ACADB1] flex-1 font-arabicmed ">
                      Estmated Time
                    </p>
                    <p className="text-[18px] text-[#ACADB1] font-arabicmed ">
                      Seconds
                    </p>
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    <p className="text-[18px] ml-3 text-[#ACADB1] flex-1 font-arabicmed ">
                      Swap fee
                    </p>
                    <p className="text-[18px]  sm:text-[14px] text-[#ACADB1] font-arabicmed ">
                      <span className="text-white">0.00000000</span> USDT
                    </p>
                  </div>
                </div>
              </>
              <button className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C]  w-full mt-10 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:from-[#82e402] hover:to-[#146626]">
                Confirm
              </button>
            </div>
          </div>
        </div>
        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};
