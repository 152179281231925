import aud from "../../assets/img/currency/aud.png";
import bgn from "../../assets/img/currency/bgn.png";
import brl from "../../assets/img/currency/brl.png";
import cad from "../../assets/img/currency/cad.png";
import chf from "../../assets/img/currency/chf.png";
// import cop from "../../assets/img/currency/cop.png";
import czk from "../../assets/img/currency/czk.png";
import dkk from "../../assets/img/currency/dkk.png";
import dop from "../../assets/img/currency/dop.png";
import egp from "../../assets/img/currency/egp.png";
import eur from "../../assets/img/currency/eur.png";
import gbp from "../../assets/img/currency/gbp.png";
import hkd from "../../assets/img/currency/hkd.png";
import idr from "../../assets/img/currency/idr.png";
import ils from "../../assets/img/currency/ils.png";
import jod from "../../assets/img/currency/jod.png";
import kwd from "../../assets/img/currency/kwd.png";
import lkr from "../../assets/img/currency/lkr.png";
import mxn from "../../assets/img/currency/mxn.png";
import ngn from "../../assets/img/currency/ngn.png";
import nok from "../../assets/img/currency/nok.png";
import nzd from "../../assets/img/currency/nzd.png";
import omr from "../../assets/img/currency/omr.png";
import pen from "../../assets/img/currency/pen.png";
import php from "../../assets/img/currency/php.png";
import pln from "../../assets/img/currency/pln.png";
import ron from "../../assets/img/currency/ron.png";
import sek from "../../assets/img/currency/sek.png";
import thb from "../../assets/img/currency/thb.png";
import trys from "../../assets/img/currency/try.png";
import twd from "../../assets/img/currency/twd.png";
import usd from "../../assets/img/currency/usd.png";
import vnd from "../../assets/img/currency/vnd.png";
import zad from "../../assets/img/currency/zar.png";

export const CurrencyCoins = [
  {
    img: aud,
    heading: "AUD",
    currency: "Australian Dollar",
  },
  { img: bgn, heading: "BGN", currency: "Bulgarian Lev" },
  { img: php, heading: "PHP", currency: "Philippine Peso" },
  { img: brl, heading: "BRL", currency: "Brazilian Real" },
  { img: cad, heading: "CAD", currency: "Canadian Dollar" },
  { img: chf, heading: "CHF", currency: "Swiss Franc" },
  // { img: cop, heading: "COP", currency: "Colombia Peso" },
  { img: czk, heading: "CZK", currency: "Czech Koruna" },
  { img: dkk, heading: "DKK", currency: "Danish Krone" },
  { img: dop, heading: "DOP", currency: "Dominican Peso" },
  { img: egp, heading: "EGP", currency: "Egyptian Pound" },
  { img: eur, heading: "EUR", currency: "Euro" },
  { img: gbp, heading: "GBP", currency: "Pound Sterling" },
  { img: hkd, heading: "HKD", currency: "Hong Kong Dollar" },
  { img: idr, heading: "IDR", currency: "Indonesian Rupiah" },
  { img: ils, heading: "ILS", currency: "Israeli New Shekel" },
  { img: jod, heading: "JOD", currency: "Jordanian Dollar" },
  // { img: kes, heading: "KES", currency: "Kenyan Shilling" },
  { img: kwd, heading: "KWD", currency: "Kuwaiti Dinar" },
  { img: lkr, heading: "LKR", currency: "Sri Lankan Rupee" },
  { img: mxn, heading: "MXN", currency: "Mexican Peso" },
  { img: ngn, heading: "NGN", currency: "Nigerian Naira" },
  { img: nok, heading: "NOK", currency: "Norwegian Krone" },
  { img: nzd, heading: "NZD", currency: "New Zealand Dollar" },
  { img: omr, heading: "OMR", currency: "Omani Rial" },
  { img: pen, heading: "PEN", currency: "Peruvian Sol" },
  { img: pln, heading: "PLN", currency: "Polish Złoty" },
  { img: ron, heading: "RON", currency: "Romanian Leu" },
  { img: sek, heading: "SEK", currency: "Swedish Krona" },
  { img: thb, heading: "THB", currency: "Thai Baht" },
  { img: trys, heading: "TRY", currency: "Turkish Lira" },
  { img: twd, heading: "TWD", currency: "Taiwan Dollar" },
  { img: usd, heading: "USD", currency: "US Dollar" },
  { img: vnd, heading: "VND", currency: "Vietnamese Dong" },
  { img: zad, heading: "ZAR", currency: "South African Rand" },
];
