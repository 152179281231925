import React from "react";

export const LeaderboardTable= () => {

  const data = [
    {
      rank: "1st",
      user: "Hidden",
      wager: "$46,541,936.72",
      amount: "$25,000.00",
    },
    {
      rank: "2nd",
      user: "Hidden",
      wager: "$31,297,738.96",
      amount: "$12,000.00",
    },
    {
      rank: "3rd",
      user: "leader8746",
      wager: "$27,270,811.35",
      amount: "$8,000.00",
    },
    {
      rank: "4th",
      user: "TheGreat521",
      wager: "$18,690,217.11",
      amount: "$6,000.00",
    },
  ];

  return (
    <>
    <div className="bg-[#1A1A1B] px-6 py-4 mt-4 rounded-lg w-full">
        {data && data.length === 0 ? (
          <div>
            <h1 className="no-data-available text-center text-white">Oops! There is no data yet!</h1>
          </div>
        ) : (
          <table className="w-full lgmed:min-w-[800px]">
            <thead>
              <tr className="h-[56px] border-b border-[#3A3A3A]">
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Rank
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  User
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Wagered
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row, key) => (
                <tr key={key} className="h-[56px]">
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.rank}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.user}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.wager}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.amount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
    </>
  );
};