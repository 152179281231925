import { Maximize, Minimize } from "lucide-react";
import { useEffect, useState } from "react";
import { MdFavorite } from "react-icons/md";
import playIcon from "../../assets/img/casino/playIcon2.png";
import rightArrow from "../../assets/img/casino/right-arrow.png";
import { useGameDetailsQuery, usePlayGameMutation } from "../../queries/gaming";
import { Header } from "../Header";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "../../components/ButtonLoader";
import close from "../../assets/img/popup/close.svg";
import back from "../../assets/img/popup/back.svg";

const GameInfo = ({
  gameId,
  gameType,
  login,
  setsigninState,
  setSignUpState,
}) => {
  const [openGame, setOpenGame] = useState(false);

  const [isPopupFullScreen, setIsPopupFullScreen] = useState(false);
  const [gameDetails, setGameDetails] = useState(null);

  const [playGameError, setPlayGameError] = useState(null);

  const gbcBalance = 1000; // Replace with actual GBC balance
  console.log("GAME TYPE IS: ", gameType);
  const {
    data: gameDetail,
    isLoading: isGameLoading,
    isError: isGameError,
  } = useGameDetailsQuery(gameId, gameType);
  useEffect(() => {
    if (gameDetail) {
      setGameDetails(gameDetail);
    }
  }, [gameDetail]);
  console.log(gameDetails);

  const {
    mutate: playGame,
    data: gameData,
    isLoading: loadingGame,
  } = usePlayGameMutation();
  console.log("loadingGame", loadingGame);

  const handlePlayGameClick = () => {
    setOpenGame(true);
    playGame(gameDetail.game_id, {
      onSuccess: () => {
        //  console.log("gameData", gameData);
        setPlayGameError(null);
        document.body.style.overflow = "hidden";
      },
      onError: (error) => {
        console.error("Error playing game:", error.message);
        setPlayGameError(error.message || "An unexpected error occurred");
      },
    });
  };

  //Back Button
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This will navigate to the previous page
  };
  // Close modal function
  const handleCloseModal = () => {
    setOpenGame(false);
    setIsPopupFullScreen(false);
    document.body.style.overflow = "unset"; // Re-enable scrolling
  };
  const toggleFullScreen = () => {
    setIsPopupFullScreen(!isPopupFullScreen);
  };
  // if (loadingGame) return <div>Loading game...</div>;
  // if (isError) return <div>Error loading game</div>;
  console.log(loadingGame);

  if (!gameDetails || !gameDetails.details) {
    return null; // Return null if details is not available
  }

  // Parse the details JSON string into a JavaScript object
  const parsedDetails = JSON.parse(gameDetails.details);

  return (
    <div className="flex-1 overflow-hidden">
      <Header
        login={login}
        headerType="player"
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div
        className="relative inset-0 flex justify-center items-center "
        style={{
          backgroundImage: `url(${gameDetails?.image_background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "100vh",
        }}
      >

        {/* === BLACK OPACITY BG === */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        ></div>
        
        {/* === CLOSE BUTTON === */}
        {!openGame && (
          <div
            className="absolute text-white top-10 right-10 sm:right-2 py-2 px-3 self-start sm:self-end"
            style={{ zIndex: 99 }}
          >
            <img
              src={back}
              className="cursor-pointer py-3"
              alt=""
              onClick={handleGoBack}
            />
          </div>
        )}

        {/* === GAME BUTTONS AND DETAILS === */}
        <div className="overflow-hidden">
          <div className="relative w-full flex flex-wrap z-50 overflow-x-auto no-scrollbar 1xl:p-10">

            <div className="w-full sm:mx-auto mx-20 1xl:mx-10 mb-10 border-[#403c3c] align-middle" style={{ zIndex: 2 }} >
              <h3 className="text-white text-[30px] sm:text-[18px] font-bold mb-2"> {" "} {gameDetails?.name}{" "} </h3>
              <div className="flex h-auto gap-2">
                <h6 className="text-white text-[16px] sm:text-[12px] font-medium uppercase"> {" "} Provider:{" "}
                  <span className="text-[#6FCF17]"> {" "} {gameDetails?.provider_name}{" "} </span>
                </h6>
                <div>
                  {gameDetails?.new === "1" && (
                    <p className="text-[#FFF] bg-[#1FA83C] px-2 py-1 rounded-lg text-[14px] sm:text-[8px]  font-medium"> NEW </p>
                  )}
                </div>
                <div>
                  {gameDetails?.has_jackpot && (
                    <p className="text-[#FFF] bg-[#101101] px-2 py-1 rounded-lg text-[14px] font-medium"> JACKPOT </p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-between w-full mx-20 1xl:mx-10 border-[#403c3c] lg:min-flex-col" style={{ zIndex: 2 }} >
              <div className="w-4/5 lg:w-full">
                <img
                  src={gameDetails?.image_preview}
                  alt=""
                  className="w-full object-cover rounded-lg"
                />
                <div className="flex md:flex-col justify-between gap-3 my-4">
                  <button
                    className="py-2 px-20 sm:w-full w-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C] rounded-xl text-white text-semibold transform transition-transform hover:scale-105"
                    onClick={(e) => {
                      handlePlayGameClick(e);
                      e.target.style.boxShadow = "none";
                      e.target.style.transform = "scale(1)";
                    }}
                  >
                    Play Now
                  </button>
                  {gameDetails?.play_for_fun_supported && (
                    <button
                      className="py-2 px-20 sm:w-full w-full bg-transparent border-2 border-[#6FCF17] text-[#6FCF17] text-semibold rounded-xl transform transition-transform hover:scale-105"
                      onClick={(e) => {
                        handlePlayGameClick(e);
                        e.target.style.boxShadow = "none";
                        e.target.style.transform = "scale(1)";
                      }}
                    >
                      Free Play
                    </button>
                  )}
                </div>
              </div>

              <div className="w-4/5 lg:hidden">
                <div className=" mx-14 mb-4">
                  {Object.entries(parsedDetails).map(([key, value]) => (
                    <div className="flex border-b border-gray-100/30 pb-2 mb-2">
                      <h3 className="text-white text-[20px] font-medium my-2 mx-4 capitalize w-40">
                        <span className="capitalize text-[#FFF]">{key}: </span>
                      </h3>
                      <h3 className="text-white text-[20px] font-semibold my-2 capitalize w">
                        <span className="capitalize text-[#6FCF17]">{value}</span>
                      </h3>
                    </div>
                  ))}

                  <div className="flex border-b border-gray-100/30 pb-2 mb-2">
                    <h3 className="text-white text-[20px] font-medium my-2 mx-4 capitalize w-40">
                      <span className="capitalize text-[#FFF]">Plays: </span>
                    </h3>
                    <h3 className="text-white text-[20px] font-semibold my-2 capitalize w">
                      <span className="capitalize text-[#6FCF17]">
                        {gameDetails?.plays}{" "}
                      </span>
                    </h3>
                  </div>

                  <div className="flex border-b border-gray-100/30 pb-2 mb-2">
                    <h3 className="text-white text-[20px] font-medium my-2 mx-4 capitalize w-40">
                      <span className="capitalize text-[#FFF]">Rtp: </span>
                    </h3>
                    <h3 className="text-white text-[20px] font-semibold my-2 capitalize w">
                      <span className="capitalize text-[#6FCF17]">
                        {gameDetails?.rtp}{" "}
                      </span>
                    </h3>
                  </div>

                  {gameDetails?.wagering && (
                    <div className="flex border-b border-gray-100/30 pb-2 mb-2">
                      <h3 className="text-white text-[20px] font-medium my-2 mx-4 capitalize w-40">
                        <span className="capitalize text-[#FFF]">Wagering: </span>
                      </h3>
                      <h3 className="text-white text-[20px] font-semibold my-2 capitalize w">
                        <span className="capitalize text-[#6FCF17]">
                          {gameDetails?.wagering}{" "}
                        </span>
                      </h3>
                    </div>
                  )}

                  {gameDetails?.licence && (
                    <div className="flex border-b border-gray-100/30 pb-2 mb-2">
                      <h3 className="text-white text-[20px] font-medium my-2 mx-4 capitalize w-40">
                        <span className="capitalize text-[#FFF]">License: </span>
                      </h3>
                      <h3 className="text-white text-[20px] font-semibold my-2 capitalize w">
                        <span className="capitalize text-[#6FCF17]">
                          {gameDetails?.licence}{" "}
                        </span>
                      </h3>
                    </div>
                  )}
                </div>
              </div>
            </div>

          </div>
        </div>

        {/*======== GAME MODAL ========*/}
        {openGame && (
          <>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              }}
            ></div>

            <div className="fixed overflow-auto inset-0 justify-center items-center flex" style={{ zIndex: 9999 }} >
              <div
                className={`flex flex-col border border-[#3C403F] rounded-xl ${isPopupFullScreen ? "w-full h-full" : "w-[70%] h-[90%]"
                  }`}
                style={{
                  backgroundImage: `url(${gameDetails?.image_background})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <div className="flex flex-col flex-grow">
                  <div className="flex items-center justify-between p-4 border-b border-[#3C403F]">
                    <h4 className="text-white text-lg font-bold">
                      {gameDetails?.name || "Game name"}
                    </h4>

                    <div className="flex items-center">
                      <button
                        onClick={handleCloseModal}
                        className="border text-white border-[#3C403F] rounded-lg w-10 h-10 inline-flex items-center justify-center"
                      >
                        X
                      </button>
                    </div>
                  </div>
                  {loadingGame ? (
                    <div className="flex-grow flex justify-center items-center">
                      <ButtonLoader />
                    </div>
                  ) : playGameError ? (
                    <div className="flex-grow bg-white flex justify-center items-center">
                      <p>Error playing the game: </p>
                      <p> {playGameError}</p>
                    </div>
                  ) : (
                    <div className="flex-grow">
                      <iframe
                        src={gameData.data.response}
                        title="Game"
                        className="w-full h-full"
                      ></iframe>
                    </div>
                  )}

                  {/*======== GAME MODAL FOOTER ========*/}
                  <div className="flex justify-between items-center p-4 border-t border-[#3C403F]">
                    <div>
                      {/* Display GBC Balance - replace 'gbcBalance' with actual variable */}
                      {/* <span className="text-white">
                      GBC Balance: {gbcBalance}
                    </span> */}
                    </div>
                    <div className="flex items-center gap-3">
                      <button
                        onClick={toggleFullScreen}
                        className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center gap-2"
                      >
                        {isPopupFullScreen ? (
                          <>
                            <Minimize size={18} /> {/* Adjust the size as needed */}
                            <span>Exit Fullscreen</span>
                          </>
                        ) : (
                          <>
                            <Maximize size={18} /> {/* Adjust the size as needed */}
                            <span>Go Fullscreen</span>
                          </>
                        )}
                      </button>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

      </div>
    </div>
  );
};

export default GameInfo;
