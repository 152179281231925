import React from "react";
import { convertToCurrency, dateFormatter } from "../../utils";

export const SportsTable = () => {
  const leftData = [
    {
      event: "Detroit Pistons",
      amount: "100 GBC",
      odds: "None",
      user: "13412351",
      status: "Cancel",
    },
    {
      event: "Detroit Pistons",
      amount: "100 GBC",
      odds: "None",
      user: "13412351",
      status: "Matched",
    },
    {
      event: "Detroit Pistons",
      amount: "100 GBC",
      odds: "None",
      user: "13412351",
      status: "Matched",
    },
    {
      event: "Detroit Pistons",
      amount: "100 GBC",
      odds: "None",
      user: "13412351",
      status: "Matched",
    },
    {
      event: "Detroit Pistons",
      amount: "100 GBC",
      odds: "None",
      user: "13412351",
      status: "Matched",
    },
  ];

  const rightData = [
    {
      event: "Los Angeles Clippers",
      amount: "100 GBC",
      odds: "None",
      user: "13412351",
      status: "Waiting",
    },
    {
      event: "Los Angeles Clippers",
      amount: "100 GBC",
      odds: "None",
      user: "13412351",
      status: "Matched",
    },
    {
      event: "Los Angeles Clippers",
      amount: "100 GBC",
      odds: "None",
      user: "13412351",
      status: "Matched",
    },
    {
      event: "Los Angeles Clippers",
      amount: "100 GBC",
      odds: "None",
      user: "13412351",
      status: "Matched",
    },
    {
      event: "Los Angeles Clippers",
      amount: "100 GBC",
      odds: "None",
      user: "13412351",
      status: "Matched",
    },
  ];

  return (
    <>
      <div className="bg-[#1A1A1B] p-6 mt-4 rounded-lg w-full">
        <h1 className="text-white font-arabicbold">Betting Book</h1>
        <div className="flex md:flex-col">
          {leftData && leftData.length === 0 ? (
            <div>
              <h1 className="no-data-available text-center text-white">
                Oops! There is no data yet!
              </h1>
            </div>
          ) : (
            <div className="w-full overflow-x-auto">
              <table className="my-4 w-full whitespace-nowrap">
                <thead>
                  <tr className="h-[56px] border-b border-[#3A3A3A]">
                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                      Event
                    </th>
                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                      Amount
                    </th>
                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                      Odds
                    </th>
                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                      User
                    </th>
                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {leftData?.map((row, key) => (
                    <tr key={key} className="h-[56px]">
                      <td
                        className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                      >
                        {row.event}
                      </td>
                      <td
                        className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                      >
                        {row.amount}
                      </td>
                      <td
                        className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                      >
                        {row.odds}
                      </td>
                      <td
                        className={`text-left px-2 font-arabicreg text-[14px] text-red-500`}
                      >
                        {row.user}
                      </td>
                      <td
                        className={`text-left px-2 font-arabicreg text-[14px] ${
                          row.status === "Cancel"
                            ? "text-red-500"
                            : row.status === "Waiting"
                            ? "text-yellow-500"
                            : "text-green-500"
                        }`}
                      >
                        {row.status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="border-r border-white m-4"></div>
          {rightData && rightData.length === 0 ? (
            <div>
              <h1 className="no-data-available text-center text-white">
                Oops! There is no data yet!
              </h1>
            </div>
          ) : (
            <div className="w-full overflow-x-auto">
              <table className="my-4 w-full whitespace-nowrap">
                <thead>
                  <tr className="h-[56px] border-b border-[#3A3A3A]">
                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                      Event
                    </th>
                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                      Amount
                    </th>
                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                      Odds
                    </th>
                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                      User
                    </th>
                    <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rightData?.map((row, key) => (
                    <tr key={key} className="h-[56px]">
                      <td
                        className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                      >
                        {row.event}
                      </td>
                      <td
                        className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                      >
                        {row.amount}
                      </td>
                      <td
                        className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}
                      >
                        {row.odds}
                      </td>
                      <td
                        className={`text-left px-2 font-arabicreg text-[14px] text-green-500`}
                      >
                        {row.user}
                      </td>
                      <td
                        className={`text-left px-2 font-arabicreg text-[14px] ${
                          row.status === "Cancel"
                            ? "text-red-500"
                            : row.status === "Waiting"
                            ? "text-yellow-500"
                            : "text-green-500"
                        }`}
                      >
                        {row.status}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};