import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
// import { Home } from "./pages/Home";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./contexts/AuthContext";
import { Affiliate } from "./pages/Affiliate";
import { BonusDashboard } from "./pages/BonusDashboard";
import { BonusDetails } from "./pages/BonusDetails";
import SubcategoryComponent from "./pages/CategoryFetchTest";
import { CommunityTeam } from "./pages/CommunityTeam";
import { GeneralActiveSession } from "./pages/GeneralActiveSession";
import { GeneralMailPhone } from "./pages/GeneralMailPhone";
import { GeneralPrivacy } from "./pages/GeneralPrivacy";
import { GeneralSecurity } from "./pages/GeneralSecurity";
import { GeneralSettings } from "./pages/GeneralSettings";
import { GeneralVerify } from "./pages/GeneralVerify";
import { Home } from "./pages/Home";
import { Stake } from "./pages/Stake";
import { StakeDashboard } from "./pages/StakeDashboard";
import { StakeDetails } from "./pages/StakeDetails";
import { StakeDone } from "./pages/StakeDone";
import { StakePaymentMethodCash } from "./pages/StakePaymentMethodCash";
import { StakePaymentMethodCrypto } from "./pages/StakePaymentMethodCrypto";
import { StakePaymentMethodFiat } from "./pages/StakePaymentMethodFiat";
import { StakeTerms } from "./pages/StakeTerms";
import { SwapCrypto } from "./pages/SwapCrypto";
import { TransactionHistory } from "./pages/TransactionHistory";
import { Transfer } from "./pages/Transfer";
import { TransferGBC } from "./pages/TransferGBC";
import { Crypto } from "./pages/wallet/deposit/Crypto";
import { CryptoWithDraw } from "./pages/wallet/withdraw/CryptoWithDraw";
import { WalletBinance } from "./pages/WalletBinance";
import { WalletBuyCrypto } from "./pages/WalletBuyCrypto";
import { WalletDeposit } from "./pages/WalletDeposit";
import { WalletGameBoxSwap } from "./pages/WalletGameBoxSwap";
import { WalletNFT } from "./pages/WalletNFT";
import { WalletTransaction } from "./pages/WalletTransaction";
import { WalletVaultPro } from "./pages/WalletVaultPro";
import { WalletWithDraw } from "./pages/WalletWithDraw";
import { WalletWithDrawAmount } from "./pages/WalletWithDrawAmount";
import PrivateRoute from "./PrivateRoute";
import TokenHandler from "./TokenHandler";
// admin
import { CasinoGames } from "./components/casino/CasinoGames";
import { CasinoInfo } from "./components/casino/CasinoInfo";
import GameModal from "./components/casino/GameModal"; //game-details
import { AddNewBank } from "./pages/admin/AddNewBank";
import { AdminBanner } from "./pages/admin/AdminBanner";
import { AdminConfigure } from "./pages/admin/AdminConfigure";
import { AdminCredentials } from "./pages/admin/AdminCredentials";
import { AdminMenu } from "./pages/admin/AdminMenu";
import { AdminUserRegistration } from "./pages/admin/AdminUserRegistration";
import { AgentReport } from "./pages/admin/AgentReport";
import { Agents } from "./pages/admin/Agents";
import { BankDetails } from "./pages/admin/BankDetails";
import { Bonus } from "./pages/admin/Bonus";
import { CMS } from "./pages/admin/CMS";
import { CreateAgents } from "./pages/admin/CreateAgents";
import { CreateBanner } from "./pages/admin/CreateBanner";
import { CreateCMS } from "./pages/admin/CreateCMS";
import { CreateCredentials } from "./pages/admin/CreateCredentials";
import { CreatePaymentProvider } from "./pages/admin/CreatePaymentProvider";
import { CreatePlayer } from "./pages/admin/CreatePlayer";
import { CreatePromoCode } from "./pages/admin/CreatePromoCode";
import { CreateSubAdmin } from "./pages/admin/CreateSubAdmin";
import { CreateTransaction } from "./pages/admin/CreateTransaction";
import { Dashboard } from "./pages/admin/Dashboard";
import { DepositeRequest } from "./pages/admin/DepositeRequest";
import { EditAgents } from "./pages/admin/EditAgents";
import { EditBanner } from "./pages/admin/EditBanner";
import { EditPlayer } from "./pages/admin/EditPlayer";
import { EditSubAdmin } from "./pages/admin/EditSubAdmin";
import { Notifications } from "./pages/admin/Notifications";
import { PaymentProviders } from "./pages/admin/PaymentProviders";
import { PendingWithdrawl } from "./pages/admin/PendingWithdrawl";
import { PlayerReport } from "./pages/admin/PlayerReport";
import { Players } from "./pages/admin/Players";
import { RejectedWithdrawl } from "./pages/admin/RejectedWithdrawl";
import { SiteMantainence } from "./pages/admin/SiteMantainence";
import { Subadmins } from "./pages/admin/Subadmins";
import { TenantsPaymentConfigurations } from "./pages/admin/TenantsPaymentConfigurations";
import { ThemeSettings } from "./pages/admin/ThemeSettings";
import { TransactionReport } from "./pages/admin/TransactionReport";
import { Transactions } from "./pages/admin/Transactions";
import { VerifiedWithdrawal } from "./pages/admin/VerifiedWithdrawal";
import { AllGames } from "./pages/AllGames";
import { DepositDone } from "./pages/DepositDone";
import { GameLists } from "./pages/GameList";
import { P2PPostAd } from "./pages/P2PPostAd";
import { P2PSell } from "./pages/P2PSell";
import { PlayerDashboard } from "./pages/PlayerDashboard";
import { PlayerGameHub } from "./pages/PlayerGameHub";
import { StakePaymentMethodExternalWallet } from "./pages/StakePaymentMethodExternalWallet";
import { SwapFiat } from "./pages/SwapFiat";
import { TransactionHistoryStake } from "./pages/TransactionHistoryStake";
import { P2P, P2PBuy } from "./pages/P2PBuy";
import { P2PTradeBuy } from "./components/p2p/P2PTradeBuy";
import { P2PTradeSell } from "./components/p2p/P2PTradeSell";
import { P2PTradeSellPayment } from "./components/p2p/P2PTradeSellPayment";
import { P2PTradeBuyPayment } from "./components/p2p/P2PTradeBuyPayment";
import { P2PTradeBuyPaymentResult } from "./components/p2p/P2PTradeBuyPaymentResult";
import { P2PTradeSellPaymentResult } from "./components/p2p/P2PTradeSellPaymentResult";
import { Sports } from "./components/sports/Sports";
import { P2PBuyDetails } from "./components/p2p/P2PBuyDetails";
import { P2PSellDetails } from "./components/p2p/P2PSellDetails";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Affiliate />
      </PrivateRoute>
    ),
  },
  {
    path: "/affiliate",
    element: (
      <PrivateRoute>
        <Affiliate />
      </PrivateRoute>
    ),
  },
  {
    path: "/home",
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
  },
  {
    path: "/deposit-pending",
    element: (
      <PrivateRoute>
        <DepositDone />
      </PrivateRoute>
    ),
  },
  {
    path: "/gaming-hub",
    element: (
      <PrivateRoute>
        <PlayerGameHub />
      </PrivateRoute>
    ),
  },
  {
    path: "/all-games",
    element: <AllGames category="gaminghub" />,
  },
  {
    path: "/sports-bet",
    element: <AllGames category="sportsbet" />,
  },
  {
    path: "/browse-game/:type",
    element: <GameLists />,
  },
  {
    path: "/browse-game/:category?/:subcategory?",
    element: <AllGames />,
  },
  {
    path: "/casino/:subcategory?",
    element: <AllGames category="Casino" />,
  },
  {
    path: "/bonus-dashboard",
    element: <BonusDashboard />,
  },
  {
    path: "/bonus-dashboard/details",
    element: <BonusDetails />,
  },
  {
    path: "/casino/:cat/:game",
    element: <CasinoGames />,
  },
  {
    path: "/game/:id",
    element: <CasinoGames />,
  },
  {
    path: "/game-details/:id",
    element: <GameModal />,
  },
  {
    path: "/game-info/:id",
    element: <CasinoInfo />,
  },
  {
    path: "/games",
    element: (
      <PrivateRoute>
        <AllGames />
      </PrivateRoute>
    ),
  },
  {
    // path: "/sports/:subcategory?",
    // element: <AllGames category="Sports" />,
    path: "/sports",
    element: (
      <PrivateRoute>
        <AllGames category="Sports" />
      </PrivateRoute>
    ),
  },
  {
    path: "/FetchCategory",
    element: (
      <PrivateRoute>
        <SubcategoryComponent game_subcategory="Slots" />
      </PrivateRoute>
    ),
  },
  {
    path: "/live-casino/:subcategory?",
    element: (
      <PrivateRoute>
        <AllGames category="Live Casino" />
      </PrivateRoute>
    ),
  }, {
    path: "/sportsbook",
    element: (
      <PrivateRoute>
        <AllGames category="Sportsbook" />
      </PrivateRoute>
    ),
  }, {
  }, {
    path: "/live-games",
    element: (
      <PrivateRoute>
        <AllGames category="Live Games" />
      </PrivateRoute>
    ),
  }, {
    path: "/virtual-sports",
    element: (
      <PrivateRoute>
        <AllGames category="Virtual Sports" />
      </PrivateRoute>
    ),
  }, {
    path: "/crash-fast/:subcategory?",
    element: (
      <PrivateRoute>
        <AllGames category="Crash & Fast" />
      </PrivateRoute>
    ),
  },
  {
    path: "/racing",
    element: (
      <PrivateRoute>
        <AllGames type="racing" />
      </PrivateRoute>
    ),
  },
  {
    path: "/community-team",
    element: (
      <PrivateRoute>
        <CommunityTeam />
      </PrivateRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <PrivateRoute>
        <TokenHandler />
      </PrivateRoute>
    ),
  },

  {
    path: "/home/global-settings",
    element: (
      <PrivateRoute>
        <GeneralSettings />
      </PrivateRoute>
    ),
  },
  {
    path: "/home/global-privacy",
    element: (
      <PrivateRoute>
        <GeneralPrivacy />
      </PrivateRoute>
    ),
  },
  {
    path: "/home/global-mail-phone",
    element: (
      <PrivateRoute>
        <GeneralMailPhone />
      </PrivateRoute>
    ),
  },
  {
    path: "/home/global-security",
    element: (
      <PrivateRoute>
        <GeneralSecurity />
      </PrivateRoute>
    ),
  },
  {
    path: "/home/global-active-session",
    element: (
      <PrivateRoute>
        <GeneralActiveSession />
      </PrivateRoute>
    ),
  },
  {
    path: "/home/global-verify",
    element: (
      <PrivateRoute>
        <GeneralVerify />
      </PrivateRoute>
    ),
  },
  {
    path: "/home/wallet/balance",
    element: (
      <PrivateRoute>
        <WalletBinance />
      </PrivateRoute>
    ),
  },
  {
    path: "/home/wallet/deposite",
    element: (
      <PrivateRoute>
        <WalletDeposit />
      </PrivateRoute>
    ),
  },
  {
    path: "/home/wallet/withDraw",
    element: (
      <PrivateRoute>
        <WalletWithDraw />
      </PrivateRoute>
    ),
  },
  {
    path: "/home/wallet/withDraw/:coin",
    element: (
      <PrivateRoute>
        <WalletWithDraw />
      </PrivateRoute>
    ),
  },

  {
    path: "/home/wallet/buy-crypto",
    element: (
      <PrivateRoute>
        <WalletBuyCrypto />
      </PrivateRoute>
    ),
  },
  {
    path: "/home/wallet/gamebox-sawp",
    element: (
      <PrivateRoute>
        <WalletGameBoxSwap />
      </PrivateRoute>
    ),
  },
  {
    path: "/home/wallet/vault-pro",
    element: (
      <PrivateRoute>
        <WalletVaultPro />
      </PrivateRoute>
    ),
  },

  {
    path: "/home/wallet/nft",
    element: (
      <PrivateRoute>
        <WalletNFT />
      </PrivateRoute>
    ),
  },

  {
    path: "/home/wallet/trasaction",
    element: (
      <PrivateRoute>
        <WalletTransaction />
      </PrivateRoute>
    ),
  },
  {
    path: "/stake",
    element: (
      <PrivateRoute>
        <Stake />
      </PrivateRoute>
    ),
  },
  {
    path: "/stake/:id",
    element: (
      <PrivateRoute>
        <StakeDetails />
      </PrivateRoute>
    ),
  },
  {
    path: "/stake/terms",
    element: (
      <PrivateRoute>
        <StakeTerms />
      </PrivateRoute>
    ),
  },
  {
    path: "/stake/payment/method/crypto",
    element: (
      <PrivateRoute>
        <StakePaymentMethodCrypto />
      </PrivateRoute>
    ),
  },
  {
    path: "/stake/payment/method/fiat",
    element: (
      <PrivateRoute>
        <StakePaymentMethodFiat />
      </PrivateRoute>
    ),
  },
  {
    path: "/stake/payment/method/cash-wallet",
    element: (
      <PrivateRoute>
        <StakePaymentMethodCash />
      </PrivateRoute>
    ),
  },
  {
    path: "/stake/payment/method/external-wallet",
    element: (
      <PrivateRoute>
        <StakePaymentMethodExternalWallet />
      </PrivateRoute>
    ),
  },
  {
    path: "/stake/done",
    element: (
      <PrivateRoute>
        <StakeDone />
      </PrivateRoute>
    ),
  },
  {
    path: "/stake/dashboard",
    element: (
      <PrivateRoute>
        <StakeDashboard />
      </PrivateRoute>
    ),
  },
  {
    path: "/transaction-history",
    element: (
      <PrivateRoute>
        <TransactionHistory />
      </PrivateRoute>
    ),
  },
  {
    path: "/stake/daily-grr",
    element: (
      <PrivateRoute>
        <TransactionHistoryStake />
      </PrivateRoute>
    ),
  },
  {
    path: "/transfer",
    element: (
      <PrivateRoute>
        <Transfer />
      </PrivateRoute>
    ),
  },

  {
    path: "/transfer/gbc",
    element: (
      <PrivateRoute>
        <TransferGBC />
      </PrivateRoute>
    ),
  },

  {
    path: "/wallet/deposit/:coin",
    element: (
      <PrivateRoute>
        <Crypto />
      </PrivateRoute>
    ),
  },

  {
    path: "/wallet/withdraw",
    element: (
      <PrivateRoute>
        <CryptoWithDraw />
      </PrivateRoute>
    ),
  },
  {
    path: "/withdraw-amount",
    element: (
      <PrivateRoute>
        <WalletWithDrawAmount />
      </PrivateRoute>
    ),
  },

  // SwapCrypto
  {
    path: "/swap/crypto",
    element: (
      <PrivateRoute>
        <SwapCrypto />
      </PrivateRoute>
    ),
  },
  {
    path: "/swap/fiat",
    element: (
      <PrivateRoute>
        <SwapFiat />
      </PrivateRoute>
    ),
  },

  // P2P
  {
    path: "/p2p/post-ad",
    element: (
      <PrivateRoute>
        <P2PPostAd />
      </PrivateRoute>
    ),
  },
  {
    path: "/p2p/buy",
    element: (
      <PrivateRoute>
        <P2PBuy />
      </PrivateRoute>
    ),
  },
  {
    path: "/p2p/buy/details",
    element: (
      <PrivateRoute>
        <P2PBuyDetails />
      </PrivateRoute>
    ),
  },
  // {
  //   path: "/buy",
  //   element: (
  //     <PrivateRoute>
  //       <P2PTradeBuy />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   path: "/buy/payment",
  //   element: (
  //     <PrivateRoute>
  //       <P2PTradeBuyPayment />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   path: "/buy/payment/result",
  //   element: (
  //     <PrivateRoute>
  //       <P2PTradeBuyPaymentResult />
  //     </PrivateRoute>
  //   ),
  // },
  {
    path: "/p2p/sell",
    element: (
      <PrivateRoute>
        <P2PSell />
      </PrivateRoute>
    ),
  },
  {
    path: "/p2p/sell/details",
    element: (
      <PrivateRoute>
        <P2PSellDetails />
      </PrivateRoute>
    ),
  },
  // {
  //   path: "/sell",
  //   element: (
  //     <PrivateRoute>
  //       <P2PTradeSell />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   path: "/sell/payment",
  //   element: (
  //     <PrivateRoute>
  //       <P2PTradeSellPayment />
  //     </PrivateRoute>
  //   ),
  // },
  // {
  //   path: "/sell/payment/result",
  //   element: (
  //     <PrivateRoute>
  //       <P2PTradeSellPaymentResult />
  //     </PrivateRoute>
  //   ),
  // },

  // admin start,

  {
    path: "/admin-dashboard",
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/agent",
    element: (
      <PrivateRoute>
        <Agents />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/agent/create",
    element: (
      <PrivateRoute>
        <CreateAgents />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/agent/edit",
    element: (
      <PrivateRoute>
        <EditAgents />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/tenants-payment-configuration",
    element: (
      <PrivateRoute>
        <TenantsPaymentConfigurations />
      </PrivateRoute>
    ),
  },

  // Subadmins
  {
    path: "/admin/sub-admin",
    element: (
      <PrivateRoute>
        <Subadmins />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/sub-admin/create",
    element: (
      <PrivateRoute>
        <CreateSubAdmin />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/sub-admin/edit",
    element: (
      <PrivateRoute>
        <EditSubAdmin />
      </PrivateRoute>
    ),
  },

  // Players
  {
    path: "/player/dashboard",
    element: (
      <PrivateRoute>
        <PlayerDashboard />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/players",
    element: (
      <PrivateRoute>
        <Players />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/players/create",
    element: (
      <PrivateRoute>
        <CreatePlayer />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/players/edit",
    element: (
      <PrivateRoute>
        <EditPlayer />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/transactions",
    element: (
      <PrivateRoute>
        <Transactions />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/transactions/create",
    element: (
      <PrivateRoute>
        <CreateTransaction />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/notifications",
    element: (
      <PrivateRoute>
        <Notifications />
      </PrivateRoute>
    ),
  },

  // SiteMantainence

  {
    path: "/admin/site-mantainence",
    element: (
      <PrivateRoute>
        <SiteMantainence />
      </PrivateRoute>
    ),
  },

  // Bonus

  {
    path: "/admin/bonus",
    element: (
      <PrivateRoute>
        <Bonus />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/bonus/create",
    element: (
      <PrivateRoute>
        <CreatePromoCode />
      </PrivateRoute>
    ),
  },

  // CMS
  {
    path: "/admin/CMS",
    element: (
      <PrivateRoute>
        <CMS />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/CMS/create",
    element: (
      <PrivateRoute>
        <CreateCMS />
      </PrivateRoute>
    ),
  },

  // AgentReport
  {
    path: "/admin/agent/report",
    element: (
      <PrivateRoute>
        <AgentReport />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/player/report",
    element: (
      <PrivateRoute>
        <PlayerReport />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/transaction/report",
    element: (
      <PrivateRoute>
        <TransactionReport />
      </PrivateRoute>
    ),
  },

  // RejectedWithdrawl
  {
    path: "/admin/withdrawl/rejected",
    element: (
      <PrivateRoute>
        <RejectedWithdrawl />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/withdrawl/pending",
    element: (
      <PrivateRoute>
        <PendingWithdrawl />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/withdrawl/verified",
    element: (
      <PrivateRoute>
        <VerifiedWithdrawal />
      </PrivateRoute>
    ),
  },

  // payment

  {
    path: "/admin/payment/bank/details",
    element: (
      <PrivateRoute>
        <BankDetails />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/payment/bank/create",
    element: (
      <PrivateRoute>
        <AddNewBank />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/payment/bank/deposite",
    element: (
      <PrivateRoute>
        <DepositeRequest />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/admin/credentials",
    element: (
      <PrivateRoute>
        <AdminCredentials />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/paymentprovider",
    element: (
      <PrivateRoute>
        <PaymentProviders />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/admin/credentials/create",
    element: (
      <PrivateRoute>
        <CreateCredentials />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/admin/paymentprovider/create",
    element: (
      <PrivateRoute>
        <CreatePaymentProvider />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/admin/configure",
    element: (
      <PrivateRoute>
        <AdminConfigure />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/admin/banner",
    element: (
      <PrivateRoute>
        <AdminBanner />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/admin/banner/edit",
    element: (
      <PrivateRoute>
        <EditBanner />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/admin/banner/create",
    element: (
      <PrivateRoute>
        <CreateBanner />
      </PrivateRoute>
    ),
  },
  {
    path: "/admin/admin/menu",
    element: (
      <PrivateRoute>
        <AdminMenu />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/admin/theme/settings",
    element: (
      <PrivateRoute>
        <ThemeSettings />
      </PrivateRoute>
    ),
  },

  {
    path: "/admin/admin/user/registration",
    element: (
      <PrivateRoute>
        <AdminUserRegistration />
      </PrivateRoute>
    ),
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
