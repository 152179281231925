import React, { useEffect, useRef, useState } from "react";
import "../assets/css/wallet/index.css";

// images
import close from "../assets/img/settings/svg/close.svg";
import eplay from "../assets/img/stake/eplayment.svg";
// import step1 from "../assets/img/wallet/step1.svg";
import step1 from "../assets/img/sidebar/step1.png";
// import step2 from "../assets/img/wallet/step2.svg";
import step2 from "../assets/img/sidebar/step2.png";

import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { privateRequest } from "../config/axios.config";
import { useAuth } from "../contexts/AuthContext";
import { useGetBalance } from "../queries/balance";
import { useGetAvailableCryptos } from "../queries/crypto";
import { useGetWallet } from "../queries/wallet";
import { getCoinName } from "../utils";
import { CurrencyCoins } from "../utils/staking/CurrencyCoins";
import ButtonLoader from "./ButtonLoader";
import { GA } from "./common/GA";
import { PayDropdown } from "./popups/PayDropdown";
import { BankTransfer } from "./staker/BankTransfer";
import { CreditCard } from "./staker/CreditCard";
import { EWallet } from "./staker/E-Wallet";

const cryptoNetworkMapping = {
  BTC: ["Bitcoin"],
  ETH: ["ERC20"],
  USDT: ["BEP20", "ERC20"],
  BNB: ["BEP20"],
  XRP: ["Ripple"],
  USDC: ["Ethereum"],
  BUSD: ["BEP20", "ERC20"],
  SOL: ["Solana"],
  ADA: ["Cardano"],
  DOGE: ["Dogecoin"],
  TRX: ["Tron"],
};

export const WithdrawAmount = () => {
  const ref = useRef(null);
  const [otps, setOtps] = useState(["", "", "", "", "", ""]);

  const [active, setActive] = useState(1);
  const [Paycheck, setPaycheck] = useState(false);
  const [fiatConditional, setfiatConditional] = useState(1);
  const [formattedCryptoData, setFormattedCryptoData] = useState([]);

  const navigate = useNavigate();
  const ResizeWork = (e) => {
    let Element = window.innerWidth;
    if (ref?.current) {
      ref.current.style.width = `${Element - 600}px`;
    }
  };
  useEffect(() => {
    ResizeWork();
    window.addEventListener("resize", ResizeWork);
  }, []);

  const { data: wallets } = useGetWallet();

  const { data: balance } = useGetBalance();

  const [amount, setAmount] = useState();
  const [coin, setCoin] = useState(formattedCryptoData[0]);
  const [address, setAddress] = useState();
  const [network, setNetwork] = useState();
  const { userInfo } = useAuth();
  const [loading, setLoading] = useState(false);
  const [gasetter, setgasetter] = useState(false);
  const [onConfirm, setOnConfirm] = useState(true);

  const handleOtpsChange = (otps) => {
    setOtps(otps);
  };

  const withdrawAmount = () => {
    toast.error(
      "Please configure your Google Authentication to continue the transfer"
    );
  };

  const showGA = () => {
    if (!coin) {
      toast.error("Please select a coin");
      return;
    }
    if (!amount) {
      toast.error("Please enter an amount");
      return;
    }
    if (amount > balance?.balance) {
      toast.error("Insufficient balance");
      return;
    }

    if (!address) {
      toast.error("Please enter a wallet address");
      return;
    }



    if (userInfo?.user?.is2FAEnabled) {
      setgasetter(true);
    }
  };

  const onConfirmWithdraw = () => {
    if (onConfirm) {
      confirmWithdraw();
    }
  };
  const { data: cryptoData, isLoading: loadingCryptos, error: cryptoError } = useGetAvailableCryptos();
  useEffect(() => {

    if (cryptoData && cryptoData.data) {
      const newFormattedCryptoData = Object.entries(cryptoData.data).map(([key, value]) => {
        return {
          symbol: key,
          heading:  value?.symbol?.includes("_")
          ? `${value?.symbol.split("_")[0]} (${
            value?.symbol.split("_")[1]
            })`
          : value?.symbol,

          img: `https://cryptologos.cc/logos/thumbs/${getCoinName(
            value?.symbol.split("_")[0]
          )}.png?v=030`
        };
      });
      console.log("newFormattedCryptoData",newFormattedCryptoData);
      setFormattedCryptoData(newFormattedCryptoData);
    }
  }, [cryptoData]);
  const withAmountMutation = useMutation(
    (payload) => privateRequest.post("user/balance/withdraw-balance", payload),
    {
      onSuccess: () => {
        toast.success("Withdrawal successful");
        setAmount("");
        setAddress("");
        setNetwork("");
        setgasetter(false);
      },
      onError: (error) => {
        if (error?.response.data?.message) {
          toast.error(error?.response.data?.message);
          return;
        }
        toast.error("Withdrawal failed");
      },
    }
  );

  const confirmWithdraw = () => {
    const otp = otps.join("");
    const payload = {
      amount,
      paymentMethod: active ? "crypto" : "fiat",
      currency: coin?.symbol,
      walletAddress: address,

      otp: otp,
    };
    withAmountMutation.mutate(payload);
  };
console.log(coin);
  return (
    <div className="body-left-area !w-full" ref={ref}>
      {gasetter && (
        <GA
          isLoading={withAmountMutation.isLoading}
          onOtpsChange={handleOtpsChange}
          confirm={onConfirmWithdraw}
          setgasetter={setgasetter}
        />
      )}
      <ToastContainer theme="dark" autoClose={3000} />
      <div className="settings-area  rounded-lg">
        <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
          <h1>Withdraw</h1>
          <img
            src={close}
            className="cursor-pointer"
            alt=""
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>
        {/* <div className="px-5 mt-5">
          <div className="bg-[#252525] border-[1px] border-[#3C403F] h-[48px]   flex items-center  justify-between rounded-xl  sm:!h-10  ">
            <button
              className={`text-[#B7B8BC] cursor-pointer h-full rounded-xl text-[18px] font-arabicmed  ${
                active === 1 && "bg-[#44D09F] !text-[#111010]"
              } flex-1 sm:!rounded-lg`}
              onClick={(e) => {
                setActive(1);
              }}
            >
              Crypto
            </button>
            <button
              className={`text-[#B7B8BC] cursor-pointer h-full rounded-xl text-[18px] font-arabicmed ${
                active === 2 && "bg-[#44D09F] !text-[#111010]"
              } flex-1 sm:!text-[16px] sm:!rounded-lg xs:!text-[14px]
        `}
              onClick={(e) => {
                setActive(2);
              }}
            >
              Fiat
            </button>
          </div>
        </div> */}

        {active == 1 && (
          <div className=" flex items-start relative">
            <div className="wallet-content-body-area py-6 px-6 flex-1">
              <div className="popup DepositePopUp rounded-xl">
                <div className="flex items-center gap-4 mb-8">
                  <img src={step1} alt="" className="sm:w-[30px]" />
                  <h1 className="text-[22px] text-white font-arabicreg sm:text-[16px]">
                    Payment method
                  </h1>
                </div>

                <div className="mb-[36px]">
                  <label
                    htmlFor="#"
                    className="text-[18px] font-arabicreg text-[#AEB2B1] mb-3 block"
                  >
                    Select Coin
                  </label>
                  {!loadingCryptos && formattedCryptoData && (
                    <PayDropdown
                      stake
                      classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
                      dollar={coin?.img}
                      heading={coin?.heading}
                      list={formattedCryptoData}
                      callback={(e) => {
                        setCoin(e);
                        setNetwork("");
                      }}
                    />
                  )}
                  {/* <PayDropdown
                    stake
                    classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
                    dollar={coin?.img}
                    heading={coin?.heading}
                    list={Coin}
                    callback={(e) => {
                      setCoin(e);
                      setNetwork("");
                    }}
                  /> */}
                </div>

                {/* <div className="mb-[36px] flex items-center xs:flex-col xs:items-start xs:gap-2">
                  <label
                    htmlFor="#"
                    className="text-[18px] sm:text-[14px] mr-6 font-arabicreg text-[#AEB2B1]  block "
                  >
                    Withdraw to
                  </label>
                  <div className="flex items-center gap-3">
                    <button className="text-[14px] font-arabicmed text-white bg-[#2E2E2E] w-[150px] h-[43px] rounded-lg sm:w-[120px]  border-[1px] border-[#5DF9C2]">
                      New Address
                    </button>
                    <button className="text-[14px] font-arabicmed text-white bg-[#252525] w-[150px] h-[43px] rounded-lg sm:w-[120px]  border-[1px] border-[transparent]">
                      Address Book
                    </button>
                  </div>
                </div> */}

                {/* <div className="mb-3">
                  <label
                    htmlFor="#"
                    className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                  >
                    Select Network
                  </label>
                  <DepositeChooseNetwork
                    heading={network}
                    list={cryptoNetworkMapping[coin?.heading]}
                    cb={(e) => {
                      setNetwork(e);
                    }}
                  />
                </div> */}
                <div className="mb-3">
                  <label
                    htmlFor="#"
                    className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                  >
                    Wallet Address
                  </label>
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6 sm:text-[14px]"
                  />
                </div>
                <p className="text-[16px] sm:text-[12px] font-arabicreg text-[#5DF9C2]">
                  Wallet address automatically matched to corresponding network.
                </p>

                <div className="flex items-center gap-4 mt-8">
                  <img src={step2} alt="" className="sm:w-[30px]" />
                  <h1 className="text-[22px] sm:text-[18px] text-white font-arabicreg">
                    Withdrawal Information
                  </h1>
                </div>
                <div className="mt-8">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="#"
                      className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                    >
                      Amount
                    </label>
                    <label
                      htmlFor="#"
                      className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                    >
                      <span className="text-white">
                        {" "}
                        {balance?.balance} USD
                      </span>{" "}
                      Available
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      type="text"
                      value={amount}
                      onChange={(e) => {
                        if (e.target.value.match(/^\d*\.?\d{0,2}$/)) {
                          setAmount(e.target.value);
                        }
                      }}
                      className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full  rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed px-6 sm:text-[14px]"
                    />

                    <div className="flex items-center absolute top-[13px] right-5">
                      <p
                        className="text-[18px] cursor-pointer pr-3 border-r-[1px] border-r-[#3C403F] font-arabicreg text-[#5DF9C2] sm:text-[14px]"
                        onClick={() => setAmount(balance?.balance ?? 0)}
                      >
                        MAX
                      </p>
                      <p className="text-[18px] sm:text-[14px]  pl-3 font-arabicreg text-white">
                        USD
                      </p>
                    </div>
                  </div>

                  {/* <p className="text-[#AEB2B1] sm:text-[12px] mt-3 text-right text-[16px] font-arabicreg">
                    24h remaining limit
                  </p> */}
                </div>

                {/* <div className="bg-[#2E2E2E] p-4 mt-[36px] rounded-xl">
                  <div className="flex items-start justify-between">
                    <h1 className="text-[16px] sm:text-[14px] font-arabicreg text-white">
                      Receive amount
                    </h1>
                    <div className="text-right">
                      <h1 className="text-[26px] sm:text-[20px] font-arabicrmed text-white">
                        0.00 USDT
                      </h1>
                      <p className="text-[16px] mt-2 sm:text-[14px] font-arabicreg text-[#AEB2B1]">
                        0.00 USD network fee included
                      </p>
                    </div>
                  </div>
                </div> */}
                {userInfo?.user?.is2FAEnabled ? (
                  <>
                    <button
                      onClick={() => showGA()}
                      className="text-[18px] text-[#FFF] font-arabicbold h-[50px] w-full bg-gradient-to-br from-[#9BE402] to-[#1FA83C] sm:text-[14px] sm:h-[45px] mt-6 rounded-lg hover:from-[#82e402] hover:to-[#146626]"
                      disabled={withAmountMutation.isLoading}
                    >
                      {withAmountMutation.isLoading ? (
                        <ButtonLoader />
                      ) : (
                        "Confirm to Withdraw"
                      )}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className={`text-[18px] mt-6 font-arabicbold text-[#111010] bg-[#5DF9C2]
                border-[#3C403F] w-full  h-[50px] rounded-lg cursor-pointer sm:!text-[14px] hover:from-[#82e402] hover:to-[#146626]`}
                      onClick={(e) => {
                        withdrawAmount();
                      }}
                      disabled={withdrawAmount.isLoading}
                    >
                      Confirm to Transfer
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {active == 2 && (
          <div className="px-5 py-5">
            <div className="bg-[#252525] p-6 rounded-xl">
              <div className="flex items-center justify-between xs:flex-col">
                <h1 className="text-[18px] sm:text-[14px] font-arabicmed text-white">
                  Select your currency
                </h1>
              </div>
              <div className="mt-4 w-full pr-6 pl-[6px] h-[50px] rounded-[10px] flex items-center bg-[#2E2E2E]">
                <PayDropdown
                  classStyle="bg-[#3A3A3A] min-w-[196px] sm:min-w-[120px]"
                  callback={(e) => {
                    if (e === "PHP") {
                      setPaycheck(true);
                    } else {
                      setPaycheck(false);
                    }
                  }}
                  dollar={CurrencyCoins[0].img}
                  stakeFix
                  heading={CurrencyCoins[0].heading}
                  list={CurrencyCoins}
                />
              </div>
            </div>
            <div className="fiat-area mt-4">
              <div className="input-wrapper mb-12 ">
                <label
                  htmlFor=""
                  className="text-[14px] font-arabicreg text-white"
                >
                  Amount
                </label>
                <input
                  type="text"
                  value={0}
                  className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white text-[12px] font-arabicmed px-6"
                />
              </div>

              <div className="flex items-center lgmed:grid lgmed:grid-cols-2 lgmed:gap-3">
                <h1 className="text-[20px] font-arabicmed text-white lgmed:col-span-2">
                  Payment with
                </h1>

                <button
                  className={`h-[43px] border-[1px] w-[150px] bg-[#252525] font-arabicmed border-[transparent] text-white text-[14px] rounded-lg ml-8 ${
                    fiatConditional === 1 && "!border-[#52E3B0]"
                  } lgmed:ml-0 lgmed:w-full`}
                  onClick={(e) => {
                    setfiatConditional(1);
                  }}
                >
                  Bank transfer
                </button>

                <button
                  className={`h-[43px] border-[1px] w-[150px] bg-[#252525] font-arabicmed text-white border-[transparent] text-[14px] rounded-lg ml-3 ${
                    fiatConditional === 3 && "!border-[#52E3B0]"
                  } lgmed:ml-0 lgmed:w-full`}
                  onClick={(e) => {
                    setfiatConditional(3);
                  }}
                >
                  Credit Card
                </button>

                <button
                  className={`h-[43px] border-[1px] w-[150px] bg-[#252525] font-arabicmed text-white border-[transparent] text-[14px] rounded-lg ml-3 ${
                    fiatConditional === 4 && "!border-[#52E3B0]"
                  } lgmed:ml-0 lgmed:w-full`}
                  onClick={(e) => {
                    setfiatConditional(4);
                  }}
                >
                  E-wallet
                </button>
              </div>
              {fiatConditional === 1 && (
                <BankTransfer
                  classnames="grid grid-cols-4"
                  Paycheck={Paycheck}
                />
              )}
              {fiatConditional === 3 && <CreditCard />}
              {fiatConditional === 4 && (
                <EWallet classnames="grid grid-cols-6" Paycheck={Paycheck} />
              )}
            </div>

            {Paycheck ? (
              <button
                className="text-[#111010] text-[18px] font-arabicbold bg-[#58F2BB] h-[50px] w-full rounded-lg mt-12 flex items-center justify-center"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Pay via
                <img src={eplay} alt="" className="ml-3 mr-1" />
                Eplayment
              </button>
            ) : (
              <button
                className="text-[#111010] text-[18px] font-arabicbold bg-[#58F2BB] h-[50px] w-full rounded-lg mt-12"
                onClick={(e) => {
                  navigate("/stake/done");
                }}
              >
                Moonpay
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
