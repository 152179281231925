import React from "react";
import btc from "../../assets/img/sports/btc.svg";

export const HighRollersTable= () => {

  const data = [
    {
      event: "Rezai",
      user: "Hidden",
      time: "02-24-2024 08:06:22",
      odds: "1.01",
      amount: "94.00052146",
    },
    {
      event: "Hanwha",
      user: "Hidden",
      time: "02-24-2024 08:06:15",
      odds: "1.30",
      amount: "63.84512633",
    },
    {
      event: "BetBoom",
      user: "real.user12",
      time: "02-24-2024 08:06:08",
      odds: "3.10",
      amount: "21.11002568",
    },
    {
      event: "Hsu Yu",
      user: "Hidden",
      time: "02-24-2024 08:06:02",
      odds: "1.02",
      amount: "0.05525486",
    },
  ];


  return (
    <>
    <div className="bg-[#1A1A1B] px-6 py-4 mt-4 rounded-lg w-full">
        {data && data.length === 0 ? (
          <div>
            <h1 className="no-data-available text-center text-white">Oops! There is no data yet!</h1>
          </div>
        ) : (
          <table className="w-full lgmed:min-w-[800px]">
            <thead>
              <tr className="h-[56px] border-b border-[#3A3A3A]">
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Game/Event
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  User
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Timestamp
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Odds
                </th>
                <th className="text-left px-2 font-arabicreg text-[14px] text-[#3C403F]">
                  Bet Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row, key) => (
                <tr key={key} className="h-[56px]">
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.event}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.user}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.time}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    {row.odds}
                  </td>
                  <td className={`text-left px-2 font-arabicreg text-[14px] text-[#fff]`}>
                    <div className="flex">
                    {row.amount}&nbsp;<img src={btc}/>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
    </>
  );
};