import React, { useRef, useState } from "react";
import "../assets/css/wallet/index.css";

// images
import close from "../assets/img/settings/svg/close.svg";

// components
import { useNavigate } from "react-router-dom";
import { Coin } from "../utils/staking/Coin";
import { WalletOptions } from "./common/WalletOptions";
import { PayDropdown } from "./popups/PayDropdown";

export const VaultProBinanceLeft = () => {
  const ref = useRef(null);
  const [optionDeposite, setoptionDeposite] = useState(1);
  const [activeState, setActive] = useState(null);
  const navigate = useNavigate();
  // const ResizeWork = (e) => {
  //   let Element = window.innerWidth;
  //   ref.current.style.width = `${Element - 600}px`;
  // };
  // useEffect(() => {
  //   ResizeWork();
  //   window.addEventListener("resize", ResizeWork);
  // }, []);

  return (
    <div className="body-left-area  mr-6 w-full">
    {/* <div className="body-left-area  mr-6" ref={ref}> */}
      <div className="settings-area  rounded-lg">
        <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
          <h1>Wallet</h1>
          <img
            src={close}
            className="cursor-pointer"
            alt=""
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>

        <div className="settings-content-area flex items-start relative">
          <div className="settings-options-area flex flex-col p-3">
            <WalletOptions active={6} />
          </div>

          <div className="wallet-content-body-area py-6 px-6 flex-1">
            <div className="popup DepositePopUp rounded-xl">
              <div className="buttons-wrapper pb-6  mb-6 grid grid-cols-2 gap-3">
                <button
                  className={`${optionDeposite === 1 && "active"}`}
                  onClick={(e) => {
                    setoptionDeposite(1);
                  }}
                >
                  Transfer In
                </button>
                <button
                  className={`${optionDeposite === 2 && "active"}`}
                  onClick={(e) => {
                    setoptionDeposite(2);
                  }}
                >
                  Transfer Out
                </button>
              </div>

              <div className="flex average-percentage items-center mb-6 justify-between">
                <h1>
                  Annual Percentage Rate <span>5%</span>
                </h1>
                <p>Security Rules</p>
              </div>

              <div className="flex average-percentage items-center mb-6 justify-between">
                <p>Wallet Balance : 0</p>
              </div>

              <div className="option-three">
                <div className="input-container ">
                  <div className="input-notify-wrapper rounded-lg flex items-center px-4">
                    <p className="flex-1">0</p>
                    <div className="flex items-center mr-3 tags-wrapper">
                      <button
                        style={{ height: 34 }}
                        className={`${activeState === 1 && "active"}`}
                        onClick={(e) => {
                          setActive(1);
                        }}
                      >
                        Max
                      </button>
                    </div>
                    <PayDropdown
                      stakeFix
                      dollar={Coin[0].img}
                      heading={Coin[0].heading}
                      list={Coin}
                    />
                  </div>
                </div>
                <button
                  className="flex  mt-6 moon-pay-btn items-center justify-center w-full "
                  style={{ fontSize: 14 }}
                >
                  Transfer to Vault Pro
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
