import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import WorldMap from "react-svg-worldmap";
import affliateProgram from "../assets/img/affiliate/affliate-program.png";
import deposite from "../assets/img/affiliate/deposite.svg";
import facebook from "../assets/img/affiliate/facebook.svg";
import telegram from "../assets/img/affiliate/send.svg";
import tesla from "../assets/img/affiliate/tesla.svg";
// import tick from "../assets/img/affiliate/tick.svg";
import transaction from "../assets/img/affiliate/transaction.svg";
import twitter from "../assets/img/affiliate/twitter.svg";
import banner4 from "../assets/img/banner/banner4.png";
import banner10 from "../assets/img/banner/banner10.png";
import banner11 from "../assets/img/banner/banner11.png";
import banner12 from "../assets/img/banner/banner12.png";
import tick from "../assets/img/sidebar/tick.png";
import { useAuth } from "../contexts/AuthContext";
import { useGetBalance, useGetGBCBalance } from "../queries/balance";
import { useGetCommunityStatistics } from "../queries/community";
import { convertToCurrency } from "../utils";
import { shares } from "../utils/modules/Shares";
import { AffiliateHeader } from "./AffiliateHeader";
import { SidebarArea } from "./SidebarArea";
import Carousel from "./affiliate/Carousel";
import RewardChart from "./affiliate/RewardChart";
import { ShowcaseBox } from "./affiliate/ShowcaseBox";
import { Table } from "./affiliate/Table";
import { TableCommunity } from "./affiliate/TableCommunity";
import { Timer } from "./common/Timer";

export const AffiliateRight = ({ setsigninState, setSignUpState, login }) => {
  const [copied, setCopied] = useState(false);
  const [prizeState, setprizeState] = useState(true);
  const { setUserInfo, userInfo } = useAuth();
  const { data: balance } = useGetBalance();
  const { data: communityStatistics } = useGetCommunityStatistics();
  const [typeShowAside, settypeShowAside] = useState(-1);
  const navigate = useNavigate();
  const { data } = useGetGBCBalance();

  // Function to handle the copy action
  const handleCopy = (values) => {
    setCopied(values);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  const referralLink = userInfo
    ? `https://gamebox888.net/signup?ref=${userInfo?.user?.userCode}`
    : "https://gamebox888.net/";
  const userCode = userInfo ? userInfo?.user?.userCode : "";

  const countriesData = [
    { country: "cn", value: 1389618778 }, // china
    { country: "in", value: 1311559204 }, // india
    { country: "us", value: 331883986 }, // united states
    { country: "id", value: 264935824 }, // indonesia
    { country: "pk", value: 210797836 }, // pakistan1
    { country: "br", value: 210301591 }, // brazil
    { country: "ng", value: 208679114 }, // nigeria
    { country: "bd", value: 161062905 }, // bangladesh
    { country: "ru", value: 141944641 }, // russia
    { country: "mx", value: 127318112 }, // mexico
    { country: "ph", value: 127318112 }, // mexico
  ];
  const getStyle = ({ countryCode, color }) => {
    let finder = countriesData.find(
      (data) => data.country == countryCode.toLowerCase()
    );

    return {
      fill: finder != undefined ? "#6FCF17" : color,
      stroke: "#3C403F",
      strokeWidth: 1,
      strokeOpacity: 1,
      cursor: "pointer",
    };
  };

  const [showBenefits, setShowBenefits] = useState(false);

  const toggleBenefits = () => {
    setShowBenefits(!showBenefits);
  };

  const [chartType, setChartType] = useState("bar"); // Default chart type is 'bar'

  const handleChartTypeChange = (newChartType) => {
    setChartType(newChartType);
  };

  const imageUrls = [banner4, banner10, banner11, banner12];

  return (
    <div className="flex-1">
      <AffiliateHeader
        login={login}
        headerType="Affiliate"
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />

      <div className="body-area flex gap-2 affiliate-body-area items-start px-6 pt-6">
        <div className="body-left-area w-full">
          {/* <img src={banner4} className="w-full rounded-lg mb-6" alt="" /> */}
          <Carousel imageUrls={imageUrls} />

          <div className="flex justify-center items-center py-8">
            <button
              onClick={toggleBenefits}
              className="py-4 px-8 bg-gradient-to-br from-[#9BE402] to-[#1FA83C] hover:from-[#82e402] hover:to-[#146626] text-white duration-200 transition-all  ease-in-out rounded-lg font-arabicmed text-[18px]"
            >
              Discover Country Operator Benefits.
            </button>
          </div>
          {showBenefits && (
            <>
              <div
                className={`text-black mb-8 bg-[#1A1A1B] py-8 px-12 rounded-md font-arabicmed slideToggleContainer ${
                  showBenefits ? "open" : ""
                }`}
              >
                <h4 className="text-2xl mt-2 mb-8 text-center text-white">
                  🌍 Unlock Exclusive Benefits
                </h4>
                <ul className="space-y-3 mt-3 grid sm:grid-cols-1 grid-cols-2  text-base items-baseline">
                  <li className="text-white">
                    {" "}
                    ✅ Earn 6% from Gross Staking Sales (GSS)
                  </li>
                  <li className="text-white">
                    {" "}
                    ✅ Receive 10% Gross Revenue from GBXchange
                  </li>
                  <li className="text-white">
                    {" "}
                    ✅ Access Your Exclusive Admin Panel
                  </li>
                  <li className="text-white">
                    {" "}
                    ✅ Enjoy Whitelabel Casino and Sportsbook
                  </li>
                  <li className="text-white"> ✅ Get Marketing Support</li>
                  <li className="text-white">
                    {" "}
                    ✅ VIP Service for Landbased Gaming Network
                  </li>
                </ul>

                <h3 className="mt-14 text-center">
                  <span className="text-3xl text-white">
                    Join us for a rewarding partnership! 🚀
                  </span>
                  <br />
                  <span className="text-lg text-[#AEB2B1]">
                    {" "}
                    For inquiries:{" "}
                    <a
                      href="mailto:support@gamebox888.net"
                      className="text-[#6FCF17]"
                    >
                      support@gamebox888.net
                    </a>
                  </span>
                </h3>
              </div>
            </>
          )}

          {/* {prizeState && <Prize setprizeState={setprizeState} />} */}
          <div className="body-left-area w-full">
            <Timer />
          </div>
          <div
            className={`grid grid-cols-4 gap-6 lg:grid-cols-2 xs:!grid-cols-1 ${
              typeShowAside != -1 && "!grid-cols-3"
            }`}
          >
            <ShowcaseBox
              // coin={bitcoin}
              dollar
              extra="Cash Wallet"
              heading="Available Balance"
              value={convertToCurrency(balance?.balances?.cashWallet ?? 0, 2)}
              depositeButton
              transactionButton
            />
            <ShowcaseBox
              dollar
              heading="Total Commissions"
              value={convertToCurrency(
                communityStatistics?.totalDirectReferralCommission ?? 0,
                2
              )}
            />
            <ShowcaseBox
              coin={tesla}
              heading="Total Referrals"
              value={communityStatistics?.totalReferrals}
              hideIcon
            />
            <ShowcaseBox
              dollar
              dailyrb
              heading="Royalty Bonus"
              value={convertToCurrency(
                communityStatistics?.totalRoyality ?? 0,
                2
              )}
            />
          </div>

          <div className="flex items-start mt-10 gap-6 lg:flex-col">
            {/* <div className="bg-[#1A1A1B] w-[220px] lg:w-full border-[1px] border-[#3C403F]  rounded-xl p-8 sm:p-5">
              <h4 className="text-white text-[20px] font-normal">
                Available GBC Balance
              </h4>
              <div className="text-white text-[28px] font-bold my-6">
                {balance?.balances?.winningBalance
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </div>
              <button
                class="flex w-full h-[32px] mb-3 items-center border-[#3C403F] border-[1px] bg-[#2E2E2E] justify-center rounded-lg gap-1  sm:!w-full sm:!min-h-[32px]"
                onClick={(e) => {
                  navigate("/transfer/gbc");
                }}
              >
                <img src={deposite} alt="" />
                <p class="text-[12px] font-arabicmed text-white">Transfer</p>
              </button>
              <button
                class="flex w-full h-[32px] items-center border-[#3C403F] border-[1px] bg-[#2E2E2E] justify-center rounded-lg gap-1  sm:!w-full sm:!min-h-[32px]"
                onClick={(e) => {
                  navigate("/transaction-history");
                }}
              >
                <img src={transaction} alt="" />
                <p class="text-[12px] font-arabicmed text-white">
                  Transaction History
                </p>
              </button>
            </div> */}
            <div className="bg-[#1A1A1B] w-[450px] lg:w-full border-[1px] border-[#3C403F]  rounded-xl p-8 sm:p-5">
              <div className="py-3 flex justify-center items-center  mb-2 rounded-lg mx-auto">
                <div className="bg-white rounded-lg">
                  <QRCode
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      padding: "10px",
                      background: "#FFFFF",
                    }}
                    title="Referral Link"
                    value={referralLink}
                    bgColor="#FFFFFF"
                    fgcolor="#6CE4BA"
                    size="150"
                    level="M"
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
              <div className="flex bg-[#252525]  border-[1px] border-[#3C403F]  items-center justify-between mb-4 h-[51px] p-3 rounded-lg">
                <p className="text-[20px] sm:text-[16px]  font-arabicreg text-white">
                  Referral Code:{" "}
                  <span className="font-arabicmedium">{userCode}</span>
                </p>
                <input
                  type="text"
                  value={userCode}
                  className="text-white bg-transparent outline-none border-0 text-[20px] font-arabicmed flex-1 w-full ml-2 hidden"
                />

                <CopyToClipboard text={userCode} onCopy={(e) => handleCopy(1)}>
                  <button className="text-[20px] sm:text-[16px]  font-arabicmed text-[#fff] hover:text-[#6FCF17]">
                    {copied === 1 ? "Copied" : "Copy"}
                  </button>
                </CopyToClipboard>
              </div>
              <div className="flex bg-[#252525]  border-[1px] border-[#3C403F]  items-center justify-between  h-[51px] p-3 rounded-lg ">
                <div className="overflow-hidden mr-3  flex-1">
                  <p className="text-[20px] sm:text-[16px]  font-arabicreg text-white whitespace-nowrap">
                    Referral Link:{" "}
                    <span className="font-arabicmedium inline">
                      {referralLink}
                    </span>
                  </p>
                </div>
                <input
                  type="text"
                  value={referralLink}
                  className="text-white bg-transparent hidden"
                  readOnly
                />
                <CopyToClipboard
                  text={referralLink}
                  onCopy={(e) => handleCopy(2)}
                >
                  <button className="text-[20px] sm:text-[16px]  font-arabicmed text-[#fff] hover:text-[#6FCF17]">
                    {copied === 2 ? "Copied" : "Copy"}
                  </button>
                </CopyToClipboard>
              </div>
              <p className="text-[20px] font-arabicreg text-white my-6">
                Share the link with your Friend
              </p>

              <div className="grid grid-cols-3 gap-6  ">
                <a
                  href="/"
                  target="_blank"
                  onClick={(e) => {
                    shares.facebook(e, referralLink);
                  }}
                  className="bg-[#2E2E2E] flex items-center justify-center rounded-lg border-[1px] border-[#3C403F] h-[64px] hover:bg-[#3C403F]"
                >
                  <img src={facebook} className="pointer-events-none" alt="" />
                </a>

                <a
                  target="_blank"
                  onClick={(e) => {
                    shares.twitter(e, referralLink);
                  }}
                  href="/"
                  className="bg-[#2E2E2E] flex items-center justify-center rounded-lg border-[1px] border-[#3C403F] h-[64px] hover:bg-[#3C403F]"
                >
                  <img src={twitter} alt="" className="pointer-events-none" />
                </a>

                {/* <a
                  target="_blank"
                  onClick={(e) => {
                    shares.twitter(e, referralLink);
                  }}
                  href="/"
                  className="bg-[#2E2E2E] flex items-center justify-center rounded-lg border-[1px] border-[#3C403F] h-[64px] hover:bg-[#3C403F]"
                >
                  <img src={twitter} alt="" className="pointer-events-none" />
                </a> */}

                <a
                  target="_blank"
                  onClick={(e) => {
                    shares.telegram(e, referralLink);
                  }}
                  href="/"
                  className="bg-[#2E2E2E] flex items-center justify-center rounded-lg border-[1px] border-[#3C403F] h-[64px] hover:bg-[#3C403F]"
                >
                  <img
                    src={telegram}
                    alt=""
                    className="pointer-events-none w-[32px]"
                  />
                </a>
              </div>
            </div>

            {/* <div className="bg-[#1A1A1B] w-[462px] lg:w-full border-[1px] border-[#3C403F] rounded-xl p-5 ">
              <h1 className="text-[24px] sm:text-[20px] mb-4 font-arabicmed text-white">
                Add New Wallet
              </h1>
              <p className="text-[#AEB2B1] mb-3 text-[16px] sm:text-[14px]  font-arabicreg">
                Select your wallet
              </p>
              <PayDropdown
                stake
                classStyle="bg-[#3A3A3A] min-w-[196px]"
                dollar={Coin[0].img}
                heading={Coin[0].heading}
                list={Coin}
              />
              <button className="text-[#111010] rounded-lg font-arabicbold text-[18px] h-[50px] w-full bg-[#6FCF17] mt-4 sm:text-[16px] sm:h-[45px] ">
                Add Now
              </button>
            </div> */}
            <div className="transaction-box bg-[#1A1A1B] border-[1px] border-[#3C403F] rounded-xl p-5 flex-1 h-full lg:w-full">
              <div className="flex items-center justify-between">
                <h1 className="text-[24px] sm:text-[20px]  font-arabicmed text-white">
                  Community Leaders
                </h1>
              </div>
              <TableCommunity />
            </div>
          </div>

          <div className="flex mt-10 gap-8 lg:flex-col">
            <div className="transaction-box bg-[#1A1A1B] border-[1px] border-[#3C403F] rounded-xl p-8 flex-1 sm:p-5">
              <div className="flex items-center justify-between">
                <h1 className="text-[24px] sm:text-[20px]  font-arabicmed text-white">
                  Transaction History
                </h1>
                {/* <img src={filter} alt="" /> */}
              </div>
              <div className="lg:overflow-auto">
                <Table />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 mt-10 gap-8  lg:grid-cols-1">
            <div className="bg-[#1A1A1B] h-[321px]   border-[1px] border-[#3C403F] rounded-xl py-8 flex-1 sm:!py-5">
              <RewardChart />
            </div>

            <div className="bg-[#1A1A1B] h-[321px] border-[1px] border-[#3C403F] rounded-xl p-8 flex-1 sm:!p-5 relative sm:h-[unset]">
              <div className="flex items-center justify-between xs:flex-col xs:items-start sm:gap-5">
                <h1 className="text-[24px] sm:text-[20px]  font-arabicmed text-white">
                  By Country
                </h1>
                <div className="grid grid-cols-3 gap-1    sm:grid-cols-2 sm:gap-2 ">
                  <div className="flex items-center">
                    <p className="text-[12px] xs:text-[9px] 1xl:text-[10px]  font-arabicreg text-white">
                      Players 0
                    </p>
                    <span className="w-2 h-2 block rounded-full bg-[#6FCF17] ml-1"></span>
                  </div>
                  <div className="flex items-center col-span-2 sm:col-span-1 1xl:col-span-1">
                    <p className="text-[12px] xs:text-[9px] 1xl:text-[10px]    font-arabicreg text-white">
                      Stakers 0
                    </p>
                    <span className="w-2 h-2 block rounded-full bg-[#36B5FF] ml-1"></span>
                  </div>
                  <div className="flex items-center">
                    <p className="text-[12px] xs:text-[9px] 1xl:text-[10px]   font-arabicreg text-white">
                      Junior Associate 0
                    </p>
                    <span className="w-2 h-2 block rounded-full bg-[#C84716] ml-1"></span>
                  </div>
                  <div className="flex items-center">
                    <p className="text-[12px] xs:text-[9px] 1xl:text-[10px]   font-arabicreg text-white">
                      Senior Partners
                    </p>
                    <span className="w-2 h-2 block rounded-full bg-[#fff] ml-1"></span>
                  </div>
                  <div className="flex items-center 1xl:col-span-2 md:!col-span-1">
                    <p className="text-[12px] xs:text-[9px] 1xl:text-[10px]   font-arabicreg text-white">
                      Managing Directors
                    </p>
                    <span className="w-2 h-2 block rounded-full bg-[#ECA11E] ml-1"></span>
                  </div>
                </div>
              </div>
              <div className="world-map">
                <WorldMap
                  tooltipBgColor="#3FC999"
                  tooltipTextColor="#FFF"
                  color="#252525"
                  size="md"
                  data={countriesData}
                  styleFunction={getStyle}
                />
              </div>
              {/* <img src={world} alt="" className="absolute bottom-0 left-0" /> */}
            </div>
          </div>

          <div className="affiliate-program h-[245px] px-[70px] lgmed:px-10 lgmed:h-[auto] lgmed:py-10 sm:!p-5  justify-between flex items-center  rounded-xl mt-10">
            <img src={affliateProgram} alt="" className="lgmed:hidden" />
            <div className="affilicate-program-presentation max-w-[757px] lgmed:max-w-full">
              <h1 className="text-[24px] sm:text-[20px]  mb-6 font-arabicmed text-white">
                Learn More About Our Affiliate Program
              </h1>
              <p className="text-[16px] sm:text-[14px]  flex items-start  font-arabicreg mb-3 text-white">
                <img src={tick} alt="" className="mr-2 mt-1" />
                If you have a large audience and followers. We have special
                conditions for you to customize your referral program!
              </p>
              <p className="text-[16px] sm:text-[14px]  flex items-start font-arabicreg text-white">
                <img src={tick} alt="" className="mr-2 mt-1" />
                If you can invite players and their wager amount reaches a $100k
                dollars and above, you will get your own customized casino! You
                can set up a casino website with your domain and design style.
              </p>
            </div>
          </div>
        </div>

        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};
