import { ArrowRight, Clock4, LayoutDashboard, Star } from "lucide-react";
import { useEffect, useMemo, useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import info from "../../assets/img/casino/circle-excl.svg";

import leftArrow from "../../assets/img/arrow-left.svg";
import rightArrow from "../../assets/img/arrow-right.svg";
import dropDownWhite from "../../assets/img/admin/svg/arrow-down-white.svg";
import dropDownBlack from "../../assets/img/admin/svg/arrow-down-black.svg";
import expand from "../../assets/img/admin/svg/expand.svg";
import collapse from "../../assets/img/admin/svg/collapse.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import search from "../../assets/img/search-header.svg";

import "swiper/css";
import "../../assets/css/game-slider.css";
import "swiper/css/grid";
import "swiper/css/pagination";
// import required modules

import "swiper/css/navigation";
// import "./styles.css";

import { Grid, Pagination, Navigation } from "swiper/modules";
import { Sports } from "../sports/Sports";

import {
  useCategories,
  useHotGames,
  useInfiniteGames,
  fetchProviders,
  fetchAllSubcategory,
  useFetchGamesPerCategory,
  usePlayGameMutation,
  useGameDetailsQuery
} from "../../queries/gaming";
import { GameCategory } from "./GameCategory";
import ButtonLoader from "../ButtonLoader";

export const BrowseGame = ({
  //games: gameData,
  filterCategory,
  title,
  types,
  category,
  subcategory,
}) => {
  const swiperRef = useRef();

  const activeQuery =
    subcategory == null || subcategory == "" ? "All" : subcategory;
  const [active, setactive] = useState(activeQuery);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // mobile filter
  const [mobile, setMobile] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [searchGame, setSearchGame] = useState("");
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [subCategories, setSubcategories] = useState([]);
  const [Providers, setProviders] = useState([]);
  const [activeProvidersFilter, setActiveProvidersFilter] = useState([]);
  // const [games, setGames] = useState([]);

  const { data: categories, isLoading, isError } = useCategories({ category });
  const preparedCategoty = () => {
    const categoryName = title.split(" ");
    if (categoryName.length > 1) {
      return categoryName.join("-").toLowerCase();
    } else {
      return title.toLowerCase();
    }
  };

  const fetchSubcategories = async () => {
    try {
      const data = await fetchAllSubcategory({ category });
      setSubcategories(data.subcategories);
    } catch (error) {
      console.error(error.message);
    }
  };

  // const {
  //   data: subcategoryData,
  //   isLoading: subcategoryLoading,
  //   error: subcategoryError,
  // } = fetchAllSubcategory(category);

  // const {
  //   data,
  //   fetchNextPage,
  //   hasNextPage,
  //   isLoading: loadingGames,
  // } = useInfiniteGames({ category, selectedCategory, mobile });

  // const {
  //   data,
  //   fetchNextPage,
  //   hasNextPage,
  //   isLoading: loadingGames,
  // } = useFetchGamesPerCategory({
  //   selectedProvider,
  //   category,
  //   subcategory,
  //   mobile: isMobile,
  // });
  const {
    data: hotgames,
    refetch: refetchHotgames,
    fetchNextPage: nextPage,
    hasNextPage: hasPage,
    isLoading: loadingHotgames,
  } = useHotGames({ mobile: isMobile, searchGame });

  const filteredHotGames = useMemo(() => {
    return selectedCategory
      ? hotgames?.pages
        ?.flatMap((page) => page.games)
        .filter((game) => game.category === selectedCategory)
      : hotgames?.pages?.flatMap((page) => page.games).map((game) => game);
  }, [hotgames?.pages, selectedCategory]);

  // Function to manually load more games
  const loadMoreGames = () => {
    setLoading(true);
    if (category === "hot-games") {
      nextPage().then(
        () => {
          setLoading(false);
          console.log("Next page loaded successfully!");
        },
        (error) => {
          setLoading(false);
          console.error("Error loading next page:", error);
        }
      );
    } else {
      anotherPage().then(
        () => {
          setLoading(false);
          console.log("Another page loaded successfully!");
        },
        (error) => {
          setLoading(false);
          console.error("Error loading another page:", error);
        }
      );
    }
  };

  const condition =
    category !== "gaminghub" &&
    category !== "hot-games" &&
    filterCategory !== "Gaming Hub" && category !== "Sports";

  const {
    data: games,
    refetch,
    fetchNextPage: anotherPage,
    hasNextPage: hasAnotherPage,
    isLoading: loadingAnotherGames,
  } = useFetchGamesPerCategory({
    selectedProvider,
    category,
    subcategory,
    mobile: isMobile,
    searchGame
  });

  const filteredGames = useMemo(() => {
    if (!games?.pages) {
      return;
    }
    if (category !== "Sports") {
      return selectedProvider
      ? (
        console.log("Selected provider is satisfied"),
        games?.pages
          ?.flatMap((page) => page.games)
          .filter((game) => selectedProvider.includes(game.provider))
      )
      : (
        console.log("Selected provider is not satisfied"),
        games?.pages?.flatMap((page) => page.games).map((game) => game)
      );
    }
  }, [games?.pages, selectedProvider]);

  useEffect(() => {
    if (isMobile) {
      setMobile(true);
    }

    const fetchProvidersPerCategory = async () => {
      try {
        const data = await fetchProviders({ category, subcategory });
        setProviders(data.providers);
      } catch (error) {
        console.error(error.message);
      }
    };

    if (fetchTrigger) {
      setSelectedProvider(selectedProvider);
      setFetchTrigger(false);
    } else {
      if (condition) {
        fetchSubcategories();
        fetchProvidersPerCategory();
      }
    }
  }, [fetchTrigger, selectedProvider, category, subcategory, isMobile]);

  const handleButtonClick = (subcategory) => {
    let main;
    if (category === "Crash & Fast") {
      main = "crash-fast";
    } else if (category.trim().split(/\s+/).length === 2) {
      main = category.toLowerCase().replace(/\s+/g, "-");
    } else {
      main = category;
    }
    const path = `/${main.toLowerCase()}/${subcategory}`;
    setactive(subcategory);
    setSelectedProvider("");
    setActiveProviders([]);
    setActiveProvidersFilter([]);
    setFetchTrigger(true);
    navigate(path);
  };
  const handleProviderClick = (e) => {
    const selectedProviderValue = e.target.value;
    setSelectedProvider(selectedProviderValue);
    setActiveProviders([]);
    setFetchTrigger(true);
  };

  const [showProviders, setShowProviders] = useState(false);
  const [activeProviders, setActiveProviders] = useState([]);

  useEffect(() => {
    if (category) {
      setActiveProviders([]);
      setSelectedProvider("");
    }
  }, [category]);

  const handleProviderChoose = (providerName) => {
    setActiveProviders((prevActiveProviders) => {
      const index = prevActiveProviders.indexOf(providerName);
      if (index === -1) {
        const prevActiveUpdateProviders =
          prevActiveProviders.concat(providerName);
        return prevActiveUpdateProviders;
      } else {
        const updatedProviders = prevActiveProviders.filter(
          (provider) => provider !== providerName
        );
        return updatedProviders;
      }
    });
  };

  // const handleApplyClick = () => {
  //   if (activeProviders.length > 0) {
  //     setSelectedProvider(activeProviders);
  //   } else {
  //     setActiveProviders([]);
  //     setSelectedProvider("");
  //   }
  //   setFetchTrigger(true);
  //   setShowProviders(false);
  // };

  const handleApplyClick = () => {
    // setLoading(true);
    if (activeProviders.length > 0) {
      const activeProvidersFilter = Providers.filter(provider => activeProviders.includes(provider.name));
      const systems = activeProvidersFilter.map(provider => provider.system);
      setSelectedProvider(systems);

      if (condition) {
        refetch();
      } else {
        refetchHotgames();
      }
    }
    else {
      setActiveProviders([]);
      setActiveProvidersFilter([]);
      setSelectedProvider("");
      setFetchTrigger(true);
    }
    setShowProviders(false);
  };

  const handleSearchGame = (e) => {
    setSearchGame(e.target.value);
    if (condition) {
      refetch();
    } else {
      refetchHotgames();
    }
  }
  const handlePasteFetch = (e) => {
    setSearchGame(e.target.value);
    if (condition) {
      refetch();
    } else {
      refetchHotgames();
    }
  };

  const [playGameError, setPlayGameError] = useState(null);
  const [openGame, setOpenGame] = useState(false);
  const [gameDetails, setGameDetails] = useState(null);

  const {
    data: gameDetail,
    isLoading: isGameLoading,
    isError: isGameError,
  } = useGameDetailsQuery("65c7b05d47dcbcfcdaf4ba16");

  const {
    mutate: playGame,
    data: gameData,
    isLoading: loadingGame,
  } = usePlayGameMutation("sports");

  useEffect(() => {
    if (gameDetail) {
      setGameDetails(gameDetail);
      if (category === "Sports") {
        setOpenGame(true);
        playGame(gameDetail.game_id, {
          onSuccess: () => {
            setPlayGameError(null);
          },
          onError: (error) => {
            console.error("Error playing game:", error.message);
            setPlayGameError(error.message || "An unexpected error occurred");
          },
        });
      }
    }
  }, [gameDetail]);

  const scriptContainerRef = useRef(null);
  
  useEffect(() => {
    if (gameData && gameData.data && gameData.data.embed_code) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(gameData.data.embed_code, 'text/html');
        const scripts = Array.from(doc.querySelectorAll('script'));

        scripts.forEach((script, index) => {
            const newScript = document.createElement('script');
            if (script.src) {
                newScript.src = script.src;
                newScript.async = true;
            } else {
                newScript.textContent = script.textContent;
            }

            if (index === 2 && scriptContainerRef.current) {
              scriptContainerRef.current.appendChild(newScript)
            }
        });

        return () => {
            scripts.forEach((script) => {
                const existingScript = document.querySelector(`[src="${script.src}"]`);
                if (existingScript) {
                  existingScript.src = script.src;
                }
            });
        };
    }
  }, [gameData, category]);

  return (
    <>
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-start z-50 bg-black bg-opacity-50">
          <div className="text-center mt-24">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-green-900 mx-auto"></div>
            <p className="text-white text-lg mt-3">
              Loading Games...
            </p>
          </div>
        </div>
      )}
      <div className="flex w-full flex-wrap font-arabicmed">
        {/* providers */}
        {condition ? (
          <>
            <div className="flex justify-between  items-start w-full my-5 lg:flex-col">
              {/* search */}
              <div className="flex flex-row sm:flex-col sm:w-full justify-between items-center gap-5 sm:py-2">
                {/* <div className="flex sm:flex-col sm:w-full justify-between items-center">
                  <h4 className="text-white text-lg sm:text-sm mr-2 sm:m-4">
                    Select Provider
                  </h4>
                  <div className="sm:px-2 sm:py-2 sm:w-full px-3 py-3 rounded-lg bg-[#1a1a1b] text-gray-200 outline-none">
                    <select
                      className="rounded-lg bg-[#1a1a1b] text-gray-200 outline-none sm:w-full"
                      onChange={handleProviderClick}
                      value={selectedProvider}
                    >
                      <option className="bg-[#1a1a1b]" value="">
                        All
                      </option>
                      {Providers?.map((provider) => (
                        <option
                          key={provider._id}
                          className="bg-[#1a1a1b] hover:text-gray-400"
                          value={provider.name}
                        >
                          {provider.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}

                {/* Button that will trigger the option to select multiple provider */}
                <button
                  className={`flex justify-between items-center py-2 px-4 rounded-lg sm:w-full border-2 border-white ${showProviders
                    ? "bg-gradient-to-br from-[#9BE402] to-[#1FA83C] text-[#FFF]"
                    : "bg-white text-[#262626]"
                    }`}
                  onClick={() => setShowProviders(!showProviders)}
                >
                  <p className="mr-16">Providers</p>
                  {showProviders ? (
                    <img src={collapse} />
                  ) : (
                    <img src={expand} />
                  )}
                </button>
                {selectedProvider !== "" && (
                  <button
                    className="text-[#6FCF17] text-[14px] hover:text-[#FFF]"
                    onClick={() => {
                      if (activeProviders.length > 0) {
                        setActiveProviders([]);
                        setActiveProvidersFilter([]);
                        setSelectedProvider("");
                      }
                      setFetchTrigger(true);
                      setShowProviders(false);
                    }}
                  >
                    Clear Selection
                  </button>
                )}
                <div className="search-box-container w-[201px] h-11 px-4 bg-[#2E2E2E] border-[1px] border-[#3C403F] rounded-lg  flex items-center gap-1 sm:hidden">
                  <img src={search} alt="" />
                  <input
                    type="text"
                    value={searchGame}
                    placeholder="Search game"
                    className="w-full bg-transparent border-0 outline-none text-[14px] text-[#AEB2B1]"
                    onChange={handleSearchGame}
                    onKeyDown={handleSearchGame}
                    onPaste={handlePasteFetch}
                  />
                </div>
              </div>

              {(category == "Live Casino" ||
                category == "Casino" ||
                category == "Crash & Fast") && (
                  <>
                    <div className="sm:w-full overflow-x-auto py-4">
                      <div className="text-gray-500 flex items-center justify-between gap-5 sm:px-4 whitespace-nowrap">
                        <button
                          className={`flex items-center gap-2 ${active === "All" ? "text-[#6FCF17]" : "text-gray-500"
                            }`}
                          onClick={() => {
                            let main;
                            if (category === "Crash & Fast") {
                              main = "crash-fast";
                            } else if (
                              category.trim().split(/\s+/).length === 2
                            ) {
                              main = category.toLowerCase().replace(/\s+/g, "-");
                            } else {
                              main = category;
                            }
                            const path = `/${main.toLowerCase()}`;
                            setactive("All");
                            setSelectedProvider("");
                            setFetchTrigger(true);
                            navigate(path);
                          }}
                        >
                          {" "}
                          All{" "}
                        </button>
                        {subCategories.map((subcategory) => (
                          <button
                            key={subcategory._id}
                            className={`flex items-center gap-2 ${active === subcategory.name
                              ? "text-[#6FCF17]"
                              : "text-gray-500"
                              }`}
                            onClick={() => handleButtonClick(subcategory.name)}
                          >
                            {" "}
                            {subcategory.name}{" "}
                          </button>
                        ))}
                      </div>
                    </div>
                  </>
                )}
            </div>

            {/* Display selected provider logos */}
            <div className="flex flex-wrap mb-4 w-full">
              {activeProviders.length > 0 && (
                <p className="text-white italic mr-4 my-auto">Selected Providers: </p>
              )}
              {activeProviders.map((providerName, index) => (
                <div className="bg-[#535353] p-3 mr-4 mb-2 rounded-lg border border-white">
                  <img
                    key={index}
                    src={Providers.find(provider => provider.name === providerName)?.image_colored}
                    alt={providerName}
                    className="w-auto h-5 sm:h-3"
                  />
                </div>
              ))}
            </div>

            {/* Multiple Providers Selection */}
            {showProviders && (
              <div className="flex flex-wrap w-full py-5 sm:p-0">
                <div className="flex flex-wrap justify-content bg-[#3f3f3f] border border-[#3C403F] rounded-t-lg w-full max-h-[30vh] sm:max-h-[20vh] overflow-y-auto relative p-4 mr-1">
                  <div className="w-full grid sm:grid-cols-2 grid-cols-4 gap-4">
                    {Providers?.map((provider, index) => (
                      <div
                        className="items-start m-2 w-50"
                        key={index}
                      >
                        <button
                          className="flex items-center focus:outline-none "
                          onClick={() => handleProviderChoose(provider.name)}
                        >
                          <img
                            src={
                              activeProviders.includes(provider.name)
                                ? provider.image_small_color
                                : provider.image_small_gray
                            }
                            className="w-6 h-6 "
                          />
                          <h1
                            className={`text-[13px] pl-2 text-start uppercase font-semibold ${activeProviders.includes(provider.name)
                              ? "text-[#FFF]"
                              : "text-[#808080]"
                              }`}
                          >
                            {provider.name}
                          </h1>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <button
                  className="w-full h-auto p-3 bg-gradient-to-br from-[#9BE402] to-[#1FA83C] text-white rounded-b-lg "
                  onClick={handleApplyClick}
                >
                  Apply
                </button>
              </div>
            )}
          </>
        ) : (
          <>
            {category !== "Sports" && (
              <div className="flex justify-between  items-start w-full my-5 lg:flex-col">
                <div className="flex flex-row sm:flex-col sm:w-full justify-between items-center gap-5 sm:py-2">
                  <div className="search-box-container w-[201px] h-11 px-4 bg-[#2E2E2E] border-[1px] border-[#3C403F] rounded-lg  flex items-center gap-1 sm:hidden">
                    <img src={search} alt="" />
                    <input
                      type="text"
                      value={searchGame}
                      placeholder="Search game"
                      className="w-full bg-transparent border-0 outline-none text-[14px] text-[#AEB2B1]"
                      onChange={handleSearchGame}
                      onKeyDown={handleSearchGame}
                      onPaste={handlePasteFetch}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {/* providers end */}

        {/* Games Grid */}
        {category === "hot-games" && (
          <>
            <div className="flex w-full mb-2 mt-5 overflow-hidden">
              <h3 className="text-[24px] text-white font-medium">
                Gamebox Originals
              </h3>
            </div>
            <GameCategory />

            <div className="flex w-full mb-2 mt-5 ">
              <h3 className="text-[24px] text-white font-medium">
                {title || ""}
              </h3>
            </div>
            {/* <div className="flex gap-3 overflow-x-auto no-scrollbar"> */}

            {/* ==== GAMEHUB GAMES ==== */}
            <div className="flex justify-left flex-wrap mt-10 mb-5 -mx-1.5 w-full">

              {filteredHotGames?.map((game) => (
                <div
                  key={game._id}
                  className="rounded-xl w-1/6 md:w-1/2 overflow-visible cursor-pointer px-1 mb-3"
                >
                  <div className="h-full rounded-xl overflow-hidden border border-[#3C403F] cursor-pointer hover:shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out flex flex-col">
                    <div className="w-full flex-grow flex items-center">
                      <img
                        src={game.image_square}
                        alt=""
                        className="w-full object-cover "
                        onClick={() => {
                          window.scrollTo(0, 0); // Reset scroll to top of the page
                          // navigate(`/game/${game?._id}`);
                          navigate(`/game-info/${game?._id}`);
                        }}
                      />
                    </div>
                    <div className="w-full flex justify-between items-center p-3">
                      <h5 className="text-sm text-center text-white truncate overflow-ellipsis">
                        {game.name}
                      </h5>
                      <img
                        src={info}
                        alt=""
                        className="w-6 object-cover ml-2"
                        onClick={() => navigate(`/game-info/${game._id}`)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* ==== END GAMEHUB GAMES ==== */}
          </>
        )}

        {condition && (
          <>
            {/* ==== ALL GAMES ==== */}
            {/* <div className="text-white w-full flex justify-end py-5">
              <button
                className="px-1 opacity-40 hover:opacity-100 transition-opacity"
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <img src={leftArrow} alt="LeftArrow" />
              </button>
              <button
                className="px-1 opacity-40 hover:opacity-100 transition-opacity"
                onClick={() => swiperRef.current?.slideNext()}
              >
                <img src={rightArrow} alt="Right" />
              </button>
            </div> */}

            {/* <Swiper
              // autoHeight={true}
              // navigation={true}
              slidesPerView={6}
              slidesPerGroup={6}
              // slidesPerColumn={3}
              grid={{
                rows: 3,
                fill: "row",
              }}
              spaceBetween={100}
              pagination={{
                type: "fraction",
              }}
              modules={[Grid, Pagination, Navigation]}
              className="mySwiper"
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 2,
                  spaceBetween: 2,
                  slidesPerGroup: 2,
                  grid: {
                    rows: 4, // Change rows to 4 for 480 breakpoint
                    fill: "row",
                  },
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 2,
                  spaceBetween: 2,
                  slidesPerGroup: 2,
                  grid: {
                    rows: 4, // Change rows to 4 for 480 breakpoint
                    fill: "row",
                  },
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 3,
                  spaceBetween: 2,
                  slidesPerGroup: 3,
                  grid: {
                    rows: 4, // Change rows to 4 for 480 breakpoint
                    fill: "row",
                  },
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 3,
                  spaceBetween: 4,
                  slidesPerGroup: 3,
                },
                // when window width is >= 1024px
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 6,
                  slidesPerGroup: 4,
                },
                // when window width is >= 1200px
                1200: {
                  slidesPerView: 5,
                  spaceBetween: 6,
                  slidesPerGroup: 5,
                },
                1440: {
                  slidesPerView: 6,
                  spaceBetween: 6,
                  slidesPerGroup: 6,
                },
              }}
            > */}
            {filteredGames?.map((game) => (
              // <SwiperSlide key={game?._id}>
              <div
                key={game._id}
                className="rounded-xl w-1/6 md:w-1/2 overflow-visible cursor-pointer px-1 mb-3"
              >
                <div className="h-full rounded-xl overflow-hidden border border-[#3C403F] cursor-pointer hover:shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out flex flex-col">
                  <div className="w-full flex-grow flex items-center">
                    <img
                      src={game.image_square}
                      alt=""
                      className="w-full object-cover "
                      onClick={() => {
                        window.scrollTo(0, 0); // Reset scroll to top of the page
                        // navigate(`/game/${game?._id}`);
                        navigate(`/game-info/${game?._id}`);
                      }}
                    />
                  </div>
                  <div className="w-full flex justify-between items-center p-3">
                    <h5 className="text-sm text-center text-white truncate overflow-ellipsis">
                      {game.name}
                    </h5>
                    <img
                      src={info}
                      alt=""
                      className="w-6 object-cover ml-2"
                      onClick={() => navigate(`/game-info/${game._id}`)}
                    />
                  </div>
                </div>
              </div>
              // </SwiperSlide>
            ))}
            {/* </Swiper> */}

            {/* <div className="flex justify-left flex-wrap mt-10 mb-5 -mx-1.5 w-full">
              {games?.pages
                .flatMap((page) => page.games)
                .map((game) => (
                  <div
                    key={game?.id}
                    className="rounded-xl w-1/6 md:w-1/2 overflow-visible cursor-pointer px-0.5 mb-3"
                  >
                    <div className="h-full rounded-xl overflow-hidden border border-[#3C403F] cursor-pointer hover:shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out flex flex-col">
                      <div className="w-full flex-grow flex items-center">
                        <img
                          src={game?.image_square}
                          alt=""
                          className="w-full object-cover "
                          onClick={() => {
                            window.scrollTo(0, 0); // Reset scroll to top of the page
                            navigate(`/game/${game?._id}`);
                          }}
                        />
                      </div>
                      <div className="w-full flex justify-between items-center p-3">
                        <h5 className="text-sm text-center text-white truncate overflow-ellipsis">
                          {game?.name}
                        </h5>
                        <img
                          src={info}
                          alt=""
                          className="w-6 object-cover ml-2"
                          onClick={() => navigate(`/game-info/${game._id}`)}
                        />
                      </div>
                    </div>
                  </div>
                ))} */}

            {/* {game?.map((game) => (
                <div
                  key={game.id}
                  className="rounded-xl w-1/6 md:w-1/2 overflow-visible cursor-pointer px-0.5 mb-3"
                >
                  <div className="h-full rounded-xl overflow-hidden border border-[#3C403F] cursor-pointer hover:shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out flex flex-col">
                    <div className="w-full flex-grow flex items-center">
                      <img
                        src={game.image_square}
                        alt=""
                        className="w-full  object-cover "
                        onClick={() => navigate(`/game/${game._id}`)}
                      />
                    </div>
                    <div className="w-full flex justify-between items-center p-3">
                      <h5 className="text-sm text-center text-white truncate overflow-ellipsis">
                        {game.name}
                        {game.game_id}
                      </h5>
                      <img
                        src={info}
                        alt=""
                        className="w-6 object-cover ml-2"
                        // onClick={() => navigate(`/game-details/${game._id}`)}
                      />
                    </div>
                  </div>
                </div>
              ))} */}
            {/* </div> */}
            {/* ==== END ALL GAMES ==== */}
          </>
        )}

        {category === "Sports" && (
          <>
            {openGame && (
              <div
                className="overflow-auto w-full mt-5"
              >
                {loadingGame ? (
                  <div className="flex-grow flex justify-center items-center">
                    <ButtonLoader />
                  </div>
                ) : playGameError ? (
                  <div className="flex-grow bg-white flex justify-center items-center">
                    <p>Error playing the game: </p>
                    <p> {playGameError}</p>
                  </div>
                ) : (
                  <div className="flex-grow mt-5">
                    {/* <iframe
                        frameborder="0"
                        allowfullscreen=""
                        allow="autoplay; clipboard-write"
                        scrolling="no"
                        src="https://sport.betcobogem.com/SportsBook/Home?token=10861631-15579-aaf74885f225fce26a37a4c66cad1430&amp;d=d&amp;l=en&amp;tz=&amp;of=0&amp;ofl=&amp;parent=betcobogem.com&amp;parent=user.gamebox888.net&amp;customCssUrl=&amp;sportsBookView=europeanView&amp;clearSiteStyles=false&amp;resetAllStyles=false&amp;theme=&amp;projectTheme=&amp;pushStateDisabled=false"
                        style={{
                          width: '100%',
                          height: '100vh',
                          display: 'block'
                        }}
                    ></iframe> */}
                    <div id="sport_div_iframe"></div>
                    <div ref={scriptContainerRef}>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {/* Load More Button */}
        {category === "hot-games" ? (
          <>
            {hasPage && (
              <div className="flex justify-center mt-4 pb-4 w-full buttons-wrapper !border-none">
                <button
                  onClick={loadMoreGames}
                  className="active py-3 rounded-xl my-2 bg-[#9ce402b9] text-white px-4 !text-lg hover:bg-gradient-to-br from-[#9BE402] to-[#1FA83C] hover:text-white hover:scale-105 transition duration-200 ease-in-out"
                >
                  {loading ? <ButtonLoader /> : "Load More Games"}
                </button>
              </div>
            )}
          </>
        ) : (
          <>
            {hasAnotherPage && (
              <div className="flex justify-center mt-4 pb-4 w-full buttons-wrapper !border-none">
                <button
                  onClick={loadMoreGames}
                  className="active py-3 rounded-xl my-2 bg-[#9ce402b9] text-white px-4 !text-lg hover:bg-gradient-to-br from-[#9BE402] to-[#1FA83C] hover:text-white hover:scale-105 transition duration-200 ease-in-out"
                >
                  {loading ? <ButtonLoader /> : 'Load More Games'}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
