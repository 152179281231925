import React from "react";
import close from "../assets/img/stake/close-details.svg";
import risk from "../assets/img/stake/risk.svg";
import { Interweave } from "interweave";
import { Terms } from "../utils/staking/Terms";
import { HeaderStaker } from "./HeaderStaker";
import { useNavigate } from "react-router-dom";

export const RightAreaStakeTerms = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This will navigate to the previous page
  };

  return (
    <div className="flex-1 RightAreaStakeTerms">
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex-1 px-6 pt-6">
        <div className="bg-[#1A1A1B] border-[1px] rounded-xl border-[#3C403F] ">
          <div className="flex px-10 h-[64px] border-b-[1px] border-b-[#3C403F] items-center justify-between mb-8 sm:px-5">
            <h1 className="text-white text-[23px] sm:text-[18px] font-arabicmed">
              Terms and Conditions
            </h1>
            <img src={close} alt="" className="cursor-pointer" onClick={handleGoBack} />
          </div>

          <div className="px-10  py-6  sm:px-5 sm:py-0">
            <h1 className="text-[45px] font-arabicbold text-[#fff] sm:text-[22px]">
              Staking Terms and Conditions
            </h1>
            <p className="mt-6 text-[22px] font-arabicreg text-[#C8CBCA] mb-[70px] sm:text-[14px]">
              Please read these Terms and Conditions ("Terms") carefully before
              using GameBox services. By accessing or using our services, you
              agree to comply with and be bound by these Terms. If you do not
              agree to these Terms, please do not use our services
            </p>

            <ol className="flex flex-col gap-[60px] mt-[100px] sm:mt-[30px]">
              {Terms.map((eachterm, outerKey) => (
                <li key={outerKey}>
                  <h1 className="heading-li mb-8 text-[29px] sm:text-[20px] font-arabicbold text-white">
                    {eachterm.heading}
                  </h1>
                  <ol className="flex flex-col gap-6 ml-3">
                    {eachterm.list.map((EachList, innerKey) => (
                      <li
                        key={innerKey}
                        className="text-[20px] sm:text-[14px] font-arabicreg text-[#C8CBCA]"
                      >
                        <Interweave content={EachList} />
                      </li>
                    ))}
                  </ol>
                </li>
              ))}
              <li className="outerlist text-[22px] sm:text-[16px] text-[#C8CBCA] font-arabicreg">
                By using{" "}
                <span className="font-arabicbold">GameBox services</span>, you
                acknowledge that you have read,{" "}
                <span className="font-arabicbold">understood</span>, and agreed
                to these
                <span className="font-arabicbold">Terms and Conditions</span>.
              </li>

              <li className="outerlist">
                <h1 className="flex items-center gap-3 mb-8 text-[29px] sm:text-[20px] text-[#fff] font-arabicbold">
                  <img src={risk} alt="" />
                  Risk Warning
                </h1>
                <p className="text-[22px] text-[#C8CBCA] sm:text-[16px] sm:mb-10 font-arabicreg">
                  GameBox service platform involves
                  <span className="font-arabicbold">risks associated</span> with
                  gaming, cryptocurrencies, and{" "}
                  <span className="font-arabicbold">
                    financial transactions
                  </span>
                  . Users should exercise caution and conduct their own research
                  before participating.
                  <span className="font-arabicbold">GameBox</span> does not
                  provide financial or investment advice. Users are{" "}
                  <span className="font-arabicbold">responsible</span> for their
                  own decisions and actions on the platform.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};
