import copy from "copy-to-clipboard";
import React, { useEffect, useRef, useState } from "react";
import "../assets/css/settings/index.css";
import ButtonLoader from "../components/ButtonLoader";

// images
import close from "../assets/img/settings/svg/close.svg";
import copyIcon from "../assets/img/settings/svg/copy.svg";
import eye from "../assets/img/settings/svg/eye.svg";

// components
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { SettingsOptions } from "../components/common/SettingsOptions";
import { privateRequest } from "../config/axios.config";
import { useAuth } from "../contexts/AuthContext";

export const GeneralSecurityLeft = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const queryClient = useQueryClient();

  const ResizeWork = (e) => {
    let Element = window.innerWidth;
    if(ref?.current){
      ref.current.style.width = `${Element - 600}px`;
    }
  };

  const [qrCode, setQaCode] = useState("");
  const [secret, setSecret] = useState("");

  const [otps, setOtps] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [changePasswordLoading, setChangePasswordLoading] = useState(false);

  const oldPasswordHandler = (e) => {
    setOldPassword(e.target.value);
  }

  const newPasswordHandler = (e) => {
    setNewPassword(e.target.value);
  }

  const confirmNewPasswordHandler = (e) => {
    setConfirmNewPassword(e.target.value);
  }

  const changePasswordSubmitHandler = (e) => {
    e.preventDefault();

    if (oldPassword === "") {
      toast.error("Please enter your old password");
      return;
    }

    if (newPassword === "") {
      toast.error("Please enter your new password");
      return;
    }

    if (confirmNewPassword === "") {
      toast.error("Please enter your confirm new password");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      toast.error("New password and confirm new password does not match");
      return;
    }

    setChangePasswordLoading(true);

    privateRequest.post("user/profile/updatePassword", {
      oldPassword,
      newPassword,
      confirmPassword: confirmNewPassword,
    })
      .then((res) => {
        toast.success(res.data.message);
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setChangePasswordLoading(false);
      });
  }

  const focusOnNextInput = (index) => {
    const nextInput = document.getElementById(`text-${index + 1}`);
    if (nextInput) {
      nextInput.focus();
    }
  };

  const otpHandler = (e, index) => {
    let value = e.target.value;
    let newOtps = [...otps];
    if (index < otps.length - 1 && e.target.value !== "") {
      focusOnNextInput(index);
    }
    newOtps[index] = value;
    setOtps(newOtps);
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").trim();
    const newOtps = [...otps];

    for (let i = 0; i < pastedData.length && index + i < newOtps.length; i++) {
      newOtps[index + i] = pastedData[i];
    }

    setOtps(newOtps);
    // If there are more characters in the pasted data, focus on the next input
    if (index + pastedData.length < otps.length) {
      focusOnNextInput(index + pastedData.length - 1);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && e.target.value === "") {
      const previousInput = document.getElementById(`text-${index - 1}`);
      if (previousInput) {
        e.preventDefault(); // Prevent the browser from navigating back
        previousInput.focus();
      }
    }
  };

  useEffect(() => {
    if (!qrCode && userInfo && !userInfo?.is2FAEnabled) {
      privateRequest
        .post("user/profile/setup-2fa")
        .then((res) => {
          setQaCode(res.data.qrCode);
          setSecret(res.data.secret);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const firstInput = document.getElementById(`text-0`);
    if (firstInput) {
      firstInput.focus();
    }
  }, [qrCode, userInfo]);

  useEffect(() => {
    ResizeWork();
    window.addEventListener("resize", ResizeWork);

    return () => {
      window.removeEventListener("resize", ResizeWork);
    };
  }, []);

  const enable2fa = () => {
    if (otps.includes("")) {
      toast.error("Please enter the otp");
      return;
    }

    setLoading(true);

    const otp = otps.join("");
    privateRequest
      .post("user/profile/enable-2fa", {
        otp,
      })
      .then((res) => {
        toast.success(res.data.message);
        setOtps(["", "", "", "", "", ""]);
        queryClient.invalidateQueries('userInfos');
      })
      .catch((err) => {
        if (typeof err?.response?.data === "string") {
          toast.error(err.response.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const disable2fa = () => {
    if (otps.includes("")) {
      toast.error("Please enter the otp");
      return;
    }

    setLoading(true);

    const otp = otps.join("");
    privateRequest
      .post("user/profile/disable-2fa", {
        otp,
      })
      .then((res) => {
        toast.success(res.data.message);
        queryClient.invalidateQueries('userInfos');
      })
      .catch((err) => {
        if (typeof err?.response?.data === "string") {
          toast.error(err.response.data);
        } else {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    // <div className="body-left-area mr-6" ref={ref}>
    <div className="body-left-area w-full">
      <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />
      <div className="settings-area  rounded-lg">
        <div className="settings-head flex p-6 rounded-tl-lg rounded-tr-lg items-center justify-between">
          <h1>Global Settings</h1>
          <img
            src={close}
            className="cursor-pointer"
            alt=""
            onClick={(e) => {
              navigate("/");
            }}
          />
        </div>

        <div className="settings-content-area flex items-start relative">
          <div className="settings-options-area flex flex-col p-3">
            <SettingsOptions active={4} />
          </div>

          <div className="settings-content-body-area settings-content-body-area-grid-settings gap-6 py-6 px-6 flex-1 grid grid-cols-2">
            <div className="form-card-mail relative">
              <h1>Change Password</h1>

              <div className="input-wrapper mt-6">
                <label htmlFor="op">Old password</label>
                <div className="input-container flex items-center pr-4 rounded-lg  mt-2">
                  <input
                    id="op"
                    type={showOldPassword ? "text" : "password"}
                    value={oldPassword}
                    onChange={oldPasswordHandler}
                    className="w-full px-6 bg-transparent h-full "
                    placeholder="Enter your password"
                  />
                  <img src={eye} style={{
                    cursor: 'pointer',
                    opacity: showOldPassword ? 1 : 0.5
                  }} onClick={() => setShowOldPassword(!showOldPassword)} alt="" />
                </div>
              </div>

              <div className="input-wrapper mt-4">
                <label htmlFor="np">New password</label>
                <div className="input-container pr-4 flex items-center rounded-lg  mt-2">
                  <input
                    id="np"
                    type={showNewPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={newPasswordHandler}
                    className="w-full px-6 bg-transparent h-full "
                    placeholder="Enter your password"
                  />
                  <img src={eye} style={{
                    cursor: 'pointer',
                    opacity: showNewPassword ? 1 : 0.5
                  }} onClick={() => setShowNewPassword(!showNewPassword)} alt="" />
                </div>
              </div>

              <div className="input-wrapper mt-4">
                <label htmlFor="cnp">Confirm new password</label>
                <div className="input-container flex items-center pr-4 rounded-lg  mt-2">
                  <input
                    id="cnp"
                    type={showConfirmNewPassword ? "text" : "password"}
                    value={confirmNewPassword}
                    onChange={confirmNewPasswordHandler}
                    className="w-full px-6 bg-transparent h-full "
                    placeholder="Enter your password"
                  />
                  <img src={eye} style={{
                    cursor: 'pointer',
                    opacity: showConfirmNewPassword ? 1 : 0.5
                  }} onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)} alt="" />
                </div>
              </div>
              <p className="mt-6">
                Re-login will be required after changing the password. Save
                Changes
              </p>
              <button onClick={changePasswordSubmitHandler} className="mt-6 w-full rounded-lg">
                {changePasswordLoading ? <ButtonLoader /> : "Save Change"}
              </button>
            </div>
            <div className="form-card-mail form-card-mail-security">
              <h1>Two-factor Authentication</h1>

              {!userInfo?.user?.is2FAEnabled && (
                <>
                  <p className="mt-6">
                    Download and install Google Authenticator. Enable Two-factor
                    Authentication to protect your account from unauthorized
                    access.
                  </p>
                  <p className="mt-4">
                    Scan the QR code with your Google Authenticator App or enter
                    the secret key manually.
                  </p>
                  {qrCode && (
                    <img src={qrCode} className="my-4 mx-auto" alt="" />
                  )}
                  <div className="input-wrapper mt-6">
                    <label htmlFor="mail">Your secret key</label>
                    <div className="input-container flex items-center rounded-lg  mt-2">
                      <input
                        type="text"
                        className="w-full px-6 bg-transparent h-full "
                        placeholder={secret}
                        value={secret}
                      />
                      <button
                        onClick={() => {
                          copy(secret);
                          toast.success("Copied to clipboard");
                        }}
                        className="flex items-center rounded-lg h-full justify-center"
                      >
                        <img
                          src={copyIcon}
                          alt=""
                          className="transofrm rotate-90"
                        />
                      </button>
                    </div>
                  </div>
                </>
              )}

              <p className="mt-4">
                Write down this code, never reveal it to others. You can use it
                to regain access to your account if there is no access to the
                authenticator.
              </p>

              <div className="input-wrapper mt-6">
                <label htmlFor="mail">Verification code</label>
                <div className="grid grid-cols-6 gap-1">
                  <div className="input-container  flex items-center  rounded-lg  mt-2">
                    <input
                      type="text"
                      className="w-full  bg-transparent h-full outline-none text-center"
                      maxLength={1}
                      value={otps[0]}
                      id={`text-0`}
                      onPaste={(e) => handlePaste(e, 0)}
                      onKeyDown={(e) => handleKeyDown(e, 0)}
                      onChange={(e) => otpHandler(e, 0)}
                    />
                  </div>
                  <div className="input-container  flex items-center   rounded-lg  mt-2">
                    <input
                      type="text"
                      className="w-full  bg-transparent h-full outline-none text-center"
                      maxLength={1}
                      value={otps[1]}
                      id={`text-1`}
                      onPaste={(e) => handlePaste(e, 1)}
                      onKeyDown={(e) => handleKeyDown(e, 1)}
                      onChange={(e) => otpHandler(e, 1)}
                    />
                  </div>
                  <div className="input-container  flex items-center   rounded-lg  mt-2">
                    <input
                      type="text"
                      className="w-full  bg-transparent h-full outline-none text-center"
                      maxLength={1}
                      value={otps[2]}
                      id={`text-2`}
                      onPaste={(e) => handlePaste(e, 2)}
                      onKeyDown={(e) => handleKeyDown(e, 2)}
                      onChange={(e) => otpHandler(e, 2)}
                    />
                  </div>
                  <div className="input-container  flex items-center   rounded-lg  mt-2">
                    <input
                      type="text"
                      className="w-full  bg-transparent h-full outline-none text-center"
                      maxLength={1}
                      value={otps[3]}
                      id={`text-3`}
                      onPaste={(e) => handlePaste(e, 3)}
                      onKeyDown={(e) => handleKeyDown(e, 3)}
                      onChange={(e) => otpHandler(e, 3)}
                    />
                  </div>
                  <div className="input-container  flex items-center   rounded-lg  mt-2">
                    <input
                      type="text"
                      className="w-full  bg-transparent h-full outline-none text-center"
                      maxLength={1}
                      value={otps[4]}
                      id={`text-4`}
                      onPaste={(e) => handlePaste(e, 4)}
                      onKeyDown={(e) => handleKeyDown(e, 4)}
                      onChange={(e) => otpHandler(e, 4)}
                    />
                  </div>
                  <div className="input-container  flex items-center   rounded-lg  mt-2">
                    <input
                      type="text"
                      className="w-full  bg-transparent h-full outline-none text-center"
                      maxLength={1}
                      value={otps[5]}
                      id={`text-5`}
                      onPaste={(e) => handlePaste(e, 5)}
                      onKeyDown={(e) => handleKeyDown(e, 5)}
                      onChange={(e) => otpHandler(e, 5)}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="input-wrapper mt-6">
                <label htmlFor="mail">Password</label>
                <div className="input-container flex items-center rounded-lg pr-4  mt-2">
                  <input
                    type="text"
                    className="w-full px-6 bg-transparent h-full outline-none "
                    placeholder="Enter your password"
                  />
                  <img src={eye} alt="" />
                </div>
              </div> */}

              {userInfo?.user?.is2FAEnabled ? (
                <button className="mt-6 w-full rounded-lg" onClick={disable2fa}>
                  {loading ? <ButtonLoader /> : "Disable 2FA"}
                </button>
              ) : (
                <button className="mt-6 w-full rounded-lg" onClick={enable2fa}>
                  {loading ? <ButtonLoader /> : "Enable 2FA"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
