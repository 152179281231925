import axios from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";
export const usePayEplayment = () => {
  return useMutation(
    async ({ amount, currency, eplaymentOption, provider }) => {
      const response = await privateRequest.post("/user/balance/deposit", {
        amount,
        currency,
        eplaymentOption,
        provider,
      });
      return response.data;
    }
  );
};

export const useWithDrawCryptoBalance = () => {
  return useMutation(
    async ({ payload }) => {
      const response = await privateRequest.post("/user/syntheticWallet/crypto-withdraw-balance", {
        payload
      });
      return response.data;
    }
  );
};

export const usePayWithExternalWallet = () => {
  return useMutation(
    async ({ amount, currency, identifier, name, description, packageId }) => {
      const response = await privateRequest.post(
        "/user/balance/create-payment",
        {
          amount,
          currency,
          identifier,
          name,
          description,
          packageId
        }
      );
      console.log(response.data)
      return response.data;
    }
  );
};

export const useGetAllBalance = () =>
  useQuery(
    "allBalance",
    async () => {
      try {
        const res = await privateRequest.get("user/balance/get-allbalance");
        console.log(res.data);
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(
          error?.response?.data?.message ?? "Error getting balance"
        );
      }
    },
    {
      // Disable refetching on window focus
      refetchOnWindowFocus: true,

      // Disable refetching when the network status changes
      refetchOnReconnect: false,

      // Adjust the stale time as needed, for example, 5 minutes
      staleTime: 5 * 60 * 1000,

      // Disable automatic refetching on mount
      refetchOnMount: true,
    }
  );
export const useGetBalance = () =>
  useQuery(
    "allBalance",
    async () => {
      try {
        const res = await privateRequest.get("user/balance/get-balance");
        console.log(res.data);
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(
          error?.response?.data?.message ?? "Error getting balance"
        );
      }
    },
    {
      // Disable refetching on window focus
      refetchOnWindowFocus: false,

      // Disable refetching when the network status changes
      refetchOnReconnect: false,

      // Adjust the stale time as needed, for example, 5 minutes
      staleTime: 5 * 60 * 1000,

      // Disable automatic refetching on mount
      refetchOnMount: false,
    }
  );

export const useGetStakingStatistics = () =>
  useQuery("stakingStatistics", async () => {
    try {
      const res = await privateRequest.get("user/stacking/getStatistics");
      return res.data;
    } catch (error) {
      console.log(error);
      throw new Error(
        error?.response?.data?.message ?? "Error getting staking statistics"
      );
    }
  });

export const useGetCoinMarketPrices = () =>
  useQuery(
    "coinMarketPrices",
    async () => {
      try {
        const res = await axios.get(
          "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum%2Ctether%2Ccoin%2Cripple%2Ccoin%2Cusd%2Csolana%2Ccardano%2Cdogecoin%2Ctron&order=market_cap_desc&per_page=100&page=1&sparkline=false&locale=en"
        );
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(
          error?.response?.data?.message ?? "Error getting coin market prices"
        );
      }
    },
    {
      // Disable refetching on window focus
      refetchOnWindowFocus: false,

      // Disable refetching when the network status changes
      refetchOnReconnect: false,

      // Adjust the stale time as needed, for example, 5 minutes
      staleTime: 5 * 60 * 1000,

      // Disable automatic refetching on mount
      refetchOnMount: false,
    }
  );

export const useGetGBCBalance = () =>
  useQuery("getGBCBalance", async () => {
    try {
      const res = await privateRequest.get("user/gbc-balance/get-balance");
      return res.data;
    } catch (error) {
      console.log(error);
      throw new Error(
        error?.response?.data?.message ?? "Error getting GBC Balance"
      );
    }
  });

export const usePaymentStatus = (chargeId) => {
  const [charges, setCharges] = useState(null);
  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const response = await privateRequest.post(
          "user/balance/check-payment",
          {
            chargeId: chargeId,
          }
        );
        setCharges(response.data);
      } catch (error) {
        console.log(error);
      }
    }, 6000);

    return () => clearInterval(intervalId);
  }, [chargeId]);

  return charges;
};
