import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import tick from "../../assets/img/glass.png";
import StakePackageBorder from "../../assets/img/stake-package-border.png";
export const StakerPriceCard = ({ data }) => {
  const content  = useMemo(() => {
    let packageData;
    if(data?.name === "Premiere") {
      packageData = [
        "Daily Gaming Revenue Rewards (GRR)",
        "Extra Gaming Bonus (Casino & Sports)",
        "Up to 144% Annual Percentage Yield (APY)",
        "Asset Security Fund",
        "Locked-In 12 Months",
        "Crypto-Fiat-Casino Chips",
        "Free NFT Mystery Box"
      ]
    } else if (data?.name === "Advanced") {
      packageData = [
        "Daily Gaming Revenue Rewards (GRR)",
        "Extra Gaming Bonus (Casino & Sports)",
        "Up to 84% Annual Percentage Yield (APY)",
        "Asset Security Fund",
        "Locked-In 12 Months",
        "Crypto-Fiat-Casino Chips",
        "Free NFT Mystery Box"
      ]
    } else {
      packageData = [
        "Daily Gaming Revenue Rewards (GRR)",
        "Extra Gaming Bonus (Casino & Sports)",
        "60% Annual Percentage Yield (APY)",
        "Asset Security Fund",
        "Locked-In 9 Months",
        "Crypto-Fiat-Casino Chips"
      ]
    }
    return packageData;
  },[data?.name]);

  const headingClasses  = useMemo(() => {
    let classes;
    if(data?.name === "Premiere") {
      classes = "bg-gradient-to-b from-[#9435E2] to-[#3733B9]"
    } else if (data?.name === "Advanced") {
      classes = "bg-gradient-to-b from-[#F9B016] from-0% via-[#A97913] via-39% to-[#F4AC14] to-100%"
    } else {
      classes = "bg-gradient-to-br from-[#B1B0B2] from-29% via-[#919191] via-62% to-[#C7C7C7] to-100%";
    }
    return classes;
  },[data?.name]);



  // const stakteNow = () =>
  return (
    <div className="bg-[rgba(40,100,151,0.27)] relative z-20 backdrop-blur-[25px] pb-[110px] rounded-xl xs:p-4 overflow-hidden">
      <div className="absolute right-4 left-4 top-4 bottom-[110px] -z-10">
        <img src={StakePackageBorder} alt="Border" className="h-full" />
      </div>
      <div className={`flex flex-col py-6 px-4 items-center w-full ${headingClasses}`}>
        <h1 className="text-[35px] text-white sm:text-[25px] sm:mt-3">
          ${data.minAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{data.maxAmount ? ` - ${data.maxAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : ' - Up'}
        </h1>
        <h4 className="text-lg font-bold text-white">{data.name}</h4>
      </div>
      <ul className="my-10 flex flex-col gap-6 sm:mt-3 sm:gap-3 px-9">
          {
          content.map((item, index) => (
            <li
              key={index}
              className="relative flex items-center gap-3 pl-[58px] font-arabicmed text-[18px] text-white sm:text-[14px] "
            >
              <img src={tick} alt="" className="absolute left-0 w-[50px] h-[50px] -top-4" />
              {item}
            </li>
          ))}
      </ul>

      <Link
        to={`/stake/${data._id}`}
        state={{ data }}
        className="absolute right-8 left-8 bottom-8 bg-gradient-to-b from-[#9435E2] to-[#3733B9] text-white font-arabicbold
        text-[18px] h-[45px] rounded-lg flex items-center justify-center sm:text-[14px] hover:shadow-md hover:ring-2 hover:ring-[#FFF] hover:scale-105"
        > 
        Stake now
      </Link>
    </div>
  );
};
