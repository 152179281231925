import React, { useState } from "react";
import { HeaderStaker } from "./HeaderStaker";
import { SidebarArea } from "./SidebarArea";
import { BecomeStaker } from "./staker/BecomeStaker";
import { CalculateEarning } from "./staker/CalculateEarning";
export const RightAreaStake = ({ setsigninState, setSignUpState, login }) => {
  const [typeShowAside, settypeShowAside] = useState(-1);
  return (
    <div className="flex-1">
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex items-start px-6 pt-6">
        <div className="body-left-area w-full">
          <BecomeStaker />
          <CalculateEarning />
        </div>
        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};
