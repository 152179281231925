import { InfoIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import close from "../assets/img/settings/svg/close.svg";
import { GA } from "../components/common/GA";
import { privateRequest } from "../config/axios.config";
import { useAuth } from "../contexts/AuthContext";
import { useGetBalance, useGetGBCBalance } from "../queries/balance";
import { AffiliateHeader } from "./AffiliateHeader";
import ButtonLoader from "./ButtonLoader";
import { TransferDone } from "./common/TransferDone";

export const TransferGBCRight = ({ setsigninState, setSignUpState, login }) => {
  const [complete, setComplete] = useState(false);
  const [transferType, setTransferType] = useState("cashWallet");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const { userInfo, refetch } = useAuth();
  const [otps, setOtps] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [gasetter, setgasetter] = useState(false);
  const [onConfirm, setOnConfirm] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const { data } = useGetGBCBalance();
  const queryClient = useQueryClient();
  const { data: balance } = useGetBalance();
  const handleOtpsChange = (otps) => {
    setOtps(otps);
  };

  useEffect(() => {
    setIsDisabled(amount === "");
  }, [amount]);

  console.log("userInfo", userInfo);

  const transferAmount = () => {
    if (!userInfo?.user?.is2FAEnabled) {
      toast.error(
        "Please configure your Google Authentication to continue the transfer"
      );
    } else {
      showGA();
    }
  };

  const showGA = () => {
    if (data?.gbcBalance.toFixed(2)) {
      if (parseFloat(amount) > parseFloat(data?.gbcBalance.toFixed(2))) {
        toast.error("Insufficient balance");
        return;
      }
    }


    if (userInfo?.user?.is2FAEnabled) {
      setgasetter(true);
    }
  };

  const handleTransferWithGA = async () => {
    setIsDisabled(true);
    const otp = otps.join("");
    const payload = {
      email: email,
      amount: amount,
      otp: otp,
    };
    await transferAmountWithGA.mutate(payload);
  };

  const transferAmountWithGA = useMutation(
    (payload) => privateRequest.post("user/gbc-balance/transfer-gbc", payload),
    {
      onSuccess: () => {
        setComplete(true);
        setgasetter(false);
        queryClient.invalidateQueries({ queryKey: ["balance"] });
        setIsDisabled(false);
      },
      onError: (error) => {
        setIsDisabled(false);
        if (error.response.data?.message) {
          toast.error(error.response.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      },
    }
  );

  const onConfirmTransfer = () => {
    if (onConfirm) {
      handleTransferWithGA();
    }
  };

  // const convertGBCtoCash = useMutation(
  //   (payload) => privateRequest.post("user/gbc-balance/transfer-gbc-to-cashwallet", payload),
  //   {
  //     onSuccess: () => {
  //       setComplete(true);
  //       queryClient.invalidateQueries({ queryKey: ['balance'] });
  //       setIsDisabled(false);
  //     },
  //     onError: (error) => {
  //       setIsDisabled(false);
  //       if (error.response.data?.message) {
  //         console.log("error.response.data?.message", error.response.data?.message);
  //         toast.error(error.response.data?.message);
  //       } else {
  //         toast.error("Something went wrong");
  //       }
  //     },
  //   }
  // );

  const convertGBCtoCash = useMutation(async (payload) => {
    try {
      setLoading(true);
      const res = await privateRequest.post(
        "user/gbc-balance/transfer-gbc-to-cashwallet",
        payload
      );
      setLoading(false);
      setComplete(true);
      refetch();
      return res?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);

      setLoading(false); // Make sure to set loading to false in case of an error
    }
  });

  return (
    <div className="flex-1">
      {gasetter && (
        <GA
          isLoading={transferAmountWithGA.isLoading}
          onOtpsChange={handleOtpsChange}
          confirm={onConfirmTransfer}
          setgasetter={setgasetter}
        />
      )}

      <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />
      {complete && <TransferDone setRefAllow={setComplete} />}

      <AffiliateHeader
        login={login}
        headerType="Affiliate"
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />

      <div className="body-area flex-1  px-6 pt-6">
        <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
          <div className="flex items-center  px-6 h-[91px] border-[#3C403F] border-b-[1px]">
            <h1 className="text-white flex-1 text-[24px]  sm:text-[18px]">
              Transfer GBC
            </h1>
            <img
              src={close}
              className="cursor-pointer"
              alt=""
              onClick={(e) => {
                navigate("/bonus-dashboard");
              }}
            />
          </div>

          {/* <div className="px-6 py-8 min-h-[813px] ">
            <div className="mb-8">
              <label
                htmlFor=""
                className="text-[14px] font-arabicreg text-[#fff]"
              >
                Email
              </label>
              <div className="block h-[50px] border-[1px] border-[#3C403F] w-full px-4 bg-[#111010] rounded-lg mt-2">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="example@gmail.com"
                  className="h-full w-full border-none outline-none bg-[transparent] text-[14px] font-arabicreg  text-[#fff] placeholder:text-[#3C403F]"
                />
              </div>
            </div>

            <div className="mb-8 relative">
              <label
                htmlFor=""
                className="text-[14px] font-arabicreg text-[#fff]"
              >
                Amount
              </label>
              <span className="absolute right-0 top-0 text-md text-white">
                {data?.gbcBalance.toFixed(2)} GBC{" "}
                <span className="text-[#AEB2B1]">Available</span>
              </span>
              <div className="block relative h-[50px] border-[1px] border-[#3C403F] w-full pl-4 pr-[80px] bg-[#111010] rounded-lg mt-2">
                <span
                  className="absolute right-4 top-0 text-lg text-[#6FCF17] inline-flex leading-[48px] px-2 cursor-pointer"
                  onClick={() => setAmount(data?.gbcBalance.toFixed(2))}
                >
                  Max
                </span>
                <input
                  type="text"
                  value={amount}
                  onChange={(e) => {
                    if (e.target.value.match(/^\d*\.?\d{0,2}$/)) {
                      setAmount(e.target.value);
                      setIsDisabled(false);
                    }
                  }}
                  placeholder="0.00"
                  className="h-full w-full border-none outline-none bg-[transparent] text-[14px] font-arabicreg text-[#fff] placeholder:text-[#3C403F]"
                />
              </div>
            </div>
            <div className="mb-8 relative">
              <div className="block relative h-[50px] w-full pl-4 pr-[80px] bg-[#2E2E2E] rounded-lg mt-2">
                <span
                  className="text-lg text-[#FFF] inline-flex leading-[48px] px-2 cursor-pointer"
                  onClick={() => setAmount(data?.gbcBalance.toFixed(2))}
                >
                  Receive amount
                </span>
                <span className="absolute right-4 top-0 text-lg text-[#FFF] inline-flex leading-[48px] px-2 cursor-pointer">
                  {amount} GBC
                </span>
              </div>
            </div>
            <button
              className={`text-[18px] font-arabicbold text-[#FFF] bg-gradient-to-br from-[#9BE402] to-[#1FA83C]
                border-[#3C403F] w-full  h-[50px] rounded-lg cursor-pointer sm:!text-[14px]`}
              onClick={(e) => {
                transferAmount();
              }}
              disabled={isDisabled}
            >
              {transferAmountWithGA.isLoading ? (
                <ButtonLoader />
              ) : (
                "Confirm to transfer"
              )}
            </button>
          </div> */}

          <div className="px-6 py-8 min-h-[813px] ">
            <div className="flex items-center gap-4 mb-9">
              <button
                className={`py-3 px-5 border border-[#3C403F] text-white rounded-lg ${
                  transferType === "cashWallet" &&
                  "bg-[#252525] border-[#6FCF17]"
                }`}
                onClick={() => setTransferType("cashWallet")}
              >
                Convert to Cash Wallet
              </button>
              <button
                className={`py-3 px-5 border border-[#3C403F] text-white rounded-lg ${
                  transferType === "gbcTransfer" &&
                  "bg-[#252525] border-[#6FCF17]"
                }`}
                onClick={() => setTransferType("gbcTransfer")}
              >
                GBC Transfer
              </button>
            </div>

            {transferType === "cashWallet" ? (
              <>
                <span className="] py-2 text-white rounded-md mb-3 flex gap-3">
                  <InfoIcon />
                  When you transfer GBC to your Cash Wallet, the amount will be
                  deducted from your Available GBC Balance. This transaction
                  affects only your Available GBC Balance, enabling you to
                  convert your GBC into Cash wallet balance.
                </span>
              </>
            ) : (
              <>
                <span className="] py-2 text-white rounded-md mb-3 flex gap-3">
                  <InfoIcon />
                  Transfering GBC to another user credits the amount to the
                  recipient’s In-Play Balance, while deducting the same from
                  your In-Play Balance. This facilitates direct user-to-user
                  transactions, affecting exclusively the In-Play balances.
                </span>
              </>
            )}

            {transferType === "gbcTransfer" && (
              <div className="mb-8">
                <label
                  htmlFor=""
                  className="text-[14px] font-arabicreg text-[#fff]"
                >
                  Email
                </label>
                <div className="block h-[50px] border-[1px] border-[#3C403F] w-full px-4 bg-[#111010] rounded-lg mt-2">
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="example@gmail.com"
                    className="h-full w-full border-none outline-none bg-[transparent] text-[14px] font-arabicreg  text-[#fff] placeholder:text-[#3C403F]"
                  />
                </div>
              </div>
            )}

            <div className="mb-8 relative">
              <label
                htmlFor=""
                className="text-[14px] font-arabicreg text-[#fff]"
              >
                Amount
              </label>
              <span className="absolute right-0 top-0 text-md text-white">
                {transferType === "cashWallet" ? (
                  <>{balance?.balances?.winningBalance.toFixed(2)} GBC </>
                ) : (
                  <>{balance?.balances?.gbcBalance.toFixed(2)} GBC </>
                )}

                <span className="text-[#AEB2B1]">Available</span>
              </span>
              <div className="block relative h-[50px] border-[1px] border-[#3C403F] w-full pl-4 pr-[80px] bg-[#111010] rounded-lg mt-2">
                {transferType === "cashWallet" ? (
                  <>
                    <span
                      className="absolute right-4 top-0 text-lg text-[#6FCF17] inline-flex leading-[48px] px-2 cursor-pointer"
                      onClick={() =>
                        setAmount(balance?.balances?.winningBalance.toFixed(2))
                      }
                    >
                      Max
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="absolute right-4 top-0 text-lg text-[#6FCF17] inline-flex leading-[48px] px-2 cursor-pointer"
                      onClick={() =>
                        setAmount(balance?.balances?.gbcBalance.toFixed(2))
                      }
                    >
                      Max
                    </span>
                  </>
                )}

                <input
                  type="text"
                  value={amount}
                  onChange={(e) => {
                    if (e.target.value.match(/^\d*\.?\d{0,2}$/)) {
                      setAmount(e.target.value);
                      setIsDisabled(false);
                    }
                  }}
                  placeholder="0.00"
                  className="h-full w-full border-none outline-none bg-[transparent] text-[14px] font-arabicreg text-[#fff] placeholder:text-[#3C403F]"
                />
              </div>
            </div>

            {userInfo?.user?.is2FAEnabled && transferType === "gbcTransfer" ? (
              <>
                <button
                  className={`text-[18px] font-arabicmed text-[#FFF] bg-gradient-to-br from-[#9BE402] to-[#1FA83C] hover:from-[#82e402] hover:to-[#146626]
                border-[#3C403F] w-full  h-[50px] rounded-lg cursor-pointer sm:!text-[14px]`}
                  onClick={(e) => {
                    transferAmount();
                  }}
                  disabled={isDisabled}
                >
                  {transferAmountWithGA.isLoading ? (
                    <ButtonLoader />
                  ) : (
                    "Transfer to User"
                  )}
                </button>
              </>
            ) : (
              <>
                <button
                  className={`text-[18px] font-arabicmed text-[#FFF] bg-gradient-to-br from-[#9BE402] to-[#1FA83C] hover:from-[#82e402] hover:to-[#146626]
                border-[#3C403F] w-full  h-[50px] rounded-lg cursor-pointer sm:!text-[14px]`}
                  onClick={(e) => {
                    if (transferType === "gbcTransfer") {
                      setIsDisabled(true);
                      transferAmount();
                    } else {
                      setIsDisabled(true);
                      convertGBCtoCash.mutate({ amount: amount });
                    }
                  }}
                  disabled={isDisabled}
                >
                  {convertGBCtoCash.isLoading ? (
                    <ButtonLoader />
                  ) : (
                    <>
                      {transferType === "gbcTransfer"
                        ? "Transfer to User"
                        : "Transfer to Cash Wallet"}
                    </>
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
