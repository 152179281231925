//gaming.js
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

export const usePlayGameMutation = (gameType) => {
  return useMutation(async (gameId) => {
    try {
      let response;
      if (gameType === "original") {
        response = await privateRequest.post("imoon-game/play-imoon", { gameId });
      } else if (gameType === "sports") {
        response = await privateRequest.post(`bc-game/sports-game?gameId=${gameId}`);
      } else {
        response = await privateRequest.post("bc-game/play-game", { gameId });
      }
      //console.log("URL RESPONSE", response.data);
      return response.data ?? {};
    } catch (error) {
      console.error(error);
      throw new Error(error?.response?.data?.error ?? "Error playing game");
    }
  });
};

export const fetchSports = async () => {
  try {
    const res = await privateRequest.post("bc-game/sports-game");
    const embed_code = res.data.data.embed_code;
    return embed_code;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports games"
    );
  }
};

export const fetchSportsBetGroups = async () => {
  try {
    const response = await privateRequest.get("bc-game/sportsbet-groups");
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports bet groups"
    );
  }
};
export const fetchSportsBetCategory = async (sportName) => {
  try {
    const response = await privateRequest.post("bc-game/sportsbet-category", { sportName });
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports bet category"
    );
  }
};
export const fetchSportsEvents = async (keys) => {
  try {
    const response = await privateRequest.post("bc-game/sport-events", { keys });
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching sports events"
    );
  }
};


export const fetchProviders = async ({ category, subcategory }) => {
  try {

    const queryParams = [
      category ? `category=${encodeURIComponent(category)}` : "",
      subcategory ? `subcategory=${encodeURIComponent(subcategory)}` : "",
    ].filter(Boolean).join("&");
    const res = await privateRequest.get(
      `bc-game/get-providers?${queryParams}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching providers list"
    );
  }
};
export const fetchAllSubcategory = async ({ category }) => {
  try {
    const res = await privateRequest.get(
      `bc-game/get-subcategories?category=${encodeURIComponent(category)}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error(
      error?.response?.data?.error ?? "Error fetching subcategory details"
    );
  }
};
export const useFetchGamesPerCategory = ({
  selectedProvider,
  category,
  subcategory,
  mobile,
  searchGame
}) => {
  return useInfiniteQuery(
    ["games", selectedProvider, category, subcategory, mobile, searchGame],
    async ({ pageParam = 1 }) => {
      try {
        const condition = category !== "gaminghub" && category !== "hot-games" && category !== "Sports";
        if (condition) {
          const queryParams = [
            `page=${pageParam}`,
            mobile ? `mobile=${mobile}` : "",
            selectedProvider ? `provider=${selectedProvider}` : "",
            category ? `category=${encodeURIComponent(category)}` : "",
            subcategory ? `subcategory=${subcategory}` : "",
            searchGame ? `searchGame=${searchGame}` : "",
          ]
            .filter(Boolean)
            .join("&");

          const url = `bc-game/categories?${queryParams}`;
          console.log("url games = " + url);

          const res = await privateRequest.get(url);
          console.log(res.data)
          return res.data;
        } else {
          return {};
        }

      } catch (error) {
        console.error(error);
        throw new Error(error?.response?.data?.error ?? "Error fetching games");
      }
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length;
        const maxPage = Math.ceil(lastPage.total / 60);
        return currentPage < maxPage ? currentPage + 1 : undefined;
      },
    }
  );
};

export const useSubcategoryQuery = (game_subcategory) => {
  return useQuery(["subcategory", game_subcategory], async () => {
    const data = await fetchAllSubcategory(game_subcategory);
    return data;
  });
};
export const useCategories = ({ type }) => {
  return useQuery("categories", async () => {
    const queryParams = [type ? `${type}` : ""];
    const res = await privateRequest.get(
      `bc-game/get-categories?${queryParams}`
    );
    return res.data.categories;
  });
};
export const useInfiniteGames = ({
  filterCategory,
  type,
  types,
  mobile,
} = {}) => {
  return useInfiniteQuery(
    ["games", filterCategory, type, types, mobile],
    async ({ pageParam = 1 }) => {
      const queryParams = [
        `page=${pageParam}`,
        type ? `type=${type}` : "",
        types ? `types=${types}` : "",
        mobile ? `mobile=${mobile}` : "",
      ]
        .filter(Boolean)
        .join("&");

      const url = `bc-game/get-games?${queryParams}`;

      const res = await privateRequest.get(url);
      return res.data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length;
        const maxPage = Math.ceil(lastPage.total / 30);
        return currentPage < maxPage ? currentPage + 1 : undefined;
      },
    }
  );
};

export const useHotGames = ({ filterCategory, type, types, mobile, limit = 30, searchGame } = {}) => {
  return useInfiniteQuery(
    ["games", filterCategory, type, types, mobile, limit, searchGame],
    async ({ pageParam = 1 }) => {
      const queryParams = [
        `page=${pageParam}`,
        filterCategory ? `category=${filterCategory}` : "",
        type ? `type=${type}` : "",
        types ? `types=${types}` : "",
        mobile ? `mobile=${mobile}` : "",
        limit ? `limit=${limit}` : "",
        searchGame ? `searchGame=${searchGame}` : "",
      ]
        .filter(Boolean)
        .join("&");
      const url = `bc-game/hot-games?${queryParams}`;
      const res = await privateRequest.get(url);
      return res.data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length;
        const maxPage = Math.ceil(lastPage.total / 60);
        return currentPage < maxPage ? currentPage + 1 : undefined;
      },
    }
  );
};

export const useGameDetailsQuery = (gameId, gameType) => {
  return useQuery(
    ["gameDetails", gameId],
    async () => {
      // console.log("GAME TYPE RECEIVED: ", gameType);
      let response;
      if (gameType === "original") {
        response = await privateRequest.get(`imoon-game/game-iMoondetails/${gameId}`);
      } else {
        response = await privateRequest.get(`bc-game/game-details/${gameId}`);
      }
      return response.data;
    },
    // {
    //   enabled: !!gameId, // Only run query if gameId is provided
    // }
  );
};

export const GetImoonGames = () => {
  return useQuery("imoongames", async () => {
    const res = await privateRequest.get(`imoon-game/getImoon-games`);
    return res.data;
  })
};

