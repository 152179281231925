import React from "react";
// css
import "../../assets/css/admin/index.css";

// components
import { Aside } from "../../components/admin/Aside";
import { Header } from "../../components/admin/Header";
import { Footer } from "../../components/admin/Footer";

// images
import arrowback from "../../assets/img/admin/svg/arrow-back.svg";
// utils
import { CheckboxCustom } from "../../components/admin/CheckboxCustom";
import { useNavigate } from "react-router-dom";

export const EditAgents = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-start home admin-dashboard">
        {/* left area */}
        <Aside active={2} />

        <div className="flex-1">
          <Header heading="Agents" />

          <div className="body-area-admin py-8 px-6">
            <div className="top-body-area flex items-center justify-between">
              <h1>Agents</h1>
              <button className="flex items-center">
                <img src={arrowback} alt="" className="mr-2" />
                <span
                  className="mr-1"
                  onClick={(e) => {
                    navigate("/admin-dashboard");
                  }}
                >
                  Home
                </span>{" "}
                /
                <span
                  className="mr-1"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/admin/agent");
                  }}
                >
                  Agent
                </span>
                / Edit
              </button>
            </div>

            <div className="agent-wrapper create-agents mt-8  rounded-xl">
              <div className="top-agent  rounded-tl-xl  rounded-tr-xl py-7 px-6 top-agent-page flex items-center justify-between">
                <h1>Edit Agents</h1>
              </div>
              <div className="forms-wrapper px-6 py-6">
                <form action="#" className="grid grid-cols-2 gap-x-3 gap-y-6">
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Email <span>*</span>
                    </label>
                    <div className="input-container  mt-3">
                      <input type="text" placeholder="Enter email" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Password <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input type="text" placeholder="Enter password" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      First Name <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input type="text" placeholder="Enter First name" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Last Name <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input type="text" placeholder="Enter Last name" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Agent Name <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input type="text" placeholder="GameBox" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Phone number <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <input type="text" placeholder="Enter phone number" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Roles <span>*</span>
                      <span className="ml-1">(agent)</span>
                    </label>
                    <div className="input-container mt-3">
                      <input type="text" placeholder="Agent" />
                    </div>
                  </div>
                  <div className="input-wrapper">
                    <label htmlFor="">
                      Currencies (PHP) <span>*</span>
                    </label>
                    <div className="input-container mt-3">
                      <select name="" id="">
                        <option value="Select your currency">
                          Select your currency
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="input-wrapper  flex items-center justify-between col-span-2">
                    <div className="flex items-center">
                      <div className="flex mr-9 items-center">
                        <CheckboxCustom type="checkbox" id="whitelist-agree" />
                        <p className="ml-2 checkbox-para">
                          Enable IP Whitelist
                        </p>
                      </div>
                      <div className="flex mr-9 items-center">
                        <CheckboxCustom type="checkbox" id="active-agree" />
                        <p className="ml-2 checkbox-para">Active</p>
                      </div>
                      <div className="flex items-center">
                        <CheckboxCustom type="checkbox" id="kyc-agree" />
                        <p className="ml-2 checkbox-para">KYC Regulated</p>
                      </div>
                    </div>
                    <div className="flex buttons-wrapper items-center">
                      <button
                        className="h-11 px-4 rounded mr-3"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/admin/agent");
                        }}
                      >
                        Submit
                      </button>
                      <button className="h-11 px-4 rounded outline-button">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};
