import React from "react";
import fullgraph from "../../assets/img/stake/full-graph.svg";
import thirdgraph from "../../assets/img/stake/3rd-graph.svg";

export const EstimationCalculator = ({ months }) => {
  return (
    <div className="mt-[82px]">
      {months === 9 && <img src={thirdgraph} alt="" className="w-full" />}
      {months === 12 && <img src={fullgraph} alt="" className="w-full" />}

      <div className="flex items-center justify-between mt-3">
        <button
          className={`text-[14px] text-white px-3 py-2 bg-[#252525] border-[1px] border-[#3C403F] rounded-[5px] font-arabicmed mr-10 sm:py-1 sm:px-1 sm:mr-2 `}
        >
          Start
        </button>
        <button
          className={`text-[14px] text-white px-3 py-2 bg-[#252525] border-[1px] border-[#3C403F] rounded-[5px] font-arabicmed mr-2 sm:py-1 sm:px-1 `}
        >
          3 Month
        </button>
        <button
          className={`text-[14px] text-white px-3 py-2 bg-[#252525] border-[1px] border-[#3C403F] rounded-[5px] font-arabicmed mr-4 sm:py-1 sm:px-1 `}
        >
          6 Month
        </button>

        <button
          className={`text-[14px] text-white px-3 py-2 bg-[#252525] border-[1px] border-[#3C403F] rounded-[5px] font-arabicmed  ${
            months === 9 &&
            "bg-gradient-to-br from-[#9BE402] to-[#1FA83C] !text-white sm:py-1 sm:px-1 "
          }`}
        >
          9 Month
        </button>
        <button
          className={`text-[14px] text-[#AEB2B1] px-3 py-2 bg-[#252525] border-[1px] border-[#3C403F] rounded-[5px] font-arabicmed  ${
            months === 12 &&
            "bg-gradient-to-br from-[#9BE402] to-[#1FA83C] !text-white sm:py-1 sm:px-1 "
          }`}
        >
          12 Month
        </button>
      </div>
    </div>
  );
};
