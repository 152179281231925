import React, { useEffect, useRef, useState } from "react";
import copy from "../../assets/img/p2p/copy.svg";
// import bankwhite from "../../assets/img/p2p/bankwhite.svg";
// import profile from "../../assets/img/p2p/profile.svg";
import emoji from "../../assets/img/emoji.svg";
import send from "../../assets/img/send.svg";
// import confetti from "../../assets/img/p2p/confetti.svg";
import { HeaderStaker } from "../HeaderStaker";
import { SidebarArea } from "../SidebarArea";


export const RightAreaP2PBuyDetails = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [showModal, setShowModal] = useState(false);

  const handleConfirmClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className="flex-1">
        <HeaderStaker
          headerType="Staker"
          login={login}
          settypeShowAside={settypeShowAside}
          setsigninState={setsigninState}
          setSignUpState={setSignUpState}
        />
        <div className="body-area flex items-start px-6 pt-6">
          <div className="body-left-area w-full">
            <div className="flex justify-between gap-4 sm:flex-col">

              <div className="bg-[#1A1A1B] border border-[#3C403F] rounded-lg p-4 w-full">
                <h1 className="text-white text-[20px] font-arabicbold">Buy USDT</h1>
                <div className="flex justify-between pb-6 border-b border-[#3C403F]  sm:flex-col">
                  <p className="text-[#AEB2B1] text-[16px] font-arabicreg">Created time: <span className="text-white">2023-11-04, 13:15:18</span></p>
                  <p className="text-[#AEB2B1] text-[16px] font-arabicreg flex gap-2">Order Number: <span className="text-white">2545482875963</span><img src={copy} /></p>
                </div>
                <div className="flex justify-between py-6 border-b border-[#3C403F]">
                  <div>
                    <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">Amount</p>
                    <h1 className="text-white text-[16px] font-arabicbold">$ 100.00</h1>
                  </div>
                  <div>
                    <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">Price</p>
                    <h1 className="text-white text-[16px] font-arabicbold">75.30 EUR</h1>
                  </div>
                  <div>
                    <p className="text-[#AEB2B1] text-[14px] font-arabicreg mb-2">Quantity</p>
                    <h1 className="text-white text-[16px] font-arabicbold">1.31 USDT</h1>
                  </div>
                </div>
                <div className="flex justify-between items-start py-6 border-b border-[#3C403F] sm:flex-col">
                  <div className="flex items-center gap-2 w-1/3 sm:mb-4">
                    {/* <img src={bankwhite} /> */}
                    <h1 className="text-white text-[16px] font-arabicbold">Bank</h1>
                  </div>
                  <div className="w-full">
                    <div className="flex justify-between items-center mb-2">
                      <p className="text-[#AEB2B1] text-[16px] font-arabicbold">Recipient:</p>
                      <h1 className="text-white text-[16px] font-arabicbold flex items-center">2545482875963<img src={copy} className="ml-2" /></h1>
                    </div>
                    <div className="flex justify-between items-center mb-2">
                      <p className="text-[#AEB2B1] text-[16px] font-arabicbold">Contact:</p>
                      <h1 className="text-white text-[16px] font-arabicbold flex items-center">52148<img src={copy} className="ml-2" /></h1>
                    </div>
                    <div className="flex justify-between items-center mb-2">
                      <p className="text-[#AEB2B1] text-[16px] font-arabicbold">Bank Information:</p>
                      <h1 className="text-white text-[16px] font-arabicbold flex items-center">National Bank, USA</h1>
                    </div>
                    <div className="flex justify-between items-center mb-2">
                      <p className="text-[#AEB2B1] text-[16px] font-arabicbold">Card Number:</p>
                      <h1 className="text-white text-[16px] font-arabicbold flex items-center">8475963<img src={copy} className="ml-2" /></h1>
                    </div>
                    <div className="flex justify-between items-center mb-2">
                      <p className="text-[#AEB2B1] text-[16px] font-arabicbold">Card Number:</p>
                      <h1 className="text-white text-[16px] font-arabicbold flex items-center">8475963<img src={copy} className="ml-2" /></h1>
                    </div>
                    <div className="flex gap-4 items-start mt-8">
                      <p className="text-[#6FCF17] text-[14px] font-arabicbold">Note:</p>
                      <p className="text-white text-[14px] font-arabicmed text-justify">Lorem ipsum dolor sit amet consectetur. Rhoncus senectus diam sapien nisi hendrerit nam pretium consequat. Consectetur eget enim dignissim at adipiscing ultrices.</p>
                    </div>
                  </div>
                </div>
                <h1 className="text-white text-[20px] font-arabicbold pt-4">Payment to be made  <span className="text-[#6FCF17]">00:58:06</span></h1>
                <p className="text-[#AEB2B1] text-[14px]">Please make a payment within 60:30 minutes, otherwise the order will be cancelled.</p>
                <div className="flex justify-between gap-4 my-4">
                  <button className="text-white bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-2 w-full">Dispute</button>
                  <button className="text-white bg-gradient-to-br from-[#9BE402] to-[#1FA83C] rounded-lg py-2 w-full"
                    onClick={handleConfirmClick}
                  >Confirm</button>
                </div>
              </div>

              <div className="bg-[#1A1A1B] border border-[#3C403F] rounded-lg p-4 w-full relative">
                <div className="flex items-start gap-2 pb-6 border-b border-[#3C403F]">
                  {/* <img src={profile} /> */}
                  <div>
                    <h1 className="text-white text-[18px] font-arabicbold">Laptalist</h1>
                    <p className="text-[#AEB2B1] text-[16px] font-arabicreg">ID- 54876215985</p>
                  </div>
                </div>

                <div className="my-4 overflow-y-auto h-[100vh]">
                  <p className="text-[#AEB2B1] text-[14px] font-arabicreg text-center">2023-05-03, 13:15</p>
                  <p className="text-[#AEB2B1] text-[14px] font-arabicreg text-center">Successfully placed an order, please pay within the time limit.</p>
                  <div className="p-4 bg-[#252525] rounded-lg my-4">
                    <p className="text-[#AEB2B1] text-[14px] font-arabicreg">Lorem ipsum dolor sit amet consectetur. Rhoncus senectus diam sapien nisi hendrerit nam pretium consequat. Consectetur eget enim dignissim at adipiscing ultrices. Facilisis amet blandit adipiscing sem sodales vitae ultrices amet accumsan. Justo adipiscing rhoncus varius purus.</p>
                  </div>
                </div>

                <div className="message-area flex items-center px-4 h-14 rounded-lg border border-[#3C403F]">
                  <input
                    type="text"
                    placeholder="Send a message..."
                    className="flex-1 w-full"
                  />
                  <div className="flex">
                    <img src={emoji} alt="emoji" className="mr-3" />
                    <img src={send} alt="send" />
                  </div>
                </div>
              </div>

            </div>
          </div>
          <SidebarArea typeShowAside={typeShowAside} />
        </div>
      </div>
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-[#111010] bg-opacity-70 z-999">
          <div className="bg-[#252525] border border-[#3C403F] p-8 rounded-lg text-center">
            {/* <img src={confetti} className="mx-auto my-4 w-50 h-50" /> */}
            <h1 className="text-white text-[20px] font-arabicbold">Payment Successfully</h1>
            <p className="text-[#AEB2B1] text-[16px] font-arabicreg mb-10">Thank you for Buying your Crypto from GameBox. </p>
            <div className="flex justify-between gap-4 my-4">
              <button className="text-white text-[14px] bg-[#2E2E2E] border border-[#3C403F] rounded-lg py-3 w-full"
                onClick={() => setShowModal(false)}
              >Go to P2P</button>
              <button className="text-white text-[14px] bg-gradient-to-br from-[#9BE402] to-[#1FA83C] rounded-lg py-3 w-full"
                onClick={handleConfirmClick}
              >See Transaction History</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};