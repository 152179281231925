import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import down from "../../assets/img/p2p/down.svg";
import next from "../../assets/img/p2p/next.svg";
import time from "../../assets/img/p2p/time.svg";
import { HeaderStaker } from "../HeaderStaker";
import { SidebarArea } from "../SidebarArea";
import { PayDropdown } from "../popups/PayDropdown";
import { Coin } from "../../utils/staking/Coin";
import { CurrencyDropdown } from "../popups/CurrencyDropdown";
import { CurrencyCoins } from "../../utils/staking/CurrencyCoins";


export const RightAreaP2PSellDetails = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const navigate = useNavigate();
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [active, setActive] = useState(true);
  const [flag, setFlag] = useState("");
  const { pathname } = useLocation();
  const [selectedCurrency, setSelectedCurrency] = useState("PHP");
  const [paycheck, setPaycheck] = useState(true);
  
  const handleClick = () => {
    setActive(!active);
  };
  const [activeButton, setActiveButton] = useState('USDT');
  const handleButtonClick = (currency) => {
    setActiveButton(currency);
  };



  return (
    <div className="flex-1">
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex items-start px-6 pt-6">
        <div className="body-left-area w-full">
          <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] p-6">
            <h1 className="text-[#AEB2B1] font-arabicbold text-[18px] mb-4">You Pay</h1>
            <div className="h-[60px] sm:h-[60px] rounded-lg  bg-[#252525] pl-4 pr-2 flex items-center sm:px-4">
              <p className="text-[24px] sm:text-[18px] text-white font-arabicmed flex-1"> 0 </p>
              <p className="text-[#6FCF17] text-[16px] font-arabicmed mr-6"> MAX </p>
              <CurrencyDropdown
                onCurrencyChange={(currency) => {
                  setSelectedCurrency(currency);
                  setPaycheck(currency === "PHP");
                }}
                list={CurrencyCoins}
              />
            </div>
            <h1 className="text-[#AEB2B1] font-arabicbold text-[18px] mb-4 mt-6">You Receive (estimate)</h1>
            <div className="h-[60px] sm:h-[60px] rounded-lg  bg-[#252525] pl-4 pr-2 flex items-center sm:px-4">
              <p className="text-[24px] sm:text-[18px] text-white font-arabicmed flex-1"> 0 </p>
              <PayDropdown
                stakeFix
                dollar={Coin[0].img}
                heading={Coin[0].heading}
                list={Coin}
              />
            </div>
            <div className="flex justify-end items-center gap-2 p-2">
              <img src={time} />
              <p className="text-[#AEB2B1] font-arabicmed text-[16px]">5 sec</p>
            </div>
            <div className="flex justify-between my-6 sm:items-center">
              <p className="text-[#AEB2B1] font-arabicmed text-[16px] sm:text-[14px] sm:whitespace-nowrap">Your order</p>
              <div className="flex justify-between gap-2">
                <h1 className="text-[#FFF] font-arabicmed text-[16px] sm:text-[14px]">0.00656349 BTC for 300 USD</h1>
                <button><img src={down}/></button>
              </div>
            </div>
            <div className="flex justify-between mb-2">
              <p className="text-[#AEB2B1] font-arabicmed text-[16px] sm:text-[14px]">1 BTC</p>
              <h1 className="text-[#AEB2B1] font-arabicmed text-[16px] sm:text-[14px]">= 42214.76 USD</h1>
            </div>
            <div className="flex justify-between mb-2">
              <p className="text-[#AEB2B1] font-arabicmed text-[16px] sm:text-[14px]">Including Ramp Fee</p>
              <h1 className="text-[#AEB2B1] font-arabicmed text-[16px] sm:text-[14px]">As low as $0.00</h1>
            </div>
            <div className="flex justify-between mb-2">
              <p className="text-[#AEB2B1] font-arabicmed text-[16px] sm:text-[14px]">Including Network Fee</p>
              <h1 className="text-[#AEB2B1] font-arabicmed text-[16px] sm:text-[14px]">0.00000000001 BTC</h1>
            </div>
            <Link to="/buy/payment" className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] hover:from-[#82e402] hover:to-[#146626] font-arabicbold text-[16px] text-white w-full rounded-lg flex justify-center gap-2 py-2 items-center">
              Proceed Buy BTC
              <img src={next} />
            </Link>
          </div>
        </div>
        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};