import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import backImg from "../assets/img/transaction/back.svg";
import { GA } from "../components/common/GA";
import { privateRequest } from "../config/axios.config";
import { useAuth } from "../contexts/AuthContext";
import { AffiliateHeader } from "./AffiliateHeader";
import ButtonLoader from "./ButtonLoader";
import { TransferDone } from "./common/TransferDone";
export const TransferRight = ({ setsigninState, setSignUpState, login }) => {
  const [transferType, setTransferType] = useState("cashWallet");
  const [complete, setComplete] = useState(false);
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const { userInfo, refetch } = useAuth();
  const [otps, setOtps] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [gasetter, setgasetter] = useState(false);
  const [onConfirm, setOnConfirm] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleOtpsChange = (otps) => {
    setOtps(otps);
  };

  useEffect(() => {
    setIsDisabled(amount === "");
  }, [amount]);

  const transferAmount = () => {
    if (!userInfo?.user?.is2FAEnabled) {
      toast.error(
        "Please configure your Google Authentication to continue the transfer"
      );
    } else {
      showGA();
    }
  };

  // const transferAmount = useMutation(
  //   (payload) => privateRequest.post("user/balance/transfer-balance", payload),
  //   {
  //     onSuccess: () => {
  //       setComplete(true);
  //     },
  //     onError: (error) => {
  //       if (error.response.data?.message) {
  //         toast.error(error.response.data?.message);
  //       } else {
  //         toast.error("Something went wrong");
  //       }
  //     },
  //   }
  // );

  const showGA = () => {
    if (userInfo?.user?.is2FAEnabled) {
      setgasetter(true);
    }
  };

  const handleTransferWithGA = async () => {
    setIsDisabled(true);
    const otp = otps.join("");
    const payload = {
      email: email,
      amount: amount,
      otp: otp,
    };
    await transferAmountWithGA.mutate(payload);
  };

  const transferAmountWithGA = useMutation(
    (payload) => privateRequest.post("user/balance/transfer-balance", payload),
    {
      onSuccess: () => {
        setComplete(true);
        setgasetter(false);
        setIsDisabled(false);
      },
      onError: (error) => {
        setIsDisabled(false);
        if (error.response.data?.message) {
          toast.error(error.response.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      },
    }
  );

  const onConfirmTransfer = () => {
    setIsDisabled(true);
    if (onConfirm) {
      handleTransferWithGA();
    }
  };

  const convertCashToGbc = useMutation(async (payload) => {
    try {
      setLoading(true);
      const res = await privateRequest.post(
        "user/balance/transfer-cashwallet-to-gbc",
        payload
      );
      setLoading(false);
      setComplete(true);
      setAmount("");
      refetch();
      console.log("Refetch called");
      return res?.data;
    } catch (error) {
      console.log(error);
      setLoading(false); // Make sure to set loading to false in case of an error
    }
  });

  return (
    <div className="flex-1">
      {gasetter && (
        <GA
          isLoading={transferAmountWithGA.isLoading}
          onOtpsChange={handleOtpsChange}
          confirm={onConfirmTransfer}
          setgasetter={setgasetter}
        />
      )}

      <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />
      {complete && <TransferDone setRefAllow={setComplete} />}

      <AffiliateHeader
        login={login}
        headerType="Affiliate"
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />

      <div className="body-area flex-1  px-6 pt-6">
        <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
          <div className="flex items-center  px-6 h-[91px] border-[#3C403F] border-b-[1px]">
            <Link to="/">
              <img src={backImg} alt="" />
            </Link>
            <h1 className="text-white flex-1 text-[24px]  sm:text-[18px] ml-4">
              Transfer
            </h1>
            <Link
              to="/"
              className="text-[14px] font-arabicmed text-white w-[129px] h-[43px]
               bg-[#2E2E2E] border-[1px] border-[#6FCF17] flex items-center justify-center rounded-lg sm:text-[12px] sm:w-[140px] gap-2 hover:bg-[#3C403F]"
            >
              Go to Affiliate
            </Link>
          </div>

          <div className="px-6 py-8 min-h-[813px] ">
            <div className="flex items-center gap-4 mb-9">
              <button
                className={`py-3 px-5 border border-[#3C403F] text-white rounded-lg ${
                  transferType === "gbcTransfer" &&
                  "bg-[#252525] border-[#6FCF17]"
                }`}
                onClick={() => setTransferType("gbcTransfer")}
              >
                Convert to GBC Wallet
              </button>
              <button
                className={`py-3 px-5 border border-[#3C403F] text-white rounded-lg ${
                  transferType === "cashWallet" &&
                  "bg-[#252525] border-[#6FCF17]"
                }`}
                onClick={() => setTransferType("cashWallet")}
              >
                Cash Wallet Transfer
              </button>
            </div>
            {transferType === "gbcTransfer" ? (
              <>
                <div className="mb-8">
                  <label
                    htmlFor=""
                    className="text-[14px] font-arabicreg text-[#fff]"
                  >
                    Amount
                  </label>
                  <div className="block h-[50px] border-[1px] border-[#3C403F] w-full px-4 bg-[#111010] rounded-lg mt-2">
                    <input
                      type="text"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      placeholder="0.00"
                      className="h-full w-full border-none outline-none bg-[transparent] text-[14px] font-arabicreg  text-[#fff] placeholder:text-[#3C403F]"
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mb-8">
                  <label
                    htmlFor=""
                    className="text-[14px] font-arabicreg text-[#fff]"
                  >
                    Transfer to
                  </label>
                  <div className="block h-[50px] border-[1px] border-[#3C403F] w-full px-4 bg-[#111010] rounded-lg mt-2">
                    <input
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="example@gmail.com"
                      className="h-full w-full border-none outline-none bg-[transparent] text-[14px] font-arabicreg  text-[#fff] placeholder:text-[#3C403F]"
                    />
                  </div>
                </div>

                <div className="mb-8">
                  <label
                    htmlFor=""
                    className="text-[14px] font-arabicreg text-[#fff]"
                  >
                    Amount
                  </label>
                  <div className="block h-[50px] border-[1px] border-[#3C403F] w-full px-4 bg-[#111010] rounded-lg mt-2">
                    <input
                      type="text"
                      value={amount}
                      onChange={(e) => {
                        if (e.target.value.match(/^\d*\.?\d{0,2}$/)) {
                          setAmount(e.target.value);
                        }
                      }}
                      placeholder="0.00"
                      className="h-full w-full border-none outline-none bg-[transparent] text-[14px] font-arabicreg text-[#fff] placeholder:text-[#3C403F]"
                    />
                  </div>
                </div>
              </>
            )}

            {transferType == "cashWallet" && (
              <>
                <button
                  className={`text-[18px] font-arabicmed text-[#FFF] bg-gradient-to-br from-[#9BE402] to-[#1FA83C]
                border-[#3C403F] w-full  h-[50px] rounded-lg cursor-pointer sm:!text-[14px] hover:from-[#82e402] hover:to-[#146626]`}
                  onClick={(e) => {
                    transferAmount();
                  }}
                  disabled={isDisabled}
                >
                  {transferAmountWithGA.isLoading ? (
                    <ButtonLoader />
                  ) : (
                    "Confirm to Transfer"
                  )}
                </button>
              </>
            )}

            {transferType == "gbcTransfer" && (
              <>
                <button
                  className={`text-[18px] font-arabicmed text-[#FFF] bg-gradient-to-br from-[#9BE402] to-[#1FA83C]
                border-[#3C403F] w-full h-[50px] rounded-lg cursor-pointer sm:!text-[14px] hover:from-[#82e402] hover:to-[#146626]`}
                  onClick={(e) => {
                    setIsDisabled(true);
                    convertCashToGbc.mutate({ amount: amount });
                  }}
                  disabled={isDisabled}
                >
                  {convertCashToGbc.isLoading ? (
                    <ButtonLoader />
                  ) : (
                    "Transfer to GBC"
                  )}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
