import React from "react";
export const TypeTransfer = () => {
  return (
    <div className="grid grid-cols-2 gap-6 my-10">
      <div className="input-wrapper relative ">
        <label htmlFor="" className="text-[14px] font-arabicreg text-[#AEB2B1]">
          Bank name
        </label>
        <input
          type="text"
          placeholder="Citibank"
          className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white placeholder:text-white text-[16px] font-arabicmed px-6"
        />
      </div>
      <div className="input-wrapper relative ">
        <label htmlFor="" className="text-[14px] font-arabicreg text-[#AEB2B1]">
          Bank address
        </label>
        <input
          type="text"
          placeholder="111 Wall Street New York, NY 10043 USA"
          className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white placeholder:text-white text-[16px] font-arabicmed px-6"
        />
      </div>
      <div className="input-wrapper relative ">
        <label htmlFor="" className="text-[14px] font-arabicreg text-[#AEB2B1]">
          Routing (ABA)
        </label>
        <input
          type="text"
          placeholder="031100209"
          className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white placeholder:text-white text-[16px] font-arabicmed px-6"
        />
      </div>
      <div className="input-wrapper relative ">
        <label htmlFor="" className="text-[14px] font-arabicreg text-[#AEB2B1]">
          SWIFT code
        </label>
        <input
          type="text"
          placeholder="CITIUS33"
          className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white placeholder:text-white text-[16px] font-arabicmed px-6"
        />
      </div>
      <div className="input-wrapper relative col-span-2">
        <label htmlFor="" className="text-[14px] font-arabicreg text-[#AEB2B1]">
          Account number
        </label>
        <input
          type="text"
          placeholder="70588330000571941"
          className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white placeholder:text-white text-[16px] font-arabicmed px-6"
        />
      </div>
      <div className="input-wrapper relative ">
        <label htmlFor="" className="text-[14px] font-arabicreg text-[#AEB2B1]">
          Account type
        </label>
        <input
          type="text"
          placeholder="CHECKING"
          className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white placeholder:text-white text-[16px] font-arabicmed px-6"
        />
      </div>
      <div className="input-wrapper relative ">
        <label htmlFor="" className="text-[14px] font-arabicreg text-[#AEB2B1]">
          Beneficiary name
        </label>
        <input
          type="text"
          placeholder="Gamebox"
          className="bg-[#111010] border-[1px] border-[#3C403F] h-[50px] w-full mt-3 rounded-lg outline-none text-white placeholder:text-white text-[16px] font-arabicmed px-6"
        />
      </div>
    </div>
  );
};
