import React from "react";
import "../assets/css/sign-in.css";
import signInMainImg from "../assets/img/sign-in-girl.png";

export const Otp = ({ setcreatePassword, setOtpState }) => {
  return (
    <div className="SignIn LayerUp">
      <div className="inner-work-sign-in grid grid-cols-2">
        <div className="left-side px-[26px] flex items-center justify-center flex-col">
          <img src={signInMainImg} alt="sign in main presentation" />
          <div className="presentation">
            <h1>Welcome to GameBox</h1>
            <p className="mt-4">
              Welcome to GameBox, where you've just made the perfect choice by
              signing up! We're thrilled to have you on board, and we're here to
              set you up for success.
            </p>
          </div>
        </div>
        <div className="right-side  px-8 py-10">
          <form action="#" className="flex flex-col h-full">
            <h1 className="mb-12">OTP Verification </h1>
            <div className="input-wrapper grid grid-cols-6 gap-3">
              <input
                type="text"
                id="text"
                maxLength={1}
                className="rounded-lg  text-center !text-[20px] !text-white !w-[50px] !h-[50px]"
              />
              <input
                type="text"
                id="text"
                maxLength={1}
                className="rounded-lg  text-center !text-[20px] !text-white !w-[50px] !h-[50px]"
              />
              <input
                type="text"
                id="text"
                maxLength={1}
                className="rounded-lg  text-center !text-[20px] !text-white !w-[50px] !h-[50px]"
              />
              <input
                type="text"
                id="text"
                maxLength={1}
                className="rounded-lg  text-center !text-[20px] !text-white !w-[50px] !h-[50px]"
              />
              <input
                type="text"
                id="text"
                maxLength={1}
                className="rounded-lg  text-center !text-[20px] !text-white !w-[50px] !h-[50px]"
              />
              <input
                type="text"
                id="text"
                maxLength={1}
                className="rounded-lg  text-center !text-[20px] !text-white !w-[50px] !h-[50px]"
              />
            </div>

            <button
              className="w-full rounded-lg mt-8 mb-8"
              onClick={(e) => {
                e.preventDefault();
                setOtpState(false);
                setcreatePassword(true);
              }}
            >
              Verify
            </button>
            <p className="dont-have-p">
              Didn’t received code?{" "}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Resend
              </a>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};
