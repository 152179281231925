import React, { useState } from "react";
import arrowDown from "../../assets/img/popup/arrow-down.svg";
export const PayDropdown = ({
  dollar,
  heading,
  list,
  stake = null,
  classStyle,
  setcryptoCode = null,
  stakeFix = null,
  callback = null,
}) => {
  const [active, setActive] = useState(false);
  const [data, setData] = useState(null);
  return (
    <div className="dropdown relative ">
      <div
        className={`dropdown-head bg-[#2E2E2E] cursor-pointer rounded flex h-12 px-3 py-2 items-center ${classStyle} `}
        onClick={(e) => {
          setActive(!active);
        }}
      >
        {dollar && (
          <img
            src={data == null ? dollar : data.img}
            alt=""
            className={`mr-2 ${stake && "w-[26px] h-[26px] sm:mr-1"}  ${stakeFix && "w-[20px] h-[20px] sm:mr-1"
              } `}
          />
        )}
        <p className="text-[18px] sm:text-[14px] text-white font-arabicreg flex-1">
          {data == null ? heading : data.heading}
        </p>
        <img src={arrowDown} alt="" className="ml-3 " />
      </div>

      {list && (
        <>
          {active && (
            <ul
              className={`paydropdown overflow-auto scrollbar-hide w-full absolute z-10 bg-[#2E2E2E]`}
            >
              {list.map((EachList) => (
                <li
                  className="h-10 flex items-center pl-3 cursor-pointer"
                  onClick={(e) => {
                    setActive(false);
                    setData({
                      img: EachList.img,
                      heading: EachList.heading,
                    });
                    if (setcryptoCode) {
                      setcryptoCode(EachList.heading);
                    }
                    if (callback) {
                      callback(EachList);
                    }
                  }}
                >
                  <img
                    src={EachList.img}
                    alt=""
                    className={`mr-2 ${stake && "w-[26px] h-[26px]"}  ${stakeFix && "w-[20px] h-[20px]"
                      }`}
                  />

                  <p className="text-[18px] text-white font-arabicreg flex-1">
                    {EachList.heading}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};
