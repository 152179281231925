import { useQuery } from "react-query";
import { privateRequest } from "../config/axios.config";

export const useGetTransactions = (
  page,
) =>
  useQuery(["transactionData", page], () =>
    privateRequest.get(
      `user/balance/get-transactions?page=${page?.page}&interval=${page?.interval}&date=${page?.date}&flag=${page?.flag}`
    )
  );
export const useGetGBCTransactions = (
  page,
) =>
  useQuery(["gbctransactionData", page], () =>
    privateRequest.get(
      `user/balance/get-gbc-transactions?page=${page?.page}&interval=${page?.interval}&date=${page?.date}&flag=${page?.flag}`
    )
  );
