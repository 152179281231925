import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import add from "../../assets/img/p2p/add.svg";
import arrow from "../../assets/img/p2p/arrow.svg";
import crypto from "../../assets/img/p2p/crypto.svg";
import filter from "../../assets/img/p2p/filter.svg";
import loan from "../../assets/img/p2p/loan.svg";
import order from "../../assets/img/p2p/order.svg";
import pdf from "../../assets/img/p2p/pdf.svg";
import { Coin } from "../../utils/staking/Coin";
import { HeaderStaker } from "../HeaderStaker";
import { SidebarArea } from "../SidebarArea";
import { PayDropdown } from "../popups/PayDropdown";
import { P2PTable } from "./P2PTable";


export const RightAreaP2PSell = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const navigate = useNavigate();
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [active, setActive] = useState(true);
  const handleClick = () => {
    setActive(!active);
  };
  const [activeButton, setActiveButton] = useState('USDT');
  const handleButtonClick = (currency) => {
    setActiveButton(currency);
  };
  const [flag, setFlag] = useState("");
  const { pathname } = useLocation();


  return (
    <div className="flex-1">
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex items-start px-6 pt-6">
        <div className="body-left-area w-full">
          <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
            <div className="flex justify-between items-center p-6 border-[#3C403F] border-b-[1px]">
              <h1 className="text-white text-[26px] text-center"> P2P </h1>
              <Link target="_blank" className="text-[20px] font-arabicmed text-[#6FCF17] hover:text-[#a6e66b]" >
                Terms and Conditions
              </Link>
            </div>

            <div className="p-6">
              <>
                <div className="flex flex-col justify-center mb-4">
                  <h1 className="text-[36px] text-[#fff] text-center flex flex-col">Buy and Sell With Your Favorite <span className="text-[#6FCF17] font-arabicbold">Payment Methods</span></h1>
                  <p className="text-[18px] text-[#AEB2B1] text-center flex flex-col w-1/2 sm:w-full mx-auto mt-6 mb-10">Using your preferred payment method, you can buy or sell on P2P using your preferred method of payment!</p>
                </div>
                <div className="border border-[#3C403F] rounded-lg p-4 mb-4">
                  <div className="flex justify-between mb-4">
                    <h1 className="text-[20px] text-white">P2P</h1>
                    <h1 className="text-[20px] text-white flex gap-2 items-center"><img src={pdf} className="w-[20px] h-[20px]"/>User Guide</h1>
                  </div>
                  <div className="flex justify-between sm:flex-col">
                    <div className="flex justify-between p-1 rounded-md w-full max-w-[200px] sm:max-w-full sm:mb-4 border border-[#F6465D]">
                      <Link to="/p2p/buy" className={`text-white text-center font-arabicmed rounded-md text-[14px] py-2 w-full ${ active ? "bg-transparent" : "bg-[#6FCF17]" }`}>
                        Buy
                      </Link>
                      <Link to="/p2p/sell" className={`text-white text-center font-arabicmed rounded-md text-[14px] py-2 w-full ${ active ? "bg-[#F6465D]" : "bg-transparent" }`}>
                        Sell
                      </Link>
                    </div>
                    <div className="flex justify-between gap-4 sm:overflow-x-auto">
                      <button
                        className={`border ${activeButton === 'USDT' ? 'border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]' : 'border-[#3C403F] bg-[##111010] text-[#AEB2B1]'} p-2 rounded-md w-20`}
                        onClick={() => handleButtonClick('USDT')}
                      >
                        USDT
                      </button>
                      <button
                        className={`border ${activeButton === 'BTC' ? 'border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]' : 'border-[#3C403F] bg-[##111010] text-[#AEB2B1]'} p-2 rounded-md w-20`}
                        onClick={() => handleButtonClick('BTC')}
                      >
                        BTC
                      </button>
                      <button
                        className={`border ${activeButton === 'TUSD' ? 'border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]' : 'border-[#3C403F] bg-[##111010] text-[#AEB2B1]'} p-2 rounded-md w-20`}
                        onClick={() => handleButtonClick('TUSD')}
                      >
                        TUSD
                      </button>
                      <button
                        className={`border ${activeButton === 'BNB' ? 'border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]' : 'border-[#3C403F] bg-[##111010] text-[#AEB2B1]'} p-2 rounded-md w-20`}
                        onClick={() => handleButtonClick('BNB')}
                      >
                        BNB
                      </button>
                      <button
                        className={`border ${activeButton === 'ETH' ? 'border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]' : 'border-[#3C403F] bg-[##111010] text-[#AEB2B1]'} p-2 rounded-md w-20`}
                        onClick={() => handleButtonClick('ETH')}
                      >
                        ETH
                      </button>
                      <button
                        className={`border ${activeButton === 'DOGE' ? 'border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]' : 'border-[#3C403F] bg-[##111010] text-[#AEB2B1]'} p-2 rounded-md w-20`}
                        onClick={() => handleButtonClick('DOGE')}
                      >
                        DOGE
                      </button>
                      <button
                        className={`border ${activeButton === 'BUSD' ? 'border-[#6FCF17] bg-[#70cf1769] text-[#6FCF17]' : 'border-[#3C403F] bg-[##111010] text-[#AEB2B1]'} p-2 rounded-md w-20`}
                        onClick={() => handleButtonClick('BUSD')}
                      >
                        BUSD
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between sm:flex-col">
                  <div className="bg-[#1A1A1B] pl-4 pr-2 h-[60px] lg:min-h-[60px] sm:w-full flex items-center justify-between rounded-xl border border-[#3C403F]">
                    <input className="text-[18px] font-arabicmed text-[#AEB2B1] bg-transparent w-full flex-1 mr-2 outline-none" placeholder="Enter Amount" />
                    <PayDropdown
                      stakeFix
                      dollar={Coin[0].img}
                      heading={Coin[0].heading}
                      list={Coin}
                    />
                  </div>
                  <div className="flex justify-between gap-2 sm:mt-4 sm:mb-2">
                    <div className="bg-[#1A1A1B] px-4 sm:px-1 mb-2 rounded-lg flex items-center h-[60px] sm:h-[40px] lg:min-h-[60px] border border-[#3C403F]">
                      <select
                        name=""
                        id=""
                        value={flag}
                        onChange={(e) => setFlag(e.target.value)}
                        className="bg-[transparent] w-full text-[18px] sm:text-[14px] text-[#AEB2B1] font-arabicmed cursor-pointer outline-none h-full"
                      >
                        <option value="">All payments&nbsp;</option>
                      </select>
                    </div>
                    <div className="bg-[#1A1A1B] px-4 sm:px-1 mb-2 rounded-lg flex items-center h-[60px] sm:h-[40px] lg:min-h-[60px] border border-[#3C403F]">
                      <select
                        name=""
                        id=""
                        value={flag}
                        onChange={(e) => setFlag(e.target.value)}
                        className="bg-[transparent] w-full text-[18px] sm:text-[14px] text-[#AEB2B1] font-arabicmed cursor-pointer outline-none h-full"
                      >
                        <option value="">All Regions&nbsp;</option>
                      </select>
                    </div>
                    <div className="bg-[#1A1A1B] px-4 sm:px-1 mb-2 rounded-lg flex items-center h-[60px] sm:h-[40px] lg:min-h-[60px] border border-[#3C403F]">
                      <select
                        name=""
                        id=""
                        value={flag}
                        onChange={(e) => setFlag(e.target.value)}
                        className="bg-[transparent] w-full text-[18px] sm:text-[14px] text-[#AEB2B1] font-arabicmed cursor-pointer outline-none h-full"
                      >
                        <option value="">Price&nbsp;</option>
                      </select>
                    </div>
                    <div className="bg-[#1A1A1B] px-4 sm:px-1 mb-2 rounded-lg flex items-center h-[60px] sm:h-[40px] lg:min-h-[60px] border border-[#3C403F]">
                      <button
                        name=""
                        id=""
                        value={flag}
                        onChange={(e) => setFlag(e.target.value)}
                        className="bg-[transparent] w-full text-[18px] sm:text-[14px] text-[#AEB2B1] font-arabicmed cursor-pointer outline-none h-full"
                      >
                        <img src={filter}/>
                      </button>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <button className="flex justify-center items-center text-[#fff] gap-2 bg-[#252525] border border-[#3C403F] p-4 text-[18px] rounded-lg h-[60px] lg:min-h-[60px] sm:w-full"><img src={arrow} />Not Now</button>
                    <button className="flex justify-center items-center text-[#fff] gap-2 bg-[#70cf1769] border border-[#6FCF17] p-4 text-[18px] rounded-lg h-[60px] lg:min-h-[60px] sm:w-full" onClick={(e) => navigate ("/p2p/post-ad")}><img src={add} />Post Ad</button>
                  </div>
                </div>
                <P2PTable active={active} handleButtonClick={handleButtonClick} pathname={pathname} />
              </>
            </div>
            <div className="bg-[#1E1E1E] p-6 my-4">
              <div className="flex justify-between sm:flex-col">
                <h1 className="text-[#fff] text-[30px] font-arabicbold text-center sm:mb-4">HOW P2P WORKS</h1>
                <div className="flex justify-between p-1 rounded-md w-full max-w-[200px] sm:max-w-full sm:mb-4 border border-[#6FCF17]">
                  <button className={`text-white font-arabicmed rounded-md text-[14px] py-2 w-full sm:py-4 ${ active ? "bg-[#6FCF17]" : "bg-transparent" }`} onClick={handleClick}>
                    Buy crypto
                  </button>
                  <button className={`text-white font-arabicmed rounded-md text-[14px] py-2 w-full sm:py-4 ${ active ? "bg-transparent" : "bg-[#6FCF17]" }`} onClick={handleClick} >
                    Sell crypto
                  </button>
                </div>
              </div>
              <div className="flex justify-between gap-4 my-4 sm:flex-col">
                <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-10 text-center w-1/3 sm:w-full">
                  <img src={order} className="mx-auto my-4" />
                  <h1 className="text-white font-arabicbold text-[20px] mb-4">Place an Order</h1>
                  <p className="text-[#AEB2B1] font-arabicreg text-[14px]">Once you place a P2P order the crypto asset will be escrowed by Binance P2P.</p>
                </div>
                <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-10 text-center w-1/3 sm:w-full">
                  <img src={loan} className="mx-auto my-2" />
                  <h1 className="text-white font-arabicbold text-[20px] mb-4">Pay the Seller</h1>
                  <p className="text-[#AEB2B1] font-arabicreg text-[14px]">Send money to the seller via the suggested payment method. Complete the fiat transaction and click Transferred notify seller on Binance P2P.</p>
                </div>
                <div className="bg-[#252525] border border-[#3C403F] rounded-lg p-10 text-center w-1/3 sm:w-full">
                  <img src={crypto} className="mx-auto my-4" />
                  <h1 className="text-white font-arabicbold text-[20px] mb-4">Get your Crypto</h1>
                  <p className="text-[#AEB2B1] font-arabicreg text-[14px]">Once the confirms receipt of money the escrowed crypto will be released to you.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};
