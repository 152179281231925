import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swap from "../../assets/img/swap/swap.svg";
import swapTCx from "../../assets/img/swap/swap_TC.pdf";
import loader from "../../assets/img/swap/loader.svg";
import { HeaderStaker } from "../HeaderStaker";
import { SidebarArea } from "../SidebarArea";
import { PayDropdown } from "../popups/PayDropdown";
import { Coin } from "../../utils/staking/Coin";
import "../../assets/css/settings/index.css";
import { useGetAvailableCryptos } from "../../queries/crypto";
import { getCoinName } from "../../utils";
import { DepositeCurrencyDropdown } from "../popups/DepositeCurrencyDropdown";
import { CurrencyCoins } from "../../utils/staking/CurrencyCoins";
import "../../assets/css/p2p-post-ad.css";
import Equal from "../../assets/img/equals.svg";
import arrowDown from "../../assets/img/popup/arrow-down.svg";
import backImg from "../../assets/img/transaction/back.svg";
import MultiSelect from "react-multiple-select-dropdown-lite";
import "react-multiple-select-dropdown-lite/dist/index.css";

export const RightAreaP2PPostAd = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(true);
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [stepActive, setStepActive] = useState(1);
  const handleClick = () => {
    setActive(!active);
  };
  const [formattedCryptoData, setFormattedCryptoData] = useState([]);
  const params = useParams();
  const [coin, setCoin] = useState(params?.coin);
  const [network, setNetwork] = useState();

  const [selectedCurrency, setSelectedCurrency] = useState("PHP");
  const [paycheck, setPaycheck] = useState(true);

  const {
    data: cryptoData,
    isLoading: loadingCryptos,
    error: cryptoError,
  } = useGetAvailableCryptos();

  const [flag, setFlag] = useState("");

  const handleProceed1 = () => {
    setStepActive(2);
  };

  const handleProceed2 = () => {
    setStepActive(3);
  };

  const handlePrevious = () => {
    setStepActive(stepActive - 1);
  };

  const [limit, setLimit] = useState("");

  const handleChange = (event) => {
    setLimit(event.target.value);
  };

  //INCREMENT, DECREMENT FUNCTION
  const [usdtValue, setUsdtValue] = useState(1.34);

  //MODAL STATE
  const [isOpen, setIsOpen] = useState(false);

  // Function to handle opening the modal
  const openModal = () => {
    setIsOpen(true);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setIsOpen(false);
  };

  const increment = () => {
    setUsdtValue((prevValue) => prevValue + 0.01);
  };

  const decrement = () => {
    if (usdtValue > 0.01) {
      // To avoid negative values
      setUsdtValue((prevValue) => prevValue - 0.01);
    }
  };

  useEffect(() => {
    if (cryptoData && cryptoData.data) {
      const newFormattedCryptoData = Object.entries(cryptoData.data).map(
        ([key, value]) => {
          return {
            symbol: key,
            heading: value?.symbol?.includes("_")
              ? `${value?.symbol.split("_")[0]} (${
                  value?.symbol.split("_")[1]
                })`
              : value?.symbol,

            img: `https://cryptologos.cc/logos/thumbs/${getCoinName(
              value?.symbol.split("_")[0]
            )}.png?v=030`,
          };
        }
      );
      console.log("newFormattedCryptoData", newFormattedCryptoData);
      setFormattedCryptoData(newFormattedCryptoData);
    }
  }, [cryptoData]);

  const [value, setvalue] = useState("");

  const handleOnchange = (val) => {
    setvalue(val);
  };

  const options = [
    { label: "Aground", value: "option_1" },
    { label: "Big Pay", value: "option_2" },
    { label: "Google Pay", value: "option_3" },
    { label: "Bank Transfer", value: "option_4" },
    { label: "E-payment", value: "option_5" },
  ];

  return (
    <div className="flex-1">
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={settypeShowAside}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex items-start px-6 pt-6">
        <div className="body-left-area w-full">
          <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
            <div className="flex items-center px-6 py-4 h-[91px] border-[#3C403F] border-b-[1px]">
              <div className="flex items-center">
                <Link to="/p2p/buy" className="px-2">
                  <img src={backImg} alt="" />
                </Link>
                <h1 className="text-white text-[24px] text-center sm:text-[18px]">
                  Post Your Ad
                </h1>
              </div>

              <span className="flex-1"></span>

              <Link
                target="_blank"
                to={swapTCx}
                className="text-[18px] font-arabicmed text-[#6FCF17] sm:text-[12px] hover:text-[#a6e66b] "
              >
                Terms and Conditions
              </Link>
            </div>

            <div className="p-6">
              <>
                <div className="grid grid-cols-3 mb-4 ">
                  <h1 className="text-[18px]  text-[#AEB2B1] sm:text-[12px]  font-arabicmed place-self-start md:place-self-start">
                    Set Type Price
                  </h1>
                  <h1 className="text-[18px] text-[#AEB2B1] sm:text-[12px]  font-arabicmed place-self-center">
                    Set Total Amount Payment Method
                  </h1>
                  <h1 className="text-[18px] text-[#AEB2B1] sm:text-[12px]  font-arabicmed place-self-end md:place-self-center">
                    Set Remarks Automatic Response
                  </h1>
                </div>

                {stepActive !== 4 && (
                  <div className="flex justify-around buttons-wrapper-step gap-4 mb-6">
                    <div className="w-auto">
                      <button
                        className={`button-step  bg-[#252525] text-white ${
                          stepActive === 1 ||
                          stepActive === 2 ||
                          stepActive === 3
                            ? "bg-[#6FCF17]"
                            : "" && "active"
                        } rounded-full px-5 h-12`}
                        onClick={(e) => {
                          setStepActive(1);
                        }}
                      >
                        1
                      </button>
                    </div>

                    <hr
                      style={{ borderWidth: "1.5px" }}
                      className={`border-[#4b4b4b] w-full my-auto ${
                        stepActive === 2 || stepActive === 3
                          ? "border-[#6cb339]"
                          : ""
                      }`}
                    />

                    <div className="w-auto">
                      <button
                        className={`button-step bg-[#252525] text-white ${
                          stepActive === 2 || stepActive === 3
                            ? "bg-[#6FCF17]"
                            : "" && "active"
                        } rounded-full px-5 h-12`}
                        onClick={(e) => {
                          setStepActive(2);
                        }}
                      >
                        2
                      </button>
                    </div>

                    <hr
                      style={{ borderWidth: "1.5px" }}
                      className={`border-[#4b4b4b] w-full my-auto ${
                        stepActive === 3 ? "border-[#6cb339]" : ""
                      }`}
                    />

                    <div className="w-auto">
                      <button
                        className={`button-step bg-[#252525] text-white ${
                          stepActive === 3 ? "bg-[#6FCF17]" : "" && "active"
                        } rounded-full px-5 h-12`}
                        onClick={(e) => {
                          setStepActive(3);
                        }}
                      >
                        3
                      </button>
                    </div>
                  </div>
                )}

                {stepActive === 1 && (
                  <div className="">
                    <div className="flex w-full justify-between mb-4 rounded-md border-[1px] border-blue-gray-800 bg-[#252525]">
                      <button
                        className={`text-white font-arabicmed rounded-md text-[14px] py-3 w-full ${
                          active ? "bg-[#6FCF17]" : "bg-transparent"
                        }`}
                        onClick={handleClick}
                      >
                        I want to buy
                      </button>
                      <button
                        className={`text-white font-arabicmed rounded-md text-[14px] py-3 w-full ${
                          active ? "bg-transparent" : "bg-[#6FCF17]"
                        }`}
                        onClick={handleClick}
                      >
                        I want to sell
                      </button>
                    </div>

                    <div className="flex md:flex-col md:justify-center justify-between items-end gap-8 md:gap-4  mb-6">
                      {active ? (
                        <div className="w-full h-auto py-4">
                          <h1 className="text-[18px] text-[#AEB2B1] font-arabicmed mb-2">
                            Asset
                          </h1>
                          <PayDropdown
                            stake
                            classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
                            dollar={coin?.img}
                            heading={coin?.heading}
                            list={formattedCryptoData}
                            callback={(e) => {
                              setCoin(e);
                              setNetwork("");
                            }}
                          />
                        </div>
                      ) : (
                        <div className="w-full h-auto py-4">
                          <h1 className="text-[18px] text-[#AEB2B1] font-arabicmed mb-2">
                            With Fiat
                          </h1>
                          <div className="w-full h-auto py-[11px] bg-[#111010] rounded-lg border-[1px] border-[#3C403F]">
                            <DepositeCurrencyDropdown
                              onCurrencyChange={(currency) => {
                                setSelectedCurrency(currency);
                                setPaycheck(currency === "PHP");
                              }}
                              list={CurrencyCoins}
                            />
                          </div>
                        </div>
                      )}

                      <div className="w-28 h-auto py-4 flex justify-center items-center md:w-full">
                        <img
                          src={swap}
                          alt=""
                          onClick={(e) => {
                            setActive(!active);
                          }}
                          className="cursor-pointer md:ml-4 md:self-center"
                          style={{ transform: "rotate(90deg)" }}
                        />
                      </div>

                      {active ? (
                        <div className="w-full h-auto py-4">
                          <h1 className="text-[18px] text-[#AEB2B1] font-arabicmed mb-2">
                            With Fiat
                          </h1>
                          <div className="w-full h-auto py-[11px] bg-[#111010] rounded-lg border-[1px] border-[#3C403F]">
                            <DepositeCurrencyDropdown
                              onCurrencyChange={(currency) => {
                                setSelectedCurrency(currency);
                                setPaycheck(currency === "PHP");
                              }}
                              list={CurrencyCoins}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="w-full h-auto py-4">
                          <h1 className="text-[18px] text-[#AEB2B1] font-arabicmed mb-2">
                            Asset
                          </h1>
                          <PayDropdown
                            stake
                            classStyle="!bg-[#111010] border-[#3C403F] rounded-lg border-[1px] min-w-[196px] sm:min-w-[120px]"
                            dollar={coin?.img}
                            heading={coin?.heading}
                            list={formattedCryptoData}
                            callback={(e) => {
                              setCoin(e);
                              setNetwork("");
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <div className="w-full flex justify-between gap-6 md:flex-col">
                      <div className="w-full h-[120px] mt-2 flex flex-col justify-center rounded-lg border border-[#4b4b4b] px-6">
                        <div className="flex items-center justify-between">
                          <p className="text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                            Your Price
                          </p>
                        </div>
                        <div className="flex items-center justify-between mt-6">
                          <p className="text-[18px] text-[#ACADB1] font-arabicmed ">
                            B$ <span className="text-white">1.340</span>
                          </p>
                        </div>
                      </div>

                      <div className="w-full h-[120px] mt-2 flex flex-col justify-center rounded-lg border border-[#4b4b4b] px-6">
                        <div className="flex items-center justify-between">
                          <p className="text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                            Highest Order Price
                          </p>
                        </div>
                        <div className="flex items-center justify-between mt-6">
                          <p className="text-[18px] text-[#ACADB1] font-arabicmed ">
                            B$ <span className="text-white">1.340</span>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="w-full flex justify-between gap-6 mt-6 md:flex-col">
                      <div className="w-full h-[120px] mt-2 flex flex-col justify-center rounded-lg border border-[#4b4b4b] px-6">
                        <div className="flex items-center justify-between">
                          <p className="text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                            Price Type
                          </p>
                        </div>
                        <div className="flex gap-2 mt-4">
                          <div className="w-full flex items-center gap-4">
                            <input
                              type="radio"
                              id="option1"
                              name="priceType"
                              value="fixed"
                              style={{ transform: "scale(1.5)" }} // Increase size
                            />
                            <label
                              htmlFor="option1"
                              className="text-[18px] text-[#ACADB1] font-arabicmed"
                            >
                              <span className="text-white">Fixed</span>
                            </label>
                          </div>

                          <div className="w-full flex items-center gap-2">
                            <input
                              type="radio"
                              id="option2"
                              name="priceType"
                              value="floating"
                              style={{ transform: "scale(1.5)" }} // Increase size
                            />
                            <label
                              htmlFor="option2"
                              className="text-[18px] text-[#ACADB1] font-arabicmed"
                            >
                              <span className="text-white">Floating</span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="w-full h-[120px] mt-2 flex flex-col justify-center rounded-lg border border-[#525252] px-6 ">
                        <div className="flex items-center justify-between">
                          <p className="text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                            Fixed
                          </p>
                        </div>
                        <div className="flex items-center justify-between mt-3 p-[6px] border-gray-700 border-[1px] rounded-md">
                          <button
                            onClick={decrement}
                            className="flex justify-center items-center text-center w-10 h-10 bg-gray-800 rounded-md text-white border-gray-700 text-[36px]"
                          >
                            <p className="mb-1">-</p>
                          </button>
                          <span className="text-white text-[18px]">
                            {usdtValue.toFixed(3)}
                          </span>
                          <button
                            onClick={increment}
                            className="flex justify-center items-center text-center w-10 h-10 bg-gray-800 rounded-md text-white border-gray-700 text-[26px] font-normal"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex  gap-4 mt-10 ">
                      <button
                        className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:from-[#82e402] hover:to-[#146626]"
                        onClick={handleProceed1}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                )}

                {stepActive === 2 && (
                  <div className="">
                    <div className="flex w-full justify-between mb-4 rounded-md border-[1px] border-blue-gray-800 bg-[#252525]">
                      <button
                        className={`text-white font-arabicmed rounded-md text-[14px] py-3 w-full ${
                          active ? "bg-[#6FCF17]" : "bg-transparent"
                        }`}
                        onClick={handleClick}
                      >
                        I want to buy
                      </button>

                      <button
                        className={`text-white font-arabicmed rounded-md text-[14px] py-3 w-full ${
                          active ? "bg-transparent" : "bg-[#6FCF17]"
                        }`}
                        onClick={handleClick}
                      >
                        I want to sell
                      </button>
                    </div>

                    <div className="pt-4">
                      <div className="w-full flex justify-between text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                        <p>Total Amount</p>
                        <p>= 0 USD</p>
                      </div>
                      <div className="w-full h-[60px] my-4 flex justify-between rounded-lg border border-[#4b4b4b] px-4">
                        <div className="flex w-full justify-between items-center">
                          <p className="text-white font-arabicmed">0</p>
                          <p className="text-white font-arabicmed">USDT</p>
                        </div>
                      </div>
                    </div>

                    <div className="pt-4">
                      <div className="w-full flex justify-between text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                        <p>Order Limit</p>
                      </div>
                      <div className="flex gap-6">
                        <div className="w-full h-[60px] my-4 flex justify-between rounded-lg border border-[#4b4b4b] px-4">
                          <div className="flex w-full justify-between items-center">
                            <p className="text-white font-arabicmed">15,000</p>
                            <p className="text-white font-arabicmed">USDT</p>
                          </div>
                        </div>
                        <div className="flex justify-center items-center">
                          <img src={Equal} alt="equal" />
                        </div>
                        <div className="w-full h-[60px] my-4 flex justify-between rounded-lg border border-[#4b4b4b] px-4">
                          <div className="flex w-full justify-between items-center">
                            <p className="text-white font-arabicmed">0</p>
                            <p className="text-white font-arabicmed">USDT</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-4">
                      <div className="w-full flex justify-between text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                        <p>Payment Method</p>
                      </div>

                      <div className="flex pt-4 justify-end ">
                        {/* <div className="preview-values">
                        <h4>Values</h4>
                        {value}
                      </div> */}

                        <MultiSelect
                          placeholder="Add Payment.."
                          onChange={handleOnchange}
                          options={options}
                        />
                      </div>
                    </div>

                    <div className="pt-4">
                      <div className="w-full flex justify-between text-[18px] text-[#ACADB1] flex-1 font-arabicmed ">
                        <p>Tamest Time Limit</p>
                      </div>
                      <div className="bg-[#1A1A1B] px-4 my-4 sm:px-1 mb-2 rounded-lg flex items-center h-[60px] sm:h-[40px] lg:min-h-[60px] border border-[#3C403F]">
                        {/* <select
                          name=""
                          id=""
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                          className="bg-[transparent] w-full text-[18px] sm:text-[14px] text-[#AEB2B1] font-arabicmed cursor-pointer outline-none h-full"
                          style={{ maxWidth: "fit-content" }}
                        >
                          <option value="" className="w-full">
                            15 min&nbsp;
                          </option>
                        </select> */}
                        <select
                          id="time"
                          value={limit}
                          onChange={handleChange}
                          className="bg-[transparent] text-[18px] sm:text-[14px] text-[#AEB2B1] font-arabicmed w-full outline-none"
                        >
                          <option value="15">15 minutes</option>
                          <option value="30">30 minutes</option>
                          <option value="60">1 hour</option>
                        </select>
                      </div>
                      {/* <div className="w-full h-[60px] my-4 flex justify-between rounded-lg border border-[#4b4b4b] px-4">
                        <div className="flex w-full justify-between items-center">
                          <p className="text-white font-arabicmed">15 min</p>
                          <img src={arrowDown} alt="arrowdown" />
                        </div>
                      </div> */}
                    </div>

                    <div className="w-full flex  gap-4 mt-10 ">
                      <button
                        className="bg-[#252525] w-full rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:bg-[#363636]"
                        onClick={handlePrevious}
                      >
                        Previous
                      </button>
                      <button
                        className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:from-[#82e402] hover:to-[#146626]"
                        onClick={handleProceed2}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}

                {stepActive === 3 && (
                  <div className="">
                    <div className="pb-3">
                      <label
                        htmlFor="#"
                        className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                      >
                        Remarks (Optional)
                      </label>
                      <textarea
                        // If you want to handle the value and onChange events, uncomment the following lines
                        // value={comments}
                        // onChange={(e) => setComments(e.target.value)}
                        className="bg-[#111010] border-[1px] border-[#3C403F] h-[100px] w-full rounded-lg outline-none text-white placeholder:text-white text-[18px] font-arabicmed p-4 sm:text-[14px]"
                      />
                    </div>
                    <div className="pb-3">
                      <label
                        htmlFor="#"
                        className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                      >
                        Auto Reply (Optional)
                      </label>
                      <textarea
                        // If you want to handle the value and onChange events, uncomment the following lines
                        // value={comments}
                        // onChange={(e) => setComments(e.target.value)}
                        className="bg-[#111010] border-[1px] border-[#3b3d3c] h-[100px] w-full rounded-lg outline-none text-white placeholder:text-[#8f9090] text-[18px] font-arabicmed p-4 sm:text-[14px]"
                        placeholder="Auto reply message will be sent to the counterparty once the order is created"
                      />
                    </div>
                    <div className="pb-3">
                      <label
                        htmlFor="#"
                        className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                      >
                        Select Region(s) Availability
                      </label>
                      <div className="bg-[#1A1A1B] px-4 sm:px-1 mb-2 rounded-lg flex items-center h-[60px] sm:h-[40px] lg:min-h-[60px] border border-[#3C403F]">
                        <select
                          name=""
                          id=""
                          value={flag}
                          onChange={(e) => setFlag(e.target.value)}
                          className="bg-[transparent] w-full text-[18px] sm:text-[14px] text-[#AEB2B1] font-arabicmed cursor-pointer outline-none h-full"
                        >
                          <option value="">All Regions&nbsp;</option>
                        </select>
                      </div>
                    </div>
                    <div className="py-5 flex lg:flex-col gap-5 ">
                      <div className="flex-col w-full">
                        <label
                          htmlFor="#"
                          className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                        >
                          Counterparty conditions
                        </label>
                        <div className="flex sm:flex-col gap-10 mt-4 lg:gap-5">
                          <div className="flex items-center text-white">
                            <input type="checkbox" id="registered" />
                            <label htmlFor="registered" className="ml-2">
                              Registered
                            </label>
                            <div className="bg-[#252525] h-[40px] lg:min-h-[40px] mx-2 flex items-center justify-between rounded-xl">
                              <input
                                className="text-[18px] text-center w-20 font-arabicmed text-white bg-transparent  outline-none"
                                placeholder="0"
                              />
                            </div>
                            <div></div>

                            <div>day (s) ago</div>
                          </div>
                          <div className="flex items-center text-white">
                            <input type="checkbox" id="registered" />
                            <label htmlFor="registered" className="ml-2">
                              Holdings more than
                            </label>
                            <div className="bg-[#252525] h-[40px] lg:min-h-[40px] mx-2 flex items-center justify-between rounded-xl">
                              <input
                                className="text-[18px] text-center w-20 font-arabicmed text-white bg-transparent  outline-none"
                                placeholder="0.01"
                              />
                            </div>
                            <div>BTC</div>
                          </div>
                        </div>
                      </div>
                      <div className="flex-col w-full">
                        <label
                          htmlFor="#"
                          className="text-[16px] sm:text-[14px] font-arabicreg text-[#AEB2B1] mb-3 block"
                        >
                          Status
                        </label>
                        <div className="flex gap-2 mt-4 xs:flex-col ">
                          <div className="w-full flex items-center gap-2">
                            <input
                              type="radio"
                              id="option1"
                              name="priceType"
                              value="fixed"
                              className="styled-radio"
                            />
                            <label
                              htmlFor="option1"
                              className="text-[18px] text-[#ACADB1] font-arabicmed"
                            >
                              <span className="text-white">
                                Online right now
                              </span>
                            </label>
                          </div>

                          <div className="w-full flex items-center gap-2">
                            <input
                              type="radio"
                              id="option1"
                              name="priceType"
                              value="fixed"
                              className="styled-radio"
                            />
                            <label
                              htmlFor="option2"
                              className="text-[18px] text-[#ACADB1] font-arabicmed"
                            >
                              <span className="text-white">
                                Offline, manually later
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex  gap-4 mt-10 ">
                      <button
                        className="bg-[#252525] w-full rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:bg-[#363636]"
                        onClick={handlePrevious}
                      >
                        Previous
                      </button>
                      <button
                        className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicbold hover:from-[#82e402] hover:to-[#146626] "
                        onClick={openModal}
                      >
                        Post Now
                      </button>
                      {isOpen && (
                        <div className="modal w-full">
                          <div className=" modal-content font-arabicmed rounded-lg overflow-hidden w-full">
                            <div className="w-full flex items-center border-b-[1px] border-[#3C403F] justify-between bg-[#2E2E2E] py-[12px] px-6">
                              <h1 className="text-[20px]">Confirm to Post</h1>
                              <div className="flex items-center justify-center rounded-lg bg-[#252525] border-[1px] border-[#818584] h-[26px] w-[26px] p-2">
                                <span className="close" onClick={closeModal}>
                                  &times;
                                </span>
                              </div>
                            </div>
                            <div className="w-full flex-col items-center bg-[#252525] text-[18px] px-6">
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Type</p>
                                <p>Buy</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Asset</p>
                                <p>USDT</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Currency</p>
                                <p>USD</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Price Type</p>
                                <p>Fixed</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Fixed</p>
                                <p>1.000USD</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Order limit</p>
                                <p>15.000 - 150USD USD</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">
                                  Total Trading Amount
                                </p>
                                <p>1.0000USD</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Estimated Fee</p>
                                <p>2.80 USD</p>
                              </div>
                              <div className="py-3">
                                <hr
                                  className="border-[#3C403F]"
                                  style={{ borderWidth: "1px" }}
                                />
                              </div>

                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">Payment Method</p>
                                <p>Bank Transfer</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px]">
                                <p className="text-[#AEB2B1]">
                                  Payment Time Limit
                                </p>
                                <p>15 min</p>
                              </div>
                              <div className="w-full flex items-center justify-between font-arabicreg py-1.5 my-2 text-[16px] ">
                                <p className="text-[#AEB2B1]">Type</p>
                                <p>All Regions</p>
                              </div>
                              <div className="w-full flex sm:flex-col items-center py-2 my-2 gap-4">
                                <button
                                  className="bg-[#252525] w-full sm:order-2 border-2 border-[#3C403F] rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:bg-[#363636]"
                                  onClick={closeModal}
                                >
                                  Cancel
                                </button>
                                <button className="bg-gradient-to-br from-[#9BE402] to-[#1FA83C] w-full sm:order-1 rounded-lg text-[#FFF] h-[50px] text-[18px] font-arabicmed hover:from-[#82e402] hover:to-[#146626] ">
                                  Confirm to Post
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
        <SidebarArea typeShowAside={typeShowAside} />
      </div>
    </div>
  );
};
