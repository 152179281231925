import React, { useState } from "react";
import { HeaderStaker } from "./HeaderStaker";
import backImg from "../assets/img/transaction/back.svg";
import { SwitchDeposite } from "./wallet/SwitchDeposite";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DepositeFiat } from "./wallet/DepositeFiat";
import { DepositeCrypto } from "./wallet/DepositeCrypto";
import { useNavigate } from "react-router-dom";
export const DepositeCryptoRightArea = ({
  setsigninState,
  setSignUpState,
  login,
}) => {
  const [depositType, setdepositType] = useState(1);
  const navigate = useNavigate();


  return (
    <div className="flex-1">
      <ToastContainer theme="dark" autoClose={3000} position="bottom-right" />
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex-1  px-6 pt-6">
        <div className="bg-[#1A1A1B] rounded-xl border-[1px] border-[#3C403F] ">
          <div className="flex items-center  px-6 h-[91px] border-[#3C403F] border-b-[1px]">
            <img src={backImg}  className="cursor-pointer" onClick={() => {
          navigate("/home/wallet/balance");
        }} alt="" />
            <h1 className="text-white flex-1 text-[24px]  sm:text-[18px] ml-4">
              Deposit
            </h1>
            {/* <SwitchDeposite setdepositType={setdepositType} /> */}
          </div>

          <div className="content-area min-h-[813px]  px-6 py-6">
            {depositType == 1 ? <DepositeCrypto /> : <DepositeFiat />}
          </div>
        </div>
      </div>
    </div>
  );
};
