import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { privateRequest } from "../config/axios.config";
import { usePayWithExternalWallet, usePaymentStatus } from "../queries/balance";
import { useGetWallet } from "../queries/wallet";
import { Coin } from "../utils/staking/Coin";
import { HeaderStaker } from "./HeaderStaker";
import { SwitchPayment } from "./staker/SwitchPayment";
import banner13 from "../assets/img/banner/banner13.png";
export const RightAreaStakePaymentExternalWallet = ({
  setsigninState,
  setSignUpState,
  login,
  active,
}) => {
  const navigate = useNavigate();
  const [fiatConditional, setfiatConditional] = useState(1);
  const [currencyCode, setCurrencyCode] = useState("BTC");
  const [coin, setCoin] = useState();
  const [Paycheck, setPaycheck] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState();
  const [provider, setProvider] = useState();
  const [loading, setLoading] = useState(false);
  const [conversionLoading, setconversionLoading] = useState(false);
  const [fiatAmount, setFiatAmount] = useState(0);
  const [loadingGcash, setLoadingGcash] = useState(false);
  const [loadingEplayment, setLoadingEplayment] = useState(false);
  const [externalPaymentData, setExternalPaymentData] = useState();
  const paywithExternalWallet = usePayWithExternalWallet();
  const [paymentAddress, setPaymentAddress] = useState("");
  const [cryptoCoin, setcryptoCoin] = useState("bitcoin");
  const [paymentReceived, setPaymentReceived] = useState(false);
  const [showExternalPaymentMethod, setShowExternalPaymentMethod] =
    useState(false);
  const [redirectCounter, setRedirectCounter] = useState(5);
  // Use useEffect to set the default payment method based on the active prop
  const getConversion = () => {
    setconversionLoading(true);
    const amount = window.sessionStorage.getItem("stake-amount");

    privateRequest
      .post("/user/stacking/get-fiat-conversion", {
        amount,
        currency: currencyCode,
      })
      .then((res) => {
        const fiatAmount = res.data.fiatAmount;
        setFiatAmount(fiatAmount);
        localStorage.setItem("phpAmount", fiatAmount.toString());
        localStorage.setItem("fiatAmount", amount.toString());
        setconversionLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching conversion: ", err);
        toast.error("Error in currency conversion");
      });
  };

  const charges = usePaymentStatus(externalPaymentData?.coinbaseCharge?.id);
  // console.log("CHARGES HERE!!!!!!!!!!!!!")
  // console.log(charges)
  useEffect(() => {
    // Check the payment status and update the state
    if (charges?.charge === "COMPLETED") {
      setPaymentReceived(true);
      setShowExternalPaymentMethod(false);
      setPaymentMethod("");
    } else {
      setPaymentReceived(false);
    }
  }, [charges]);

  useEffect(() => {
    if (paymentReceived) {
      // Countdown logic
      const countdownInterval = setInterval(() => {
        setRedirectCounter((prevCounter) => prevCounter - 1);
      }, 1000);
      // Redirect when the counter reaches 0
      if (redirectCounter === 0) {
        window.location.href = "/home/wallet/deposite";
      }
      // Cleanup interval when component unmounts
      return () => clearInterval(countdownInterval);
    }
  }, [redirectCounter, paymentReceived]);

  const GeneratePayment = async () => {
    setLoading(true);
    const storedFiatAmount = localStorage.getItem(
      currencyCode === "PHP" ? "phpAmount" : "fiatAmount"
    );
    const packid = window.sessionStorage.getItem("stake-package-id");
    const data = await paywithExternalWallet.mutateAsync({
      amount: storedFiatAmount,
      currency: currencyCode,
      identifier: "staking_deposit",
      name: "Staking",
      description: "Staking Subscription payment",
      packageId: packid,
    });

    // setPaymentAddress(data.coinbaseCharge.hosted_url);

    window.location.href = data.coinbaseCharge.hosted_url;

    setTimeout(() => {
      window.location.href = data.coinbaseCharge.hosted_url; // Redirect to redUrl on success
    }, 2000); // 2000 milliseconds = 2 seconds

    console.log(data);
    setExternalPaymentData(data);
    setShowExternalPaymentMethod(true);
    setLoading(false);
  };

  useEffect(() => {
    getConversion();
  }, [currencyCode]);

  useEffect(() => {
    try {
      switch (active) {
        case 1:
          setPaymentMethod("crypto");
          setCoin({ currency: "BTC" });
          break;
        case 2:
          setPaymentMethod("fiat");
          setCoin({ currency: currencyCode });
          break;
        default:
          setPaymentMethod("cashwallet");
          setCoin({ currency: "USD" });
      }
    } catch (error) {
      console.error("Error fetching payment data:", error);
    }
  }, [active, currencyCode]); // Dependency array with active ensures this runs when active prop changes

  // useEffect(() => {
  //   if (
  //     externalPaymentData &&
  //     externalPaymentData.coinbaseCharge
  //   ) {
  //     const { pricing, payments, addresses } = externalPaymentData.coinbaseCharge;
  //     setPaymentAddress(addresses[cryptoCoin]);
  //     console.log(externalPaymentData.coinbaseCharge);
  //     console.log(cryptoCoin);
  //     console.log(paymentAddress);
  //   } else {
  //     console.log("No external payment data available.-------------------------------------");
  //   }
  // }, [externalPaymentData]);

  console.log("active", active);
  const stakeNow = (eplaymentOption = null) => {
    setLoading(true);
    if (eplaymentOption === "gcash") {
      setLoadingGcash(true);
    } else if (eplaymentOption === "select") {
      setLoadingEplayment(true);
    }
    const amount = window.sessionStorage.getItem("stake-amount");
    const id = window.sessionStorage.getItem("stake-package-id");
    const payload = {
      packageId: id,
      amount: amount,
      paymentCurrency: coin?.currency ?? "PHP",
      paymentMethod: paymentMethod,
      eplaymentOption: eplaymentOption ? eplaymentOption : undefined,
      provider: coin?.currency === "PHP" ? "eplayment" : "moneycollect",
    };

    privateRequest
      .post("user/stacking/subscribe", payload)
      .then((res) => {
        if (paymentMethod === "fiat") {
          const paymentUrl = res.data.subscription.paymentMeta.paymentUrl;
          console.log(paymentUrl);
          window.location.href = paymentUrl; // Redirect happens here
          setLoadingGcash(false);
          setLoadingEplayment(false);
        } else {
          navigate("/stake/done", { state: { data: res.data.subscription } });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.message ?? "Something went wrong");
        setLoading(false);
      });
  };
  //console.log(CurrencyCoins);
  const { data: wallets } = useGetWallet();
  if (loading && provider === "eplayment") {
    return (
      <div className="flex justify-center items-center w-full h-screen bg-[#1A1A1B]">
        <div className="text-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-green-900 mx-auto"></div>
          <p className="text-white text-lg mt-3">
            Getting you to the payment processor page, please wait...
          </p>
        </div>
      </div>
    );
  }
  // const handleItemClick = async (selectedItem) => {
  //   // getConversion();
  //   setCurrencyCode(selectedItem !== null ? selectedItem.heading : Coin[0].heading);
  //   // const { pricing, payments, addresses } = externalPaymentData.coinbaseCharge;
  //   // console.log(addresses)
  //   // setPaymentAddress(addresses[selectedItem.crypto]);
  // };

  const handleItemClick = async (selectedItem) => {
    setCurrencyCode(
      selectedItem !== null ? selectedItem.heading : Coin[0].heading
    );
  };

  const handleCryptClick = async (selectedItem) => {
    getConversion();
    setCurrencyCode(
      selectedItem !== null ? selectedItem.heading : Coin[0].heading
    );
    const { pricing, payments, addresses } = externalPaymentData.coinbaseCharge;
    console.log(externalPaymentData);
    setPaymentAddress(addresses[selectedItem.crypto]);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen bg-[#1A1A1B]">
        <div className="text-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-green-900 mx-auto"></div>
          <p className="text-white text-lg mt-3">
            Getting you to the payment processor page, please wait...
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="flex-1">
      <HeaderStaker
        headerType="Staker"
        login={login}
        settypeShowAside={1}
        setsigninState={setsigninState}
        setSignUpState={setSignUpState}
      />
      <div className="body-area flex-1 px-6 pt-6 DepositePopUp">
        <div className="px-10 py-6 bg-[#1A1A1B] border-[1px] rounded-xl border-[#3C403F]  sm:px-5">
          <div className="flex items-center gap-8 mb-5 sm:gap-2 sm:flex-col sm:items-start">
            <h1 className="text-white text-[22px] font-arabicmed">
              Payment method
            </h1>
            <SwitchPayment active={active} classStyle="sm:ml-0 sm:mt-0 " />
          </div>

          {/* <div className="flex items-center justify-between xs:flex-col"> */}
          {/* <div className="mb-8 relative">
            <h1 className="text-[18px] sm:text-[14px] font-arabicmed text-white">
              Select Cryptocurrency
            </h1>
            <PayDropdown
              stakeFix
              dollar={Coin[0].img}
              heading={Coin[0].heading}
              list={Coin}
              callback={handleItemClick}
            />
          </div> */}
          {!showExternalPaymentMethod && (
            <>
              {/* <p className="text-[24px] text-[#6FCF17] my-2">
                {conversionLoading
                  ? "Updating..."
                  : `${fiatAmount} ${currencyCode}`}
              </p> */}
              <div className="p-6 mb-3 rounded-lg">
                <h4 className="text-xl mb-5 text-white">We accept</h4>
                <img src={banner13} className="w-3/4 md:full rounded-lg" alt="Crypto Payments" />
              </div>
              <div className="flex space-x-4 pb-4 buttons-wrapper">
                <button
                  className="active px-20 !text-lg transition duration-200 ease-in-out"
                  onClick={() => GeneratePayment("none")}
                  disabled={loading}
                >
                  {showExternalPaymentMethod
                    ? "Processing..."
                    : "Pay Now"}
                </button>
              </div>
            </>
          )}
          {showExternalPaymentMethod && (
            <>
              {/* <div className="flex justify-center items-center">
                <div className="mx-full text-center justify-center px-10 py-6 bg-[#111010] border-[1px] rounded-xl border-[#3C403F] sm:px-5 sm:w-11/12">
                  <p className="text-[24px] text-[#6FCF17] my-2">
                    {conversionLoading
                      ? "Updating..."
                      : `${fiatAmount} ${currencyCode}`}
                  </p>
                  <div>
                    <div className="mx-full flex justify-center">
                      <QRCode
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          padding: "10px",
                          background: "#FFFFF",
                        }}
                        title="Referral Link"
                        value={paymentAddress} // Use the dynamic QR code value
                        bgColor="#FFFFFF"
                        fgcolor="#6CE4BA"
                        size="350"
                        level="L"
                        viewBox={`0 0 256 256`}
                      />
                    </div>
                    <a
                      className="text-white text-[16px] sm:text-[12px]"
                      href={paymentAddress}
                      target="_blank"
                    >
                      {paymentAddress}
                    </a>
                  </div>
                  <button className="w-1/2 py-2 my-2 bg-[#252525] text-white rounded-md text-[16px] sm:text-[12px] sm:px-2 sm:w-full">
                    Awaiting Payment From You
                  </button>
                </div>
              </div> */}
            </>
          )}

          {active === 2 ? (
            currencyCode === "PHP" ? (
              <div className="flex space-x-4 !mt-12 mb-10 p-4 buttons-wrapper">
                <button
                  className="active px-4 !text-lg hover:bg-blue-500 hover:text-white hover:scale-105 transition duration-200 ease-in-out"
                  onClick={() => stakeNow("select")}
                  disabled={loadingEplayment}
                >
                  {loadingEplayment ? "Processing..." : "Pay via Eplayment"}
                </button>
              </div>
            ) : (
              <div className="flex space-x-4 !mt-12 mb-10 p-4 buttons-wrapper">

                <button
                  className="active px-20 !text-lg hover:bg-blue-500 hover:text-white hover:scale-105 transition duration-200 ease-in-out"
                  onClick={() => stakeNow("none")}
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Pay Now"}
                </button>
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};
