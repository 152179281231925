import { useMemo, useState } from "react";
import { HiMiniXMark } from "react-icons/hi2";
import { MdStarRate } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import card1 from "../../assets/img/bonus-dashboard/bonus-card-1.png";
import card2 from "../../assets/img/bonus-dashboard/bonus-card-2.png";
import card3 from "../../assets/img/bonus-dashboard/bonus-card-3.png";
import card4 from "../../assets/img/bonus-dashboard/bonus-card-4.png";
import card5 from "../../assets/img/bonus-dashboard/bonus-card-5.png";
import card6 from "../../assets/img/bonus-dashboard/bonus-card-6.png";
import claim from "../../assets/img/bonus-dashboard/claim.png";
import grayStar from "../../assets/img/bonus-dashboard/gray-star.png";
import info from "../../assets/img/bonus-dashboard/info.png";
import unclaim from "../../assets/img/bonus-dashboard/unclaim.png";
import Tooltip from "../../components/common/Tooltip";
import {
  useCashBackStatistics,
  useClaimCashback,
  useStats,
  useUnClaimedBonuses,
  useVipLevelBonus,
} from "../../queries/bonus";
import { getStats } from "../../utils/modules/Stats";
import { Header } from "../Header";
import { SidebarArea } from "../SidebarArea";
import BonusStats from "./BonusStats";
import CashbackCard from "./CashbackCard";
import AmountHistoryModal from "./amountHistory";
const BonusDashboardPage = ({
  setsigninState,
  setSignUpState,
  login,
  balance,
}) => {
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const [showAmontHistory, setShowAmountHistory] = useState(false);
  const [cashBackType, setCashBackType] = useState("");
  const [typeShowAside, settypeShowAside] = useState(-1);
  const [showClaimButton, setShowClaimButton] = useState(false);
  const [timeUntilNextSaturday, setTimeUntilNextSaturday] = useState(0);
  const { data } = useVipLevelBonus();
  const { data: cashBackStats, error: cashBackStatsError } =
    useCashBackStatistics();
  const queryClient = useQueryClient();
  const { data: stats, error: statsError } = useStats();
  const claimCashbackBonus = useClaimCashback();
  const { data: monthlyCashback } = useUnClaimedBonuses("monthly_cashback");
  const { data: weeklyCashback } = useUnClaimedBonuses("weekly_cashback");
  const { data: sportsWeeklyCashback } = useUnClaimedBonuses(
    "sports_weekly_cashback"
  );
  const { data: vipLevelReward } = useUnClaimedBonuses("vip_level_reward");
  const bonusStats = data?.levelAmount[0]?.totalAmount;
  // const bonusStats = 7500;
  const getStatsLevel = useMemo(() => {
    return getStats(Math.floor(bonusStats));
  }, [bonusStats]);
  const isStartingOut = bonusStats < 5000;

  console.log("Bonus ", getStatsLevel);
  // claimCashback
  const claimCashback = async () => {
    setDisable(true);

    try {
      await toast.promise(claimCashbackBonus.mutateAsync(cashBackType), {
        pending: `Claiming Cashback`,
        success: "Cashback Claimed",
        error: (error) => error.error,
      });

      setShowAmountHistory(false);
      queryClient.invalidateQueries(["unClaimedBonus"]);
    } catch (error) {
      setShowAmountHistory(false);
    } finally {
      setDisable(false);
    }
  };

  return (
    <>
      {showAmontHistory && (
        <>
          <AmountHistoryModal
            onClose={(e) => setShowAmountHistory(false)}
            amount={vipLevelReward?.totalUnclaimed}
            claimCashback={claimCashback}
            title={cashBackType}
          />
        </>
      )}
      <div className="flex-1 overflow-hidden DepositePopUp">
        <ToastContainer position="bottom-right" theme="dark" autoClose={3000} />
        <Header
          login={login}
          headerType="player"
          settypeShowAside={settypeShowAside}
          setsigninState={setsigninState}
          setSignUpState={setSignUpState}
        />
        <div className="body-area flex gap-2 affiliate-body-area items-start px-6 pt-6">
          <div className="body-left-area w-full">
            <div className="flex flex-wrap w-full bg-[#1A1A1B] border-[1px] border-[#3C403F] rounded-xl p-5">
              <div className="flex-1 flex justify-between pl-6 pr-10 lg:flex-col sm:mx-auto">
                <div className="inline-flex justify-center items-center flex-col">
                  <div className="flex flex-wrap gap-x-2 items-center mb-2">
                    <img src={claim} alt="Claim" className="w-4 h-4" />
                    <p className="text-[14px] text-white text-medium">
                      Total Bonus Claimed
                    </p>
                    <div className="inline-flex relative group">
                      <img src={info} alt="Info" />
                      <Tooltip
                        message='The "Claimed Bonus" in Gamebox refers to the aggregate of all bonuses a player has successfully claimed while engaging with the platform. This total reflects the rewards and incentives the player has accumulated over time.'
                        classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center"
                      />
                    </div>
                  </div>
                  <div className="text-[18px] font-bold text-[#6FCF17]">
                    ${balance?.claimedBonus.toFixed(2) ?? 0.0}
                  </div>
                </div>
                <div className="inline-flex justify-center items-center flex-col">
                  <div className="flex flex-wrap gap-x-2 items-center mb-2">
                    <img src={unclaim} alt="Claim" className="w-4 h-4" />
                    <p className="text-[14px] text-white text-medium capitalize">
                      Unclaimed Bonus
                    </p>
                    <div className="inline-flex relative group">
                      <img src={info} alt="Info" />
                      <Tooltip
                        message='The "Unclaimed Bonus" in Gamebox represents the total bonuses accumulated by a player that are yet to be claimed. To unlock and claim these bonuses, the player is required to meet certain wagering requirements set by Gamebox.'
                        classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center"
                      />
                    </div>
                  </div>
                  <div className="text-[18px] font-bold text-[#464646]">
                    ${balance?.claimableBonus.toFixed(2) ?? 0.0}
                  </div>
                </div>
                <div className="inline-flex justify-center items-center flex-col">
                  <button
                    className="h-10 px-5 bg-[#252525] rounded-lg inline-flex items-center justify-start text-white text-[14px] font-medium sm:mt-4 hover:bg-[#3C403F]"
                    onClick={() => navigate("/bonus-dashboard/details")}
                  >
                    Details
                  </button>
                </div>
              </div>
              <div className="inline-flex items-center gap-x-3 px-8 py-2 border-l border-[#3C403F] sm:border-l-0 lg:flex-col sm:mx-auto">
                <button className="inline-flex h-[42px] items-center px-2.5 rounded-[10px] text-base font-medium text-white bg-gradient-to-b from-[#9435E2] to-[#3733B9] hover:from-[#804ba8] hover:to-[#262297] sm:order-2 my-2">
                  Redeem Bonus Code
                </button>
                <input
                  type="text"
                  placeholder="Type your code here"
                  className="h-[42px] bg-[#2E2E2E] border border-[#3C403F] w-[200px] px-5 text-center rounded-lg text-[#AEB2B1] text-[14px] font-normal my-2 focus:outline-nonesm:order-1"
                />
              </div>
            </div>

            {/* <div className="flex flex-wrap w-full bg-[#1A1A1B] border-[1px] border-[#3C403F] rounded-xl p-2.5 mt-7  lg:flex-col"> */}
            <div className="flex justify-left flex-wrap w-full bg-[#1A1A1B] border-[1px] border-[#3C403F] rounded-xl p-2.5 mt-7">
              <div className="p-2.5 w-1/3 sm:w-full md:min-w-1/2 ">
                <div className="flex-col relative flex-1 z-20 rounded-[20px]">
                  <img
                    src={card6}
                    alt="Card 1"
                    className="-z-10 rounded-[20px] absolute w-full h-full object-cover object-center"
                  />
                  <div className="p-5 bg-transparent flex-col w-full">
                    <div className="inline-flex items-center mb-1.5">
                      <h4 className="font-medium text-[18px] text-white">
                        Level Up Reward
                      </h4>
                      <div className="inline-flex ml-2 relative group">
                        <img src={info} alt="Info" />
                        <Tooltip
                          message="Level up Reward info"
                          classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center"
                        />
                      </div>
                    </div>
                    <p className="text-[10px] text-[#AEB2B1] font-normal">
                      Level up to get exclusive rewards
                    </p>
                    <p className="text-[10px] text-[#AEB2B1] font-normal">
                      Current VIP
                    </p>
                    <div className="inline-flex items-center mt-[18px] w-full">
                      <p className="text-[10px] text-[#AEB2B1] font-normal w-[130px]">
                        Next Level
                      </p>
                      <p className="text-[10px] text-white font-bold">
                        {isStartingOut ? (
                          <img src={grayStar} alt="1 Star" className="h-5" /> // Show 1 gray star for the first level
                        ) : (
                          <>
                            <div className="flex">
                              {Array.from(
                                { length: getStatsLevel.nextLevel },
                                (value) => value
                              ).map((_, index) => {
                                return (
                                  <>
                                    <MdStarRate />
                                  </>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </p>
                    </div>
                    <br />
                    <div className="inline-flex items-center w-full">
                      <p className="text-[10px] text-[#AEB2B1] font-normal w-[130px]">
                        Ready to claim
                      </p>
                      <p className="text-[10px] text-white font-bold">
                        ${vipLevelReward?.totalUnclaimed ?? 0}
                      </p>
                    </div>
                    {vipLevelReward?.totalUnclaimed > 0 ? (
                      <>
                        <button
                          className="py-1 mt-8 px-5 bg-gradient-to-b from-[#F8D634] to-[#D08A1A] rounded-lg flex items-center justify-start text-white text-[14px] font-medium"
                          disabled={disable}
                          onClick={() => {
                            setShowAmountHistory(true);
                            setCashBackType("vip_level_reward");
                          }}
                        >
                          Claim Reward
                        </button>
                      </>
                    ) : (
                      <>
                        <br />
                        <br />
                        <br />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <CashbackCard
                image={card1}
                title="Monthly Cashback"
                duration="25th"
                info={info}
                type="monthly_cashback"
                cashbackData={monthlyCashback}
                tootipContent="Monthly Cashback is a bonus that is airdropped to players on the 25th of each month. It is calculated based on the player's activities and engagement over the entire month. This bonus serves as a reward for the player's consistent participation throughout the month."
              />
              <CashbackCard
                image={card2}
                title="Weekly Cashback"
                duration="Saturday"
                info={info}
                type="weekly_cashback"
                cashbackData={weeklyCashback}
                tootipContent="Weekly Cashback refers to a bonus airdropped to a player every Saturday, based on their weekly activity. This bonus acts as a reward, reflecting the player's engagement and actions over the week."
              />
              <CashbackCard
                image={card4}
                title="Sports Weekly Bonus"
                duration="Saturday"
                info={info}
                type="sports_weekly_cashback"
                cashbackData={sportsWeeklyCashback}
                tootipContent="Maximize your weekend excitement at the casino with our Sports Weekly Bonus every Saturday. Wager 100GBC for exclusive bonuses, turning your gaming into a winning experience!"
              />

              <div className="p-2.5 w-1/3 sm:w-full md:min-w-1/2 ">
                <div className="flex-col relative flex-1 z-20 rounded-[20px]">
                  <img
                    src={card5}
                    alt="Card 1"
                    className="-z-10 rounded-[20px] absolute w-full h-full object-cover object-center"
                  />
                  <div className="p-5 bg-transparent flex-col w-full">
                    <div className="inline-flex items-center mb-1.5">
                      <h4 className="font-medium text-[18px] text-white">
                        Deposit Bonus
                      </h4>
                      <div className="inline-flex ml-2 relative group">
                        <img src={info} alt="Info" />
                        <Tooltip
                          message="A deposit bonus from Gamebox is a generous reward given to players each time they make a deposit, up to three times. This bonus enhances the value of their deposits, providing additional funds for gaming."
                          classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center"
                        />
                      </div>
                    </div>
                    <div className="inline-flex items-center mt-[18px] w-full">
                      <p className="text-[10px] text-[#AEB2B1] font-normal w-[130px]">
                        1st deposit (100%)
                      </p>
                      <p className="text-[10px] font-bold">
                        {stats?.stats?.[0]?.amount ? (
                          <>
                            <div className="flex text-white items-center justify-between gap-6">
                              ${stats?.stats?.[0]?.amount} <TiTick />
                            </div>
                          </>
                        ) : (
                          <>
                            <HiMiniXMark />
                          </>
                        )}
                      </p>
                    </div>
                    <br />
                    <div className="inline-flex items-center w-full">
                      <p className="text-[10px] text-[#AEB2B1] font-normal w-[130px]">
                        2st deposit (200%)
                      </p>
                      <p className="text-[10px] text-white font-bold">
                        {" "}
                        {stats?.stats[1]?.amount ? (
                          <>
                            <div className="flex items-center justify-between gap-6">
                              ${stats?.stats[1]?.amount} <TiTick />
                            </div>
                          </>
                        ) : (
                          <>
                            0.00
                            {/* <HiMiniXMark /> */}
                          </>
                        )}
                      </p>
                    </div>
                    <div className="inline-flex items-center w-full">
                      <p className="text-[10px] text-[#AEB2B1] font-normal w-[130px]">
                        3st deposit (300%)
                      </p>
                      <p className="text-[10px] text-white font-bold">
                        {" "}
                        {stats?.stats[2]?.amount ? (
                          <>
                            <div className="flex items-center justify-between gap-6">
                              ${stats?.stats[2]?.amount} <TiTick />
                            </div>
                          </>
                        ) : (
                          <>
                            0.00
                            {/* <HiMiniXMark /> */}
                          </>
                        )}
                      </p>
                    </div>
                    <br />
                    <br />
                    <br />
                    {/* <div className="w-full mt-6 rounded-md bg-[#121212] h-[30px] flex items-center justify-center text-white text-[10px] font-normal">
                    End in &nbsp; <Countdown date={Date.now() + 50000} />
                  </div> */}
                  </div>
                </div>
              </div>

              <div className="p-2.5 w-1/3 sm:w-full md:min-w-1/2">
                <div className="flex-col relative flex-1 z-20 rounded-[20px]">
                  <img
                    src={card3}
                    alt="Card 1"
                    className="-z-10 rounded-[20px] absolute w-full h-full object-cover object-center"
                  />
                  <div className="p-5 bg-transparent flex-col w-full">
                    <div className="inline-flex items-center mb-1.5">
                      <h4 className="font-medium text-[18px] text-white">
                        Rakeback
                      </h4>
                      <div className="inline-flex ml-2 relative group">
                        <img src={info} alt="Info" />
                        <Tooltip
                          message="Rakeback is a bonus given back to players each time they place a bet. The amount of rakeback increases with the frequency and size of the bets, rewarding players the more they bet."
                          classes="group-hover:inline-flex !w-[300px] -translate-x-1/2 text-[10px] text-center"
                        />
                      </div>
                    </div>
                    <p className="text-[10px] text-[#AEB2B1] font-normal">
                      extra rewards for every wager
                    </p>
                    <div className="inline-flex items-center mt-[18px] w-full">
                      <p className="text-[10px] text-[#AEB2B1] font-normal w-[130px]">
                        Minimum to claim
                      </p>
                      <p className="text-[10px] text-white font-bold">$5</p>
                    </div>
                    <br />
                    <div className="inline-flex items-center w-full">
                      <p className="text-[10px] text-[#AEB2B1] font-normal w-[130px]">
                        Last week received
                      </p>
                      <p className="text-[10px] text-white font-bold">$0</p>
                    </div>
                    {/* <div className="w-full mt-6 rounded-md bg-[#121212] h-[30px] flex items-center justify-center text-white text-[10px] font-normal">
                    End in &nbsp; <Countdown date={Date.now() + 500000} />
                  </div> */}
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <BonusStats vipLevelBonus={data?.levelAmount[0]} />
          </div>
          <SidebarArea typeShowAside={typeShowAside} />
        </div>
      </div>
    </>
  );
};

export default BonusDashboardPage;
